import React, { useCallback } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { useActivation } from "controllers";
import { LandingPageLayout } from "layouts";
import {
  Button,
  CardBackButton,
  Field,
  FieldErrorMessage,
  FieldLabel,
  Input,
  LandingPageCard,
  LandingPageCardTitle,
  LandingPageForm,
  LandingPageFormMetaLink,
  LandingPageFormMetaText,
  LandingPageHelpLink,
  LandingPageHelpText,
  ProfileImageUpload,
  SimpleLink,
} from "components";
import { getEnv } from "helpers";
import { ActivationParams, routes } from "routes";

interface Props extends RouteComponentProps<ActivationParams> {}

export const Activation = ({ match }: Props) => {
  const { t, tk, media, data, state, handlers } = useActivation(match.params.step);

  const back = useCallback(() => handlers.goToStep(state.step === "3" ? "2" : "1", false), [handlers, state.step]);

  return (
    <LandingPageLayout>
      <LandingPageCard>
        {state.step === "1" && <LandingPageCardTitle>{t(tk.activation.heading)}</LandingPageCardTitle>}

        {state.step === "2" && <CardBackButton onClick={back}>{t(tk.activation.back)}</CardBackButton>}

        <LandingPageForm onSubmit={handlers.handleSubmit}>
          {/** Profile image */}
          {state.step === "3" && !!data?.user && (
            <ProfileImageUpload media={media} defaultSrc={data.user.profileImage} />
          )}

          {/** Activation code */}
          {state.step === "1" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.activationCode.label)}</FieldLabel>
              <Input
                value={state.activationCode}
                placeholder={t(tk.activation.form.activationCode.placeholder)}
                type={"text"}
                required={true}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangeActivationCode}
              />
              <FieldErrorMessage>{state.error}</FieldErrorMessage>
            </Field>
          )}

          {/** Password */}
          {state.step === "2" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.password.label)}</FieldLabel>
              <Input
                value={state.password}
                placeholder={t(tk.activation.form.password.placeholder)}
                type={"password"}
                required={true}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangePassword}
              />
            </Field>
          )}

          {/** Password confirmation */}
          {state.step === "2" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.passwordConfirmation.label)}</FieldLabel>
              <Input
                value={state.passwordConfirmation}
                placeholder={t(tk.activation.form.passwordConfirmation.placeholder)}
                type={"password"}
                required={true}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangePasswordConfirmation}
              />
              <FieldErrorMessage>{state.error}</FieldErrorMessage>
            </Field>
          )}

          {/** First name */}
          {state.step === "3" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.firstName.label)}</FieldLabel>
              <Input
                value={state.firstName}
                placeholder={""}
                type={"text"}
                required={true}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangeFirstName}
              />
            </Field>
          )}

          {/** Last name */}
          {state.step === "3" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.lastName.label)}</FieldLabel>
              <Input
                value={state.lastName}
                placeholder={""}
                type={"text"}
                required={true}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangeLastName}
              />
            </Field>
          )}

          {/** Phone number */}
          {state.step === "3" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.phoneNumber.label)}</FieldLabel>
              <Input
                value={state.phoneNumber}
                placeholder={""}
                type={"text"}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangePhoneNumber}
              />
              {!!state.phoneNumberError && <FieldErrorMessage>{state.phoneNumberError}</FieldErrorMessage>}
            </Field>
          )}

          {/** Email */}
          {state.step === "3" && (
            <Field width={"100%"} margin={"38px 0 0 0"}>
              <FieldLabel>{t(tk.activation.form.email.label)}</FieldLabel>
              <Input
                value={state.email}
                placeholder={""}
                type={"email"}
                required={true}
                width={"100%"}
                isDisabled={state.loading}
                onChange={handlers.handleChangeEmail}
              />
              {!!state.emailError && <FieldErrorMessage>{state.emailError}</FieldErrorMessage>}
            </Field>
          )}

          {/** Submit button */}
          <Button color={"green"} type={"submit"} isDisabled={state.loading} width={"336px"} margin={"28px 0 0 0"}>
            {t(state.step === "1" ? tk.activation.form.submit : tk.common.save)}
          </Button>

          {/** Terms & conditions */}
          {state.step === "1" && (
            <LandingPageFormMetaText>
              {t(tk.activation.termsAndConditions.prefix)}
              &nbsp;
              <LandingPageFormMetaLink>
                <a href={getEnv("REACT_APP_PRIVACY_POLICY_URL")} target="_blank" rel="noopener noreferrer">
                  {t(tk.activation.termsAndConditions.link)}
                </a>
              </LandingPageFormMetaLink>
            </LandingPageFormMetaText>
          )}
        </LandingPageForm>
      </LandingPageCard>

      {state.step === "1" && (
        <>
          {/** Back to login */}
          <LandingPageHelpText>{t(tk.activation.loginLabel)}</LandingPageHelpText>
          <LandingPageHelpLink>
            <Link to={routes.login}>{t(tk.activation.login)}</Link>
          </LandingPageHelpLink>

          {/** Moyobo web link */}
          <LandingPageHelpText>
            <SimpleLink
              href={getEnv("REACT_APP_MOYOBO_WEB_URL")}
              title={""}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {t(tk.common.signUp)}
            </SimpleLink>
          </LandingPageHelpText>
        </>
      )}
    </LandingPageLayout>
  );
};
