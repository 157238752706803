import React, { useContext } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { LanguageSelectContext } from "components/LanguageSelect/LanguageSelect";
import { IconChevron } from "icons";
import { theme } from "styles";

type Props = {
  children?: never;
};

export const LanguageSelectToggleIndicator = (_: Props) => {
  const { isSelectOpen } = useContext(LanguageSelectContext);

  const style = useSpring({
    transform: `rotate3d(0, 0, 1, ${isSelectOpen ? "-180" : "0"}deg)`,
  });

  return (
    <AnimatedWrapper style={style}>
      <IconChevron width={"13px"} height={"8px"} color={theme.languageSelect.toggleIndicatorColor} />
    </AnimatedWrapper>
  );
};

const AnimatedWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  transform: rotate3d(0, 0, 0, 0deg);
  transform-origin: center center;
  flex-shrink: 0;
`;
