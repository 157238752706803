import React from "react";
import styled from "styled-components";
import { Icon } from "components";
import { ChatMember } from "chat/components/groupSettings/GroupSettingsDialog";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

interface Props {
  members: ChatMember[];
  newMembers: ChatMember[];
  isChatOwner: boolean;
  onRemove: (member: ChatMember) => any;
}

export const MembersList = ({ members, newMembers, isChatOwner, onRemove }: Props) => {
  const { t } = useTranslation();

  return (
    <ComponentWrapper>
      <Title>{t(tk.chat.settings.members)}</Title>
      <List>
        {members.map((member) => (
          <ListItem key={member.id}>
            <MemberName>
              {member.firstName} {member.lastName}
            </MemberName>
            {isChatOwner && (
              <MemberControls>
                <RemoveControl onClick={() => onRemove(member)}>
                  <StyledIcon shape={"cancel"} color={theme.colors.IronGrey} />
                </RemoveControl>
              </MemberControls>
            )}
          </ListItem>
        ))}

        {newMembers.map((member) => (
          <ListItem key={member.id}>
            <MemberName>
              {member.firstName} {member.lastName}
            </MemberName>
            <MemberControls>
              <RemoveControl onClick={() => onRemove(member)}>
                <StyledIcon shape={"cancel"} color={theme.colors.IronGrey} />
              </RemoveControl>
            </MemberControls>
          </ListItem>
        ))}
      </List>
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: ${font.weight.bold};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`;

const ListItem = styled.div`
  display: flex;
  font-size: 13px;
  margin: 4px 0;
`;

const MemberName = styled.div`
  align-self: flex-start;
`;

const MemberControls = styled.div`
  align-self: flex-end;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const RemoveControl = styled.a`
  cursor: pointer;
  padding: 1px 3px;
`;
