import styled from "styled-components";
import { Icon, IconShape } from "components/Icon";
import { font, theme } from "styles";
import React from "react";

interface Props {
  label: string;
  icon: IconShape;
  disabled?: boolean;
  meta?: string;
  onClick: React.HTMLAttributes<HTMLDivElement>["onClick"];
}

export const Option = ({ label, icon, disabled, meta, onClick }: Props) => {
  return (
    <OptionWrapper onClick={disabled ? undefined : onClick}>
      <OptionIcon shape={icon} style={{ color: disabled ? theme.colors.IronGrey : theme.colors.TealGreen }} />
      <OptionLabel style={{ color: disabled ? theme.colors.IronGrey : theme.colors.TealGreen }}>{label}</OptionLabel>
      {!!meta && <OptionMeta>{breakLines(meta)}</OptionMeta>}
    </OptionWrapper>
  );
};

const breakLines = (text: string) => {
  return text.split("\n").map((str, key) => (
    <span key={key}>
      {str}
      <br />
    </span>
  ));
};

/**
 * Styles
 */

const OptionWrapper = styled.div`
  border-top: 1px solid ${theme.colors.SilverGrey};
  padding: 24px;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const OptionIcon = styled(Icon)`
  color: ${theme.colors.TealGreen};
  font-size: 23px;
  width: 22px;
  text-align: center;
  margin-right: 15px;
`;

const OptionLabel = styled.span`
  font-weight: ${font.weight.regular};
  font-size: 14px;
  color: ${theme.colors.TealGreen};
`;

const OptionMeta = styled.div`
  font-weight: ${font.weight.regular};
  font-size: 11px;
  color: ${theme.colors.IronGrey};
  text-align: right;
  flex-grow: 1;
`;
