import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { hasActiveSpace } from "helpers";
import { useAuth, useMeValidationLazyQuery } from "api";
import { tk, useTranslation } from "translations";
import { routes } from "routes";

export const useLogin = () => {
  const { t } = useTranslation();

  useTitle(t(tk.login.heading) + " " + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const { isLoggedIn, login, loading, resetStore } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const handleSuccess = useCallback(() => history.push(routes.dashboard), [history]);
  const handleError = useCallback(() => setError(t(tk.login.form.error)), [t]);

  const [meValidation] = useMeValidationLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => (hasActiveSpace(data.me) ? handleSuccess() : setError(t(tk.login.noOrganizationOrSpace))),
    onError: handleError,
  });

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) return;

      await login(email, password, () => meValidation(), handleError);
    },
    [loading, login, email, password, handleError, meValidation]
  );

  const handleChangeEmail = useCallback((value: string) => setEmail(value), []);
  const handleChangePassword = useCallback((value: string) => setPassword(value), []);

  /** Always reset apollo cache */
  useEffect(resetStore, [resetStore]);

  /** If logged in, redirect to dashboard */
  useEffect(() => (isLoggedIn() ? history.push(routes.dashboard) : undefined), [isLoggedIn, history]);

  return {
    t,
    tk,
    state: { loading, email, password, error },
    handlers: { handleSubmit, handleChangeEmail, handleChangePassword },
  };
};
