import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { theme } from "styles";
import { animated, useSpring } from "react-spring";

type Props = {
  children?: never;
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  margin?: string;
};

export const Switch = ({ isChecked, onChange, margin }: Props) => {
  const springStyle = useSpring({
    transform: isChecked ? "translate3d(19px, 0, 0)" : "translate3d(0px, 0, 0)",
  });

  return (
    <Container margin={margin}>
      <Track isChecked={isChecked} />
      <ThumbUnderlay style={springStyle}>
        <Thumb />
      </ThumbUnderlay>
      <StyledInput type={"checkbox"} onChange={onChange} checked={isChecked} />
    </Container>
  );
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "margin">;

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["margin"].includes(prop) })<ContainerProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 49px;
  height: 30px;
  flex-shrink: 0;
  margin: ${(props) => props.margin ?? 0};
  grid-area: switch;
`;

type TrackProps = Pick<Props, "isChecked">;

const Track = styled("div").withConfig({
  shouldForwardProp: (prop) => !["isDisabled", "isChecked"].includes(prop),
})<TrackProps>`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.isChecked ? theme.switch.backgroundColor.active : theme.switch.backgroundColor.default};
  border-radius: 15px;
`;

const ThumbUnderlay = styled(animated.div)`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  top: 4px;
  left: 4px;
  transform-origin: center center;
`;

const Thumb = styled("div")`
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: ${theme.switch.thumb.backgroundColor};
  box-shadow: 0 3px 10px ${theme.switch.thumb.shadowColor};
`;

const StyledInput = styled("input")`
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
`;
