import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { Icon } from "components/Icon";

type Props = {
  onClick: () => void;
  disabled?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export class ReactionButton extends React.PureComponent<Props> {
  render() {
    const { onClick, ...props } = this.props;
    return (
      <StyledButton
        onClick={props.disabled ? () => null : onClick}
        disabled={props.disabled}
        secondary={props.secondary}
        selected={props.selected}
        {...props}
      >
        {this.props.selected && <ButtonIcon shape={"checked"} />}
        {this.props.children}
      </StyledButton>
    );
  }
}

/**
 * Styles
 */

const getButtonBackground = (props: Props) => {
  if (props.disabled) {
    return "#BFC0C2";
  }
  if (props.secondary) {
    return "#008C82";
  }
  if (props.tertiary) {
    return "transparent";
  }
  if (props.selected) {
    return "#20AD76";
  }
  return "#2B729C";
};

const getButtonShadowColor = (props: Props) => {
  if (props.disabled) {
    return "#BFC0C2";
  }
  if (props.secondary) {
    return "#0A6F68";
  }
  if (props.tertiary) {
    return "none";
  }
  if (props.selected) {
    return "#108F5D";
  }
  return "#1E5F85";
};

const getButtonColor = (props: Props) => {
  if (props.tertiary) {
    return "#008C82";
  }
  return "#FFFFFF";
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["secondary", "tertiary", "selected"].includes(prop),
})<Props>`
  background: ${getButtonBackground};
  color: ${getButtonColor};
  text-transform: uppercase;
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 12px;
  padding: 3px 0 0 0;
  box-shadow: 0 3px 0 0 ${getButtonShadowColor};
  text-align: center;
  line-height: 35px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const ButtonIcon = styled(Icon)`
  text-transform: none;
  padding-right: 8px;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
`;
