import React from "react";

type Props = {
  children?: never;
  width?: string | number;
  height?: string | number;
};

export const ColorfulLogo = ({ width = "100%", height = "100%" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 225 51"}
      version={"1.1"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      xmlSpace={"preserve"}
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }}
    >
      <g transform={"matrix(0.999151,0,0,0.98898,0.0954189,0.277903)"}>
        <g>
          <g>
            <g transform={"matrix(1,0,0,1,-48.2,-88.845)"}>
              <path
                d={
                  "M68.444,101.756C68.719,102.005 69.086,102.127 69.455,102.093C69.829,102.09 70.194,101.973 70.499,101.756C72.835,100.201 75.613,99.444 78.415,99.6C86.634,99.6 90.743,103.844 90.743,113.68L90.743,127.053L84.043,127.053L84.043,113.68C84.043,104.787 82.224,101.015 78.451,101.015C74.611,101.015 72.792,104.956 72.792,113.68L72.792,127.053L66.12,127.053L66.12,113.68C66.12,104.787 64.301,101.015 60.528,101.015C56.688,101.015 54.869,104.956 54.869,113.68L54.869,127.053L48.2,127.053L48.2,100.274L53.084,100.274C53.556,100.274 53.926,100.543 54.398,100.543C54.604,100.538 54.808,100.504 55.004,100.443C56.777,99.867 58.631,99.583 60.495,99.601C63.863,99.6 66.491,100.307 68.444,101.756Z"
                }
                style={{ fill: "rgb(0,166,154)", fillRule: "nonzero" }}
              />
            </g>
            <g transform={"matrix(1,0,0,1,-142.443,-88.257)"}>
              <path
                d={
                  "M221.863,112.852C221.863,122.545 216.575,126.871 205.998,126.871C195.051,126.871 190.198,122.612 190.198,112.986C190.198,103.193 195.385,99.068 205.998,98.799C216.642,98.8 221.863,103.126 221.863,112.852ZM196.836,112.986C196.836,121.672 199.767,125.596 205.964,125.596C212.161,125.596 215.092,121.672 215.092,112.986C215.092,104.199 212.161,100.376 205.964,100.376C199.867,100.376 196.836,104.3 196.836,112.987L196.836,112.986Z"
                }
                style={{ fill: "rgb(0,166,154)", fillRule: "nonzero" }}
              />
            </g>
            <g transform={"matrix(1,0,0,1,-213.258,-90.501)"}>
              <path
                d={
                  "M316,140.939L309.33,140.939L309.33,128.779C301.852,125.848 296.9,112.712 296.9,102L303.569,102L303.569,102.3C303.569,108.161 305.051,128.069 312.664,128.069C320.277,128.069 321.792,109.069 321.792,102.3L321.792,102L328.462,102C328.462,113.554 323.477,125.95 315.999,128.678L316,140.939Z"
                }
                style={{ fill: "rgb(251,140,0)", fillRule: "nonzero" }}
              />
            </g>
            <g transform={"matrix(1,0,0,1,-284.007,-88.257)"}>
              <path
                d={
                  "M435.163,112.852C435.163,122.545 429.875,126.871 419.298,126.871C408.351,126.871 403.498,122.612 403.498,112.986C403.498,103.193 408.685,99.068 419.298,98.799C429.942,98.8 435.163,103.126 435.163,112.852ZM410.136,112.986C410.136,121.672 413.067,125.596 419.264,125.596C425.461,125.596 428.392,121.672 428.392,112.986C428.392,104.199 425.461,100.376 419.264,100.376C413.167,100.376 410.136,104.3 410.136,112.987L410.136,112.986Z"
                }
                style={{ fill: "rgb(251,140,0)", fillRule: "nonzero" }}
              />
            </g>
            <g transform={"matrix(1,0,0,1,-356.615,-67.5)"}>
              <path
                d={
                  "M512.9,67.5L519.57,67.5L519.57,79.66C522.474,78.515 525.578,77.965 528.698,78.043C539.342,78.043 544.563,82.388 544.563,92.123C544.563,101.858 539.376,106.17 528.7,106.2L528.094,106.2C525.516,106.27 522.945,105.918 520.481,105.156C520.281,104.988 520.043,105.056 519.807,105.056C519.066,105.056 518.594,105.696 517.786,105.696L512.9,105.696L512.9,67.5ZM519.57,92.157C519.57,100.881 522.501,104.822 528.698,104.822C534.895,104.822 537.826,100.881 537.826,92.157C537.826,83.332 534.895,79.492 528.698,79.492C522.6,79.458 519.57,83.4 519.57,92.157Z"
                }
                style={{ fill: "rgb(55,128,255)", fillRule: "nonzero" }}
              />
            </g>
            <g transform={"matrix(1,0,0,1,-429.354,-88.257)"}>
              <path
                d={
                  "M654.163,112.852C654.163,122.545 648.875,126.871 638.298,126.871C627.351,126.871 622.498,122.612 622.498,112.986C622.498,103.193 627.685,99.068 638.298,98.799C648.942,98.8 654.163,103.126 654.163,112.852ZM629.136,112.986C629.136,121.672 632.067,125.596 638.265,125.596C644.463,125.596 647.393,121.672 647.393,112.986C647.393,104.199 644.462,100.376 638.265,100.376C632.167,100.376 629.136,104.3 629.136,112.987L629.136,112.986Z"
                }
                style={{ fill: "rgb(55,128,255)", fillRule: "nonzero" }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
