import styled from "styled-components";

export const HeaderDropdownMenu = styled("ul")`
  display: flex;
  position: relative;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 300px;
  max-width: 523px;
`;
