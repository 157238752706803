import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { CloseButton, DialogButton, DialogScreen, Field, FieldLabel, Input } from "components";
import { MembersSearch } from "chat/components/groupSettings/MembersSearch";
import { MembersList } from "chat/components/groupSettings/MembersList";
import { useDashboardState, useMe } from "hooks";
import {
  mapConnection,
  resolveError,
  useChatAddUsersMutation,
  useChatCreateMutation,
  useChatGroupSettingsQuery,
  useChatRemoveUsersMutation,
  useChatSetNameMutation,
} from "api";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

export type ChatMember = {
  id: string;
  firstName: string;
  lastName: string;
};

interface Props {
  chat?: string;
  onClose: () => any;
  onChange: (chat: string) => any;
}

export const GroupSettingsDialog = ({ chat, onClose, onChange }: Props) => {
  const { t } = useTranslation();
  const { organization } = useDashboardState();
  const { me } = useMe();

  const [name, setName] = useState("");
  const [membersToAdd, setMembersToAdd] = useState<ChatMember[]>([]);
  const [membersToRemove, setMembersToRemove] = useState<ChatMember[]>([]);

  const { data, loading: loadingGroupSettings } = useChatGroupSettingsQuery({
    variables: { id: chat || "" },
    fetchPolicy: "cache-and-network",
    skip: !chat,
  });

  const [createMutation, { loading: loadingCreate }] = useChatCreateMutation();
  const [addUsersMutation, { loading: loadingAddUsers }] = useChatAddUsersMutation();
  const [removeUsersMutation, { loading: loadingRemoveUsers }] = useChatRemoveUsersMutation();
  const [setNameMutation, { loading: loadingSetName }] = useChatSetNameMutation();

  const loading = useMemo(
    () => loadingGroupSettings || loadingCreate || loadingAddUsers || loadingRemoveUsers || loadingSetName,
    [loadingAddUsers, loadingCreate, loadingGroupSettings, loadingRemoveUsers, loadingSetName]
  );

  const isEditMode = !!chat;

  const isChatOwner = useMemo(() => !isEditMode || me?.userId === data?.chat?.createdBy.id, [data, isEditMode, me]);

  const interactions = useMemo(() => {
    if (data?.chat?.name) setName(data.chat.name);

    return mapConnection(data?.chat?.interactions).filter(
      ({ user, archivedAt }) => user.id !== me?.userId && !archivedAt
    );
  }, [data, me]);

  const membersWithoutRemoved = useMemo(() => {
    return interactions.filter(({ user }) => !membersToRemove.some(({ id }) => id === user.id)).map(({ user }) => user);
  }, [interactions, membersToRemove]);

  const addedMembers = useMemo(
    () => [...membersToAdd, ...membersWithoutRemoved],
    [membersToAdd, membersWithoutRemoved]
  );

  const toggleMember = useCallback(
    (member: ChatMember) => {
      const test = (m: ChatMember) => m.id === member.id;

      if (membersToRemove.some(test)) return setMembersToRemove(membersToRemove.filter((m) => !test(m)));

      if (membersWithoutRemoved.some(test)) return setMembersToRemove([...membersToRemove, member]);

      if (membersToAdd.some(test)) return setMembersToAdd(membersToAdd.filter((m) => !test(m)));

      setMembersToAdd([...membersToAdd, member]);
    },
    [membersToAdd, membersToRemove, membersWithoutRemoved]
  );

  const edit = useCallback(async () => {
    const membersAdded = membersToAdd.length > 0;
    const membersRemoved = membersToRemove.length > 0;
    const nameChanged = name !== (data?.chat?.name || "");

    if (!chat || (!membersAdded && !membersRemoved && !nameChanged)) return;

    try {
      let chatId = chat;

      if (membersAdded) {
        const { data } = await addUsersMutation({
          variables: { input: { chat: chatId, users: membersToAdd.map(({ id }) => id) } },
        });
        chatId = data?.chatAddUsers.chat.id || chatId;
      }

      if (membersRemoved) {
        const { data } = await removeUsersMutation({
          variables: { input: { chat: chatId, users: membersToRemove.map(({ id }) => id) } },
        });
        chatId = data?.chatRemoveUsers.chat.id || chatId;
      }

      if (nameChanged) {
        await setNameMutation({ variables: { input: { chat: chatId, name } } });
      }

      onChange(chatId);
    } catch (e: any) {
      resolveError(e);
    }
  }, [
    addUsersMutation,
    chat,
    data,
    membersToAdd,
    membersToRemove,
    name,
    onChange,
    removeUsersMutation,
    setNameMutation,
  ]);

  const create = useCallback(async () => {
    if (!membersToAdd.length || !me?.userId) return;

    try {
      const users = [me.userId, ...membersToAdd.map(({ id }) => id)];

      const { data } = await createMutation({
        variables: { input: { organization, users, name: name || null } },
      });

      if (data?.chatCreate.chat.id) onChange(data.chatCreate.chat.id);
    } catch (e: any) {
      resolveError(e);
    }
  }, [createMutation, me, membersToAdd, name, onChange, organization]);

  const submit = useCallback(async () => {
    if (isEditMode) return await edit();

    await create();
  }, [create, edit, isEditMode]);

  return (
    <DialogScreen>
      <DialogContainer>
        <HeaderSection>
          {isEditMode ? t(tk.chat.settings.groupSettings) : t(tk.chat.settings.createNewGroup)}
          <DialogCloseButton onClick={onClose} />
        </HeaderSection>
        <GroupNameSection>
          <Field width={"100%"} isDisabled={loading}>
            <FieldLabel>{t(tk.chat.settings.name)}</FieldLabel>
            <Input
              value={name}
              placeholder={t(tk.chat.settings.namePlaceholder)}
              isDisabled={loading}
              width={"100%"}
              onChange={setName}
            />
          </Field>
        </GroupNameSection>
        <MembersSection>
          <MembersSearchBlock>
            <MembersSearch addedMembers={addedMembers} onToggleMember={toggleMember} />
          </MembersSearchBlock>
          <MembersListBlock>
            <MembersList
              members={membersWithoutRemoved}
              newMembers={membersToAdd}
              isChatOwner={isChatOwner}
              onRemove={toggleMember}
            />
          </MembersListBlock>
        </MembersSection>
        <ButtonsSection>
          <DialogButton onClick={submit}>
            {isEditMode ? t(tk.common.saveChanges) : t(tk.chat.settings.createGroup)}
          </DialogButton>
        </ButtonsSection>
      </DialogContainer>
    </DialogScreen>
  );
};

/**
 * Styles
 */

const DialogContainer = styled.div`
  width: 870px;
  border-radius: 5px;
  background-color: ${theme.colors.SnowWhite};
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${theme.colors.NightSky};
  box-sizing: border-box;
`;

const Section = styled.div`
  border-top: 2px solid ${theme.colors.SilverGrey};

  &:first-child {
    border-top: none;
  }
`;

const HeaderSection = styled(Section)`
  padding: 30px;
  font-weight: ${font.weight.bold};
`;

const DialogCloseButton = styled(CloseButton)`
  position: absolute;
  top: 26px;
  right: 24px;
`;

const GroupNameSection = styled(Section)`
  padding: 25px 30px;
`;

const MembersSection = styled(Section)`
  display: flex;
  flex-direction: row;
`;

const MembersSearchBlock = styled.div`
  padding: 22px 0 0;
  flex-grow: 1;
`;

const MembersListBlock = styled.div`
  padding: 22px 20px;
  width: 310px;
  background-color: #f6f6f6;
  max-height: 339px;
  overflow-y: scroll;
`;

const ButtonsSection = styled(Section)`
  padding: 15px;
  text-align: right;
`;
