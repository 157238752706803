import React, { useContext } from "react";
import styled from "styled-components";
import { ImagePlaceholder } from "components/ImagePlaceholder";
import { ListItemContext } from "components/ListItem/ListItem";

type Props = {
  children?: never;
  src: string;
  alt: string;
  width?: "50px" | "40px" | string;
  height?: "55px" | "45px" | string;
};

export const ListItemImage = ({ src, alt, width, height }: Props) => {
  const { isDisabled } = useContext(ListItemContext);

  return (
    <Container width={width} height={height} isDisabled={isDisabled || false}>
      {Boolean(src) ? <StyledImage src={src} alt={alt} /> : <ImagePlaceholder />}
    </Container>
  );
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "width" | "height"> & { isDisabled: boolean };

const Container = styled("div").withConfig({
  shouldForwardProp: (prop) => !["isDisabled", "width", "height"].includes(prop),
})<ContainerProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width ?? "50px"};
  height: ${(props) => props.height ?? "55px"};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  flex-shrink: 0;
`;

const StyledImage = styled("img")`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
