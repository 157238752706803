import React from "react";
import styled from "styled-components";
import { IconDocument } from "icons";
import { font, theme } from "styles";

type Props = {
  fileName: string;
  fileMeta: string;
  fileLink: string;
};

export class DetailFileDetail extends React.PureComponent<Props> {
  render() {
    return (
      <DetailContainer href={this.props.fileLink} rel={"noopener noreferrer"} target={"_blank"}>
        <FileIconSection>
          <Icon width={"24px"} height={"31px"} color={theme.colors.IronGrey} />
        </FileIconSection>

        <FileDetailsSection>
          <FileName>{this.props.fileName}</FileName>
          <FileMeta>{this.props.fileMeta}</FileMeta>
        </FileDetailsSection>
      </DetailContainer>
    );
  }
}

/**
 * Styles
 */

const DetailContainer = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  text-decoration: none;
`;

const FileIconSection = styled.div`
  display: flex;
  position: relative;
`;

const Icon = styled(IconDocument)`
  width: 24px;
  height: 32px;
`;

const FileDetailsSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 15px;
`;

const FileName = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: ${font.weight.bold};
  color: ${theme.colors.TealGreen};
  line-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileMeta = styled.div`
  position: relative;
  font-weight: ${font.weight.medium};
  font-size: 12px;
  color: ${theme.colors.IronGrey};
  padding-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
