import React, { useCallback, useMemo } from "react";
import { Window } from "chat/components/Window";
import { Conversation } from "chat/components/conversation/Conversation";
import { Invitation } from "chat/components/conversation/Invitation";
import { useChatQuery } from "api";

interface Props {
  chat: string;
  compact: boolean;
  position: number;
  onClose: (chat: string) => any;
  onToggleCompact: (chat: string) => any;
  onOpenGroupSettings: (chat: string) => any;
  onOpenSelectNewOwner: (chat: string) => any;
  onOpenActivityDialog: (chat: string) => any;
}

const width = "300px";
const height = "366px";

export const ConversationWindow = ({
  chat,
  compact,
  position,
  onClose,
  onToggleCompact,
  onOpenGroupSettings,
  onOpenSelectNewOwner,
  onOpenActivityDialog,
}: Props) => {
  const { data } = useChatQuery({ variables: { id: chat }, fetchPolicy: "network-only" });

  const closeChat = useCallback(() => onClose(chat), [chat, onClose]);
  const toggleCompact = useCallback(() => onToggleCompact(chat), [chat, onToggleCompact]);
  const openGroupSettings = useCallback(() => onOpenGroupSettings(chat), [chat, onOpenGroupSettings]);
  const openSelectNewOwner = useCallback(() => onOpenSelectNewOwner(chat), [chat, onOpenSelectNewOwner]);
  const openActivity = useCallback(() => onOpenActivityDialog(chat), [chat, onOpenActivityDialog]);

  const isGroupChat = useMemo(() => (data?.chat?.interactions?.edges?.length || 0) > 2, [data]);
  const isAccepted = useMemo(() => !!data?.chat?.myInteraction.acceptedAt, [data]);

  const windowProps = useMemo(() => ({ position, width, height, compact }), [compact, position]);

  /** Data not ready */
  if (!data) return <Window {...windowProps} loading={true} />;

  if (isGroupChat && !isAccepted) {
    return (
      <Window {...windowProps}>
        <Invitation
          chatQueryData={data}
          onCloseChat={closeChat}
          onAcceptInvitation={() => {}}
          onRejectInvitation={closeChat}
        />
      </Window>
    );
  }

  return (
    <Window {...windowProps}>
      <Conversation
        chat={chat}
        chatQueryData={data}
        compact={compact}
        onClose={closeChat}
        onToggleCompact={toggleCompact}
        onOpenGroupSettings={openGroupSettings}
        onOpenSelectNewOwner={openSelectNewOwner}
        onOpenActivity={openActivity}
      />
    </Window>
  );
};
