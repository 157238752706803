const base = {
  root: "/",
  login: "/login",
  passwordRecovery: "/password-recovery/:step",
  activation: "/activation/:step",
  guide: "/guide/:step",
  dashboard: "/dashboard/:organization/:space",
};

export type PasswordRecoveryParams = { step: "1" | "2" | "error" };
export type ActivationParams = { step: "1" | "2" | "3" };
export type GuideParams = { step: "1" | "2" | "3" | "4" };
export type DashboardParams = { organization: string; space: string };

const dashboard = {
  stories: base.dashboard + "/stories",
  storiesCreate: base.dashboard + "/stories/create",
  storiesEdit: base.dashboard + "/stories/edit/:id",

  documents: base.dashboard + "/documents/:tab",
  documentsDetail: base.dashboard + "/documents/:tab/:id",
  documentsCreate: base.dashboard + "/documents/:tab/create",
  documentsEdit: base.dashboard + "/documents/:tab/edit/:id",

  announcements: base.dashboard + "/announcements/:tab",
  announcementsDetail: base.dashboard + "/announcements/:tab/:id",
  announcementsCreate: base.dashboard + "/announcements/:tab/create",

  support: base.dashboard + "/support/:role/:topic",
  feedback: base.dashboard + "/feedback",
  benefits: base.dashboard + "/benefits",
};

export type StoriesParams = DashboardParams;
export type StoriesCreateParams = DashboardParams;
export type StoriesEditParams = DashboardParams & { id: string };

export type DocumentsTabs = "sent" | "received";
export type DocumentsParams = DashboardParams & { tab: DocumentsTabs };
export type DocumentsDetailParams = DashboardParams & { tab: DocumentsTabs; id: string };
export type DocumentsCreateParams = DashboardParams & { tab: "sent" };
export type DocumentsEditParams = DashboardParams & { tab: DocumentsTabs; id: string };

export type AnnouncementsTabs = "sent" | "unresolved" | "received" | "marked" | "archive";
export type AnnouncementsParams = DashboardParams & { tab: AnnouncementsTabs };
export type AnnouncementsDetailParams = DashboardParams & { tab: AnnouncementsTabs; id: string };
export type AnnouncementsCreateParams = DashboardParams & { tab: "sent" };

export type SupportParams = DashboardParams & { role: "regular" | "manager"; topic: string };
export type FeedbackParams = DashboardParams;
export type BenefitsParams = DashboardParams;

export const routes = {
  ...base,
  ...dashboard,
};

export const setRouteParams = <Params extends { [key: string]: string }>(route: string, params: Params): string => {
  let url = route;
  for (let key of Object.keys(params)) {
    url = url.replace(`:${key}`, params[key].toString());
  }

  return url;
};

const r = routes;
const s = setRouteParams;

export const getRoute = {
  root: () => r.root,
  login: () => r.login,
  passwordRecovery: (params: PasswordRecoveryParams) => s<PasswordRecoveryParams>(r.passwordRecovery, params),
  activation: (params: ActivationParams) => s<ActivationParams>(r.activation, params),
  guide: (params: GuideParams) => s<GuideParams>(r.guide, params),
  dashboard: (params: DashboardParams) => s<DashboardParams>(r.dashboard, params),

  stories: (params: StoriesParams) => s<StoriesParams>(r.stories, params),
  storiesCreate: (params: StoriesCreateParams) => s<StoriesCreateParams>(r.storiesCreate, params),
  storiesEdit: (params: StoriesEditParams) => s<StoriesEditParams>(r.storiesEdit, params),

  documents: (params: DocumentsParams) => s<DocumentsParams>(r.documents, params),
  documentsDetail: (params: DocumentsDetailParams) => s<DocumentsDetailParams>(r.documentsDetail, params),
  documentsCreate: (params: DocumentsCreateParams) => s<DocumentsCreateParams>(r.documentsCreate, params),
  documentsEdit: (params: DocumentsEditParams) => s<DocumentsEditParams>(r.documentsEdit, params),

  announcements: (params: AnnouncementsParams) => s<AnnouncementsParams>(r.announcements, params),
  announcementsDetail: (params: AnnouncementsDetailParams) =>
    s<AnnouncementsDetailParams>(r.announcementsDetail, params),
  announcementsCreate: (params: AnnouncementsCreateParams) =>
    s<AnnouncementsCreateParams>(r.announcementsCreate, params),

  support: (params: SupportParams) => s<SupportParams>(r.support, params),
  feedback: (params: FeedbackParams) => s<FeedbackParams>(r.feedback, params),
  benefits: (params: BenefitsParams) => s<BenefitsParams>(r.benefits, params),
};
