import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { font, theme } from "styles";

export class DetailHeading extends React.Component<PropsWithChildren> {
  render() {
    return <Heading>{this.props.children}</Heading>;
  }
}

/**
 * Styles
 */

const Heading = styled.div`
  position: relative;
  font-weight: ${font.weight.bold};
  font-size: 24px;
  color: ${theme.colors.NightSky};
  line-height: 30px;
`;
