import { createTheme } from "@material-ui/core";
import { theme } from "styles";

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.TealGreen,
    },
  },
});
