import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AnnouncementList, Navigation, NoContentNotice, SidebarListSpinner } from "components";
import * as Tabs from "components/Tabs";
import { IconStarFilled } from "icons";
import { useAnnouncementsList, useDashboardState, useMe } from "hooks";
import { tk, useTranslation } from "translations";
import { AnnouncementsTabs, getRoute } from "routes";

type Props = {
  children: React.ReactNode;
  buttons: React.ReactNode;
  tab: AnnouncementsTabs;
  activeAnnouncementId?: string;
  create?: boolean;
};

export const AnnouncementsLayout = ({ children, buttons, tab, activeAnnouncementId, create = false }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { organization, space } = useDashboardState();

  const { loading, sentAnnouncements, receivedAnnouncements } = useAnnouncementsList(tab, activeAnnouncementId, create);

  const { isManager } = useMe();

  const changeTab = useCallback(
    (tab: AnnouncementsTabs) => history.push(getRoute.announcements({ organization, space, tab })),
    [history, organization, space]
  );

  const handleClickSent = useCallback(() => changeTab("sent"), [changeTab]);
  const handleClickUnresolved = useCallback(() => changeTab("unresolved"), [changeTab]);
  const handleClickReceived = useCallback(() => changeTab("received"), [changeTab]);
  const handleClickMarked = useCallback(() => changeTab("marked"), [changeTab]);
  const handleClickArchive = useCallback(() => changeTab("archive"), [changeTab]);

  const announcements = useMemo(() => {
    if (!sentAnnouncements || !receivedAnnouncements) return undefined;

    if (tab === "sent" && sentAnnouncements.length > 0) return sentAnnouncements;
    if (tab === "unresolved" && receivedAnnouncements.unresolved.length > 0) return receivedAnnouncements.unresolved;
    if (tab === "received" && receivedAnnouncements.received.length > 0) return receivedAnnouncements.received;
    if (tab === "marked" && receivedAnnouncements.marked.length > 0) return receivedAnnouncements.marked;
    if (tab === "archive" && receivedAnnouncements.archive.length > 0) return receivedAnnouncements.archive;

    return undefined;
  }, [receivedAnnouncements, sentAnnouncements, tab]);

  return (
    <Container>
      <Navigation>{buttons}</Navigation>

      <Content>
        <Tabs.Tabs width={415}>
          <Tabs.TabList width={415}>
            {isManager && (
              <Tabs.Tab isActive={tab === "sent"} onClick={handleClickSent}>
                {t(tk.announcements.tabs.sent)}
              </Tabs.Tab>
            )}
            <Tabs.Tab isActive={tab === "unresolved"} onClick={handleClickUnresolved}>
              {t(tk.announcements.tabs.unresolved)}
            </Tabs.Tab>
            <Tabs.Tab isActive={tab === "received"} onClick={handleClickReceived}>
              {t(tk.announcements.tabs.received)}
            </Tabs.Tab>
            <Tabs.Tab isActive={tab === "marked"} onClick={handleClickMarked}>
              <IconWrapper>
                <IconStarFilled width={"8px"} height={"8px"} margin={"0 3px 2px 0"} />
              </IconWrapper>
              {t(tk.announcements.tabs.marked)}
            </Tabs.Tab>
            <Tabs.Tab isActive={tab === "archive"} onClick={handleClickArchive}>
              {t(tk.announcements.tabs.archive)}
            </Tabs.Tab>
          </Tabs.TabList>

          <Tabs.TabPanels>
            <Tabs.TabPanel>
              {loading && !announcements && <SidebarListSpinner margin={"60px 0 0 0"} width={"415px"} />}

              {!loading && !announcements && <NoContentNotice margin={"60px 0 0 0"} width={"415px"} />}

              {!loading && !!announcements && <AnnouncementList items={announcements} />}
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs.Tabs>

        <ContentContainer>{children}</ContentContainer>
      </Content>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  position: relative;
  flex: 1 auto;
  overflow: hidden;
`;

const Content = styled("div")`
  display: flex;
  flex: 1 100%;
  height: calc(100% - 65px);
`;

const ContentContainer = styled("div")`
  position: relative;
  width: 100%;
  padding: 0 25px;
  overflow: auto;
`;

const IconWrapper = styled("span")`
  display: inline-flex;
  position: relative;
`;

export const AnnouncementsLayoutActionsWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`;
