import React from "react";
import styled from "styled-components";
import { Icon } from "components/Icon";
import { withTranslation, WithTranslation } from "react-i18next";
import { tk } from "translations";

type Props = {
  icon: JSX.Element;
  name: string;
  membersCount: number;
  onClick: () => void;
  dissabled?: boolean;
} & WithTranslation;

type State = {
  isSelected: boolean;
};

export const SelectionListItem = withTranslation()(
  class SelectionListItemStatic extends React.PureComponent<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        isSelected: this.props.dissabled ? this.props.dissabled : false,
      };
    }

    setAsSelected = () => {
      this.setState({
        isSelected: true,
      } as State);
    };

    renderAddButton = () => {
      return (
        <AddAuthorityText>
          {`+ ${this.props.t(tk.recipientsSelection.contactGroupsModal.add, { count: this.props.membersCount })}`}
        </AddAuthorityText>
      );
    };

    handleClick = () => {
      if (this.state.isSelected) {
        return;
      }
      this.setAsSelected();
      this.props.onClick();
    };

    render() {
      return (
        <ListItemContainer onClick={this.handleClick} selected={this.state.isSelected}>
          <ItemDetailContainer>
            {this.props.icon}
            <Name>{this.props.name}</Name>
          </ItemDetailContainer>

          {this.state.isSelected && <AddedIcon shape={"checked"} />}
          {!this.state.isSelected && this.renderAddButton()}
        </ListItemContainer>
      );
    }
  }
);

const getCursorType = (props: { selected: boolean }) => (props.selected ? "default" : "pointer");

/**
 * Styles
 */

const ListItemContainer = styled.div.withConfig({ shouldForwardProp: (prop) => !["selected"].includes(prop) })<{
  selected: boolean;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 380px;
  height: 68px;
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0;
  flex-basis: auto;
  cursor: ${getCursorType};

  &:last-of-type {
    border-bottom: none;
  }
`;

const ItemDetailContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const Name = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #404040;
  margin-left: 10px;
`;

const AddAuthorityText = styled.div`
  position: relative;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #008c82;
  user-select: none;
`;

const AddedIcon = styled(Icon)`
  color: #008c82;
  user-select: none;
`;
