import styled from "styled-components";

export const GuideDescriptionHeading = styled("span")`
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #2b2b2b;
  margin-bottom: 14px;
  text-align: center;
`;
