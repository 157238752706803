import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";
import { IconShape } from "components/Icon";
import { AnnouncementInteractionRow } from "components/Detail/AnnouncementInteractionRow";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

type Sms = { id: string; createdAt: string };
type User = { id: string; firstName: string; lastName: string; isActive: boolean };
type LastReactionLog = { id: string; createdAt: string; valueNew: string; valueOld: string };
type Reaction = { id: string; text: string; position: number };
type Interaction = {
  id: string;
  readAt?: string | null;
  archivedAt?: string | null;
  reaction?: { id: string } | null;
  user: User;
  lastReactionLog?: LastReactionLog | null;
  sms?: Sms | null;
};

interface Props {
  interactions: Interaction[];
  reactions: Reaction[];
  lastChangesSeenAt?: string;
  urgedAt?: string;
}

export const DetailAnalyticsSectionByReaction = ({ interactions, reactions, lastChangesSeenAt, urgedAt }: Props) => {
  const { t } = useTranslation();

  const seenInteractionsWithoutReaction = useMemo(() => {
    return interactions.filter(({ readAt, reaction }) => readAt && !reaction);
  }, [interactions]);

  const unseenInteractions = useMemo(() => {
    return interactions.filter(({ readAt }) => !readAt);
  }, [interactions]);

  const hasSeenInteractions = seenInteractionsWithoutReaction.length > 0;
  const hasUnseenInteractions = unseenInteractions.length > 0;
  const hasReactions = reactions.length > 0;

  return (
    <Section>
      {/** Reactions */}
      {hasReactions &&
        reactions.map(({ id, text }) => {
          const reactionInteractions = interactions.filter(({ reaction }) => reaction?.id === id);
          const hasInteractions = reactionInteractions.length > 0;

          return (
            <ReactionSection key={id}>
              <ReactionSectionTitle>{text}</ReactionSectionTitle>

              {!hasInteractions && <NoInteractions>{t(tk.common.nobody)}</NoInteractions>}

              {reactionInteractions.map((interaction) => (
                <InteractionRow
                  key={interaction.id}
                  interaction={interaction}
                  hasReactions={hasReactions}
                  lastChangesSeenAt={lastChangesSeenAt}
                  urgedAt={urgedAt}
                />
              ))}
            </ReactionSection>
          );
        })}

      {/** Seen */}
      <ReactionSection>
        <ReactionSectionTitle>{t(tk.announcements.interactions.seen)}</ReactionSectionTitle>

        {!hasSeenInteractions && <NoInteractions>{t(tk.common.nobody)}</NoInteractions>}

        {seenInteractionsWithoutReaction.map((interaction) => (
          <InteractionRow
            key={interaction.id}
            interaction={interaction}
            hasReactions={hasReactions}
            lastChangesSeenAt={lastChangesSeenAt}
            urgedAt={urgedAt}
          />
        ))}
      </ReactionSection>

      {/** Unseen */}
      <ReactionSection>
        <ReactionSectionTitle>{t(tk.announcements.interactions.unseen)}</ReactionSectionTitle>

        {!hasUnseenInteractions && <NoInteractions>{t(tk.common.nobody)}</NoInteractions>}

        {unseenInteractions.map((interaction) => (
          <InteractionRow
            key={interaction.id}
            interaction={interaction}
            hasReactions={hasReactions}
            lastChangesSeenAt={lastChangesSeenAt}
            urgedAt={urgedAt}
          />
        ))}
      </ReactionSection>
    </Section>
  );
};

interface InteractionRowProps {
  interaction: Interaction;
  hasReactions: boolean;
  lastChangesSeenAt?: string;
  urgedAt?: string;
}

const InteractionRow = ({ interaction, hasReactions, lastChangesSeenAt, urgedAt }: InteractionRowProps) => {
  const { t } = useTranslation();

  const hasUnseenChanges = useMemo(() => {
    return (
      interaction.lastReactionLog &&
      interaction.lastReactionLog.valueOld &&
      (!lastChangesSeenAt || moment.utc(lastChangesSeenAt).isBefore(moment.utc(interaction.lastReactionLog.createdAt)))
    );
  }, [interaction.lastReactionLog, lastChangesSeenAt]);

  /** Special cases (applied in this order) */

  /** newChanges - Announcement author has not seen new changes */
  const newChanges = Boolean(interaction.readAt && hasReactions && hasUnseenChanges);

  /** wontReact - User won't react - archived the announcement without reaction */
  const wontReact = Boolean(hasReactions && !interaction.reaction && interaction.archivedAt);

  /** notActive - User is not active - SMS sent */
  const notActive = Boolean(!interaction.user.isActive && interaction.sms);

  /** urged - User has not seen or reacted to the announcement and has been urged */
  const urged = Boolean(urgedAt && (!interaction.readAt || (hasReactions && !interaction.reaction)));

  const iconShape: IconShape | undefined = useMemo(() => {
    if (newChanges) return "icon-change";
    if (wontReact) return "icon-no-reaction";
    if (notActive) return "icon-sms";
    if (urged) return "icon-urged";
  }, [newChanges, notActive, urged, wontReact]);

  const metaText = useMemo(() => {
    if (newChanges) return moment.utc(interaction.lastReactionLog?.createdAt).local().fromNow();
    if (wontReact) return t(tk.announcements.interactions.wontReact);
  }, [interaction.lastReactionLog, newChanges, t, wontReact]);

  const tooltip = useMemo(() => {
    if (newChanges)
      return (
        <span>
          {t(tk.announcements.interactions.changedFrom, { from: interaction.lastReactionLog?.valueOld })}
          <br />
          <NoWrap>{moment.utc(interaction.lastReactionLog?.createdAt).local().format("LLLL")}</NoWrap>
        </span>
      );

    if (notActive)
      return (
        <span>
          <NoWrap>{`${t(tk.announcements.interactions.smsSent)} ${moment
            .utc(interaction.sms?.createdAt)
            .fromNow()}`}</NoWrap>
          <br />
          <NoWrap>{moment.utc(interaction.sms?.createdAt).local().format("LLLL")}</NoWrap>
        </span>
      );

    if (urged)
      return (
        <span>
          <NoWrap>{`${t(tk.announcements.interactions.urged)} ${moment.utc(urgedAt).local().fromNow()}`}</NoWrap>
          <br />
          <NoWrap>{moment.utc(urgedAt).local().format("LLLL")}</NoWrap>
        </span>
      );
  }, [interaction.lastReactionLog, interaction.sms, newChanges, notActive, t, urged, urgedAt]);

  return (
    <ReactionSectionRow key={interaction.id}>
      <AnnouncementInteractionRow
        isActive={interaction.user.isActive}
        firstName={interaction.user.firstName}
        lastName={interaction.user.lastName}
        metaText={metaText}
        icon={iconShape}
        tooltip={tooltip}
      />
    </ReactionSectionRow>
  );
};

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 30px 60px;
`;

const ReactionSection = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
`;

const ReactionSectionTitle = styled.div`
  font-size: 10px;
  color: ${theme.colors.NightSky};
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const ReactionSectionRow = styled.div`
  padding: 9px 0;
`;

const NoInteractions = styled(ReactionSectionRow)`
  color: ${theme.colors.IronGrey};
  line-height: 24px;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;
