import React from "react";
import styled from "styled-components";
import { Spinner } from "components";

interface Props {
  margin?: string;
  width?: string;
}

export const SidebarListSpinner = ({ margin, width }: Props) => {
  return (
    <ContentContainer margin={margin} width={width}>
      <Spinner color={"#9B9B9B"} />
    </ContentContainer>
  );
};

/**
 * Styles
 */

const ContentContainer = styled.div.withConfig({ shouldForwardProp: (prop) => !["margin", "width"].includes(prop) })<{
  margin?: string;
  width?: string;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 92px;
  min-height: 92px;
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width || "100%"};
`;
