import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle, useUpdateEffect } from "react-use";
import { useMedia } from "hooks";
import { isEmail, isPhoneNumber } from "helpers";
import {
  ChangeProfileInput,
  MediaTypes,
  resolveError,
  useActivateAccountMutation,
  useAuth,
  useChangeProfileMutation,
  useUserForActivationLazyQuery,
} from "api";
import { tk, useTranslation } from "translations";
import { ActivationParams, routes, getRoute } from "routes";

export const useActivation = (step: ActivationParams["step"]) => {
  const { t } = useTranslation();

  useTitle(t(tk.activation.heading) + " " + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const media = useMedia(MediaTypes.ProfileImage);

  const [fetchUser, { data: userData, error: fetchUserError, loading: loadingFetchUser }] =
    useUserForActivationLazyQuery({ fetchPolicy: "network-only" });

  const [activate, { loading: loadingActivate }] = useActivateAccountMutation();

  const { login, loading: loadingLogin } = useAuth();

  const [changeProfile, { loading: loadingChangeProfile }] = useChangeProfileMutation();

  const user = useMemo(() => userData?.userForActivation, [userData]);

  const loading = useMemo(
    () => loadingFetchUser || loadingActivate || loadingLogin || loadingChangeProfile || media.loading,
    [loadingActivate, loadingChangeProfile, loadingFetchUser, loadingLogin, media.loading]
  );

  const [activationCode, setActivationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");

  const goToStep = useCallback(
    (step: ActivationParams["step"], replace: boolean = false, clearState: boolean = false) => {
      if (clearState) {
        setActivationCode("");
        setPassword("");
        setPasswordConfirmation("");
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setEmail("");
      }

      if (replace) {
        history.replace(getRoute.activation({ step }));
        return;
      }

      history.push(getRoute.activation({ step }));
    },
    [history]
  );

  /** Step validation */
  useEffect(() => {
    const activationCodeRequired = step === "2";
    const userRequired = step === "2" || step === "3";

    if (activationCodeRequired && !activationCode) return goToStep("1", true);
    if (userRequired && !user) return goToStep("1", true);
  }, [activationCode, goToStep, step, user]);

  /** Handle fetchUser success */
  useUpdateEffect(() => {
    if (!user) return;

    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setPhoneNumber(user.phoneNumber || "");
    setEmail(user.email || "");

    goToStep("2");
  }, [user]);

  /** Handle fetchUser error */
  useUpdateEffect(
    () => setError(!fetchUserError ? "" : t(tk.activation.form.activationCode.invalid)),
    [fetchUserError]
  );

  const handleLoginSuccess = useCallback(() => goToStep("3", true), [goToStep]);
  const handleLoginError = useCallback(() => history.replace(routes.login), [history]);

  const submitActivationCode = useCallback(async () => {
    if (!activationCode) return;

    await setError("");
    await fetchUser({ variables: { activationCode } });
  }, [activationCode, fetchUser]);

  const activateAccount = useCallback(async () => {
    if (!activationCode || !password || !passwordConfirmation) return;

    if (password.length < 5) return setError(t(tk.activation.form.password.tooShort));

    if (password !== passwordConfirmation) return setError(t(tk.activation.form.passwordConfirmation.notEqual));

    await setError("");

    try {
      await activate({ variables: { input: { activationCode, password } } });

      if (!email) return handleLoginError();

      await login(email, password, handleLoginSuccess, handleLoginError);
    } catch (e: any) {
      const password_too_short = () => setError(t(tk.activation.form.password.tooShort));
      const not_found = () => goToStep("1", true, true);

      resolveError(e, { password_too_short, not_found });
    }
  }, [
    activate,
    activationCode,
    email,
    goToStep,
    handleLoginError,
    handleLoginSuccess,
    login,
    password,
    passwordConfirmation,
    t,
  ]);

  const editUserData = useCallback(async () => {
    if (!user || !firstName || !lastName || !email) return;

    if (!isPhoneNumber(phoneNumber)) return setPhoneNumberError(t(tk.activation.form.phoneNumber.invalid));

    setPhoneNumberError("");

    if (!isEmail(email)) return setEmailError(t(tk.activation.form.email.invalid));

    setEmailError("");

    const input: ChangeProfileInput = {};

    const profileImage = !media.preview ? undefined : await media.upload();

    if (firstName !== user.firstName) input.firstName = firstName;
    if (lastName !== user.lastName) input.lastName = lastName;
    if (phoneNumber !== user.phoneNumber) input.phoneNumber = phoneNumber;
    if (email !== user.email) input.email = email;
    if (!!profileImage) input.profileImage = profileImage;

    const changeRequired = Object.keys(input).length > 0;

    if (changeRequired) {
      try {
        await changeProfile({ variables: { input } });
      } catch (e: any) {
        const email_already_used = () => setEmailError(t(tk.activation.form.email.exists));
        const email_invalid = () => setEmailError(t(tk.activation.form.email.invalid));

        resolveError(e, { email_already_used, email_invalid });
        return;
      }
    }

    return history.replace(getRoute.guide({ step: "1" }));
  }, [changeProfile, email, firstName, history, lastName, media, phoneNumber, t, user]);

  /** Form submit */
  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) return;

      if (step === "1") await submitActivationCode();

      if (step === "2") await activateAccount();

      if (step === "3") await editUserData();
    },
    [activateAccount, editUserData, loading, step, submitActivationCode]
  );

  /** Form change handlers */
  const handleChangeActivationCode = useCallback((value: string) => setActivationCode(value), []);
  const handleChangePassword = useCallback((value: string) => setPassword(value), []);
  const handleChangePasswordConfirmation = useCallback((value: string) => setPasswordConfirmation(value), []);
  const handleChangeFirstName = useCallback((value: string) => setFirstName(value), []);
  const handleChangeLastName = useCallback((value: string) => setLastName(value), []);
  const handleChangePhoneNumber = useCallback((value: string) => setPhoneNumber(value), []);
  const handleChangeEmail = useCallback((value: string) => setEmail(value), []);

  return {
    t,
    tk,
    media,
    data: { user },
    state: {
      step,
      loading,
      activationCode,
      password,
      passwordConfirmation,
      firstName,
      lastName,
      phoneNumber,
      email,
      error,
      phoneNumberError,
      emailError,
    },
    handlers: {
      goToStep,
      handleSubmit,
      handleChangeActivationCode,
      handleChangePassword,
      handleChangePasswordConfirmation,
      handleChangeFirstName,
      handleChangeLastName,
      handleChangePhoneNumber,
      handleChangeEmail,
    },
  };
};
