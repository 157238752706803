import React from "react";
import { Moment } from "moment";
import { DateTimePicker as MaterialDateTimePicker } from "@material-ui/pickers";

interface Props {
  value?: string;
  onChange: (value: Moment | null) => any;
}

export const DateTimePicker = ({ value, onChange }: Props) => (
  <MaterialDateTimePicker
    value={value}
    ampm={false}
    autoOk={true}
    variant={"static"}
    disablePast={true}
    onChange={onChange}
  />
);
