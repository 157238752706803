import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useDashboardState, useMe } from "hooks";
import { tk, useTranslation } from "translations";
import { AnnouncementsTabs, getRoute } from "routes";

export const useAnnouncements = (tab: AnnouncementsTabs) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.announcements) + " " + t(tk.common.documentTitleSuffix));

  const { organization, space } = useDashboardState();

  const { isManager } = useMe();

  const createAnnouncement = useCallback(() => {
    history.push(getRoute.announcementsCreate({ organization, space, tab: "sent" }));
  }, [history, organization, space]);

  return {
    t,
    tk,
    data: { isManager },
    state: { tab },
    handlers: { createAnnouncement },
  };
};
