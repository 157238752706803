import styled from "styled-components";
import { font, theme } from "styles";

export const ListItemMetaText = styled("span")`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${theme.listItemMetaText.fontColor};
`;
