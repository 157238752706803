import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { tk, useTranslation } from "translations";
import { useMe, useMedia } from "hooks";
import {
  MediaTypes,
  resolveError,
  StoryUpdateInput,
  useStoryDeleteMutation,
  useStoryQuery,
  useStoryUpdateMutation,
} from "api";
import { getRoute, StoriesEditParams } from "routes";

export const useStoriesEdit = ({ organization, space, id }: StoriesEditParams) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.stories) + " " + t(tk.common.documentTitleSuffix));

  const { me } = useMe();

  const { data, loading: loadingStory } = useStoryQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: !id,
  });

  const media = useMedia(MediaTypes.StoryHeader, false);
  const [update, { loading: loadingUpdate }] = useStoryUpdateMutation();
  const [storyDelete, { loading: loadingDelete }] = useStoryDeleteMutation();

  const story = useMemo(() => data?.story || undefined, [data]);

  const loading = useMemo(
    () => loadingStory || media.loading || loadingUpdate || loadingDelete,
    [loadingStory, media.loading, loadingUpdate, loadingDelete]
  );

  const [text, setText] = useState("");
  const [textError, setTextError] = useState("");
  const [imageDeleted, setImageDeleted] = useState(false);

  useEffect(() => {
    if (!story) return;

    setText(story.text);
  }, [story]);

  const [dialog, setDialog] = useState<"progress" | "success" | "error" | "delete" | undefined>(undefined);

  const goToStories = useCallback(() => {
    history.replace(getRoute.stories({ organization, space }));
  }, [history, organization, space]);

  const closeDialog = useCallback(() => setDialog(undefined), []);

  const submit = useCallback(async () => {
    if (!space) return;

    if (!text.trim().length) return setTextError(t(tk.storiesCreate.form.text.required));

    const mediaChanged = !!media.preview;
    const mediaDeleted = !media.preview && imageDeleted;
    const textChanged = text !== story?.text;

    if (!mediaChanged && !mediaDeleted && !textChanged) return setDialog("success");

    setDialog("progress");

    try {
      const input: StoryUpdateInput = { story: id, text };

      if (mediaChanged) input.media = await media.upload();
      if (mediaDeleted) input.media = null;

      await update({ variables: { input } });

      setDialog("success");
    } catch (e: any) {
      setDialog("error");
      resolveError(e);
    }
  }, [id, imageDeleted, media, space, story, t, text, update]);

  const remove = useCallback(() => setDialog("delete"), []);

  const submitRemove = useCallback(async () => {
    try {
      await storyDelete({ variables: { input: { story: id } } });
      closeDialog();
      goToStories();
    } catch (e: any) {
      resolveError(e);
      setDialog("error");
    }
  }, [closeDialog, goToStories, id, storyDelete]);

  const cancel = useCallback(() => history.goBack(), [history]);

  const handleImageDelete = useCallback(() => setImageDeleted(true), []);

  const handleChangeText = useCallback((value: string) => {
    setText(value);
    setTextError("");
  }, []);

  /** If user is not author, go back to stories */
  useEffect(() => {
    if (!me?.userId || !story?.author.id) return;
    if (me.userId === story.author.id) return;

    goToStories();
  }, [goToStories, me, story]);

  return {
    t,
    tk,
    media,
    data: { story },
    state: { loading, dialog, text, textError, imageDeleted },
    handlers: { submit, remove, submitRemove, cancel, goToStories, closeDialog, handleImageDelete, handleChangeText },
  };
};
