import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconComment = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32.003 31.063"
      style={{ margin }}
    >
      <path
        id="comments-outline"
        d="M27.188,22.875,27.063,22l.5-.688a11.454,11.454,0,0,0,2.125-6.625c0-6.75-6.125-12.375-13.688-12.375S2.312,7.937,2.312,14.687C2.312,21.5,8.437,27.125,16,27.125A14.127,14.127,0,0,0,21.625,26l1.063-.438,1,.563q1.032.563,2.25,1.125a22.567,22.567,0,0,0,2.188.688A19.235,19.235,0,0,1,27.188,22.875Zm4.25,6.75-.063,1.438.188-1.25C31.5,29.75,31.5,29.688,31.438,29.625Zm-2-6.937c.313,3.563,1.188,6.188,2.375,7.438a.5.5,0,0,1,.125.625c-.063.188-.25.313-.563.313A30.711,30.711,0,0,1,22.5,28.126,16.551,16.551,0,0,1,16,29.439c-8.813,0-16-6.625-16-14.75C0,6.626,7.188,0,16,0S32,6.626,32,14.689a13.7,13.7,0,0,1-2.563,8Z"
        transform="translate(0 -0.001)"
        fill={color}
      />
    </svg>
  );
};
