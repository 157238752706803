import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { AnnouncementsLayout, AnnouncementsLayoutActionsWrapper } from "layouts";
import { announcementCreateDeadlineOptions, useAnnouncementsCreate } from "controllers";
import {
  Button,
  ButtonLink,
  Card,
  DateTimePickerDialog,
  Dialog,
  DialogScreen,
  Form,
  FormActions,
  HorizontalSeparator,
  ImageUpload,
  Input,
  PreviewSpinner,
  Radio,
  RecipientsSelection,
  Switch,
  Textarea,
} from "components";
import * as Field from "components/Field";
import * as Select from "components/Select";
import * as Reactions from "components/ReactionList";
import { Urgency } from "api";
import { AnnouncementsCreateParams } from "routes";
import { formatTime } from "helpers";
import { theme } from "styles";

interface Props extends RouteComponentProps<AnnouncementsCreateParams> {}

export const AnnouncementsCreate = ({ match }: Props) => {
  const { t, tk, media, reactions, data, state, handlers } = useAnnouncementsCreate(match.params.tab);

  const renderContent = !!data.recipientSelection;

  return (
    <AnnouncementsLayout
      buttons={
        <AnnouncementsLayoutActionsWrapper>
          <ButtonLink isDisabled={state.loading} margin={"0 30px 0 0"} onClick={handlers.cancel}>
            {t(tk.common.cancel)}
          </ButtonLink>
          <Button isDisabled={state.loading} color={"green"} onClick={handlers.submit}>
            {t(tk.common.send)}
          </Button>
        </AnnouncementsLayoutActionsWrapper>
      }
      activeAnnouncementId={"new-announcement"}
      tab={state.tab}
      create={true}
    >
      {!renderContent && <PreviewSpinner />}

      {renderContent && (
        <FormContainer>
          <Form onSubmit={handlers.submit}>
            {/** Title */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <Field.FieldLabel required={true}>{t(tk.announcementsCreate.form.title.label)}</Field.FieldLabel>
              <Input
                value={state.title}
                placeholder={t(tk.announcementsCreate.form.title.placeholder)}
                maxLength={250}
                width={"100%"}
                onChange={handlers.handleChangeTitle}
              />
              <Field.FieldErrorMessage>{state.titleError}</Field.FieldErrorMessage>
            </Field.Field>

            {/** Text */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <Field.FieldLabel required={true}>{t(tk.announcementsCreate.form.text.label)}</Field.FieldLabel>
              <Textarea
                value={state.text}
                placeholder={t(tk.announcementsCreate.form.text.placeholder)}
                width={"100%"}
                onChange={handlers.handleChangeText}
              />
              <Field.FieldErrorMessage>{state.textError}</Field.FieldErrorMessage>
            </Field.Field>

            {/** Video URL */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <Field.FieldLabel>{t(tk.announcementsCreate.form.videoUrl.label)}</Field.FieldLabel>
              <Input
                value={state.videoUrl}
                placeholder={t(tk.announcementsCreate.form.videoUrl.placeholder)}
                width={"100%"}
                onChange={handlers.handleChangeVideoUrl}
              />
              <Field.FieldErrorMessage>{state.videoUrlError}</Field.FieldErrorMessage>
            </Field.Field>

            {/** Image Upload */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <Field.FieldLabel>{t(tk.announcementsCreate.form.imageUpload.label)}</Field.FieldLabel>
              <ImageUpload media={media} />
            </Field.Field>

            <HorizontalSeparator />

            {/** Urgency */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 25px 0"}>
              <Field.FieldLabel>{t(tk.announcementsCreate.form.urgency.label)}</Field.FieldLabel>
              <Field.FieldHeading>{t(tk.announcementsCreate.form.urgency.heading)}</Field.FieldHeading>
              <RadioGroup>
                <Radio
                  isChecked={state.urgency === Urgency.NotUrgent}
                  onChange={() => handlers.handleChangeUrgency(Urgency.NotUrgent)}
                >
                  {t(tk.announcementsCreate.form.urgency.radio.notUrgent)}
                </Radio>
                <Radio
                  isChecked={state.urgency === Urgency.Urgent}
                  onChange={() => handlers.handleChangeUrgency(Urgency.Urgent)}
                >
                  {t(tk.announcementsCreate.form.urgency.radio.urgent)}
                </Radio>
                <Radio
                  isChecked={state.urgency === Urgency.VeryUrgent}
                  onChange={() => handlers.handleChangeUrgency(Urgency.VeryUrgent)}
                >
                  {t(tk.announcementsCreate.form.urgency.radio.veryUrgent)}
                </Radio>
              </RadioGroup>
            </Field.Field>

            <HorizontalSeparator />

            {/** Deadline */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 25px 0"}>
              <Field.FieldLabel>{t(tk.announcementsCreate.form.deadline.label)}</Field.FieldLabel>
              <SwitchLayout>
                <Field.FieldHeading>{t(tk.announcementsCreate.form.deadline.heading)}</Field.FieldHeading>
                <Field.FieldDescription>{t(tk.announcementsCreate.form.deadline.description)}</Field.FieldDescription>
                <Switch isChecked={state.hasDeadline} onChange={handlers.toggleHasDeadline} margin={"10px 0 0 56px"} />
              </SwitchLayout>

              {state.hasDeadline && (
                <SwitchLayout margin={"30px 0 0 0"}>
                  <Field.FieldHeading>{t(tk.announcementsCreate.form.deadline.exactTimeHeading)}</Field.FieldHeading>
                  <Field.FieldDescription>
                    {t(tk.announcementsCreate.form.deadline.exactTimeDescription)}
                  </Field.FieldDescription>
                  <Switch
                    isChecked={state.hasExactDeadline}
                    onChange={handlers.toggleHasExactDeadline}
                    margin={"10px 0 0 56px"}
                  />
                </SwitchLayout>
              )}

              {state.hasDeadline && !state.hasExactDeadline && (
                <Select.Select onChange={handlers.handleChangeDeadline} margin={"25px 0 0 0"}>
                  {announcementCreateDeadlineOptions.map(({ title, options, format }, i) => {
                    const time = format(moment.utc().local());
                    return (
                      <Select.SelectOption key={`deadline-${i}`}>
                        <Select.SelectOptionLabel>{t(title, options)}</Select.SelectOptionLabel>
                        <Select.SelectOptionDetail>{!time ? "" : time.format("LLLL")}</Select.SelectOptionDetail>
                      </Select.SelectOption>
                    );
                  })}
                </Select.Select>
              )}

              {state.hasDeadline && state.hasExactDeadline && (
                <ExactTimeValueWrapper>
                  <Field.FieldLabel>{t(tk.announcementsCreate.form.deadline.exactTimeLabel)}</Field.FieldLabel>
                  <ExactTimeValue onClick={handlers.openDeadlineDialog}>
                    {formatTime(state.deadline || "", "LLLL")}
                  </ExactTimeValue>
                </ExactTimeValueWrapper>
              )}

              {!!state.deadlineError && <Field.FieldErrorMessage>{state.deadlineError}</Field.FieldErrorMessage>}

              {state.hasExactDeadline && (
                <DateTimePickerDialog
                  initial={state.deadline || ""}
                  visible={state.dialog === "deadline"}
                  onClose={handlers.closeDialog}
                  onSubmit={handlers.handleChangeExactDeadline}
                />
              )}
            </Field.Field>

            <HorizontalSeparator />

            {/** Reactions */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 25px 0"}>
              <Field.FieldLabel>{t(tk.announcementsCreate.form.reactions.label)}</Field.FieldLabel>
              <SwitchLayout>
                <Field.FieldHeading>{t(tk.announcementsCreate.form.reactions.heading)}</Field.FieldHeading>
                <Field.FieldDescription>{t(tk.announcementsCreate.form.reactions.description)}</Field.FieldDescription>
                <Switch
                  isChecked={state.hasReactions}
                  onChange={handlers.toggleHasReactions}
                  margin={"10px 0 0 56px"}
                />
              </SwitchLayout>

              {state.hasReactions && (
                <Reactions.ReactionList margin={"25px 0 0 0"}>
                  {reactions.state.reactions.map((reaction, i) => (
                    <Reactions.ReactionListItem key={`reaction-list-item-${i}`}>
                      <Reactions.ReactionListItemBullet index={i} />
                      <Reactions.ReactionListItemInput
                        value={reaction}
                        placeholder={t(tk.announcementsCreate.form.reactions.reactionList.placeholder)}
                        onChange={(value) => reactions.handlers.handleChange(i, value)}
                        isFocused={i === reactions.state.activeIndex}
                      />
                      <Reactions.ReactionListItemDeleteButton onClick={() => reactions.handlers.removeReaction(i)} />
                    </Reactions.ReactionListItem>
                  ))}

                  <Reactions.ReactionListAddButton
                    isDisabled={!reactions.state.canAdd}
                    onClick={reactions.handlers.addReaction}
                  >
                    {t(tk.announcementsCreate.form.reactions.reactionList.add)}
                  </Reactions.ReactionListAddButton>
                </Reactions.ReactionList>
              )}
            </Field.Field>

            <HorizontalSeparator />

            {/** Recipients */}
            <Field.Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <Field.FieldLabel required={true}>{t(tk.announcementsCreate.form.recipients.label)}</Field.FieldLabel>
              <RecipientsSelection
                recipientSelection={data.recipientSelection || ""}
                onChange={handlers.handleChangeRecipientSelection}
              />
              <Field.FieldErrorMessage>{state.selectedError}</Field.FieldErrorMessage>
            </Field.Field>
            <HorizontalSeparator />

            <FormActions>
              <ButtonLink isDisabled={state.loading} margin={"0 30px 0 0"} onClick={handlers.cancel}>
                {t(tk.common.cancel)}
              </ButtonLink>
              <Button isDisabled={state.loading} color={"green"} onClick={handlers.submit}>
                {t(tk.common.send)}
              </Button>
            </FormActions>
          </Form>

          {/** Dialogs */}
          {!!state.dialog && state.dialog !== "deadline" && (
            <DialogScreen>
              {state.dialog === "summary" && (
                <Dialog
                  iconImage={"bell"}
                  title={state.title}
                  announcementText={state.text}
                  announcementImage={media.preview}
                  announcementReceivers={state.selected}
                  announcementIsUrged={state.urgency !== Urgency.NotUrgent}
                  reactions={reactions.state.stringReactions}
                  buttonTitle={t(tk.common.send)}
                  hasCloseButton={true}
                  onButtonClick={handlers.submitSummary}
                  onCloseButtonClick={handlers.closeDialog}
                />
              )}

              {state.dialog === "progress" && (
                <Dialog
                  iconImage={"bell"}
                  title={t(tk.announcementsCreate.sending)}
                  text={t(tk.common.pleaseWait)}
                  hasProgressBar={true}
                />
              )}

              {state.dialog === "success" && (
                <Dialog
                  buttonTitle={t(tk.common.ok)}
                  text={t(tk.announcementsCreate.sent)}
                  onButtonClick={handlers.goToSent}
                />
              )}

              {state.dialog === "error" && (
                <Dialog
                  iconImage={"bell"}
                  iconImageBackgroundColor={"#C8102E"}
                  text={t(tk.announcementsCreate.error)}
                  buttonTitle={t(tk.common.ok)}
                  onButtonClick={handlers.closeDialog}
                />
              )}
            </DialogScreen>
          )}
        </FormContainer>
      )}
    </AnnouncementsLayout>
  );
};

/**
 * Styles
 */

const FormContainer = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 740px;
  margin: 25px auto;
  flex-shrink: 0;
`;

const RadioGroup = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 10px;
  justify-items: start;
`;

const SwitchLayout = styled("div").withConfig({ shouldForwardProp: (prop) => !["margin"].includes(prop) })<{
  margin?: string;
}>`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-template-areas:
    "field-heading switch"
    "field-description switch";
  width: 100%;
  margin: ${(props) => props.margin || 0};
`;

const ExactTimeValueWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const ExactTimeValue = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.SilverGrey};
  padding: 15px 0 10px 0;
  cursor: pointer;
`;
