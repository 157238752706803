import { SyntheticEvent, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { tk, useTranslation } from "translations";
import { useDashboardState } from "hooks";
import { mapConnection, sortByLast, storiesUpdateQuery, StoryFragment, useSpaceStoriesQuery } from "api";
import { getRoute } from "routes";

export const useStories = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.stories) + " " + t(tk.common.documentTitleSuffix));

  const { organization, space } = useDashboardState();

  const { data, loading, fetchMore } = useSpaceStoriesQuery({
    variables: { id: space, cursor: null },
    fetchPolicy: "cache-and-network",
  });

  const spaceName = useMemo(() => data?.space?.name || "", [data]);
  const pageInfo = useMemo(() => data?.space?.stories.pageInfo, [data]);
  const stories = useMemo(() => sortByLast(mapConnection<StoryFragment>(data?.space?.stories)), [data]);
  const hasValidData = useMemo(() => data?.space?.id === space, [data, space]);

  const createStory = useCallback(() => history.push(getRoute.storiesCreate({ organization, space })), [
    history,
    organization,
    space,
  ]);

  const editStory = useCallback((id: string) => history.push(getRoute.storiesEdit({ organization, space, id })), [
    history,
    organization,
    space,
  ]);

  const handleScroll = useCallback(
    async (e: SyntheticEvent<HTMLDivElement>) => {
      if (loading || !data?.space?.stories.pageInfo.hasPreviousPage) return;

      const scrollTop = (e.target as any).scrollTop;
      const scrollHeight = (e.target as any).scrollHeight;
      const clientHeight = (e.target as any).clientHeight;
      const pixelsFromBottom = scrollHeight - clientHeight - scrollTop;

      const before = data?.space.stories.pageInfo.startCursor || null;

      if (pixelsFromBottom < clientHeight + 100) {
        await fetchMore({ variables: { id: space, cursor: before }, updateQuery: storiesUpdateQuery });
      }
    },
    [data, fetchMore, loading, space]
  );

  return {
    t,
    tk,
    data: { spaceName, pageInfo, stories, hasValidData },
    state: { loading },
    handlers: { createStory, editStory, handleScroll },
  };
};
