import React, { useCallback, useMemo, useState } from "react";
import linkifyStr from "linkifyjs/string";
import styled from "styled-components";
import { ImageOverlay, Icon, Author, HorizontalSeparator, Claps, CommentInput, Comment } from "components";
import { IconComment } from "icons";
import { useMe } from "hooks";
import { fromNow, resolveAuthorMetaDetails } from "helpers";
import {
  mapConnection,
  StoryCommentFragment,
  storyCommentsUpdateQuery,
  StoryFragment,
  useLoadOlderStoryCommentsLazyQuery,
} from "api";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

interface Props {
  story: StoryFragment & { cursor: string };
  spaceName: string;
  onEdit: (id: string) => any;
}

export const Story = ({ story, spaceName, onEdit }: Props) => {
  const { t } = useTranslation();

  const { me } = useMe();

  const [loadOlderComments, { data, called, loading, fetchMore }] = useLoadOlderStoryCommentsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [addedComments, setAddedComments] = useState<StoryCommentFragment[]>([]);

  const canEdit = useMemo(() => me?.userId === story.author.id, [me, story.author.id]);

  const comments = useMemo(() => mapConnection<StoryCommentFragment>(story.comments), [story.comments]);
  const olderComments = useMemo(() => mapConnection<StoryCommentFragment>(data?.story?.comments), [data]);

  const hasOlderComments = useMemo(() => {
    if (data?.story?.comments) {
      return data.story.comments.pageInfo.hasPreviousPage;
    }

    return story.comments.pageInfo.hasPreviousPage;
  }, [data, story.comments.pageInfo.hasPreviousPage]);

  const [imageOverlayVisible, setImageOverlayVisible] = useState(false);

  const showImageOverlay = useCallback(() => setImageOverlayVisible(true), []);
  const hideImageOverlay = useCallback(() => setImageOverlayVisible(false), []);

  const handleClickEdit = useCallback(() => onEdit(story.id), [onEdit, story.id]);

  const handleClickLoadOlderComments = useCallback(async () => {
    if (loading) return;

    const before = data?.story?.comments
      ? data.story.comments.pageInfo.startCursor || null
      : story.comments.pageInfo.startCursor || null;

    if (called) return await fetchMore({ variables: { before }, updateQuery: storyCommentsUpdateQuery });

    await loadOlderComments({ variables: { id: story.id, before } });
  }, [called, data, fetchMore, loadOlderComments, loading, story.comments.pageInfo.startCursor, story.id]);

  const handleAddComment = useCallback(
    (comment: StoryCommentFragment) => setAddedComments([...addedComments, comment]),
    [addedComments]
  );

  return (
    <StoryWrapper>
      {/** Header */}
      <StorySection>
        <StoryHeader>
          <Author
            fullName={`${story.author.firstName} ${story.author.lastName}`}
            metaText={resolveAuthorMetaDetails(
              spaceName,
              t(tk.role[story.authorMembership.role]),
              story.authorMembership.position || undefined
            )}
            frontImage={story.author.profileImage}
          />
          <StoryHeaderMeta>
            <Icon shape={"time"} size={"12px"} color={theme.colors.IronGrey} />
            <TimeAgo>{fromNow(story.createdAt)}</TimeAgo>
            {canEdit && <EditLink onClick={handleClickEdit}>{t(tk.stories.edit)}</EditLink>}
          </StoryHeaderMeta>
        </StoryHeader>
      </StorySection>

      {/** Image */}
      {imageOverlayVisible && (
        <ImageOverlay
          src={story.headerOriginal || story.headerThumbnail || ""}
          author={`${story.author.firstName} ${story.author.lastName}`}
          spaceName={spaceName}
          createdAt={story.createdAt}
          onClose={hideImageOverlay}
        />
      )}

      {!!story.headerThumbnail && (
        <StorySection>
          <Image src={story.headerThumbnail} onClick={showImageOverlay} />
        </StorySection>
      )}

      {/** Text */}
      <StorySection>
        <StoryText
          dangerouslySetInnerHTML={{
            __html: linkifyStr(story.text).replace(/\n/g, "<br/>"),
          }}
        />
      </StorySection>

      {/** Comment count & Claps */}
      <StorySection>
        <MetaRow>
          <CommentsMeta>
            <IconComment
              width={25}
              height={25}
              color={story.commentedOnByUser || addedComments.length ? theme.colors.TealGreen : theme.colors.IronGrey}
            />
            <CommentCountText>
              {t(tk.stories.comments, { count: story.commentCount + addedComments.length })}
            </CommentCountText>
          </CommentsMeta>
          <Claps claps={story.claps} myClaps={story.myClapCount} storyId={story.id} />
        </MetaRow>
      </StorySection>

      <HorizontalSeparator style={{ margin: "10px 0" }} />

      {/** Comments */}
      {(story.commentCount > 0 || addedComments.length > 0) && (
        <StorySection>
          {hasOlderComments && (
            <LoadOlderCommentsText onClick={handleClickLoadOlderComments}>
              {t(tk.stories.loadOlderComments)}
            </LoadOlderCommentsText>
          )}

          {olderComments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}

          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}

          {addedComments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
        </StorySection>
      )}

      <StorySection>
        <CommentInput storyId={story.id} onAdd={handleAddComment} />
      </StorySection>
    </StoryWrapper>
  );
};

/**
 * Styles
 */

const StoryWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  margin: 30px 0;
  max-width: 500px;
  width: 100%;
`;

const StorySection = styled.div`
  padding: 10px 25px;
`;

const StoryHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const StoryHeaderMeta = styled.div`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.medium};
  font-size: 11px;
  color: ${theme.colors.IronGrey};
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const TimeAgo = styled.span`
  margin-left: 5px;
`;

const EditLink = styled.span`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;

const Image = styled.img`
  aspect-ratio: 4/3;
  border-radius: 5px;
  object-fit: contain;
  cursor: pointer;
  width: 100%;
  height: auto;
  background-color: ${theme.colors.FadingGrey};
`;

const StoryText = styled.div`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 16px;
  color: ${theme.colors.NightSky};
  line-height: 24px;

  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  hyphens: auto;
`;

const MetaRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CommentsMeta = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
`;

const CommentCountText = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 12px;
  color: ${theme.colors.IronGrey};
  margin-left: 10px;
`;

const LoadOlderCommentsText = styled.a`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.semiBold};
  font-size: 14px;
  color: ${theme.colors.TealGreen};
  line-height: 28px;
  padding-bottom: 18px;
  display: inline-block;
  cursor: pointer;
`;
