import { useMemo } from "react";
import { useDashboardState } from "hooks";
import { Languages, mapConnection, resolveError, Roles, useAuth, useMeQuery } from "api";
import { Language } from "translations";

type Space = {
  id: string;
  name: string;
  logo: string;
  unresolvedDocumentCount: number;
  unresolvedAnnouncementCount: number;
  myMembership: { role: Roles };
};
type Organization = {
  id: string;
  name: string;
  logo: string;
  hasBenefits: boolean;
  unresolved: boolean;
  unresolvedChatCount: number;
  spaces: Space[];
};

export const useMe = (cacheAndNetwork: boolean = false) => {
  const { isLoggedIn } = useAuth();

  const { data, loading, refetch } = useMeQuery({
    skip: !isLoggedIn(),
    fetchPolicy: cacheAndNetwork ? "cache-and-network" : undefined,
    onError: (e) => resolveError(e),
  });

  const { organization, space } = useDashboardState();

  const me = useMemo(() => data?.me || undefined, [data]);

  const organizations: Organization[] = useMemo(
    () =>
      mapConnection(me?.organizations).map(({ spaces, ...rest }) => {
        const mappedSpaces = mapConnection(spaces);
        const unresolved =
          rest.unresolvedChatCount > 0 ||
          mappedSpaces.filter(
            ({ unresolvedDocumentCount, unresolvedAnnouncementCount }) =>
              unresolvedDocumentCount > 0 || unresolvedAnnouncementCount > 0
          ).length > 0;

        return { ...rest, spaces: mappedSpaces, unresolved };
      }),
    [me]
  );

  const activeOrganization = useMemo(
    () => organizations.find(({ id }) => id === organization),
    [organization, organizations]
  );

  const activeSpace = useMemo(
    () => activeOrganization?.spaces.find(({ id }) => id === space),
    [activeOrganization, space]
  );

  const isManager = useMemo(() => activeSpace?.myMembership.role === Roles.Manager, [activeSpace]);

  const activeLanguage = useMemo(() => {
    if (me?.language === Languages.Sk) return Language.Sk;
    if (me?.language === Languages.En) return Language.En;
    if (me?.language === Languages.De) return Language.De;
    return Language.Cs;
  }, [me]);

  return { me, organizations, activeOrganization, activeSpace, isManager, activeLanguage, loading, refetch };
};
