import React from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children?: never;
  size?: string;
  isOutlined?: boolean;
  margin?: string;
  borderWidth?: number;
  backgroundColor?: string;
  borderColor?: string;
};

export const Dot = ({
  size = "10px",
  isOutlined,
  margin,
  borderWidth = 2,
  backgroundColor = theme.dot.backgroundColor,
  borderColor = theme.dot.borderColor,
}: Props) => {
  return (
    <StyledSvg
      width={size}
      height={size}
      viewBox={"0 0 10 10"}
      xmlns={"http://www.w3.org/2000/svg"}
      isOutlined={isOutlined}
      margin={margin}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <circle cx={5} cy={5} r={5 - borderWidth / 2} strokeWidth={borderWidth} />
    </StyledSvg>
  );
};

/**
 * Styles
 */

type StyledDotProps = Pick<Props, "isOutlined" | "margin" | "backgroundColor" | "borderColor">;

export const StyledSvg = styled("svg").withConfig({
  shouldForwardProp: (prop) => !["isOutlined", "margin", "backgroundColor", "borderColor"].includes(prop),
})<StyledDotProps>`
  position: relative;
  margin: ${(props) => props.margin ?? "initial"};

  circle {
    fill: ${(props) => (props.isOutlined ? "transparent" : props.backgroundColor)};
    stroke: ${(props) => props.borderColor};
  }
`;
