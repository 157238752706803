import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntersection } from "react-use";
import styled from "styled-components";
import { BenefitsQuery, useBenefitsQuery, useLogBenefitClickMutation, useLogBenefitViewsMutation } from "api";
import { Icon, NavigationLinkText, Spinner } from "components";
import { font, theme } from "styles";
import { fromNow } from "helpers";
import { useMe } from "hooks";
import { tk } from "translations";

export const Benefits = () => {
  const { t } = useTranslation();
  const { activeOrganization } = useMe();
  const { data, loading } = useBenefitsQuery({ fetchPolicy: "cache-and-network" });

  const benefits = useMemo(
    () => data?.me.organizations.edges.find(({ node }) => node.id === activeOrganization?.id)?.node.benefits ?? [],
    [activeOrganization, data]
  );

  const isLoading = !data || loading;

  return (
    <Container>
      <Header>
        <NavigationLinkText>{t(tk.benefits)}</NavigationLinkText>
      </Header>

      <Content>
        <ContentContainer>
          {isLoading && (
            <SpinnerWrapper>
              <Spinner color={theme.colors.IronGrey} />
            </SpinnerWrapper>
          )}

          <Wrapper>
            {benefits.map((benefitProps) => (
              <Benefit key={benefitProps.id} data={benefitProps} />
            ))}
          </Wrapper>
        </ContentContainer>
      </Content>
    </Container>
  );
};

type BenefitProps = {
  data: BenefitsQuery["me"]["organizations"]["edges"][number]["node"]["benefits"][number];
  root?: Element;
};

const Benefit = ({ data: { id, url, name, text, image, createdAt }, root }: BenefitProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref, { threshold: 0.5, root });

  const isViewLoggedRef = useRef(false);
  const [isImageLoaded, setIsImageLoaded] = useState(!image);

  const [logClick] = useLogBenefitClickMutation();
  const [logView] = useLogBenefitViewsMutation();

  useEffect(() => {
    if (!isImageLoaded || isViewLoggedRef.current || !intersection?.isIntersecting) return;

    isViewLoggedRef.current = true;
    logView({ variables: { input: { benefits: [id] } } });
  }, [id, intersection, isImageLoaded, logView]);

  return (
    <BenefitWrapper ref={ref}>
      <BenefitSection>
        <BenefitHeader>
          <BenefitName>{name}</BenefitName>
          <BenefitHeaderMeta>
            <Icon shape={"time"} size={"12px"} color={theme.colors.IronGrey} />
            <TimeAgo>{fromNow(createdAt)}</TimeAgo>
          </BenefitHeaderMeta>
        </BenefitHeader>
      </BenefitSection>

      <BenefitSection>
        <BenefitText>{text}</BenefitText>
      </BenefitSection>

      {!!image && (
        <BenefitSection>
          <Image src={image} onLoad={() => window.setTimeout(() => setIsImageLoaded(true), 500)} />
        </BenefitSection>
      )}

      {!!url && (
        <BenefitSection>
          <BenefitLink
            href={url}
            target={"_blank"}
            rel={"noreferrer noopener"}
            onClick={() => logClick({ variables: { input: { benefit: id } } })}
          >
            {trimUrl(url)}
          </BenefitLink>
        </BenefitSection>
      )}
    </BenefitWrapper>
  );
};

/**
 * Utils
 * */

function trimUrl(url: string): string {
  return url.replace("https://", "").split("?")[0].replace(/(\/)$/, "");
}

/**
 * Styles
 * Mostly copy from Stories
 */

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0;
`;

const Container = styled("div")`
  position: relative;
  flex: 1 auto;
  overflow: hidden;
`;

const Content = styled("div")`
  display: flex;
  flex: 1 100%;
  height: calc(100% - 65px);
`;

const ContentContainer = styled("div")`
  position: relative;
  width: 100%;
  padding: 0 25px;
  overflow: auto;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  height: 65px;
  width: 100%;
  padding: 0 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.navbar.borderColor};
  background-color: ${theme.navbar.backgroundColor};
`;

const BenefitWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  margin: 40px 0 0 0;
  max-width: 740px;
  width: 100%;

  &:last-child {
    margin-bottom: 40px;
  }
`;

const BenefitSection = styled.div`
  padding: 10px 25px;
`;

const BenefitHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const BenefitHeaderMeta = styled.div`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.medium};
  font-size: 11px;
  color: ${theme.colors.IronGrey};
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const TimeAgo = styled.span`
  margin-left: 5px;
`;

const BenefitName = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.bold};
  font-size: 16px;
  color: ${theme.colors.TealGreen};
`;

const Image = styled.img`
  border-radius: 5px;
  max-width: 100%;
`;

const BenefitText = styled.div`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 16px;
  color: ${theme.colors.NightSky};
  line-height: 24px;

  hyphens: auto;
`;

const BenefitLink = styled.a`
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  padding: 20px;
  border-radius: 5px;
  background: ${theme.colors.FadingGrey};
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.bold};
  font-size: 14px;
  color: ${theme.colors.TealGreen};
  line-height: 24px;

  &:hover {
    background: ${theme.colors.SilverGrey};
  }
`;
