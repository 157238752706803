import styled from "styled-components";
import { font, theme } from "styles";

export const FieldHeading = styled("span")`
  position: relative;
  font-size: 14px;
  font-weight: ${font.weight.bold};
  color: ${theme.fieldHeading.fontColor};
  margin-top: 5px;
  margin-bottom: 15px;
  grid-area: field-heading;
`;
