import React from "react";
import styled from "styled-components";
import { font, theme } from "styles";

type Props = {
  children: string;
  onClick: () => void;
  isBold?: boolean;
  disabled?: boolean;
};

export const RecipientsSelectionAddButton = ({ children, onClick, isBold, disabled = false }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  return (
    <StyledButton disabled={disabled} onClick={handleClick}>
      <StyledButtonLabel isBold={isBold}>{`+ ${children}`}</StyledButtonLabel>
    </StyledButton>
  );
};

/**
 * Styles
 */

type StyledButtonLabelProps = Pick<Props, "isBold">;

const StyledButtonLabel = styled("span").withConfig({
  shouldForwardProp: (prop) => !["isBold"].includes(prop),
})<StyledButtonLabelProps>`
  position: relative;
  font-size: 12px;
  font-weight: ${(props) => (props.isBold ? font.weight.bold : font.weight.medium)};
  color: ${theme.recipientsSelectionAddButton.fontColor.default};
`;

const StyledButton = styled("button")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;

  &:hover ${StyledButtonLabel} {
    color: ${theme.recipientsSelectionAddButton.fontColor.hover};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px ${theme.recipientsSelectionAddButton.outlineColor};

    & ${StyledButtonLabel} {
      color: ${theme.recipientsSelectionAddButton.fontColor.focus};
    }
  }

  &:active ${StyledButtonLabel} {
    color: ${theme.recipientsSelectionAddButton.fontColor.active};
  }

  &:disabled {
    cursor: not-allowed;

    & ${StyledButtonLabel} {
      color: ${theme.recipientsSelectionAddButton.fontColor.disabled};
    }
  }
`;
