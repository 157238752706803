import { FormEvent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { resolveError, useAuth, useConfirmPasswordResetMutation, useRequestPasswordResetMutation } from "api";
import { tk, useTranslation } from "translations";
import { getRoute, PasswordRecoveryParams, routes } from "routes";

export const usePasswordRecovery = (step: PasswordRecoveryParams["step"]) => {
  const { t } = useTranslation();

  useTitle(t(tk.passwordRecovery.heading) + " " + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const [requestPasswordReset, { loading: loadingRequest }] = useRequestPasswordResetMutation();
  const [confirmPasswordReset, { loading: loadingConfirm }] = useConfirmPasswordResetMutation();
  const { login, loading: loadingLogin } = useAuth();

  const loading = useMemo(
    () => loadingRequest || loadingConfirm || loadingLogin,
    [loadingConfirm, loadingLogin, loadingRequest]
  );

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const handleLoginSuccess = useCallback(() => history.push(routes.dashboard), [history]);
  const handleLoginError = useCallback(() => history.push(routes.login), [history]);

  const handleTokenError = useCallback(() => {
    history.push(getRoute.passwordRecovery({ step: "error" }));
  }, [history]);

  const request = useCallback(async () => {
    try {
      await requestPasswordReset({ variables: { input: { email } } });
      await setError("");

      history.push(getRoute.passwordRecovery({ step: "2" }));
    } catch (e: any) {
      const not_found = () => setError(t(tk.passwordRecovery.form.email.invalid));

      resolveError(e, { not_found });
    }
  }, [email, history, requestPasswordReset, t]);

  const confirm = useCallback(async () => {
    try {
      await confirmPasswordReset({ variables: { input: { token, password } } });
      await setError("");

      if (!email) return handleLoginError();

      await login(email, password, handleLoginSuccess, handleLoginError);
    } catch (e: any) {
      const password_too_short = () => setError(t(tk.passwordRecovery.form.password.tooShort));
      const not_found = handleTokenError;
      const token_invalid = handleTokenError;

      resolveError(e, { password_too_short, not_found, token_invalid });
    }
  }, [confirmPasswordReset, email, handleLoginError, handleLoginSuccess, handleTokenError, login, password, t, token]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) return;

      if (step === "1") await request();

      if (step === "2") await confirm();
    },
    [confirm, loading, request, step]
  );

  const handleChangeEmail = useCallback((value: string) => setEmail(value), []);
  const handleChangeToken = useCallback((value: string) => setToken(value), []);
  const handleChangePassword = useCallback((value: string) => setPassword(value), []);

  return {
    t,
    tk,
    state: { step, loading, email, token, password, error },
    handlers: { handleSubmit, handleChangeEmail, handleChangeToken, handleChangePassword },
  };
};
