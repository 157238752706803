import React from "react";
import styled from "styled-components";
import { Title } from "components/Title";
import { DetailRecipientDetail } from "components/Detail/DetailRecipientDetail";

type Props = {
  sectionTitle: string;
  recipients: { id: string; firstName: string; lastName: string; profileImage: string; isActive?: boolean }[];
};

export const DetailSharedWithSection = ({ sectionTitle, recipients }: Props) => {
  return (
    <Section>
      <SectionTitleContainer>
        <Title>{sectionTitle}</Title>
      </SectionTitleContainer>

      <ListContainer>
        {recipients.map(({ id, firstName, lastName, profileImage, isActive }) => (
          <ListItemContainer key={id}>
            <DetailRecipientDetail
              profileImage={profileImage}
              firstName={firstName}
              lastName={lastName}
              isActive={isActive || false}
            />
          </ListItemContainer>
        ))}
      </ListContainer>
    </Section>
  );
};

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 30px 0;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
  margin-bottom: 20px;
`;

const ListContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 0 60px;
`;

const ListItemContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
