import React, { useCallback, useContext, useRef } from "react";
import styled from "styled-components";
import { LanguageSelectContext } from "components/LanguageSelect/LanguageSelect";

type Props = {
  children: React.ReactNode;
};

export const LanguageSelectToggle = ({ children }: Props) => {
  const { setIsSelectOpen, isSelectOpen } = useContext(LanguageSelectContext);

  const ref = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      ref.current && ref.current.focus();
      setIsSelectOpen && setIsSelectOpen(!isSelectOpen);
    },
    [isSelectOpen, setIsSelectOpen]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      event.preventDefault();

      if (event.key === "Escape" && isSelectOpen && setIsSelectOpen) {
        setIsSelectOpen(false);
      }
    },
    [isSelectOpen, setIsSelectOpen]
  );

  return (
    <StyledButton ref={ref} onClick={handleClick} onKeyDown={handleKeyDown}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled("button")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  padding: 13px 20px;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;
