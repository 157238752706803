import React, { RefObject, UIEvent, useEffect } from "react";
import styled from "styled-components";
import { Message } from "chat/components/message/Message";
import { TimeDivider } from "chat/components/message/TimeDivider";
import { isSameDay, timeAgo } from "helpers";

type Messages = {
  id: string;
  message: string;
  createdAt: string;
  author: { id: string; firstName: string; profileImage: string };
}[];

interface Props {
  messages: Messages;
  isGroupChat: boolean;
  myUserId: string;
  wrapperRef: RefObject<HTMLDivElement>;
  onScroll: (e: UIEvent<HTMLDivElement>) => any;
  scrollToBottom: () => void;
}

export const MessageList = ({ messages, isGroupChat, myUserId, wrapperRef, onScroll, scrollToBottom }: Props) => {
  useEffect(scrollToBottom, [scrollToBottom]);

  return (
    <Wrapper ref={wrapperRef} onScroll={onScroll}>
      {messages.map(({ id, author, message: text, createdAt }, index) => {
        const isLastMessage = index === messages.length - 1;
        const previousMessage = messages[index - 1];
        const messageType = myUserId === author.id ? "outgoing" : "incoming";
        const showDateSeparator = !previousMessage ? true : !isSameDay(previousMessage.createdAt, createdAt);

        return (
          <div key={id}>
            {showDateSeparator && <TimeDivider label={timeAgo(createdAt)} />}

            <Message
              messageType={messageType}
              message={text}
              timestamp={createdAt}
              profileImage={author.profileImage}
              author={author.firstName}
              showAuthor={messageType === "incoming" && isGroupChat}
              onShowTime={isLastMessage ? scrollToBottom : undefined}
            />
          </div>
        );
      })}
    </Wrapper>
  );
};

/**
 * Styles
 */

const Wrapper = styled.div`
  display: block;
  overflow-y: scroll;
  flex-grow: 1;
`;
