import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

type ChevronProps = {
  type?: "back" | "down" | "up" | "forward";
} & IconCommonPropsType;

export const IconChevron = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  type = "down",
  margin,
}: ChevronProps) => {
  const getRotation = () => {
    switch (type) {
      case "back":
        return "rotate(-90deg)";
      case "forward":
        return "rotate(90deg)";
      case "up":
        return "rotate(0)";
      case "down":
        return "rotate(180deg)";
    }
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30.22 16.844"
      style={{ transform: getRotation(), margin }}
    >
      <path
        id="back"
        d="M29,24.438a1.987,1.987,0,0,1-1.25-.5L15.687,11.813,3.562,23.938a1.9,1.9,0,0,1-2.5,0,1.772,1.772,0,0,1,0-2.5L14.437,8.063a1.819,1.819,0,0,1,2.438,0L30.25,21.438a1.772,1.772,0,0,1,0,2.5A1.983,1.983,0,0,1,29,24.438Z"
        transform="translate(-0.546 -7.594)"
        fill={color}
      />
    </svg>
  );
};
