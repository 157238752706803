import React, { createContext, useEffect, useState } from "react";
import styled from "styled-components";
import { IconMinusHexa, IconPlusHexa } from "icons";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
  onChange: (optionIndex: number) => void;
  margin?: string;
};

export const SelectContext = createContext<{ activeIndex?: number }>({ activeIndex: undefined });

export const Select = ({ children, onChange, margin }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const childrenCount = React.Children.count(children);

  const handleLeftButtonClick = (e: any) => {
    e.preventDefault();

    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleRightButtonClick = (e: any) => {
    e.preventDefault();

    if (activeIndex < childrenCount - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  useEffect(() => {
    onChange(activeIndex);
  }, [onChange, activeIndex]);

  return (
    <SelectContext.Provider value={{ activeIndex }}>
      <Container margin={margin}>
        <SelectButton onClick={handleLeftButtonClick}>
          <IconMinusHexa
            width={"35px"}
            height={"37px"}
            color={activeIndex === 0 ? theme.select.iconColor.inactive : theme.select.iconColor.active}
          />
        </SelectButton>
        {React.Children.toArray(children)[activeIndex]}
        <SelectButton onClick={handleRightButtonClick}>
          <IconPlusHexa
            width={"35px"}
            height={"37px"}
            color={activeIndex === childrenCount - 1 ? theme.select.iconColor.inactive : theme.select.iconColor.active}
          />
        </SelectButton>
      </Container>
    </SelectContext.Provider>
  );
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "margin">;

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["margin"].includes(prop) })<ContainerProps>`
  display: grid;
  position: relative;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: 1fr;
  width: 100%;
  margin: ${(props) => props.margin ?? 0};
`;

const SelectButton = styled("button")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
