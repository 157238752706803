import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { Badge, Hexagon } from "components";
import { tk, useTranslation } from "translations";
import { theme } from "styles";

interface Props {
  id: string;
  heading: string;
  subheading: string;
  imageSrc: string;
  onClick: (id: string) => void;
  rightContent?: ReactNode;
  isActive: boolean;
  showGroupIcon?: boolean;
}

export const ListItem = ({
  id,
  heading,
  subheading,
  imageSrc,
  isActive,
  onClick,
  rightContent = null,
  showGroupIcon = false,
}: Props) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <ComponentWrapper>
      <ClickableWrapper onClick={handleClick}>
        <ImageWrapper>
          <Hexagon imageSource={imageSrc} size={{ width: "35px", height: "37px" }} />

          {showGroupIcon && (
            <GroupIconWrapper>
              <Hexagon
                iconShape={"group"}
                strokeColor={theme.colors.SnowWhite}
                strokeWidth={"3px"}
                iconColor={theme.colors.SnowWhite}
                color={theme.colors.TealGreen}
                size={{ width: "20px", height: "20px" }}
                iconSize={"11px"}
                cursor={"pointer"}
              />
            </GroupIconWrapper>
          )}
        </ImageWrapper>

        <Details>
          <Heading color={isActive ? "inherit" : theme.colors.IronGrey}>{heading}</Heading>
          <Subheading>
            {!isActive && <NotActiveBadge>{t(tk.common.notActive)}</NotActiveBadge>}
            {subheading}
          </Subheading>
        </Details>

        {rightContent}
      </ClickableWrapper>
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const overflowStyle = `
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 165px;
`;

const ComponentWrapper = styled.li`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    background-color: #fbfbfb;
  }
`;

const ClickableWrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 17px;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Details = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0 0 0 8px;
  flex: 1;
`;

const Heading = styled.div.withConfig({ shouldForwardProp: (prop) => !["color"].includes(prop) })<{ color: string }>`
  margin: 2px 0;
  color: ${(props) => props.color};
  ${overflowStyle}
`;

const Subheading = styled.div`
  color: ${theme.colors.IronGrey};
  margin: 2px 0;
  font-size: 10px;
  ${overflowStyle}
`;

const NotActiveBadge = styled(Badge)`
  color: #404040;
  background-color: ${theme.colors.SilverGrey};
  cursor: pointer;
  margin-right: 5px;
`;

const GroupIconWrapper = styled.div`
  position: absolute;
  top: 22px;
  left: 20px;
`;
