import React, { useCallback, useContext, useRef } from "react";
import styled from "styled-components";
import { HeaderDropdownContext } from "components/HeaderDropdown/HeaderDropdown";

type Props = {
  children: React.ReactNode;
};

export const HeaderDropdownToggle = ({ children }: Props) => {
  const { isDropdownOpen, setIsDropdownOpen } = useContext(HeaderDropdownContext);

  const ref = useRef<HTMLButtonElement>(null);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      event.preventDefault();

      if (event.key === "Escape" && isDropdownOpen && setIsDropdownOpen) {
        setIsDropdownOpen(false);
      }
    },
    [isDropdownOpen, setIsDropdownOpen]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      ref.current && ref.current.focus();
      setIsDropdownOpen && setIsDropdownOpen(!isDropdownOpen);
    },
    [isDropdownOpen, setIsDropdownOpen]
  );

  return (
    <Button ref={ref} onClick={handleClick} onKeyDown={handleKeyDown}>
      {children}
    </Button>
  );
};

const Button = styled("button")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;

  &:focus {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;
