import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { font, theme } from "styles";

export class DetailListSection extends React.Component<PropsWithChildren> {
  render() {
    return (
      <Section>
        <TextContainer>
          <DetailList>{this.props.children}</DetailList>
        </TextContainer>
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`;

const TextContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;

export const DetailList = styled.ul`
  font-weight: ${font.weight.regular};
  color: ${theme.colors.NightSky};
  line-height: 24px;
  > li {
    margin-bottom: 8px;
  }
`;
