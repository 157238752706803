import React, { ComponentProps } from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children: string;
  color?: "blue" | "green";
  width?: string;
  margin?: string;
  onClick?: () => any;
  isDisabled?: boolean;
  type?: ComponentProps<"button">["type"];
};

export const Button = ({ children, onClick = () => {}, isDisabled, color, width, margin, type }: Props) => {
  const handleClick = (event: React.MouseEvent) => {
    if (type !== "submit" && !isDisabled) {
      event.preventDefault();
      onClick();
    }
  };
  return (
    <StyledButton onClick={handleClick} disabled={isDisabled} color={color} width={width} margin={margin} type={type}>
      <StyledButtonLabel>{children}</StyledButtonLabel>
    </StyledButton>
  );
};

/**
 * Styles
 */

const StyledButtonLabel = styled("span")`
  display: inline-flex;
  position: relative;
  color: ${theme.button.fontColor.default};
  font-weight: 700;
  font-size: 14px;
  padding: 3px 0 0 0;
  text-align: center;
  line-height: 1;
`;

type StyledButtonProps = Pick<Props, "color" | "width" | "margin">;

const StyledButton = styled("button").withConfig({
  shouldForwardProp: (prop) => !["color", "width", "margin"].includes(prop),
})<StyledButtonProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => {
    switch (props.color) {
      case "green":
        return theme.button.backgroundColor.green.default;
      case "blue":
      default:
        return theme.button.backgroundColor.blue.default;
    }
  }};
  height: 46px;
  width: ${(props) => props.width ?? "initial"};
  min-width: 150px;
  border: none;
  border-radius: 5px;
  border-bottom: solid 3px
    ${(props) => {
      switch (props.color) {
        case "green":
          return theme.button.borderColor.green;
        case "blue":
        default:
          return theme.button.borderColor.blue;
      }
    }};
  padding: 0 32px;
  margin: ${(props) => props.margin ?? 0};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => {
      switch (props.color) {
        case "green":
          return theme.button.backgroundColor.green.hover;
        case "blue":
        default:
          return theme.button.backgroundColor.blue.hover;
      }
    }};
  }

  &:focus {
    background-color: ${(props) => {
      switch (props.color) {
        case "green":
          return theme.button.backgroundColor.green.focus;
        case "blue":
        default:
          return theme.button.backgroundColor.blue.focus;
      }
    }};
    outline: 0;
    box-shadow: 0 0 0 5px
      ${(props) => {
        switch (props.color) {
          case "green":
            return theme.button.outlineColor.green;
          case "blue":
          default:
            return theme.button.outlineColor.blue;
        }
      }};
  }

  &:active {
    background-color: ${(props) => {
      switch (props.color) {
        case "green":
          return theme.button.backgroundColor.green.active;
        case "blue":
        default:
          return theme.button.backgroundColor.blue.active;
      }
    }};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${theme.button.backgroundColor.disabled};
    border-bottom: solid 3px ${theme.button.borderColor.disabled};

    & ${StyledButtonLabel} {
      color: ${theme.button.fontColor.disabled};
    }
  }
`;
