import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { Hexagon } from "components/Hexagon";
import { useMe } from "hooks";
import { resolveError, StoryCommentFragment, useStoryAddCommentMutation } from "api";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

interface Props {
  storyId: string;
  onAdd: (comment: StoryCommentFragment) => any;
}

export const CommentInput = ({ storyId, onAdd }: Props) => {
  const { t } = useTranslation();
  const { me } = useMe();

  const [message, setMessage] = useState("");

  const [storyAddComment, { loading }] = useStoryAddCommentMutation();

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value), []);

  const handleKeyPress = useCallback(
    async (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();

        if (message.trim().length === 0) return;

        try {
          const { data } = await storyAddComment({ variables: { input: { story: storyId, text: message } } });

          if (data) onAdd(data.storyAddComment.comment);
        } catch (e: any) {
          resolveError(e);
        }

        setMessage("");
      }
    },
    [message, onAdd, storyAddComment, storyId]
  );

  if (!me) return null;

  return (
    <CommentInputWrapper>
      <AuthorImage imageSource={me.profileImage} size={{ width: "37px", height: "41px" }} />
      <Input
        value={message}
        placeholder={t(tk.stories.addComment)}
        disabled={loading}
        rows={1}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    </CommentInputWrapper>
  );
};

/**
 * Styles
 */

const CommentInputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Input = styled.textarea`
  padding: 13px 20px;
  border: 1px solid #ececec;
  border-radius: 100px;
  width: 100%;

  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.medium};
  font-size: 14px;
  color: ${theme.colors.NightSky};

  resize: none;

  &:focus {
    outline: 0;
    border: 1px solid ${theme.colors.IronGrey};
  }
`;

const AuthorImage = styled(Hexagon)`
  margin-right: 14px;
`;
