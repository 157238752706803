import React, { HTMLAttributes } from "react";
import styled from "styled-components";

const StyledBadge = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 10px;
  color: #ffffff;
  background-color: #008c82;
  padding: 5px 14px 5px 14px;
  border-radius: 100px;
  cursor: default;
`;

export class Badge extends React.PureComponent<HTMLAttributes<HTMLSpanElement>> {
  render() {
    return <StyledBadge {...this.props}>{this.props.children}</StyledBadge>;
  }
}
