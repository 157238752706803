import React from "react";
import styled from "styled-components";
import { PreviewSpinner } from "components";
import { font, theme } from "styles";

interface Props
  extends React.PropsWithChildren<{
    position: number;
    compact?: boolean;
    loading?: boolean;
    width?: string;
    height?: string;
  }> {}

export const Window = ({
  position,
  compact = false,
  loading = false,
  width = "250px",
  height = "366px",
  children,
}: Props) => {
  return (
    <ComponentWrapper position={position} compact={compact} width={width} height={height}>
      {loading && !compact && <PreviewSpinner />}
      {!loading && children}
    </ComponentWrapper>
  );
};

/**
 * Styles
 */
const ComponentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["position", "compact", "width", "height"].includes(prop),
})<{
  position: number;
  compact?: boolean;
  width: string;
  height: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => props.width};
  height: ${(props) => (props.compact ? "auto" : props.height)};
  background-color: ${theme.colors.SnowWhite};
  font-size: 12px;
  font-weight: ${font.weight.medium};
  border: 1px solid ${font.weight.bold};
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
`;
