import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconCancel = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.595 15.595"
      style={{ margin }}
    >
      <path
        id="cancel"
        d="M16.125,14.875,9.875,8.562a1.035,1.035,0,0,0-1.313,0,1.035,1.035,0,0,0,0,1.313l6.313,6.25L8.562,22.438a.949.949,0,0,0,0,1.25,1.035,1.035,0,0,0,1.313,0l6.25-6.313,6.313,6.313a.884.884,0,0,0,1.25-1.25l-6.313-6.313,6.313-6.25a1.035,1.035,0,0,0,0-1.313.948.948,0,0,0-1.25,0Z"
        transform="translate(-8.327 -8.327)"
        fill={color}
      />
    </svg>
  );
};
