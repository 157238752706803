import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDocumentsDetail } from "controllers";
import { DocumentsLayout } from "layouts";
import { Button, Dialog, DialogScreen, HorizontalSeparator, PreviewSpinner } from "components";
import * as Detail from "components/Detail";
import { convertFileSize, fromNow } from "helpers";
import { DocumentsDetailParams } from "routes";
import { theme } from "styles";

interface Props extends RouteComponentProps<DocumentsDetailParams> {}

export const DocumentsDetail = ({ match }: Props) => {
  const { t, tk, data, state, handlers } = useDocumentsDetail(match.params.tab, match.params.id);

  const loading = useMemo(() => (state.loading && !data.document) || data.document?.id !== state.id, [
    data.document,
    state.id,
    state.loading,
  ]);

  return (
    <DocumentsLayout
      buttons={
        !data.isManager ? null : (
          <Button color={"green"} onClick={handlers.createDocument}>
            {t(tk.documents.navigation.create)}
          </Button>
        )
      }
      tab={state.tab}
      activeDocumentId={state.id}
    >
      {loading && <PreviewSpinner />}

      {!!data.document && !loading && (
        <Detail.Detail>
          <Detail.DetailHeaderSection
            heading={data.document.title}
            subheading={t(tk.documents.lastUpdate, {
              before: fromNow(data.document.updatedAt || data.document.createdAt),
            })}
          />

          <Detail.DetailMetaDetailSection
            authorFullName={`${data.document.author.firstName} ${data.document.author.lastName}`}
            authorMetaText={`${data.document.space.name} • ${
              data.document.authorMembership.position || t(tk.role[data.document.authorMembership.role])
            }`}
            authorProfileImage={data.document.author.profileImage}
            rightSideContainer={
              <Detail.DetailMetaDetailsRightSide
                isEditable={data.isAuthor}
                editAction={handlers.editDocument}
                deleteAction={handlers.deleteDocument}
                fileLink={data.document.file || undefined}
              />
            }
          />

          <HorizontalSeparator />

          <Detail.DetailFileDetailSection
            fileName={data.fileName}
            fileMeta={`${data.document.type?.toUpperCase()} • ${convertFileSize(data.document.size || 0)}`}
            fileLink={data.document.file || ""}
          />

          <HorizontalSeparator />

          <Detail.DetailSharedWithSection
            sectionTitle={t(tk.documents.sharedWith, { count: data.recipients.length })}
            recipients={data.recipients}
          />

          {state.deleteDialogVisible && (
            <DialogScreen>
              <Dialog
                buttonTitle={t(tk.documents.delete)}
                iconImage={"document"}
                iconImageBackgroundColor={theme.colors.FireRed}
                text={t(tk.documents.deleteConfirmation)}
                hasCloseButton={true}
                onButtonClick={handlers.handleDeleteDocument}
                onCloseButtonClick={handlers.closeDeleteDialog}
              />
            </DialogScreen>
          )}
        </Detail.Detail>
      )}
    </DocumentsLayout>
  );
};
