import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { theme } from "styles";
import { Icon } from "components/Icon";

type Props = {
  src: string;
  thumbnail?: string;
};

/* eslint-disable jsx-a11y/iframe-has-title */
export function AnnouncementImageSection({ src, thumbnail }: Props) {
  const [imageOverlayVisible, setImageOverlayVisible] = useState(false);

  const showImageOverlay = useCallback(() => setImageOverlayVisible(true), []);
  const hideImageOverlay = useCallback(() => setImageOverlayVisible(false), []);

  return (
    <>
      <Section>
        <Thumbnail src={thumbnail ?? src} alt="" onClick={showImageOverlay} />
      </Section>

      {imageOverlayVisible && (
        <Overlay onClick={hideImageOverlay}>
          <ImageOverlayContainer>
            <ImageOverlayHeader>
              <Icon shape={"cancel"} size={"36px"} cursor={"pointer"} onClick={hideImageOverlay} />
            </ImageOverlayHeader>
            <ImageOverlayImageWrapper>
              <ImageOverlayImage src={src} onClick={(e: any) => e.stopPropagation()} />
            </ImageOverlayImageWrapper>
          </ImageOverlayContainer>
        </Overlay>
      )}
    </>
  );
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
  margin-bottom: 30px;
`;

const Thumbnail = styled.img`
  max-width: 300px;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${theme.colors.FadingGrey};
`;

const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;

const ImageOverlayContainer = styled.div`
  max-width: 883px;
  margin: 0 auto;
  height: 100%;
  padding: 120px 20px;
`;

const ImageOverlayImageWrapper = styled.div`
  position: relative;
  height: calc(100% - 50px);
`;

const ImageOverlayImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const ImageOverlayHeader = styled.div`
  font-size: 16px;
  color: ${theme.colors.SnowWhite};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 37px;
  margin-bottom: 7px;
`;
