import React from "react";
import styled from "styled-components";
import linkifyStr from "linkifyjs/string";
import { DetailText } from "components/Detail/DetailText";

type Props = {
  text: string;
};

export class DetailTextSection extends React.PureComponent<Props> {
  render() {
    return (
      <Section>
        <TextContainer>
          <DetailText
            dangerouslySetInnerHTML={{
              __html: linkifyStr(this.props.text).replace(/\n/g, "<br>"),
            }}
          />
        </TextContainer>
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`;

const TextContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;
