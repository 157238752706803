import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SupportLayout } from "layouts";
import {
  Button,
  ButtonLink,
  Card,
  Dialog,
  DialogScreen,
  Field,
  FieldLabel,
  HorizontalSeparator,
  Textarea,
} from "components";
import { tk, useTranslation } from "translations";
import { getRoute } from "routes";
import { useDashboardState } from "hooks";
import { resolveError, useFeedbackCreateMutation } from "api";

export const Feedback = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { organization, space } = useDashboardState();

  const [text, setText] = useState("");

  const [success, setSuccess] = useState(false);
  const closeDialog = useCallback(() => setSuccess(false), []);

  const [send, { loading }] = useFeedbackCreateMutation();

  const submit = useCallback(async () => {
    if (!text.length) return;

    try {
      await send({ variables: { input: { text } } });
      setSuccess(true);
      setText("");
    } catch (e: any) {
      resolveError(e);
    }
  }, [send, text]);

  const cancel = useCallback(() => {
    history.replace(getRoute.support({ organization, space, role: "regular", topic: "all" }));
  }, [history, organization, space]);

  return (
    <SupportLayout tab={"feedback"}>
      <Content>
        <ContentCard>
          <Field width={"100%"} padding={"40px 50px"}>
            <FieldLabel>{t(tk.support.reportProblem) + "*"}</FieldLabel>
            <Textarea
              value={text}
              placeholder={t(tk.support.feedbackPlaceholder)}
              isDisabled={loading}
              width={"100%"}
              onChange={setText}
            />
          </Field>

          <HorizontalSeparator />

          <Footer>
            <ButtonLink onClick={cancel} isDisabled={loading} margin={"0 30px 0 0"}>
              {t(tk.common.cancel)}
            </ButtonLink>
            <Button onClick={submit} isDisabled={loading || !text.length} color={"green"}>
              {t(tk.common.send)}
            </Button>
          </Footer>

          {success && (
            <DialogScreen>
              <Dialog buttonTitle={t(tk.common.ok)} onButtonClick={closeDialog} text={t(tk.support.feedbackSuccess)} />
            </DialogScreen>
          )}
        </ContentCard>
      </Content>
    </SupportLayout>
  );
};

/**
 * Styles
 */

const Content = styled("div")`
  display: flex;
  padding: 40px;
  width: 100%;
  max-width: 740px;
`;

const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 30px 50px;
`;
