import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { SupportLayout } from "layouts";
import { Detail, DetailHeaderSection, DetailImageSection, DetailListSection, DetailTextSection } from "components";
import { useSupport } from "hooks";
import { SupportParams } from "routes";
import { tk, useTranslation } from "translations";

interface Props extends RouteComponentProps<SupportParams> {}

export const Support = ({ match }: Props) => {
  const { t } = useTranslation();

  const support = useSupport();

  const activeRole = useMemo(() => match.params.role, [match.params.role]);
  const activeTopicId = useMemo(() => match.params.topic, [match.params.topic]);

  const topic = useMemo(() => {
    if (activeTopicId === "all") return undefined;

    if (activeRole === "regular") return support.regular.getById(activeTopicId);

    return support.manager.getById(activeTopicId);
  }, [support, activeRole, activeTopicId]);

  return (
    <SupportLayout tab={match.params.role} activeTopicId={activeTopicId} support={support}>
      {!!topic && (
        <Detail>
          <DetailHeaderSection heading={topic.title} subheading={t(tk.support.heading)} />

          {topic.content.map((block, index) => {
            const key = `${block.type}-${index}`;

            if (block.type === "paragraph") return <DetailTextSection key={key} text={block.content} />;
            if (block.type === "image") return <DetailImageSection key={key} src={block.src()} />;
            if (block.type === "list")
              return (
                <DetailListSection key={key}>
                  {block.items.map((item, i) => (
                    <li key={`${item}-${i}`}>{item}</li>
                  ))}
                </DetailListSection>
              );

            return null;
          })}
        </Detail>
      )}
    </SupportLayout>
  );
};
