import React from "react";
import styled from "styled-components";
import { font, theme } from "styles";

type Props = {
  children: React.ReactNode;
  color: "primary" | "secondary";
};

export const SearchInputResultsListItemText = ({ children, color }: Props) => {
  return <StyledText color={color}>{children}</StyledText>;
};

/**
 * Styles
 */

type StyledTextProps = Pick<Props, "color">;

const StyledText = styled("span").withConfig({
  shouldForwardProp: (prop) => !["color"].includes(prop),
})<StyledTextProps>`
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${(props) => {
    switch (props.color) {
      case "secondary":
        return theme.searchInputResultsListItemText.fontColor.secondary;
      case "primary":
      default:
        return theme.searchInputResultsListItemText.fontColor.primary;
    }
  }};
`;
