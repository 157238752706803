import styled from "styled-components";

export const FormActions = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;
  padding: 0 50px;
`;
