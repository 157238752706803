import React from "react";
import { Link } from "react-router-dom";
import { useLogin } from "controllers";
import { LandingPageLayout } from "layouts";
import {
  Button,
  Field,
  FieldErrorMessage,
  FieldLabel,
  Input,
  LandingPageCard,
  LandingPageCardTitle,
  LandingPageForm,
  LandingPageFormMetaLink,
  LandingPageHelpLink,
  LandingPageHelpText,
  SidebarAppVersion,
  SimpleLink,
} from "components";
import { getRoute } from "routes";
import { getAppVersion, getEnv } from "helpers";

export const Login = () => {
  const { t, tk, state, handlers } = useLogin();

  return (
    <LandingPageLayout>
      <LandingPageCard>
        <LandingPageCardTitle>{t(tk.login.heading)}</LandingPageCardTitle>

        <LandingPageForm onSubmit={handlers.handleSubmit}>
          {/** Email */}
          <Field width={"100%"} margin={"38px 0 0 0"} isDisabled={state.loading}>
            <FieldLabel>{t(tk.login.form.email.label)}</FieldLabel>
            <Input
              value={state.email}
              placeholder={t(tk.login.form.email.placeholder)}
              type={"email"}
              required={true}
              width={"100%"}
              isDisabled={state.loading}
              onChange={handlers.handleChangeEmail}
            />
          </Field>

          {/** Password */}
          <Field width={"100%"} margin={"38px 0 0 0"} isDisabled={state.loading}>
            <FieldLabel>{t(tk.login.form.password.label)}</FieldLabel>
            <Input
              value={state.password}
              placeholder={t(tk.login.form.password.placeholder)}
              type={"password"}
              required={true}
              width={"100%"}
              isDisabled={state.loading}
              onChange={handlers.handleChangePassword}
            />
            <FieldErrorMessage>{state.error}</FieldErrorMessage>
          </Field>

          {/** Submit button */}
          <Button color={"green"} isDisabled={state.loading} type={"submit"} width={"336px"} margin={"28px 0 0 0"}>
            {t(tk.login.form.submit)}
          </Button>

          {/** Password recovery */}
          <LandingPageFormMetaLink>
            <Link to={getRoute.passwordRecovery({ step: "1" })}>{t(tk.login.passwordRecovery)}</Link>
          </LandingPageFormMetaLink>
        </LandingPageForm>
      </LandingPageCard>

      {/** Activate account */}
      <LandingPageHelpText>{t(tk.login.activateLabel)}</LandingPageHelpText>
      <LandingPageHelpLink>
        <Link to={getRoute.activation({ step: "1" })}>{t(tk.login.activate)}</Link>
      </LandingPageHelpLink>

      {/** Moyobo web link */}
      <LandingPageHelpText>
        <SimpleLink href={getEnv("REACT_APP_MOYOBO_WEB_URL")} title={""} target={"_blank"} rel="noopener noreferrer">
          {t(tk.common.signUp)}
        </SimpleLink>
      </LandingPageHelpText>

      <SidebarAppVersion version={`${t(tk.common.version)} ${getAppVersion}`} style={{ marginTop: "20px" }} />
    </LandingPageLayout>
  );
};
