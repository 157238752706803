import React, { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { initTranslations, Language } from "translations";
import ErrorBoundary from "components/ErrorBoundary";
import { GlobalStyle } from "styles";
import { client } from "api";
import * as Page from "pages";
import { routes } from "routes";
import { Dashboard } from "layouts";

function App() {
  const [render, setRender] = useState(false);

  useEffect(() => {
    initTranslations(Language.Cs).then(() => setRender(true));
  }, []);

  if (!render) return null;

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Redirect from={routes.root} to={routes.login} exact={true} />

            <Route path={routes.login} component={Page.Login} />
            <Route path={routes.passwordRecovery} component={Page.PasswordRecovery} />
            <Route path={routes.activation} component={Page.Activation} />
            <Route path={routes.guide} component={Page.Guide} />

            <Route path={routes.dashboard} component={Dashboard} />
          </Switch>
        </Router>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
