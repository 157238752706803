import React from "react";
import styled from "styled-components";
import { Hexagon } from "components/Hexagon";
import { Badge } from "components/Badge";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

type Props = {
  profileImage: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
};

export const DetailRecipientDetail = ({ profileImage, firstName, lastName, isActive }: Props) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <Hexagon imageSource={profileImage} size={{ width: "30px", height: "33px" }} />
      <Text isActive={isActive}>{`${firstName} ${lastName}`}</Text>
      {!isActive && <NotActiveBadge>{t(tk.common.notActive)}</NotActiveBadge>}
    </ContentContainer>
  );
};

/**
 * Styles
 */

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 2px;
`;

const Text = styled.span.withConfig({ shouldForwardProp: (prop) => !["isActive"].includes(prop) })<{
  isActive: boolean;
}>`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 16px;
  color: ${(props) => (props.isActive ? theme.colors.NightSky : theme.colors.IronGrey)};
  padding-left: 12px;
  padding-right: 12px;
  line-height: 20px;
`;

const NotActiveBadge = styled(Badge)`
  color: ${theme.colors.IronGrey};
  background-color: ${theme.colors.FadingGrey};
`;
