import styled from "styled-components";
import { theme } from "styles";

export const Tabs = styled("div").withConfig({ shouldForwardProp: (prop) => !["width"].includes(prop) })<{
  width?: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => (!props.width ? 355 : props.width)}px;
  height: 100%;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${theme.tabs.borderColor};
  background-color: ${theme.tabs.backgroundColor};
`;
