import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { useDashboardState, useMe } from "hooks";
import { mapConnection, sortByFullName, useChatOrganizationUsersLazyQuery } from "api";

export const useSearch = () => {
  const { me, activeOrganization, activeSpace } = useMe();
  const { organization, space } = useDashboardState();

  const [query, setQuery] = useState("");
  const [cleared, setCleared] = useState(true);

  const [search, { loading: searching, data }] = useChatOrganizationUsersLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: () => setCleared(false),
  });

  const loading = useMemo(() => searching || (query.length > 0 && cleared), [searching, query.length, cleared]);

  const users = useMemo(() => {
    if (!query.length || !activeOrganization || cleared) return [];

    const users = sortByFullName(mapConnection(data?.organization?.users));

    const filteredUsers = users.filter(({ id, memberships }) => {
      // Without me
      if (id === me?.userId) return false;

      // TODO: should not be here, users are pre-filtered by BE if not owner, but if not owner, memberships are empty
      if (!memberships?.edges.length) return true;

      // Only users with mutual spaces
      let isSharingSpace = false;
      for (const membership of memberships?.edges || []) {
        if (!!activeOrganization.spaces.find(({ id }) => id === membership.node.space.id)) {
          isSharingSpace = true;
        }
      }

      return isSharingSpace;
    });

    return filteredUsers.map(({ memberships, ...user }) => {
      const mappedMemberships = mapConnection(memberships || undefined);

      // shared active space or first mutual
      const primaryMembership = mappedMemberships.find((m) => m.space.id === space) || mappedMemberships[0];

      return {
        ...user,
        role: primaryMembership?.role || "",
        space: primaryMembership?.space?.name || activeSpace?.name || "",
        position: primaryMembership?.position || "",
      };
    });
  }, [activeOrganization, activeSpace, cleared, data, me, query.length, space]);

  useDebounce(
    () => {
      if (query.length > 0) search({ variables: { organization, query, first: 20 } });
    },
    500,
    [organization, query, search]
  );

  useEffect(() => {
    if (!query.length) setCleared(true);
  }, [query.length]);

  return {
    query,
    setQuery,
    loading,
    users,
  };
};
