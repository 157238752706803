import React from "react";
import styled from "styled-components";
import { DetailFileDetail } from "components/Detail/DetailFileDetail";

type Props = {
  fileName: string;
  fileMeta: string;
  fileLink: string;
};

export class DetailFileDetailSection extends React.PureComponent<Props> {
  render() {
    return (
      <Section>
        <FileDetailContainer>
          <DetailFileDetail
            fileName={this.props.fileName}
            fileMeta={this.props.fileMeta}
            fileLink={this.props.fileLink}
          />
        </FileDetailContainer>
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 30px 0;
`;

const FileDetailContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;
