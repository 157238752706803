import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ListItemImage } from "components/ListItem/ListItemImage";
import { PreviewSpinner } from "components/PreviewSpinner";
import { RecipientsSelectionActionsBar } from "components/RecipientSelection/RecipientsSelectionActionsBar";
import { RecipientsSelectionAddButton } from "components/RecipientSelection/RecipientsSelectionAddButton";
import { SelectedRecipientsList } from "components/RecipientSelection/SelectedRecipientsList";
import { SelectedRecipientsListItem } from "components/RecipientSelection/SelectedRecipientsListItem";
import { SelectedRecipientsListItemText } from "components/RecipientSelection/SelectedRecipientsListItemText";
import { GroupSelectionDialog } from "components/RecipientSelection/GroupSelectionDialog";
import {
  SearchInput,
  SearchInputResultsList,
  SearchInputResultsListItem,
  SearchInputResultsListItemText,
} from "components/SearchInput";
import { Badge } from "components/Badge";
import { useRecipientSelection } from "hooks";
import { font, theme } from "styles";
import { tk } from "translations";

type Props = { recipientSelection: string; onChange: (selected: number, userIds: string[]) => any };

export const RecipientsSelection = ({ recipientSelection, onChange }: Props) => {
  const { t } = useTranslation();

  const [groupSelectionVisible, setGroupSelectionVisible] = React.useState(false);

  const { data, state, handlers, addUser, removeUser, addAll, addContactGroup, removeAll } = useRecipientSelection(
    recipientSelection
  );

  useEffect(() => onChange(state.selected, state.selectedUserIds), [onChange, state.selected, state.selectedUserIds]);

  return (
    <>
      <RecipientsSelectionActionsBar>
        <RecipientsSelectionAddButton disabled={state.loading} onClick={addAll}>
          {`${t(tk.recipientsSelection.everyone)}`}
        </RecipientsSelectionAddButton>
        <RecipientsSelectionAddButton disabled={state.loading} onClick={() => setGroupSelectionVisible(true)}>
          {`${t(tk.recipientsSelection.addGroup)}`}
        </RecipientsSelectionAddButton>
      </RecipientsSelectionActionsBar>

      <SearchContainer>
        <SearchInput
          value={state.query}
          placeholder={t(tk.recipientsSelection.searchByName)}
          onChange={handlers.handleChangeQuery}
          width={"100%"}
        />
      </SearchContainer>

      {data.searchResult && (
        <SearchInputResultsList>
          {!data.searchResult.length && <NoResultText>{t(tk.recipientsSelection.noMatch)}</NoResultText>}

          {data.searchResult.length > 0 &&
            data.searchResult.map(({ user, membership, selected }) => {
              if (!user || !membership) return null;

              const { id, isActive, firstName, lastName, profileImage } = user;
              const { role, position } = membership;

              return (
                <SearchInputResultsListItem
                  key={id}
                  id={id}
                  selected={selected || false}
                  loading={state.togglingMemberId === id}
                  clickRow={true}
                  onClick={addUser}
                >
                  <ListItemImage src={profileImage} alt={"user-avatar"} width={"27px"} height={"30px"} />
                  <SearchInputResultsListItemText color={isActive ? "primary" : "secondary"}>
                    {`${firstName} ${lastName}`}
                    {!isActive && <NotActiveBadge>{t(tk.common.notActive)}</NotActiveBadge>}
                  </SearchInputResultsListItemText>
                  <SearchInputResultsListItemText color={"secondary"}>
                    {position || t(tk.role[role])}
                  </SearchInputResultsListItemText>
                </SearchInputResultsListItem>
              );
            })}
        </SearchInputResultsList>
      )}

      {state.loading && (
        <LoadingContainer>
          <PreviewSpinner />
        </LoadingContainer>
      )}

      {!state.loading && (
        <SelectedContainer>
          <SelectedRecipientsList count={data.recipients?.selectedCount || 0} onRemoveAll={removeAll}>
            {data.recipients?.users &&
              data.recipients.users
                .filter(({ selected }) => selected)
                .map(({ user, membership }) => {
                  if (!user || !membership) return null;

                  const { id, isActive, firstName, lastName, profileImage } = user;
                  const { role, position } = membership;

                  return (
                    <SelectedRecipientsListItem key={id} id={id} onRemoveClick={removeUser}>
                      <ListItemImage src={profileImage} alt={"user-avatar"} width={"27px"} height={"30px"} />
                      <SelectedRecipientsListItemText color={isActive ? "primary" : "secondary"}>
                        {`${firstName} ${lastName}`}
                        {!isActive && <NotActiveBadge>{t(tk.common.notActive)}</NotActiveBadge>}
                      </SelectedRecipientsListItemText>
                      <SelectedRecipientsListItemText color={"secondary"}>
                        {position || t(tk.role[role])}
                      </SelectedRecipientsListItemText>
                    </SelectedRecipientsListItem>
                  );
                })}
          </SelectedRecipientsList>
        </SelectedContainer>
      )}

      <GroupSelectionDialog
        contactGroups={data.contactGroups || []}
        visible={groupSelectionVisible}
        onAdd={addContactGroup}
        onClose={() => setGroupSelectionVisible(false)}
        onConfirmation={() => setGroupSelectionVisible(false)}
      />
    </>
  );
};

const SearchContainer = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const SelectedContainer = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const NoResultText = styled("span")`
  position: relative;
  font-size: 14px;
  text-align: center;
  font-weight: ${font.weight.regular};
  color: ${theme.recipientsSelectionList.noResult.fontColor};
`;
const NotActiveBadge = styled(Badge)`
  color: #afafaf;
  background-color: ${theme.colors.FadingGrey};
  margin: 0 7px;
`;
