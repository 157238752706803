import React, { useCallback } from "react";
import styled from "styled-components";
import { ListItem, ListItemMetaText, ListItemText, ListItemTextWrapper } from "components/ListItem";
import { NotificationDot } from "components/NotificationDot";
import { IconDocument } from "icons";
import { theme } from "styles";

export type DocumentListItem = {
  id: string;
  title: string;
  createdAt: string;
  isActive?: boolean;
  isVisited?: boolean;
  onClick?: (id: string) => any;
};

interface Props {
  items: DocumentListItem[];
}

export const DocumentList = ({ items }: Props) => {
  return (
    <>
      {items.map((item) => (
        <Item key={item.id} {...item} />
      ))}
    </>
  );
};

const Item = ({ id, title, createdAt, isVisited, isActive = false, onClick = () => {} }: DocumentListItem) => {
  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <ItemWrapper>
      <ListItem isActive={isActive} hasIndicator={false} onClick={handleClick}>
        <IconDocument width={"33px"} color={theme.colors.IronGrey} />
        <ListItemTextWrapper margin={"0 15px"}>
          <ListItemText color={"green"} isBold={isVisited === false}>
            {title}
          </ListItemText>
          <ListItemMetaText>{createdAt}</ListItemMetaText>
        </ListItemTextWrapper>
        {isVisited === false && <NotificationDot />}
      </ListItem>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 75px;
`;
