import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import linkifyStr from "linkifyjs/string";
import { Hexagon } from "components";
import { timeAgo } from "helpers";

export type MessageType = "incoming" | "outgoing";

interface Props {
  message: string;
  messageType: MessageType;
  timestamp: string;
  profileImage: string;
  author?: string;
  showAuthor?: boolean;
  onShowTime?: () => any;
}

export const Message = ({
  message,
  messageType,
  timestamp,
  profileImage,
  author,
  showAuthor = false,
  onShowTime = () => {},
}: Props) => {
  const [showTime, setShowTime] = useState(false);

  const toggleShowTime = useCallback(() => {
    setShowTime(!showTime);
    if (!showTime) window.setTimeout(onShowTime, 100);
  }, [onShowTime, showTime]);

  const __html = useMemo(
    () => linkifyStr(message, { className: "linkify-chat__" + messageType }).replace(/\n/g, "<br>"),
    [message, messageType]
  );

  return (
    <ComponentWrapper messageType={messageType}>
      <AuthorName show={showAuthor || false}>{author}</AuthorName>
      <MessageWrapper messageType={messageType}>
        <AuthorImage imageSource={profileImage} size={{ width: "35px", height: "37px" }} />
        <BubbleAndTime>
          <MessageBubble messageType={messageType} onClick={toggleShowTime} dangerouslySetInnerHTML={{ __html }} />
          <MessageTime timeVisible={showTime} messageType={messageType}>
            {timeAgo(timestamp)}
          </MessageTime>
        </BubbleAndTime>
      </MessageWrapper>
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const colors = {
  incoming: {
    bubble: "#F2F2F2",
    text: "#404040",
  },
  outgoing: {
    bubble: "#008C82",
    text: "#FFFFFF",
  },
};

const direction = {
  incoming: "row",
  outgoing: "row-reverse",
};

const textAlign = {
  incoming: "left",
  outgoing: "right",
};

type StyleProps = {
  messageType: keyof typeof textAlign;
};

const ComponentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["messageType"].includes(prop),
})<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: ${(props) => textAlign[props.messageType]};
  margin-bottom: 11px;
  padding: 0 10px;
`;

const AuthorName = styled.div.withConfig({ shouldForwardProp: (prop) => !["show"].includes(prop) })<{ show: boolean }>`
  color: #bfc0c2;
  font-size: 10px;
  margin: 0 45px ${(props) => (props.show ? "2px" : "0")} 45px;
  ${(props: { show: boolean }) => (!props.show ? "display: none;" : "")}
`;

const MessageWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["messageType"].includes(prop),
})<StyleProps>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => direction[props.messageType]};
`;

const AuthorImage = styled(Hexagon)`
  display: flex;
  align-self: flex-start;
`;

const BubbleAndTime = styled.div``;

const MessageBubble = styled.div.withConfig({
  shouldForwardProp: (prop) => !["messageType"].includes(prop),
})<StyleProps>`
  display: inline-block;
  color: ${(props) => colors[props.messageType].text};
  background-color: ${(props) => colors[props.messageType].bubble};
  border-radius: 10px;
  padding: 12px 14px;
  margin: 0 5px;
  min-width: 10px;
  align-self: ${(props) => (props.messageType === "incoming" ? "flex-end" : "flex-start")};
  text-align: left;

  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const MessageTime = styled.div.withConfig({
  shouldForwardProp: (prop) => !["messageType", "timeVisible"].includes(prop),
})<{ timeVisible: boolean } & StyleProps>`
  color: #bfc0c2;
  height: ${(props) => (props.timeVisible ? "26px" : "0px")};
  overflow: hidden;
  line-height: 26px;
  transition: all 300ms;
  text-align: ${(props: { timeVisible: boolean } & StyleProps) =>
    props.messageType === "incoming" ? "left" : "right"};
  margin: 0 5px;
`;
