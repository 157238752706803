import React, { useCallback } from "react";
import styled from "styled-components";
import { NotificationDot } from "components/NotificationDot";
import { ListItem, ListItemImage, ListItemMetaText, ListItemText, ListItemTextWrapper } from "components/ListItem";
import { tk, useTranslation } from "translations";
import { IconGallery, IconStarFilled, IconUndo } from "icons";
import { theme } from "styles";

export type AnnouncementListItem = {
  id: string;
  title: string;
  createdAt: string;
  author?: string;
  image?: string;
  hasImage?: boolean;
  statistics?: { total: number; seen: number; reacted: number };
  isActive?: boolean;
  isVisited?: boolean;
  isMarked?: boolean;
  isUnread?: boolean;
  needReaction?: boolean;
  onClick?: (id: string) => any;
  onUnArchive?: (id: string) => any;
};

interface Props {
  items: AnnouncementListItem[];
}

export const AnnouncementList = ({ items }: Props) => {
  return (
    <>
      {items.map((item) => (
        <Item key={item.id} {...item} />
      ))}
    </>
  );
};

const Item = ({
  id,
  title,
  createdAt,
  statistics,
  author,
  image, // Author
  hasImage, // Uploaded Image
  isActive = false,
  isMarked,
  isUnread,
  needReaction,
  onClick,
  onUnArchive,
}: AnnouncementListItem) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => onClick && onClick(id), [id, onClick]);
  const handleUnArchive = useCallback(() => onUnArchive && onUnArchive(id), [id, onUnArchive]);

  return (
    <ItemWrapper>
      <ListItem
        isActive={isActive}
        isDisabled={!!onUnArchive}
        hasIllustration={!!image}
        hasIndicator={false}
        onClick={handleClick}
      >
        {!!image && <ListItemImage src={image} alt={""} />}

        <ListItemTextWrapper margin={!image ? undefined : "0 15px"}>
          <ListItemText color={"green"} isBold={isUnread || needReaction}>
            {title}
          </ListItemText>
          <ListItemMetaText>
            {createdAt}
            {!!statistics && !!hasImage && (
              <>
                &nbsp;
                <IconGallery width={12} height={10} color={theme.colors.IronGrey} />
              </>
            )}
            {!!author && ` • ${author}`}
          </ListItemMetaText>
        </ListItemTextWrapper>

        {statistics && (
          <>
            <ListItemTextWrapper alignment={"right"}>
              <ListItemText color={"green"}>{statistics.total.toString()}</ListItemText>
              <ListItemMetaText>
                {t(tk.announcements.summary.recipientsLabel, { count: statistics.total })}
              </ListItemMetaText>
            </ListItemTextWrapper>
            <ListItemTextWrapper margin={"0 0 0 10px"} alignment={"right"}>
              <ListItemText color={"green"}>{statistics.seen.toString()}</ListItemText>
              <ListItemMetaText>{t(tk.announcements.summary.seenLabel, { count: statistics.seen })}</ListItemMetaText>
            </ListItemTextWrapper>
            <ListItemTextWrapper margin={"0 0 0 10px"} alignment={"right"}>
              <ListItemText color={"green"}>{statistics.reacted.toString()}</ListItemText>
              <ListItemMetaText>
                {t(tk.announcements.summary.reactionsLabel, { count: statistics.reacted })}
              </ListItemMetaText>
            </ListItemTextWrapper>
          </>
        )}

        <IconsContainer>
          {!!hasImage && !statistics && <IconGallery width={12} height={10} color={theme.colors.TealGreen} />}

          {isMarked && <IconStarFilled width={9} height={10} color={theme.colors.TealGreen} />}

          {!!isUnread && <NotificationDot />}

          {!isUnread && needReaction && <NotificationDot hasOutline={true} />}

          {!!onUnArchive && (
            <UndoWrapper onClick={handleUnArchive}>
              <IconUndo width={18} height={20} color={theme.colors.TealGreen} />
            </UndoWrapper>
          )}
        </IconsContainer>
      </ListItem>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 75px;
`;

const UndoWrapper = styled.div`
  cursor: pointer;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
