import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { AnnouncementFragment, resolveError, useAnnouncementUrgeMutation } from "api";
import { tk, useTranslation } from "translations";
import { Option } from "components/Option";
import { Dialog, DialogScreen } from "components/Dialog";

interface Props {
  announcement: AnnouncementFragment;
  onSuccess: () => any;
}

export const RemindOption = ({ announcement, onSuccess }: Props) => {
  const { t } = useTranslation();

  const [actionDialogVisible, setActionDialogVisible] = useState(false);
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);

  const [urge, { loading }] = useAnnouncementUrgeMutation();

  const { urgeCount, urgedAt } = announcement;
  const config = getConfig(announcement);

  const meta = useMemo(() => {
    const { maxRemindCount, isAfterDeadline, isActive, relativeTime, timeToNextUrge } = config;

    if (urgeCount >= maxRemindCount) return t(tk.announcements.actions.urge.urgedLimit, { count: urgeCount });
    if (isAfterDeadline) return t(tk.announcements.actions.urge.cantUrgeAfterDeadline);
    if (!urgedAt) return t(tk.announcements.actions.urge.youDidntUrge);
    if (isActive) return t(tk.announcements.actions.urge.urgedCountLast, { count: urgeCount, last: relativeTime });

    return t(tk.announcements.actions.urge.urgedCountNext, { count: urgeCount, next: timeToNextUrge });
  }, [config, t, urgeCount, urgedAt]);

  const openActionDialog = useCallback(() => setActionDialogVisible(true), []);
  const closeActionDialog = useCallback(() => setActionDialogVisible(false), []);

  const openSuccessDialog = useCallback(() => setSuccessDialogVisible(true), []);
  const closeSuccessDialog = useCallback(() => {
    setSuccessDialogVisible(false);
    onSuccess();
  }, [onSuccess]);

  const remindAnnouncement = useCallback(async () => {
    if (loading) return;

    try {
      await urge({ variables: { input: { announcement: announcement.id } } });
    } catch (e: any) {
      resolveError(e);
    }

    closeActionDialog();
    openSuccessDialog();
  }, [announcement.id, closeActionDialog, loading, openSuccessDialog, urge]);

  return (
    <>
      <Option
        icon={"announcements"}
        disabled={!config.isActive}
        label={t(tk.announcements.actions.urge.button)}
        meta={meta}
        onClick={openActionDialog}
      />

      {actionDialogVisible && (
        <DialogScreen>
          <Dialog
            iconImage={"whistle"}
            buttonTitle={t(tk.announcements.actions.urge.button)}
            text={t(tk.announcements.actions.urge.urgeAnnouncementNotice)}
            hasCloseButton={true}
            onButtonClick={remindAnnouncement}
            onCloseButtonClick={closeActionDialog}
          />
        </DialogScreen>
      )}

      {successDialogVisible && (
        <DialogScreen>
          <Dialog
            buttonTitle={t(tk.common.ok)}
            onButtonClick={closeSuccessDialog}
            text={t(tk.announcements.actions.urge.urgeAnnouncementSuccess)}
          />
        </DialogScreen>
      )}
    </>
  );
};

const getConfig = ({ urgedAt, urgeCount, reactionDeadlineAt }: AnnouncementFragment) => {
  const remindInterval = 1; // hours
  const maxRemindCount = 3;
  const isAfterDeadline = moment.utc(reactionDeadlineAt).isBefore(moment.utc());
  const isActive =
    urgeCount < maxRemindCount &&
    !isAfterDeadline &&
    (moment.utc().diff(urgedAt, "hours", true) > remindInterval || urgedAt === null);
  const relativeTime = moment.utc(urgedAt).fromNow();
  const nextUrge = moment.utc(urgedAt).add(remindInterval, "hours");
  const timeToNextUrge = moment.utc().to(nextUrge);

  return { maxRemindCount, isAfterDeadline, isActive, relativeTime, timeToNextUrge };
};
