import styled from "styled-components";
import { font, theme } from "styles";

export const FieldDescription = styled("span")`
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${theme.fieldDescription.fontColor};
  line-height: 16px;
  grid-area: field-description;
`;
