import React from "react";
import styled from "styled-components";
import { Spinner } from "components/Spinner";

export class PreviewSpinner extends React.PureComponent {
  render() {
    return (
      <SpinnerContainer>
        <Spinner color={"#008C82"} />
      </SpinnerContainer>
    );
  }
}

/**
 * Styles
 */

const SpinnerContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
