import { useCallback } from "react";
import { ApolloError } from "apollo-boost";
import { useApolloClient } from "@apollo/react-hooks";
import { useLocalStorage } from "react-use";
import { useTokenAuthMutation } from "api/generated";
import { routes } from "routes";

/** Local Storage keys */
export const LS_AUTH_TOKEN = "@moyobo-webapp/auth-token";
export const LS_REFRESH_TOKEN = "@moyobo-webapp/refresh-token";

export const useAuth = () => {
  const apolloClient = useApolloClient();
  const [mutate, { loading }] = useTokenAuthMutation();

  const [, setAuthToken] = useLocalStorage<string>(LS_AUTH_TOKEN, "");
  const [, setRefreshToken] = useLocalStorage<string>(LS_REFRESH_TOKEN, "");

  const login = useCallback(
    async (username: string, password: string, onSuccess: () => any, onError: (e: ApolloError | any) => any) => {
      try {
        const { data } = await mutate({ variables: { username, password } });

        if (!data?.tokenAuth?.token || !data.tokenAuth.refreshToken) return;

        setAuthToken(data.tokenAuth.token);
        setRefreshToken(data.tokenAuth.refreshToken);

        onSuccess();
      } catch (e: any) {
        onError(e);
      }
    },
    [mutate, setAuthToken, setRefreshToken]
  );

  const logout = useCallback(() => {
    setAuthToken("");
    setRefreshToken("");

    window.location.replace(routes.login);
  }, [setAuthToken, setRefreshToken]);

  const resetStore = useCallback(() => {
    apolloClient.resetStore().finally();
  }, [apolloClient]);

  const isLoggedIn = useCallback((): boolean => !!getToken(), []);

  return { loading, login, logout, resetStore, isLoggedIn };
};

export const getToken = (): string | undefined => {
  try {
    const token = window.localStorage.getItem(LS_AUTH_TOKEN);
    return typeof token === "string" && token.length ? JSON.parse(token) : undefined;
  } catch (e: any) {
    return undefined;
  }
};

export const getRefreshToken = (): string | undefined => {
  try {
    const token = window.localStorage.getItem(LS_REFRESH_TOKEN);
    return typeof token === "string" && token.length ? JSON.parse(token) : undefined;
  } catch (e: any) {
    return undefined;
  }
};

export const setToken = (value: string): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, JSON.stringify(value));
  } catch (e: any) {}
};

export const clearToken = (): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, "");
  } catch (e: any) {}
};

export const clearTokens = (): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, "");
    window.localStorage.setItem(LS_REFRESH_TOKEN, "");
  } catch (e: any) {}
};
