import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LandingPageLayout } from "layouts";
import { ColorfulLogo, LandingPageFormMetaLink } from "components";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";
import { routes } from "routes";
import redCardIllustration from "assets/images/red-card-illustration.png";

export const LandingPageTokenError = () => {
  const { t } = useTranslation();

  return (
    <LandingPageLayout alternative={true}>
      <Container>
        <IllustrationImage src={redCardIllustration} alt={"red-card-illustration"} />

        <ColorfulLogo width={"225px"} height={"51px"} />

        <Notice>{t(tk.passwordRecovery.tokenError.info)}</Notice>

        <Separator />

        <AdditionalNotice>{t(tk.passwordRecovery.tokenError.tip)}</AdditionalNotice>

        <LandingPageFormMetaLink>
          <Link to={routes.login}>{t(tk.passwordRecovery.tokenError.back)}</Link>
        </LandingPageFormMetaLink>
      </Container>
    </LandingPageLayout>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const IllustrationImage = styled("img")`
  position: relative;
  width: 207px;
  margin-bottom: 35px;
`;

const Notice = styled("span")`
  position: relative;
  font-size: 24px;
  font-weight: ${font.weight.bold};
  line-height: 42px;
  white-space: pre-wrap;
  margin-top: 32px;
`;

const Separator = styled.div`
  position: relative;
  width: 28px;
  height: 3px;
  background-color: ${theme.colors.SilverGrey};
  margin-top: 62px;
`;

const AdditionalNotice = styled.span`
  font-size: 16px;
  font-weight: ${font.weight.regular};
  line-height: 24px;
  margin-top: 22px;
  white-space: pre-wrap;
`;
