import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-boost";
import { getToken } from "api/auth";
import { getEnv } from "helpers";

const API = getEnv("REACT_APP_API_URL");
const API_WSS = getEnv("REACT_APP_API_WSS_URL");

const createWsLink = () => {
  return new WebSocketLink({
    uri: API_WSS,
    options: {
      connectionParams: () => ({ authToken: getToken() }),
      reconnect: true,
      reconnectionAttempts: 100,
      lazy: true,
    },
  });
};

const uploadLink = createUploadLink({ uri: API });

let wsLink = createWsLink();

export const httpLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  uploadLink
);

export const refreshTokenHttpLink = createHttpLink({ uri: API });

export const resetWsLink = () => (wsLink = createWsLink());
