import React from "react";
import { theme } from "styles";
import { IconCommonPropsType } from "icons/types";

export const IconGallery = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32.003 27.378"
      style={{ margin }}
    >
      <path
        id="gallery"
        d="M30.938,24.375H29.875V5.562A1.054,1.054,0,0,0,28.812,4.5H5.312V3.436H30.937ZM28.813,26.5H27.75V7.687a1.1,1.1,0,0,0-1.063-1.063H3.187V5.561H28.812ZM1.063,28.625v-7.25l9.063-8.125,9.25,8.25a.541.541,0,0,0,.375.188,1.008,1.008,0,0,0,.375-.188l3.313-3.875,3.25,3.688v7.314ZM26.688,7.688v12L23.875,16.5a.534.534,0,0,0-.813,0l-3.375,3.875L10.5,12.125a.568.568,0,0,0-.75,0L1.061,19.938V7.688Zm4.25-5.375H5.313A1.109,1.109,0,0,0,4.25,3.438V4.5H3.187A1.054,1.054,0,0,0,2.124,5.564V6.627H1.061A1.1,1.1,0,0,0,0,7.69V28.628a1.054,1.054,0,0,0,1.063,1.063H26.686a1.054,1.054,0,0,0,1.063-1.063V27.565h1.063A1.1,1.1,0,0,0,29.875,26.5V25.439h1.063A1.1,1.1,0,0,0,32,24.376V3.438a1.109,1.109,0,0,0-1.063-1.125ZM19.188,12a1.054,1.054,0,0,1,1.063,1.063,1.018,1.018,0,0,1-1.063,1.063,1.054,1.054,0,0,1-1.063-1.063A1.1,1.1,0,0,1,19.188,12Zm0,3.25a2.161,2.161,0,0,0,2.125-2.188,2.125,2.125,0,0,0-4.25,0A2.162,2.162,0,0,0,19.188,15.25Z"
        transform="translate(0.002 -2.313)"
        fill={color}
      />
    </svg>
  );
};
