import React, { useCallback, useMemo, useState } from "react";
import {
  mapConnection,
  resolveError,
  useRecipientSelectionCreateFromDocumentMutation,
  useRecipientSelectionCreateMutation,
  useRecipientSelectionQuery,
  useRecipientSelectionToggleMutation,
} from "api";

export const useRecipientSelection = (recipientSelection: string) => {
  const [togglingMemberId, setTogglingMemberId] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const [toggle] = useRecipientSelectionToggleMutation();
  const [createRecipientSelection] = useRecipientSelectionCreateMutation();
  const [createRecipientSelectionFromDocument] = useRecipientSelectionCreateFromDocumentMutation();

  /** Data fetch and mapping */
  const { data } = useRecipientSelectionQuery({
    variables: { id: recipientSelection },
    skip: recipientSelection === "",
  });

  const recipients = useMemo(() => {
    if (!data?.recipientSelection) return undefined;

    const { selectedCount, users } = data.recipientSelection;

    return {
      selectedCount: selectedCount || 0,
      users: mapConnection(users || undefined),
    };
  }, [data]);

  const contactGroups = useMemo(() => {
    if (!data?.recipientSelection?.contactGroups) return undefined;

    return mapConnection(data.recipientSelection.contactGroups).map(({ contactGroup, totalCount, selectedCount }) => {
      return {
        id: contactGroup?.id || "",
        name: contactGroup?.name || "",
        private: contactGroup?.private || false,
        totalCount: totalCount || 0,
        selectedCount: selectedCount || 0,
      };
    });
  }, [data]);

  const selected = useMemo(() => data?.recipientSelection?.selectedCount || 0, [data]);

  const selectedUserIds = useMemo(
    () => recipients?.users.filter(({ selected }) => selected).map(({ user }) => user?.id || "") || [],
    [recipients]
  );

  /** Search */
  const [query, setQuery] = useState("");
  const handleChangeQuery = useCallback((value: string) => setQuery(value), []);
  const searchResult = useMemo(() => {
    if (!recipients?.users || query === "") return undefined;

    return recipients.users.filter(({ user }) =>
      `${user?.firstName} ${user?.lastName}`.toLowerCase().includes(query.toLowerCase())
    );
  }, [query, recipients]);

  /** Mutations */
  const create = useCallback(
    async (space: string) => {
      try {
        const { data } = await createRecipientSelection({ variables: { input: { space } } });
        return data?.recipientSelectionCreate.recipientSelection.id;
      } catch (e: any) {
        resolveError(e);
        return undefined;
      }
    },
    [createRecipientSelection]
  );
  const createFromDocument = useCallback(
    async (document: string) => {
      try {
        const { data } = await createRecipientSelectionFromDocument({ variables: { input: { document } } });
        return data?.recipientSelectionCreateFromDocument.recipientSelection.id;
      } catch (e: any) {
        resolveError(e);
        return undefined;
      }
    },
    [createRecipientSelectionFromDocument]
  );

  const addUser = useCallback(
    async (user: string) => {
      setTogglingMemberId(user);

      try {
        await toggle({ variables: { input: { recipientSelection, user, add: !selectedUserIds.includes(user) } } });
      } catch (e: any) {
        resolveError(e);
      }

      setTogglingMemberId(undefined);
    },
    [recipientSelection, selectedUserIds, toggle]
  );

  const addAll = useCallback(async () => {
    setLoading(true);

    try {
      await toggle({ variables: { input: { recipientSelection, all: true, add: true } } });
    } catch (e: any) {
      resolveError(e);
    }

    setLoading(false);
  }, [recipientSelection, toggle]);

  const addContactGroup = useCallback(
    async (contactGroup: string) => {
      setTogglingMemberId(contactGroup);
      setLoading(true);

      try {
        await toggle({ variables: { input: { recipientSelection, contactGroup, add: true } } });
      } catch (e: any) {
        resolveError(e);
      }

      setTogglingMemberId(undefined);
      setLoading(false);
    },
    [recipientSelection, toggle]
  );

  const removeUser = useCallback(
    async (user: string) => {
      try {
        await toggle({ variables: { input: { recipientSelection, user, add: false } } });
      } catch (e: any) {
        resolveError(e);
      }
    },
    [recipientSelection, toggle]
  );

  const removeAll = useCallback(async () => {
    setLoading(true);

    try {
      await toggle({ variables: { input: { recipientSelection, all: true, add: false } } });
    } catch (e: any) {
      resolveError(e);
    }

    setLoading(false);
  }, [recipientSelection, toggle]);

  return {
    create,
    createFromDocument,
    addUser,
    removeUser,
    addAll,
    removeAll,
    addContactGroup,
    data: {
      recipients,
      searchResult,
      contactGroups,
    },
    state: { query, togglingMemberId, selected, selectedUserIds, loading },
    handlers: {
      handleChangeQuery,
    },
  };
};
