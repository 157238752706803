import React from "react";
import styled from "styled-components";
import { ListWindow } from "chat/components/list/ListWindow";
import { ConversationWindow } from "chat/components/conversation/ConversationWindow";
import { GroupSettingsDialog } from "chat/components/groupSettings/GroupSettingsDialog";
import { useChat } from "chat/hooks/useChat";
import { SelectNewOwnerDialog } from "chat/components/conversation/SelectNewOwnerDialog";
import { ActivityDialog } from "chat/components/conversation/ActivityDialog";

export const Chat = () => {
  const { state, handlers } = useChat();

  return (
    <>
      <ChatContainer>
        <Row>
          {/** Main chat window */}
          <Column>
            <ListWindow onChatOpen={handlers.openConversation} onOpenGroupSettings={handlers.openGroupSettingsDialog} />
          </Column>

          {/** Chat conversations */}
          {state.openConversations.map(({ chat, compact }, index) => (
            <Column key={chat}>
              <ConversationWindow
                chat={chat}
                compact={compact}
                position={index + 1}
                onClose={handlers.closeConversation}
                onToggleCompact={handlers.toggleCompact}
                onOpenGroupSettings={handlers.openGroupSettingsDialog}
                onOpenSelectNewOwner={handlers.openSelectNewOwnerDialog}
                onOpenActivityDialog={handlers.openActivityDialog}
              />
            </Column>
          ))}
        </Row>
      </ChatContainer>

      {/** Dialogs */}
      {state.dialog === "groupSettings" && (
        <GroupSettingsDialog
          chat={state.dialogChat}
          onClose={handlers.closeDialog}
          onChange={handlers.handleChangeGroupSettings}
        />
      )}

      {state.dialog === "selectNewOwner" && (
        <SelectNewOwnerDialog
          chat={state.dialogChat}
          onClose={handlers.closeDialog}
          onLeaveConversation={handlers.closeConversation}
        />
      )}

      {state.dialog === "activity" && <ActivityDialog chat={state.dialogChat} onClose={handlers.closeDialog} />}
    </>
  );
};

/**
 * Styles
 */

const ChatContainer = styled.div`
  position: fixed;
  right: 15px;
  bottom: 0;
  height: 100%;
  pointer-events: none;
`;

const Row = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-left: 13px;
`;
