import { GraphQLError } from "graphql";
import { ApolloError } from "apollo-client";
import { reportToSentry, Severity } from "helpers";
import {
  AnnouncementLogsQuery,
  ChatNewMessagesQuery,
  ChatPreviousMessagesQuery,
  LoadOlderStoryCommentsQuery,
  Maybe,
  SpaceStoriesQuery,
} from "api";

type Error = GraphQLError & {
  code: string;
  errorType: string;
};

type ErrorHandler = () => any;

export const resolveError = (
  error: ApolloError,
  handlers?: { [code: string]: ErrorHandler },
  defaultErrorHandler?: ErrorHandler
): void => {
  if (!error.graphQLErrors || !error.graphQLErrors.length) return;

  const firstError = error.graphQLErrors[0] as Error;
  const handler = handlers && handlers[firstError.message];

  if (handler) {
    handler();
    return;
  }

  if (!defaultErrorHandler) {
    handleDefaultError(firstError);
    return;
  }

  defaultErrorHandler();
};

export const handleDefaultError = (e: Error) => {
  reportToSentry(`API error caught by handleDefaultError: ${e.message || "undefined"}`, Severity.Error);
  // TODO: remove console.error
  console.error(e);
};

export const mapConnection = <T>(connection?: {
  edges?: Maybe<{ cursor?: string; node?: Maybe<T> }>[];
}): (T & { cursor: string })[] => {
  return (
    (connection?.edges?.filter((e) => !!e).map((e) => ({ cursor: e?.cursor || "", ...e?.node })) as (T & {
      cursor: string;
    })[]) || []
  );
};

export const sortByLast = <T>(collection: ({ createdAt: string } & T)[]): T[] =>
  collection.sort(({ createdAt: a }, { createdAt: b }) => (a < b ? 1 : -1));

export const sortByName = <T>(collection: ({ name: string } & T)[]): T[] =>
  collection.sort(({ name: a }, { name: b }) => a.localeCompare(b));

export const sortByFullName = <T>(collection: ({ lastName: string; firstName: string } & T)[]): T[] =>
  collection.sort((a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`));

export const storyCommentsUpdateQuery = (
  prev: LoadOlderStoryCommentsQuery,
  { fetchMoreResult }: { fetchMoreResult?: LoadOlderStoryCommentsQuery }
) => {
  if (!fetchMoreResult?.story?.comments || !prev?.story?.comments) return prev;

  const { edges: prevEdges } = prev.story.comments;

  const { comments, ...rest } = fetchMoreResult.story;

  return {
    story: {
      ...rest,
      comments: {
        pageInfo: comments.pageInfo,
        edges: [...comments.edges, ...prevEdges],
        __typename: comments.__typename,
      },
    },
  };
};

export const storiesUpdateQuery = (
  prev: SpaceStoriesQuery,
  { fetchMoreResult }: { fetchMoreResult?: SpaceStoriesQuery }
) => {
  if (!fetchMoreResult?.space?.stories || !prev?.space?.stories) return prev;

  const { edges: prevEdges } = prev.space.stories;

  const { stories, ...rest } = fetchMoreResult.space;

  return {
    space: {
      ...rest,
      stories: {
        pageInfo: stories.pageInfo,
        edges: [...prevEdges, ...stories.edges],
        __typename: stories.__typename,
      },
    },
  };
};

export const announcementLogsUpdateQuery = (
  prev: AnnouncementLogsQuery,
  { fetchMoreResult }: { fetchMoreResult?: AnnouncementLogsQuery }
) => {
  if (!fetchMoreResult?.announcement?.logs || !prev?.announcement?.logs) return prev;

  const { edges: prevEdges } = prev.announcement.logs;

  const { logs, ...rest } = fetchMoreResult.announcement;

  return {
    announcement: {
      ...rest,
      logs: {
        pageInfo: logs.pageInfo,
        edges: [...prevEdges, ...logs.edges],
        __typename: logs.__typename,
      },
    },
  };
};

export const chatPreviousMessagesUpdateQuery = (
  prev: ChatPreviousMessagesQuery,
  { fetchMoreResult }: { fetchMoreResult?: ChatPreviousMessagesQuery }
) => {
  if (!fetchMoreResult?.chat?.messages || !prev.chat?.messages) return prev;

  const { edges: prevEdges } = prev.chat.messages;
  const { messages, ...rest } = fetchMoreResult.chat;

  return {
    chat: {
      ...rest,
      messages: {
        pageInfo: messages.pageInfo,
        edges: [...prevEdges, ...messages.edges],
        __typename: messages.__typename,
      },
    },
  };
};

export const chatNewMessagesUpdateQuery = (
  prev: ChatNewMessagesQuery,
  { fetchMoreResult }: { fetchMoreResult?: ChatNewMessagesQuery }
) => {
  if (!fetchMoreResult?.chat?.messages || !prev.chat?.messages) return prev;

  const { edges: prevEdges } = prev.chat.messages;
  const { messages, ...rest } = fetchMoreResult.chat;

  return {
    chat: {
      ...rest,
      messages: {
        pageInfo: messages.pageInfo,
        edges: [...prevEdges, ...messages.edges],
        __typename: messages.__typename,
      },
    },
  };
};
