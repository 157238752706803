import styled from "styled-components";

export const Form = styled("form")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
