import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { DateTimePickerWrapper } from "components/DateTimePicker/DateTimePickerWrapper";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { ButtonLink } from "components/ButtonLink";
import { DialogScreen } from "components/Dialog";
import { theme } from "styles";
import { Moment } from "moment";
import { tk, useTranslation } from "translations";

interface Props {
  initial: string;
  visible: boolean;
  onClose: () => any;
  onSubmit: (value: string) => any;
}

export const DateTimePickerDialog = ({ initial, visible, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(initial);

  const handleChange = useCallback((value: Moment | null) => {
    if (value !== null) setValue(value.format());
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(value);
    onClose();
  }, [onClose, onSubmit, value]);

  if (!visible) return null;

  return (
    <DialogScreen>
      <DatePickerDialog>
        <DateTimePickerWrapper>
          <DateTimePicker value={value} onChange={handleChange} />
        </DateTimePickerWrapper>
        <DatePickerActions>
          <ButtonLink onClick={onClose}>{t(tk.common.cancel)}</ButtonLink>
          <ButtonLink onClick={handleSubmit}>{t(tk.common.select)}</ButtonLink>
        </DatePickerActions>
      </DatePickerDialog>
    </DialogScreen>
  );
};

/**
 * Styles
 */

const DatePickerDialog = styled.div`
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 3px 13px #0000005c;
`;

const DatePickerActions = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${theme.colors.SnowWhite};
  padding: 10px;
`;
