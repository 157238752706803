import React, { createContext, useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
};

export const LanguageSelectContext = createContext<{
  isSelectOpen?: boolean;
  setIsSelectOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isSelectOpen: undefined,
  setIsSelectOpen: undefined,
});

export const LanguageSelect = ({ children }: Props) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, () => setIsSelectOpen(false));

  return (
    <LanguageSelectContext.Provider
      value={{
        isSelectOpen,
        setIsSelectOpen,
      }}
    >
      <Container ref={containerRef}>{children}</Container>
    </LanguageSelectContext.Provider>
  );
};

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 15px 0;
`;
