import { useCallback, useEffect, useMemo } from "react";
import { useMe } from "hooks/useMe";
import { Languages, resolveError, useChangeLanguageMutation } from "api";
import { changeLanguage, Language } from "translations";
import czFlag from "assets/images/flags/cz-flag.png";
import skFlag from "assets/images/flags/sk-flag.png";
import gbFlag from "assets/images/flags/gb-flag.png";
import deFlag from "assets/images/flags/de-flag.png";

export const useLanguages = () => {
  const { activeLanguage } = useMe();

  useEffect(() => changeLanguage(activeLanguage), [activeLanguage]);

  const [change, { loading }] = useChangeLanguageMutation();

  const handleSelect = useCallback(
    async (language: Language) => {
      if (loading) return;

      try {
        await change({ variables: { input: { language: mapLanguageForApi(language) } } });
      } catch (e: any) {
        resolveError(e);
      }
    },
    [change, loading]
  );

  const options = useMemo(
    () =>
      Object.values(Language).map((value) => {
        if (value === Language.Sk)
          return {
            id: value,
            name: "Slovenština",
            flag: skFlag,
            onSelect: () => handleSelect(Language.Sk),
          };
        if (value === Language.En)
          return {
            id: value,
            name: "English",
            flag: gbFlag,
            onSelect: () => handleSelect(Language.En),
          };

        if (value === Language.De)
          return {
            id: value,
            name: "Deutch",
            flag: deFlag,
            onSelect: () => handleSelect(Language.De),
          };

        return {
          id: value,
          name: "Čeština",
          flag: czFlag,
          onSelect: () => handleSelect(Language.Cs),
        };
      }),
    [handleSelect]
  );

  const activeOption = useMemo(() => {
    return options.find(({ id }) => id === activeLanguage) as (typeof options)[0];
  }, [options, activeLanguage]);

  const otherOptions = useMemo(() => {
    return options.filter(({ id }) => id !== activeLanguage);
  }, [options, activeLanguage]);

  return { activeOption, otherOptions };
};

const mapLanguageForApi = (language: Language): Languages => {
  switch (language) {
    case Language.Cs:
      return Languages.Cs;
    case Language.Sk:
      return Languages.Sk;
    case Language.En:
      return Languages.En;
    case Language.De:
      return Languages.De;
  }
};
