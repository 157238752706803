import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GuideParams } from "routes";
import { useGuide } from "controllers";
import { LandingPageLayout } from "layouts";
import {
  Button,
  GuideDescription,
  GuideDescriptionHeading,
  GuideIllustration,
  LandingPageCard,
  Step,
  Steps,
} from "components";
import announcements from "assets/images/announcements.png";
import documents from "assets/images/documents.png";
import spaces from "assets/images/spaces.png";
import mobileApp from "assets/images/mobile-app.png";

interface Props extends RouteComponentProps<GuideParams> {}

export const Guide = ({ match }: Props) => {
  const { t, tk, state, handlers } = useGuide(match.params.step);

  const illustrationSrc = useMemo(
    () => ({ "1": announcements, "2": documents, "3": spaces, "4": mobileApp }[state.step]),
    [state.step]
  );

  return (
    <LandingPageLayout>
      <LandingPageCard>
        <Steps activeStep={parseInt(state.step)}>
          <Step />
          <Step />
          <Step />
          <Step />
        </Steps>

        <GuideIllustration illustrationSrc={illustrationSrc} />

        <GuideDescriptionHeading>{t(tk.guide[state.step].heading)}</GuideDescriptionHeading>

        <GuideDescription>{t(tk.guide[state.step].description)}</GuideDescription>

        <Button onClick={handlers.next} margin={"24px 0 0 0"} width={"336px"} color={"green"}>
          {t(state.step === "4" ? tk.guide.finish : tk.guide.next)}
        </Button>
      </LandingPageCard>
    </LandingPageLayout>
  );
};
