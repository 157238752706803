import styled from "styled-components";
import { theme } from "styles";

export const VerticalSeparator = styled("div")`
  display: flex;
  position: relative;
  width: 1px;
  height: 100%;
  background-color: ${theme.verticalSeparator.backgroundColor};
`;
