export const getTempUrl = (file: File): string => URL.createObjectURL(file);

export const clearTempUrl = (url?: string): void => {
  if (!!url) URL.revokeObjectURL(url);
};

export const convertFileSize = (bytes: number): string => {
  const thresh = 1000;
  const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let size = bytes;
  let unitPosition = 0;

  if (Math.abs(size) < thresh) {
    return `${size} ${units[unitPosition]}`;
  }

  do {
    size = size / thresh;
    unitPosition = unitPosition + 1;
  } while (Math.abs(size) >= thresh && unitPosition < units.length - 1);

  return `${Math.round(size)} ${units[unitPosition]}`;
};
