import React, { createContext, useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  alignment: "left" | "right";
};

export const DropdownContext = createContext<{
  isDropdownOpen?: boolean;
  setIsDropdownOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isDropdownOpen: undefined,
  setIsDropdownOpen: undefined,
});

export const Dropdown = ({ children, alignment }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, () => setIsDropdownOpen(false));

  return (
    <DropdownContext.Provider
      value={{
        isDropdownOpen,
        setIsDropdownOpen,
      }}
    >
      <Container ref={containerRef} alignment={alignment}>
        {children}
      </Container>
    </DropdownContext.Provider>
  );
};

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["alignment"].includes(prop) })<{
  alignment: Props["alignment"];
}>`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: ${(props) => {
    switch (props.alignment) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
`;
