import styled from "styled-components";
import { Card } from "components/Card";

export const LandingPageCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 546px;
  padding: 43px 58px;
`;
