import React from "react";
import styled from "styled-components";
import { IconCancel } from "icons";
import { theme } from "styles";

type Props = {
  children?: never;
  onClick: () => void;
};

export const ReactionListItemDeleteButton = ({ onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <StyledButton onClick={handleClick}>
      <IconCancel width={"11px"} height={"11px"} color={theme.reactionListItemDeleteButton.iconColor} />
    </StyledButton>
  );
};

/**
 * Styles
 */

const StyledButton = styled("button")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: transparent;
  border: none;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
