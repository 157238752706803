import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDocuments } from "controllers";
import { DocumentsLayout } from "layouts";
import { Button } from "components";
import { DocumentsParams } from "routes";

interface Props extends RouteComponentProps<DocumentsParams> {}

export const Documents = ({ match }: Props) => {
  const { t, tk, data, state, handlers } = useDocuments(match.params.tab);

  return (
    <DocumentsLayout
      buttons={
        !data.isManager ? null : (
          <Button color={"green"} onClick={handlers.createDocument}>
            {t(tk.documents.navigation.create)}
          </Button>
        )
      }
      tab={state.tab}
    >
      {null}
    </DocumentsLayout>
  );
};
