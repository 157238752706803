import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ListItemImage, ListItemText, NotificationDot, Spinner } from "components";
import * as HD from "components/HeaderDropdown";
import { useMe } from "hooks";
import { getRoute } from "routes";

type Props = {
  children?: never;
};

export const HeaderOrganizationSelect = (_: Props) => {
  const history = useHistory();

  const { organizations, activeOrganization, loading } = useMe();

  const { availableOrganizations, availableOrganizationsUnresolved } = useMemo(() => {
    let availableOrganizationsUnresolved = false;
    const availableOrganizations = organizations.filter(({ id, unresolved }) => {
      const isActive = id === activeOrganization?.id;

      if (!isActive && unresolved) availableOrganizationsUnresolved = true;

      return !isActive;
    });

    return { availableOrganizations, availableOrganizationsUnresolved };
  }, [activeOrganization, organizations]);

  const selectOrganization = useCallback(
    (organization: string) => {
      const selectedOrganization = organizations.find(({ id }) => id === organization);

      if (!selectedOrganization) return;

      const space = selectedOrganization.spaces[0].id;

      history.push(getRoute.dashboard({ organization, space }));
    },
    [history, organizations]
  );

  if (loading || !activeOrganization) return <Spinner color={"white"} />;

  return (
    <HD.HeaderDropdown alignment={"left"}>
      <HD.HeaderDropdownToggle>
        <ListItemImage src={activeOrganization.logo} alt={""} width={"40px"} height={"45px"} />
        <HD.HeaderDropdownToggleLabel>{`${activeOrganization.name}`}</HD.HeaderDropdownToggleLabel>
        {availableOrganizations.length > 0 && (
          <ArrowContainer>
            {availableOrganizationsUnresolved && (
              <ArrowDotContainer>
                <NotificationDot />
              </ArrowDotContainer>
            )}
            <HD.HeaderDropdownToggleIndicator rotationDirection={"anticlockwise"} />
          </ArrowContainer>
        )}
      </HD.HeaderDropdownToggle>

      <HD.HeaderDropdownMenuBox alignment={"left"}>
        <HD.HeaderDropdownMenu>
          {availableOrganizations.length > 0 &&
            availableOrganizations.map(({ id, name, logo, unresolved }) => (
              <HD.HeaderDropdownMenuItem key={id} onClick={() => selectOrganization(id)}>
                <LogoContainer>
                  <ListItemImage src={logo} alt={""} width={"40px"} height={"45px"} />
                  {unresolved && (
                    <LogoDotContainer>
                      <NotificationDot />
                    </LogoDotContainer>
                  )}
                </LogoContainer>
                <ListItemText margin={"0 0 0 15px"}>{`${name}`}</ListItemText>
              </HD.HeaderDropdownMenuItem>
            ))}
        </HD.HeaderDropdownMenu>
      </HD.HeaderDropdownMenuBox>
    </HD.HeaderDropdown>
  );
};

const LogoContainer = styled.div`
  position: relative;
`;

const LogoDotContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 4px;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowDotContainer = styled.div`
  position: relative;
  right: -8px;
`;
