import React from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children?: never;
  width: string;
  height: string;
  src: string;
  hasBorder?: boolean;
};

export const HexagonImage = ({ src, width, height, hasBorder = false }: Props) => {
  return (
    <svg width={width} height={height} viewBox={"0 0 60 66"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
      <defs>
        <clipPath id={"mask"} clipPathUnits={"userSpaceOnUse"}>
          <path
            d={
              "M34.837,2.522l19.275,11.05c3.023,1.733 4.888,4.952 4.888,8.437l0,21.982c0,3.485 -1.865,6.704 -4.888,8.437l-19.275,11.05c-2.996,1.718 -6.678,1.718 -9.674,0l-19.275,-11.05c-3.023,-1.733 -4.888,-4.952 -4.888,-8.437l0,-21.982c0,-3.485 1.865,-6.704 4.888,-8.437l19.275,-11.05c2.996,-1.718 6.678,-1.718 9.674,0Z"
            }
          />
        </clipPath>
      </defs>
      <Path
        d={
          "M34.837,2.522l19.275,11.05c3.023,1.733 4.888,4.952 4.888,8.437l0,21.982c0,3.485 -1.865,6.704 -4.888,8.437l-19.275,11.05c-2.996,1.718 -6.678,1.718 -9.674,0l-19.275,-11.05c-3.023,-1.733 -4.888,-4.952 -4.888,-8.437l0,-21.982c0,-3.485 1.865,-6.704 4.888,-8.437l19.275,-11.05c2.996,-1.718 6.678,-1.718 9.674,0Z"
        }
        hasBorder={hasBorder}
      />
      <image xlinkHref={src} x={0} y={0} width={"100%"} height={"100%"} clipPath={"url(#mask)"} />
    </svg>
  );
};

const Path = styled("path").withConfig({ shouldForwardProp: (prop) => !["hasBorder"].includes(prop) })<{
  hasBorder: boolean;
}>`
  fill: ${theme.hexagonImage.backgroundColor};
  stroke-width: ${(props) => (props.hasBorder ? "1px" : "0")};
  stroke: ${theme.hexagonImage.borderColor};
`;
