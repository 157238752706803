import React, { useCallback } from "react";
import styled from "styled-components";
import { DetailLatestActivityDetail } from "components/Detail/DetailLatestActivityDetail";
import { Title } from "components/Title";
import { tk, useTranslation } from "translations";
import { formatTime, fromNow } from "helpers";
import { AnnouncementLogTypes } from "api";
import { font, theme } from "styles";

type User = {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
};

type Activity = {
  id: string;
  type: AnnouncementLogTypes;
  valueOld: string;
  valueNew: string;
  createdAt: string;
  user: User;
};

type Props = {
  sectionTitle: string;
  activities: Activity[];
  hasPreviousPage?: boolean;
  loadOlderActivities: () => any;
};

export const DetailLatestActivitiesSection = ({
  sectionTitle,
  activities,
  hasPreviousPage = false,
  loadOlderActivities,
}: Props) => {
  const { t } = useTranslation();

  const resolveActivityType = useCallback(
    ({ type, valueOld, valueNew }: Activity) => {
      switch (type) {
        case AnnouncementLogTypes.Seen:
          return t(tk.announcements.activity.seen);
        case AnnouncementLogTypes.Urge:
          return t(tk.announcements.activity.reminded);
        case AnnouncementLogTypes.Reaction:
          return !valueOld
            ? t(tk.announcements.activity.reacted, { reaction: valueNew })
            : t(tk.announcements.activity.changedReaction, { reaction: valueNew });
      }
    },
    [t]
  );

  const hasActivities = activities.length > 0;

  return (
    <Section>
      <SectionTitleContainer>
        <Title>{sectionTitle}</Title>
      </SectionTitleContainer>

      <TableContainer>
        <Table>
          <TableBody>
            {!hasActivities && (
              <TableRow>
                <TableCell>{t(tk.announcements.activity.noActivity)}</TableCell>
              </TableRow>
            )}

            {hasActivities &&
              activities.map((activity) => {
                return (
                  <TableRow key={activity.id}>
                    <TableCell>
                      <DetailLatestActivityDetail
                        profileImageSource={activity.user.profileImage}
                        firstName={activity.user.firstName}
                        lastName={activity.user.lastName}
                        activity={resolveActivityType(activity)}
                      />
                    </TableCell>
                    <TableCell>{formatTime(activity.createdAt, "LLL")}</TableCell>
                    <TableCell>{fromNow(activity.createdAt)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {hasPreviousPage && (
        <ShowMoreButtonContainer>
          <ShowMoreButton onClick={loadOlderActivities}>{t(tk.announcements.activity.showOlder)}</ShowMoreButton>
        </ShowMoreButtonContainer>
      )}
    </Section>
  );
};

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 30px 0;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;

const Table = styled.table`
  position: relative;
  width: 100%;
  font-weight: ${font.weight.regular};
  font-size: 12px;
  color: ${theme.colors.IronGrey};
  border-collapse: collapse;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td`
  &:nth-of-type(2),
  &:nth-of-type(3) {
    text-align: right;
  }
  padding-left: 0;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const ShowMoreButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 100%;
  padding: 0 60px;
  margin-top: 10px;
`;

const ShowMoreButton = styled.div`
  position: relative;
  font-weight: ${font.weight.semiBold};
  font-size: 12px;
  line-height: 28px;
  color: ${theme.colors.TealGreen};
  cursor: pointer;
`;
