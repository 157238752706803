import React from "react";
import { theme } from "styles";
import { IconCommonPropsType } from "icons/types";

export const IconNotification = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.376 32.002"
      style={{ margin }}
    >
      <path
        id="notifications"
        d="M5.125,25.063A1.1,1.1,0,0,1,6.188,24H25.813a1.1,1.1,0,0,1,1.063,1.063v1.063H5.126Zm2-10.125A8.991,8.991,0,0,1,16,5.875a8.991,8.991,0,0,1,8.875,9.063v7.188H7.125Zm7.813-12a1.063,1.063,0,1,1,2.126,0,1.063,1.063,0,1,1-2.126,0ZM16,30.125A3.019,3.019,0,0,1,13.125,28h5.75A3.02,3.02,0,0,1,16,30.125ZM3.313,27.063A.962.962,0,0,0,4.251,28h7a4.82,4.82,0,0,0,9.5,0h7a.962.962,0,0,0,.938-.938v-2a3,3,0,0,0-1.938-2.813V14.937A10.95,10.95,0,0,0,18.563,4.312a3.4,3.4,0,0,0,.313-1.375,2.876,2.876,0,1,0-5.75,0,3.4,3.4,0,0,0,.313,1.375A10.951,10.951,0,0,0,5.251,14.937V22.25a3,3,0,0,0-1.938,2.813Z"
        transform="translate(-3.313 0.001)"
        fill={color}
      />
    </svg>
  );
};
