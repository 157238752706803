import React from "react";
import styled from "styled-components";
import {
  ListItemImage,
  PreviewSpinner,
  SearchInput,
  SearchInputResultsListItem,
  SearchInputResultsListItemText,
} from "components";
import { ChatMember } from "chat/components/groupSettings/GroupSettingsDialog";
import { useSearch } from "chat/hooks/useSearch";
import { tk, useTranslation } from "translations";

interface Props {
  addedMembers: ChatMember[];
  onToggleMember: (member: ChatMember) => any;
}

export const MembersSearch = ({ addedMembers, onToggleMember }: Props) => {
  const { t } = useTranslation();

  const { query, setQuery, users, loading } = useSearch();

  return (
    <ComponentWrapper>
      <SearchInputWrapper>
        <SearchInput value={query} placeholder={t(tk.chat.settings.invitationPlaceholder)} onChange={setQuery} />
      </SearchInputWrapper>
      <ListWrapper>
        {loading && <PreviewSpinner />}
        {!loading && users.length > 0 && (
          <List>
            {users.map(({ id, firstName, lastName, profileImage, space, isActive }) => {
              const selected = addedMembers.some((m) => m.id === id);
              const member = { id, firstName, lastName };

              return (
                <SearchInputResultsListItem
                  key={id}
                  id={id}
                  selected={selected}
                  clickRow={true}
                  onClick={() => onToggleMember(member)}
                >
                  <ListItemImage src={profileImage} alt={"user-avatar"} width={"27px"} height={"30px"} />
                  <SearchInputResultsListItemText color={isActive ? "primary" : "secondary"}>
                    {firstName} {lastName}
                  </SearchInputResultsListItemText>
                  <SearchInputResultsListItemText color={"secondary"}>{space}</SearchInputResultsListItemText>
                </SearchInputResultsListItem>
              );
            })}
          </List>
        )}
      </ListWrapper>
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const ComponentWrapper = styled.div``;

const SearchInputWrapper = styled.div`
  padding: 0 30px;
`;

const ListWrapper = styled.div`
  margin-top: 20px;
  height: 250px;
  max-height: 250px;
  overflow-y: scroll;
`;

const List = styled.div`
  padding: 0 10px 0 20px;
  margin: 0;
`;
