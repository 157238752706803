import React, { useState } from "react";
import { tk, useTranslation } from "translations";
import Cropper, { Area, Point } from "react-easy-crop";
import styled from "styled-components";
import { font, theme } from "styles";
import { Media } from "hooks";
import { ButtonLink } from "components/ButtonLink";
import { CloseButton } from "components/CloseButton";
import { DialogButton } from "components/DialogButton";
import { DialogScreen } from "components/Dialog";
import { FieldErrorMessage } from "components/Field";

interface Props {
  media: Media;
}

export const ImageCrop = ({ media }: Props) => {
  const { t } = useTranslation();

  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const [error, setError] = useState(false);

  const image = media.imageToCrop;

  const reset = () => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(undefined);

    setError(false);
  };

  const handleCropClose = () => {
    reset();

    media.handleCropClose();
    media.clear();
  };

  const handleSubmit = async () => {
    if (!croppedAreaPixels || !image) return;

    setError(false);

    try {
      await media.handleCropSubmit(await getCroppedImg(image.url, croppedAreaPixels, image.name, image.type));
      media.handleCropClose();
      reset();
    } catch (e) {
      setError(true);
    }
  };

  if (!image) return null;

  return (
    <DialogScreen>
      <DialogContainer>
        <HeaderSection>
          {t(tk.imageUpload.crop.heading)}
          <DialogCloseButton onClick={handleCropClose} />
        </HeaderSection>
        <CropSection>
          <CropContainer>
            <Cropper
              image={image.url}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
            />
          </CropContainer>

          {error && <FieldErrorMessage>{t(tk.imageUpload.crop.error)}</FieldErrorMessage>}
        </CropSection>
        <ButtonsSection>
          <ButtonLink onClick={handleCropClose} margin={"0 30px 0 0"}>
            {t(tk.common.cancel)}
          </ButtonLink>
          <DialogButton onClick={handleSubmit}>{t(tk.common.confirm)}</DialogButton>
        </ButtonsSection>
      </DialogContainer>
    </DialogScreen>
  );
};

/**
 * Utils
 */

function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });
}

async function getCroppedImg(imageSrc: string, pixelCrop: Area, fileName: string, fileType: string): Promise<File> {
  const image = await createImage(imageSrc);

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) throw new Error();

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) throw new Error();

  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob(
      (blob) => (blob ? resolve(new File([blob], fileName, { type: fileType })) : reject(new Error())),
      fileType
    );
  });
}

/**
 * Styles
 */

const DialogContainer = styled.div`
  width: 870px;
  border-radius: 5px;
  background-color: ${theme.colors.SnowWhite};
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${theme.colors.NightSky};
  box-sizing: border-box;
`;

const Section = styled.div`
  border-top: 2px solid ${theme.colors.SilverGrey};

  &:first-child {
    border-top: none;
  }
`;

const HeaderSection = styled(Section)`
  padding: 30px;
  font-weight: ${font.weight.bold};
`;

const DialogCloseButton = styled(CloseButton)`
  position: absolute;
  top: 26px;
  right: 24px;
`;

const CropSection = styled(Section)`
  padding: 15px;
`;

const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  max-height: 50vh;
  overflow: hidden;
  border-radius: 5px;
  background-color: ${theme.colors.SnowWhite};
`;

const ButtonsSection = styled(Section)`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
