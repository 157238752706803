import React, { useContext } from "react";
import styled from "styled-components";
import { FieldContext } from "components/Field/Field";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
  isInputDisabled?: boolean;
  htmlFor?: string;
  form?: string;
  required?: boolean;
};

export const FieldLabel = ({ children, isInputDisabled, htmlFor, form, required = false }: Props) => {
  const { isFieldDisabled } = useContext(FieldContext);

  const disabled = isFieldDisabled !== undefined ? isFieldDisabled : isInputDisabled;

  return (
    <StyledInputLabel isInputDisabled={disabled} htmlFor={htmlFor} form={form}>
      {children}
      {required && <Required>*</Required>}
    </StyledInputLabel>
  );
};

/**
 * Styles
 */

type StyledInputLabelProps = Pick<Props, "isInputDisabled">;

export const StyledInputLabel = styled("label").withConfig({
  shouldForwardProp: (prop) => !["isInputDisabled"].includes(prop),
})<StyledInputLabelProps>`
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: ${(props) =>
    props.isInputDisabled ? theme.fieldLabel.fontColor.disabled : theme.fieldLabel.fontColor.default};
  margin-bottom: 5px;
`;

const Required = styled.span`
  font-size: 14px;
`;
