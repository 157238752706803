import React from "react";
import styled from "styled-components";
import { isVimeoUrl, isYoutubeUrl } from "helpers";

type Props = {
  videoUrl: string;
};

/* eslint-disable jsx-a11y/iframe-has-title */
export class DetailVideoSection extends React.PureComponent<Props> {
  render() {
    const videoEmbed = getVideoEmbed(this.props.videoUrl);

    return <Section>{!!videoEmbed && <VideoContainer>{videoEmbed}</VideoContainer>}</Section>;
  }
}

const getVideoEmbed = (url: string) => {
  if (isVimeoUrl(url)) return getVimeoEmbed(url);

  if (isYoutubeUrl(url)) return getYoutubeEmbed(url);

  return null;
};

const getVimeoEmbed = (url: string) => {
  const id = url.match(/\d+$/)?.[0];

  return !id ? null : <iframe src={`https://player.vimeo.com/video/${id}`} {...commonIframeProps} />;
};

const getYoutubeEmbed = (url: string) => {
  const id = url.includes("watch?v=") ? url.match(/watch\?v=(.+)$/)?.[1] : url.match(/.+\/(.+)$/)?.[1];

  return !id ? null : (
    <iframe
      src={`https://www.youtube.com/embed/${id}`}
      {...commonIframeProps}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

const commonIframeProps = {
  title: "Announcement video",
  width: "100%",
  height: "360",
  frameBorder: "0",
  allowFullScreen: true,
};

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
  margin-bottom: 30px;
`;

const VideoContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;
