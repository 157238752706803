import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconChecked = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31.751 24.376"
      style={{ margin }}
    >
      <path
        id="checked"
        d="M31,6.625l-.938-.938a2.642,2.642,0,0,0-3.688,0L10.874,21.25l-5.563-5.5a2.565,2.565,0,0,0-3.625,0l-.938.875a2.642,2.642,0,0,0,0,3.688L9,28.563a2.642,2.642,0,0,0,3.688,0L31,10.25a2.565,2.565,0,0,0,0-3.625Z"
        transform="translate(0.002 -4.937)"
        fill={color}
      />
    </svg>
  );
};
