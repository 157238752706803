import React, { ChangeEvent, useContext, useState } from "react";
import styled from "styled-components";
import { FieldContext } from "components";
import { font, theme } from "styles";

const LINE_HEIGHT = 24;

type Props = {
  children?: never;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  width?: string;
  minRows?: number;
  maxRows?: number;
};

export const Textarea = ({ value, placeholder, onChange, width, isDisabled, minRows = 3, maxRows = 6 }: Props) => {
  const [currentRows, setCurrentRows] = useState(minRows);
  const { isFieldDisabled } = useContext(FieldContext);
  const disabled = isFieldDisabled !== undefined ? isFieldDisabled : isDisabled;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);

    const previousRows = event.target.rows;

    event.target.rows = minRows;

    const currentRows = Math.floor(event.target.scrollHeight / LINE_HEIGHT);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setCurrentRows(currentRows < maxRows ? currentRows : maxRows);
  };

  return (
    <TextArea
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      rows={currentRows}
      width={width}
      disabled={disabled}
    />
  );
};

/**
 * Styles
 */

type StyledTextareaProps = Pick<Props, "width">;

const TextArea = styled("textarea").withConfig({
  shouldForwardProp: (prop) => !["width"].includes(prop),
})<StyledTextareaProps>`
  position: relative;
  width: ${(props) => props.width ?? "initial"};
  box-shadow: none;
  font-family: ${font.family.default};
  font-size: 16px;
  font-weight: ${font.weight.regular};
  line-height: ${LINE_HEIGHT}px;
  color: ${theme.textarea.fontColor.default};
  border-top-color: transparent;
  border-bottom-color: ${theme.input.borderColor.default};
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  padding: 10px 0;
  margin: 0 11px;
  transform: translate3d(-11px, 0, 0);
  transform-origin: center center;
  box-sizing: content-box;
  overflow-y: auto;
  resize: none;

  &::placeholder {
    color: ${theme.textarea.placeholderFontColor.default};
    opacity: 1;
  }

  &:hover {
    border-radius: 3px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-color: ${theme.input.borderColor.hover};
    border-bottom-color: ${theme.input.borderColor.hover};
    border-left-color: ${theme.input.borderColor.hover};
    border-right-color: ${theme.input.borderColor.hover};
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
  }

  &:focus {
    border-radius: 3px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-color: ${theme.input.borderColor.focus};
    border-bottom-color: ${theme.input.borderColor.focus};
    border-left-color: ${theme.input.borderColor.focus};
    border-right-color: ${theme.input.borderColor.focus};
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    outline: none;
    box-shadow: 0 0 0 5px ${theme.input.outlineColor};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.input.fontColor.disabled};
    background-color: transparent;

    &::placeholder {
      color: ${theme.input.placeholderFontColor.disabled};
    }

    &:hover {
      border-radius: 0;
      margin: 0 11px;
      padding-left: 0;
      padding-right: 0;
      border-top-color: transparent;
      border-bottom-color: ${theme.input.borderColor.disabled};
      border-left-color: ${theme.input.borderColor.disabled};
      border-right-color: ${theme.input.borderColor.disabled};
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
    }
  }
`;
