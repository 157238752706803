import React, { useContext } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { HeaderDropdownContext } from "components/HeaderDropdown/HeaderDropdown";
import { IconChevron } from "icons";
import { theme } from "styles";

type Props = {
  children?: never;
  rotationDirection: "clockwise" | "anticlockwise";
};

export const HeaderDropdownToggleIndicator = ({ rotationDirection }: Props) => {
  const { isDropdownOpen } = useContext(HeaderDropdownContext);

  const rotationAngle = rotationDirection === "clockwise" ? "180" : "-180";

  const style = useSpring({
    transform: `rotate3d(0, 0, 1, ${isDropdownOpen ? rotationAngle : "0"}deg)`,
  });

  return (
    <AnimatedWrapper style={style}>
      <IconChevron width={"11px"} height={"7px"} color={theme.colors.SnowWhite} />
    </AnimatedWrapper>
  );
};

const AnimatedWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  transform: rotate3d(0, 0, 0, 0deg);
  transform-origin: center center;
  margin: 0 10px;
`;
