import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { DropdownContext } from "components/Dropdown/Dropdown";

type Props = {
  children: React.ReactNode;
};

export const DropdownToggle = ({ children }: Props) => {
  const { isDropdownOpen, setIsDropdownOpen } = useContext(DropdownContext);
  const ref = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (event.key === "Escape" && isDropdownOpen && setIsDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (ref.current && setIsDropdownOpen) {
      ref.current.focus();
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  return (
    <Button ref={ref} onClick={handleClick} onKeyDown={handleKeyDown}>
      {children}
    </Button>
  );
};

const Button = styled("button")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;

  &:focus {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;
