import React from "react";
import styled from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { font, theme } from "styles";
import { tk } from "translations";

type Props = {
  isEditable: boolean;
  editAction: () => void;
  fileLink?: string;
  deleteAction: () => void;
  metaDetails?: {
    type?: string;
    targetAudience?: string;
  };
} & WithTranslation;

export const DetailMetaDetailsRightSide = withTranslation()(
  class DetailMetaDetailsRightSideStatic extends React.PureComponent<Props> {
    render() {
      return (
        <ContentContainer>
          {this.props.metaDetails && this.props.metaDetails.type && (
            <ItemContainer>
              <MetaText>{this.props.metaDetails.type}</MetaText>
            </ItemContainer>
          )}

          {this.props.metaDetails && this.props.metaDetails.targetAudience && (
            <ItemContainer>
              <MetaText>{this.props.metaDetails.targetAudience}</MetaText>
            </ItemContainer>
          )}

          {!!this.props.fileLink && (
            <ItemContainer>
              <Link href={this.props.fileLink} rel={"noopener noreferrer"} target={"_blank"}>
                {this.props.t(tk.documents.download)}
              </Link>
            </ItemContainer>
          )}

          {this.props.isEditable && (
            <ItemContainer>
              <Button onClick={this.props.editAction}>{this.props.t(tk.documents.change)}</Button>
            </ItemContainer>
          )}

          {this.props.isEditable && (
            <ItemContainer>
              <Button onClick={this.props.deleteAction} negative>
                {this.props.t(tk.documents.delete)}
              </Button>
            </ItemContainer>
          )}
        </ContentContainer>
      );
    }
  }
);

/**
 * Styles
 */

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
`;

const ItemContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  &:first-of-type {
    &:before {
      display: none;
    }
  }

  &:before {
    content: "•";
    color: ${theme.colors.IronGrey};
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const getFontColor = (props: { negative?: boolean }) =>
  props.negative ? theme.colors.FireRed : theme.colors.TealGreen;

const Button = styled.div.withConfig({ shouldForwardProp: (prop) => !["negative"].includes(prop) })<{
  negative?: boolean;
}>`
  position: relative;
  font-weight: ${font.weight.bold};
  color: ${getFontColor};
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const MetaText = styled.div`
  position: relative;
  font-weight: ${font.weight.medium};
  color: ${theme.colors.IronGrey};
  padding-top: 3px;
  padding-bottom: 3px;
`;

const Link = styled.a`
  position: relative;
  font-weight: ${font.weight.bold};
  color: ${theme.colors.TealGreen};
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  text-decoration: none;
`;
