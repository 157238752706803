import React from "react";
import styled from "styled-components";
import { Icon } from "components/Icon";
import { font, theme } from "styles";

type Props = {
  isOpened: boolean;
  title: string;
  options: React.ReactNode[];
  onToggle: () => void;
};

export class Options extends React.PureComponent<Props> {
  render() {
    return (
      <Wrapper>
        <OpenerIcon shape={this.props.isOpened ? "cancel" : "options"} key="opener" onClick={this.props.onToggle} />
        {this.props.isOpened && (
          <OptionsMenu>
            <Header>
              <TitleText>{this.props.title.toUpperCase()}</TitleText>
            </Header>
            {this.props.options.map((opt) => opt)}
          </OptionsMenu>
        )}
      </Wrapper>
    );
  }
}

/**
 * Styles
 */

const OpenerIcon = styled(Icon)`
  font-size: 21px;
  line-height: 100%;
  user-select: none;
  cursor: pointer;
  color: ${theme.colors.TealGreen};
  display: inline-block;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
`;

const Wrapper = styled.div`
  position: relative;
`;

const OptionsMenu = styled.div`
  width: 300px;
  min-height: 100px;
  background: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -20px;
  right: -10px;
  z-index: 1000;
`;

const Header = styled.div`
  padding: 24px 24px 14px;
`;

const TitleText = styled.div`
  font-weight: ${font.weight.regular};
  font-size: 12px;
  color: ${theme.colors.NightSky};
  line-height: 28px;
`;
