import React, { createContext } from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  hasIndicator?: boolean;
  hasIllustration?: boolean;
  onClick: () => void;
};

export const ListItemContext = createContext<{ isDisabled?: boolean }>({ isDisabled: undefined });

export const ListItem = ({
  children,
  isActive,
  isDisabled,
  hasIndicator = true,
  hasIllustration = true,
  onClick,
}: Props) => {
  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <ListItemContext.Provider value={{ isDisabled }}>
      <Container isActive={isActive} isDisabled={isDisabled} hasIllustration={hasIllustration} onClick={handleClick}>
        {children}
        {hasIndicator && isActive && !isDisabled && <Indicator />}
      </Container>
    </ListItemContext.Provider>
  );
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "isActive" | "isDisabled" | "hasIllustration">;

const Container = styled("div").withConfig({
  shouldForwardProp: (prop) => !["isActive", "isDisabled", "hasIllustration"].includes(prop),
})<ContainerProps>`
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
  grid-template-columns: ${(props) => (props.hasIllustration ? "max-content" : "")} 1fr repeat(3, max-content);
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: ${(props) =>
    props.isActive && !props.isDisabled ? theme.listItem.backgroundColor.active : "transparent"};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
  user-select: none;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => (props.isDisabled ? "transparent" : theme.listItem.backgroundColor.hover)};
  }
`;

const Indicator = styled("div")`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%;
  background-color: ${theme.listItem.indicatorBackgroundColor};
`;
