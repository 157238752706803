import React from "react";
import styled from "styled-components";
import { LandingPageHexagonDecoration, LandingPageLogo } from "components";

type Props = {
  alternative?: boolean;
  children: React.ReactNode;
};

export const LandingPageLayout = ({ alternative = false, children }: Props) => {
  return (
    <Layout>
      <TopRightHexagonDecorationWrapper alternative={alternative}>
        <LandingPageHexagonDecoration color={"#0A9187"} width={"900px"} height={"900px"} />
      </TopRightHexagonDecorationWrapper>
      <BottomLeftHexagonDecorationWrapper>
        <LandingPageHexagonDecoration color={"#3781FF"} width={"900px"} height={"900px"} />
      </BottomLeftHexagonDecorationWrapper>
      <BottomRightHexagonDecorationWrapper>
        <LandingPageHexagonDecoration color={"#FC8C00"} width={"900px"} height={"900px"} />
      </BottomRightHexagonDecorationWrapper>
      <LogoWrapper>
        <LandingPageLogo width={"236px"} height={"62px"} />
      </LogoWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Layout>
  );
};

/**
 * Styles
 */

const Layout = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr max-content 1fr;
  grid-template-areas:
    "logo . ."
    "content content content"
    ". . .";
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 90px 90px 15px 90px;
  overflow: hidden;
`;

const LogoWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  grid-area: logo;
`;

const ContentWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-area: content;
`;

const TopRightHexagonDecorationWrapper = styled("div").withConfig({
  shouldForwardProp: (prop) => !["alternative"].includes(prop),
})<{ alternative: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: ${({ alternative }) => (!alternative ? -325 : -500)}px;
  right: ${({ alternative }) => (!alternative ? -125 : -500)}px;
`;

const BottomLeftHexagonDecorationWrapper = styled("div")`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: -600px;
  left: -450px;
`;

const BottomRightHexagonDecorationWrapper = styled("div")`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: -700px;
  right: -350px;
`;
