import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { usePasswordRecovery } from "controllers";
import { LandingPageLayout } from "layouts";
import {
  Button,
  Field,
  FieldErrorMessage,
  FieldLabel,
  Input,
  LandingPageCard,
  LandingPageCardText,
  LandingPageCardTitle,
  LandingPageForm,
  LandingPageHelpLink,
  LandingPageHelpText,
  LandingPageTokenError,
} from "components";
import { PasswordRecoveryParams, routes } from "routes";

interface Props extends RouteComponentProps<PasswordRecoveryParams> {}

export const PasswordRecovery = ({ match }: Props) => {
  const { t, tk, state, handlers } = usePasswordRecovery(match.params.step);

  /** Steps 1 & 2 */
  if (state.step === "1" || state.step === "2") {
    return (
      <LandingPageLayout>
        <LandingPageCard>
          <LandingPageCardTitle>{t(tk.passwordRecovery.heading)}</LandingPageCardTitle>

          {state.step === "2" && <LandingPageCardText>{t(tk.passwordRecovery.codeInfo)}</LandingPageCardText>}

          <LandingPageForm onSubmit={handlers.handleSubmit}>
            {/** Email */}
            {state.step === "1" && (
              <Field width={"100%"} margin={"38px 0 0 0"}>
                <FieldLabel>{t(tk.passwordRecovery.form.email.label)}</FieldLabel>
                <Input
                  value={state.email}
                  placeholder={t(tk.passwordRecovery.form.email.placeholder)}
                  type={"email"}
                  required={true}
                  width={"100%"}
                  isDisabled={state.loading}
                  onChange={handlers.handleChangeEmail}
                />
                <FieldErrorMessage>{state.error}</FieldErrorMessage>
              </Field>
            )}

            {/** Token */}
            {state.step === "2" && (
              <Field width={"100%"} margin={"38px 0 0 0"}>
                <FieldLabel>{t(tk.passwordRecovery.form.token.label)}</FieldLabel>
                <Input
                  value={state.token}
                  placeholder={t(tk.passwordRecovery.form.token.placeholder)}
                  type={"text"}
                  required={true}
                  width={"100%"}
                  isDisabled={state.loading}
                  onChange={handlers.handleChangeToken}
                />
              </Field>
            )}

            {/** Password */}
            {state.step === "2" && (
              <Field width={"100%"} margin={"38px 0 0 0"}>
                <FieldLabel>{t(tk.passwordRecovery.form.password.label)}</FieldLabel>
                <Input
                  value={state.password}
                  placeholder={t(tk.passwordRecovery.form.password.placeholder)}
                  type={"password"}
                  required={true}
                  width={"100%"}
                  isDisabled={state.loading}
                  onChange={handlers.handleChangePassword}
                />
                <FieldErrorMessage>{state.error}</FieldErrorMessage>
              </Field>
            )}

            {/** Submit button */}
            <Button color={"green"} type={"submit"} isDisabled={state.loading} margin={"28px 0 0 0"} width={"336px"}>
              {t(tk.passwordRecovery.form.submit)}
            </Button>
          </LandingPageForm>
        </LandingPageCard>

        {/** Back to login */}
        <LandingPageHelpText>{t(tk.passwordRecovery.loginLabel)}</LandingPageHelpText>
        <LandingPageHelpLink>
          <Link to={routes.login}>{t(tk.passwordRecovery.login)}</Link>
        </LandingPageHelpLink>
      </LandingPageLayout>
    );
  }

  /** Token error */
  return <LandingPageTokenError />;
};
