const colors = {
  TealGreen: "#0A9187",
  DenimBlue: "#2B729C",
  SunsetOrange: "#FB8C00",
  FireRed: "#AD2B2B",
  NightSky: "#292D32",
  DarkBlue: "#303C48",
  IronGrey: "#AFAFAF",
  SilverGrey: "#EBEAEA",
  FadingGrey: "#F9F9F9",
  SnowWhite: "#FFFFFF",
};

export const theme = {
  colors: colors,

  // body
  body: {
    backgroundColor: "#F9F9F9",
  },

  // scrollbar
  scrollbar: {
    backgroundColor: "#0A9187",
  },

  // Header
  header: {
    contentOffset: 25,
    shadow: {
      shape: "0 2px 6px",
      color: "#292D3234",
    },
    backgroundColor: "#0A9187",
  },

  // Dropdown
  dropdown: {
    selectBox: {
      backgroundColor: "#FFFFFF",
      shadow: {
        shape: "0 0 5px",
        color: "#00000026",
      },
      rounding: 5,
    },
  },

  // HexagonImage
  hexagonImage: {
    backgroundColor: "#FFFFFF",
    borderColor: "#AFAFAF",
  },

  // FieldLabel
  fieldLabel: {
    fontColor: {
      default: "#292D32",
      disabled: "#AFAFAF",
    },
  },

  // FieldErrorMessage
  fieldErrorMessage: {
    fontColor: "#AD2B2B",
  },

  // Input
  input: {
    fontColor: {
      default: "#292D32",
      disabled: "#AFAFAF",
    },
    placeholderFontColor: {
      default: "#9B9B9B",
      disabled: "#AFAFAF",
    },
    borderColor: {
      default: "#EBEAEA",
      hover: "#9B9B9B",
      focus: "#9B9B9B",
      disabled: "#EBEAEA",
    },
    outlineColor: "#0CABA040",
  },

  // Button
  button: {
    fontColor: {
      default: "#FFFFFF",
      disabled: "#969696",
    },
    backgroundColor: {
      blue: {
        default: "#2B729C",
        hover: "#2F7CA8",
        focus: "#2F7CA8",
        active: "#28698F",
      },
      green: {
        default: "#0A9187",
        hover: "#0B9E94",
        focus: "#0B9E94",
        active: "#09857C",
      },
      disabled: "#EBEAEA",
    },
    borderColor: {
      blue: "#215675",
      green: "#087870",
      disabled: "#D1D1D1",
    },
    outlineColor: {
      blue: "#2F7CA840",
      green: "#0CABA040",
    },
  },

  // ButtonLink
  buttonLink: {
    fontColor: {
      default: "#0A9187",
      hover: "#0B9E94",
      focus: "#0B9E94",
      active: "#09857c",
      disabled: "#969696",
    },
    outlineColor: "#0CABA040",
  },

  // RadioButton
  radioButton: {
    fontColor: {
      default: "#FFFFFF",
      disabled: "#969696",
    },
    backgroundColor: {
      notChecked: {
        default: "#2B729C",
        hover: "#2F7CA8",
        focus: "#2F7CA8",
        active: "#28698F",
      },
      checked: {
        default: "#0A9187",
        hover: "#0B9E94",
        focus: "#0B9E94",
        active: "#09857C",
      },
      disabled: "#EBEAEA",
    },
    borderColor: {
      default: "#215675",
      checked: "#087870",
      disabled: "#D1D1D1",
    },
    outlineColor: {
      default: "#2F7CA840",
      checked: "#0CABA040",
    },
    iconColor: {
      default: "#FFFFFF",
      disabled: "#969696",
    },
  },

  // Card
  card: {
    backgroundColor: "#FFFFFF",
    shadowColor: "#00000010",
  },

  // LandingPageHelpText
  landingPageHelpText: {
    fontColor: "#AFAFAF",
  },

  // LandingPageHelpLink
  landingPageHelpLink: {
    fontColor: "#0A9187",
  },

  // LandingPageCardTitle
  landingPageCardTitle: {
    fontColor: "#292D32",
  },

  // LandingPageFormMetaLink
  landingPageFormMetaLink: {
    fontColor: "#AFAFAF",
  },

  // LandingPageFormMetaText
  landingPageFormMetaText: {
    fontColor: "#AFAFAF",
  },

  // LandingPageCardText
  landingPageCardText: {
    fontColor: "#2B2B2B",
  },

  // CardBackButton
  cardBackButton: {
    fontColor: "#0A9187",
    outlineColor: "#0CABA040",
  },

  // Dot
  dot: {
    borderColor: "#0A9187",
    backgroundColor: "#0A9187",
  },

  // ListItem
  listItem: {
    backgroundColor: {
      active: "#F9F9F9",
      hover: "#F9F9F9",
    },
    indicatorBackgroundColor: "#0A9187",
  },

  // ListItemText
  listItemText: {
    fontColor: {
      black: "#303C48",
      green: "#0A9187",
    },
  },

  // ListItemMetaText
  listItemMetaText: {
    fontColor: "#AFAFAF",
  },

  // LanguageSelect
  languageSelect: {
    toggleIndicatorColor: "#AFAFAF",
  },

  // SidebarAppVersion
  sidebarAppVersion: {
    fontColor: "#AFAFAF",
  },

  // Sidebar
  sidebar: {
    borderColor: "#EBEAEA",
    backgroundColor: "#FFFFFF",
  },

  // SidebarSupportLink
  sidebarSupportLink: {
    fontColor: "#303C48",
    backgroundColor: {
      hover: "#F9F9F9",
    },
  },

  // SidebarTitle
  sidebarTitle: {
    fontColor: "#2B2B2B",
  },

  // SidebarListItem
  sidebarListItem: {
    fontColor: "#303C48",
  },

  // Textarea
  textarea: {
    fontColor: {
      default: "#292D32",
      disabled: "#AFAFAF",
    },
    placeholderFontColor: {
      default: "#9B9B9B",
      disabled: "#AFAFAF",
    },
    borderColor: {
      default: "#EBEAEA",
      hover: "#9B9B9B",
      focus: "#9B9B9B",
      disabled: "#EBEAEA",
    },
    outlineColor: "#0CABA040",
  },

  // SearchInput
  searchInput: {
    searchIconColor: "#0A9187",
    clearIconColor: "#FFFFFF",
    borderColor: "#EBEAEA",
    inputBorderColor: "#AFAFAF",
    buttonBackgroundColor: "#AFAFAF",
    fontColor: "#2B2B2B",
    playholderFontColor: "#9B9B9B",
  },

  // Navbar
  navbar: {
    borderColor: "#EBEAEA",
    backgroundColor: "#FFFFFF",
  },

  // NavigationLinkText
  navigationLinkText: {
    fontColor: "#292D32",
  },

  // Tabs
  tabs: {
    borderColor: "#EBEAEA",
    backgroundColor: "#FFFFFF",
  },

  // TabList
  tabList: {
    borderColor: "#EBEAEA",
  },

  // Tab
  tab: {
    fontColor: {
      default: "#AFAFAF",
      active: "#0A9187",
    },
    iconColor: {
      default: "#AFAFAF",
      active: "#0A9187",
    },
  },

  // Switch
  switch: {
    backgroundColor: {
      default: "#EBEAEA",
      active: "#0A9187",
    },
    thumb: {
      backgroundColor: "#FFFFFF",
      shadowColor: "#00000025",
    },
  },

  // HorizontalSeparator
  horizontalSeparator: {
    backgroundColor: "#EBEAEA",
  },

  // VerticalSeparator
  verticalSeparator: {
    backgroundColor: "#EBEAEA",
  },

  // Radio
  radio: {
    fontColor: {
      default: "#2B2B2B",
      disabled: "#AFAFAF",
    },
    borderColor: {
      default: "#EBEAEA",
      disabled: "#EBEAEA",
      hover: "#9EDED9",
    },
    backgroundColor: {
      default: "#FFFFFF",
      disabled: "#F9F9F9",
    },
    dotBackgroundColor: {
      default: "#0A9187",
      disabled: "#AFAFAF",
    },
    outlineColor: "#0CABA040",
  },

  // FieldHeading
  fieldHeading: {
    fontColor: "#292D32",
  },

  // FieldDescription
  fieldDescription: {
    fontColor: "#AFAFAF",
  },

  // SelectOptionLabel
  selectOptionLabel: {
    fontColor: "#2B2B2B",
  },

  // SelectOptionDetail
  selectOptionDetail: {
    fontColor: "#AFAFAF",
  },

  // Select
  select: {
    iconColor: {
      active: "#0A9187",
      inactive: "#AFAFAF",
    },
  },

  // ReactionListItem
  reactionListItem: {
    borderColor: "#EBEAEA",
  },

  // ReactionListItemInput
  reactionListItemInput: {
    fontColor: {
      input: "#0A9187",
      placeholder: "#AFAFAF",
    },
    backgroundColor: "#f2f2f2",
  },

  // ReactionListItemBullet
  reactionListItemBullet: {
    fontColor: "#FFFFFF",
    backgroundColor: "#0A9187",
  },

  // ReactionListItemDeleteButton
  reactionListItemDeleteButton: {
    iconColor: "#AFAFAF",
  },

  // ReactionListAddButton
  reactionListAddButton: {
    fontColor: "#0A9187",
  },

  // RecipientsSelectionList
  recipientsSelectionList: {
    noResult: {
      fontColor: "#AFAFAF",
    },
    heading: {
      fontColor: "#2B2B2B",
    },
  },

  // RecipientsSelectionAddButton
  recipientsSelectionAddButton: {
    fontColor: {
      default: "#0A9187",
      hover: "#0B9E94",
      focus: "#0B9E94",
      active: "#09857c",
      disabled: "#969696",
    },
    outlineColor: "#0CABA040",
  },

  // RecipientsSelectionRemoveButton
  recipientsSelectionRemoveButton: {
    fontColor: {
      default: "#AD2B2B",
      hover: "#AD2B2B",
      focus: "#AD2B2B",
      active: "#AD2B2B",
      disabled: "#969696",
    },
    outlineColor: "#AD2B2B40",
  },

  // SelectedRecipientsListItemText
  selectedRecipientsListItemText: {
    fontColor: {
      primary: "#2B2B2B",
      secondary: "#AFAFAF",
    },
  },

  // SearchInputResultsListItem
  searchInputResultsListItem: {
    backgroundColor: "#F9F9F9",
  },

  // SearchInputResultsListItemText
  searchInputResultsListItemText: {
    fontColor: {
      primary: "#2B2B2B",
      secondary: "#AFAFAF",
    },
  },
};
