import React, { KeyboardEvent, ChangeEvent, useState, useCallback } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { font, theme } from "styles";

interface Props {
  rows?: number;
  placeholder?: string;
  onSubmit: (value: string) => any;
}

export const MessageInput = ({ rows = 1, placeholder = "", onSubmit }: Props) => {
  const [message, setMessage] = useState("");

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.currentTarget.value), []);

  const submit = useCallback(() => {
    if (message.trim().length > 0) onSubmit(message);
    setMessage("");
  }, [message, onSubmit]);

  const handleKeyPress = useCallback(
    async (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !(e.ctrlKey || e.shiftKey)) {
        e.preventDefault();
        submit();
      }
    },
    [submit]
  );

  return (
    <Input
      rows={rows}
      value={message}
      autoFocus={true}
      placeholder={placeholder}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
    />
  );
};

/**
 * Styles
 */

const Input = styled(TextareaAutosize)`
  padding: 15px;
  width: 100%;
  max-height: 200px;
  border: none;
  flex-shrink: 0;
  resize: none;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.medium};
  color: ${theme.colors.NightSky};

  ::placeholder {
    color: ${theme.colors.IronGrey};
  }

  &:focus {
    outline: 3px solid rgba(200, 200, 200, 0.2);
  }
`;
