import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { font } from "styles";

interface Props extends PropsWithChildren<{ isClickable?: boolean; onClick?: () => any }> {}

export const WindowHeader = ({ isClickable = true, onClick = () => {}, children }: Props) => (
  <ComponentWrapper isClickable={isClickable} onClick={onClick}>
    {children}
  </ComponentWrapper>
);

/**
 * Styles
 */

const ComponentWrapper = styled.div.withConfig({ shouldForwardProp: (prop) => !["isClickable"].includes(prop) })<{
  isClickable: boolean;
}>`
  height: 47px;
  font-weight: ${font.weight.medium};
  font-size: 12px;
  color: #404040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 17px;
  flex-shrink: 0;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
`;
