import { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, matchPath } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useDashboardState, useMe, useAnnouncementsPoll, useDocumentsPoll } from "hooks";
import { hasActiveSpace } from "helpers";
import { useAuth } from "api";
import { DashboardParams, getRoute, routes } from "routes";

/** Local Storage keys */
export const LS_ACTIVE_ORGANIZATION = "@moyobo-webapp/active-organization-id";
export const LS_ACTIVE_SPACE = "@moyobo-webapp/active-space-id";

export const useDashboard = ({ history, location, match }: RouteComponentProps<DashboardParams>) => {
  const [render, setRender] = useState(false);

  const [lsOrganization, setLsOrganization] = useLocalStorage<string>(LS_ACTIVE_ORGANIZATION, "");
  const [lsSpace, setLsSpace] = useLocalStorage<string>(LS_ACTIVE_SPACE, "");

  const { isLoggedIn, logout } = useAuth();

  const { me, activeOrganization, organizations, isManager } = useMe();

  const { setState } = useDashboardState();

  const { organization, space } = useMemo(() => match.params, [match.params]);

  const defaultUrlParams = useMemo(() => organization === ":organization" || space === ":space", [organization, space]);

  const storiesRoute = useMemo(() => getRoute.stories({ organization, space }), [organization, space]);

  /** Documents Polling */
  useDocumentsPoll(space, isManager, defaultUrlParams);

  /** Announcements Polling */
  useAnnouncementsPoll(space, isManager, defaultUrlParams);

  /** On URL params change, set organization and space to store */
  useEffect(() => {
    setState({ organization, space });

    if (defaultUrlParams) return;

    setLsOrganization(organization);
    setLsSpace(space);
  }, [defaultUrlParams, organization, setLsOrganization, setLsSpace, setState, space]);

  useEffect(() => {
    /** If not logged in, redirect to log-in */
    if (!isLoggedIn()) return history.replace(routes.login);

    /** Skip if me is not ready */
    if (!me) return;

    /** If user has no active space, logout */
    if (!hasActiveSpace(me)) return logout();

    /** Set default active organization and space */
    if (defaultUrlParams) {
      const storedOrganization = !lsOrganization ? undefined : organizations.find(({ id }) => id === lsOrganization);
      const defaultOrganization = storedOrganization || organizations[0];

      const storedSpace = !lsSpace ? undefined : defaultOrganization.spaces.find(({ id }) => id === lsSpace);
      const defaultSpace = storedSpace || defaultOrganization.spaces[0];

      return history.replace(getRoute.dashboard({ organization: defaultOrganization.id, space: defaultSpace.id }));
    }

    /** Redirect to organization from dashboard root url (after login) */
    const isDashboardRoot = !!matchPath(location.pathname, { path: routes.dashboard, exact: true });

    if (isDashboardRoot) history.replace(storiesRoute);
  }, [
    defaultUrlParams,
    history,
    isLoggedIn,
    location.pathname,
    logout,
    lsOrganization,
    lsSpace,
    me,
    organizations,
    storiesRoute,
  ]);

  /** Render after me is ready */
  useEffect(() => {
    if (!me || defaultUrlParams) return;

    setRender(true);
  }, [defaultUrlParams, me, organization, space]);

  return {
    data: { storiesRoute, activeOrganizationId: activeOrganization?.id },
    state: { render, organization, space },
  };
};
