import React from "react";
import styled from "styled-components";
import { Icon } from "components";

// TODO: copied from original moyobo webapp - should be refactored

type Props = {
  onClick: () => void;
} & React.HTMLAttributes<HTMLButtonElement>;

const Button = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

export class CloseButton extends React.PureComponent<Props> {
  render() {
    return (
      <Button {...this.props}>
        <Icon shape={"cancel"} style={{ fontSize: "24px", color: "#9b9b9b", cursor: "pointer" }} />
      </Button>
    );
  }
}
