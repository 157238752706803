import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { DocumentList, Navigation, NoContentNotice, SidebarListSpinner } from "components";
import * as Tabs from "components/Tabs";
import { useDashboardState, useDocumentsList, useMe } from "hooks";
import { tk, useTranslation } from "translations";
import { DocumentsTabs, getRoute } from "routes";

type Props = {
  children: React.ReactNode;
  buttons: React.ReactNode;
  tab: DocumentsTabs;
  activeDocumentId?: string;
  create?: boolean;
};

export const DocumentsLayout = ({ children, buttons, tab, activeDocumentId, create = false }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { organization, space } = useDashboardState();

  const { loading, sentDocuments, receivedDocuments } = useDocumentsList(tab, activeDocumentId, create);

  const { isManager } = useMe();

  const changeTab = useCallback(
    (tab: DocumentsTabs) => history.push(getRoute.documents({ organization, space, tab })),
    [history, organization, space]
  );

  const handleClickSent = useCallback(() => changeTab("sent"), [changeTab]);
  const handleClickReceived = useCallback(() => changeTab("received"), [changeTab]);

  const documents = useMemo(() => {
    if (!sentDocuments || !receivedDocuments) return undefined;

    if (tab === "sent" && sentDocuments.length > 0) return sentDocuments;
    if (tab === "received" && receivedDocuments.length > 0) return receivedDocuments;

    return undefined;
  }, [receivedDocuments, sentDocuments, tab]);

  return (
    <Container>
      <Navigation>{buttons}</Navigation>

      <Content>
        <Tabs.Tabs>
          <Tabs.TabList>
            {isManager && (
              <Tabs.Tab isActive={tab === "sent"} onClick={handleClickSent}>
                {t(tk.documents.tabs.sent)}
              </Tabs.Tab>
            )}
            <Tabs.Tab isActive={tab === "received"} onClick={handleClickReceived}>
              {t(tk.documents.tabs.received)}
            </Tabs.Tab>
          </Tabs.TabList>

          <Tabs.TabPanels>
            <Tabs.TabPanel>
              {loading && !documents && <SidebarListSpinner margin={"60px 0 0 0"} width={"355px"} />}

              {!loading && !documents && <NoContentNotice margin={"60px 0 0 0"} width={"355px"} />}

              {!loading && !!documents && <DocumentList items={documents} />}
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs.Tabs>

        <ContentContainer>{children}</ContentContainer>
      </Content>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  position: relative;
  flex: 1 auto;
  overflow: hidden;
`;

const Content = styled("div")`
  display: flex;
  flex: 1 100%;
  height: calc(100% - 65px);
`;

const ContentContainer = styled("div")`
  position: relative;
  width: 100%;
  padding: 0 25px;
  overflow: auto;
`;

export const DocumentsLayoutActionsWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`;
