import React from "react";
import styled from "styled-components";

type Props = {
  children?: never;
  illustrationSrc: string;
};

export const GuideIllustration = ({ illustrationSrc }: Props) => {
  return (
    <Container>
      <Image src={illustrationSrc} alt={"guide image"} />
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 286px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const Image = styled("img")`
  height: 268px;
  width: auto;
  object-fit: contain;
`;
