import React, { useMemo } from "react";
import styled from "styled-components";
import { NotificationDot, VerticalSeparator } from "components";
import { NavigationLink, NavigationLinkText } from "components/Navigation";
import { IconDocument, IconFeed, IconNotification } from "icons";
import { tk, useTranslation } from "translations";
import { useDashboardState, useMe } from "hooks";
import { getRoute } from "routes";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
};

export const Navigation = ({ children }: Props) => {
  const { t } = useTranslation();

  const { organization, space } = useDashboardState();

  const { isManager, activeSpace } = useMe();

  const unresolvedDocuments = useMemo(() => activeSpace?.unresolvedDocumentCount || 0, [activeSpace]);
  const unresolvedAnnouncements = useMemo(() => activeSpace?.unresolvedAnnouncementCount || 0, [activeSpace]);

  return (
    <Container>
      <NavigationWrapper>
        {/** Stories */}
        <NavigationLink to={getRoute.stories({ organization, space })}>
          <IconFeed color={theme.colors.TealGreen} width={"20px"} height={"18px"} margin={"0 10px 0 0"} />
          <NavigationLinkText>{t(tk.common.stories)}</NavigationLinkText>
        </NavigationLink>

        <VerticalSeparator />

        {/** Documents */}
        <LinkContainer>
          <NavigationLink
            to={getRoute.documents({ organization, space, tab: isManager ? "sent" : "received" })}
            activeRoutes={[
              getRoute.documents({ organization, space, tab: "sent" }),
              getRoute.documents({ organization, space, tab: "received" }),
            ]}
          >
            <IconDocument color={theme.colors.TealGreen} width={"15px"} height={"20px"} margin={"0 10px 0 0"} />

            <NavigationLinkText>{t(tk.common.documents)}</NavigationLinkText>
          </NavigationLink>

          {unresolvedDocuments > 0 && (
            <DotContainer>
              <NotificationDot value={unresolvedDocuments} />
            </DotContainer>
          )}
        </LinkContainer>

        <VerticalSeparator />

        {/** Announcements */}
        <LinkContainer>
          <NavigationLink
            to={getRoute.announcements({ organization, space, tab: isManager ? "sent" : "unresolved" })}
            activeRoutes={[
              getRoute.announcements({ organization, space, tab: "sent" }),
              getRoute.announcements({ organization, space, tab: "unresolved" }),
              getRoute.announcements({ organization, space, tab: "received" }),
              getRoute.announcements({ organization, space, tab: "marked" }),
              getRoute.announcements({ organization, space, tab: "archive" }),
            ]}
          >
            <IconNotification color={theme.colors.TealGreen} width={"16px"} height={"20px"} margin={"0 10px 0 0"} />
            <NavigationLinkText>{t(tk.common.announcements)}</NavigationLinkText>
          </NavigationLink>

          {unresolvedAnnouncements > 0 && (
            <DotContainer>
              <NotificationDot value={unresolvedAnnouncements} />
            </DotContainer>
          )}
        </LinkContainer>
      </NavigationWrapper>

      {/** Buttons */}
      {children}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: end;
  height: 65px;
  width: 100%;
  padding: 0 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.navbar.borderColor};
  background-color: ${theme.navbar.backgroundColor};
  grid-area: navbar;
`;

const NavigationWrapper = styled("nav")`
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  align-items: center;
`;

const LinkContainer = styled.div`
  position: relative;
`;

const DotContainer = styled.div`
  position: absolute;
  top: -9px;
  left: 5px;
`;
