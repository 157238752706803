import React from "react";
import Tooltip from "rc-tooltip";
import styled from "styled-components";
import "rc-tooltip/assets/bootstrap.css";
import { IconShape, Icon } from "components/Icon";
import { Badge } from "components/Badge";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

type Props = {
  firstName: string;
  lastName: string;
  isActive: boolean;
  metaText?: string;
  icon?: IconShape;
  tooltip?: React.ReactNode;
};

export const AnnouncementInteractionRow = ({ firstName, lastName, isActive, metaText, icon, tooltip }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LeftContent>
        <Name isActive={isActive}>
          {firstName} {lastName}
        </Name>
        {!isActive && <NotActiveBadge>{t(tk.common.notActive)}</NotActiveBadge>}
      </LeftContent>

      <RightContent>
        {!!metaText && <MetaText>{metaText}</MetaText>}
        {!!icon && !tooltip && <MyIcon shape={icon} color={theme.colors.IronGrey} size={"20px"} />}
        {!!icon && !!tooltip && (
          <Tooltip
            placement={"right"}
            overlay={<span>{tooltip}</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
            overlayClassName={"primary-tooltip"}
          >
            <MyIcon shape={icon} color={theme.colors.IronGrey} size={"20px"} />
          </Tooltip>
        )}
      </RightContent>
    </Wrapper>
  );
};

/**
 * Styles
 */

const Wrapper = styled.div`
  font-family: "Raleway", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`;

const LeftContent = styled.div``;
const RightContent = styled.div``;

const Name = styled.span.withConfig({ shouldForwardProp: (prop) => !["isActive"].includes(prop) })<{
  isActive: boolean;
}>`
  color: ${(props) => (props.isActive ? theme.colors.NightSky : theme.colors.IronGrey)};
  font-size: 16px;
  padding-right: 12px;
`;

const NotActiveBadge = styled(Badge)`
  color: ${theme.colors.NightSky};
  background-color: ${theme.colors.FadingGrey};
`;

const MetaText = styled.span`
  color: ${theme.colors.IronGrey};
  font-size: 10px;
  font-weight: ${font.weight.medium};
  margin: 0 7px;
`;

const MyIcon = styled(Icon)``;
