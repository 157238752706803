import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { font, theme } from "styles";

export class DetailSubheading extends React.Component<PropsWithChildren> {
  render() {
    return <Subheading>{this.props.children}</Subheading>;
  }
}

/**
 * Styles
 */

const Subheading = styled.div`
  position: relative;
  font-weight: ${font.weight.medium};
  font-size: 12px;
  color: ${theme.colors.IronGrey};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
