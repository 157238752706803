import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import moment from "moment";
import { useDashboardState, useMedia, useReactions, useRecipientSelection } from "hooks";
import { AnnouncementCreateInput, MediaTypes, resolveError, Urgency, useAnnouncementCreateMutation } from "api";
import { AnnouncementsTabs, getRoute } from "routes";
import { tk, useTranslation } from "translations";
import { isBeforeNow, isVimeoUrl, isYoutubeUrl } from "helpers";

export const useAnnouncementsCreate = (tab: AnnouncementsTabs) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.announcements) + " " + t(tk.common.documentTitleSuffix));

  const { organization, space } = useDashboardState();

  const media = useMedia(MediaTypes.AnnouncementImage, false);
  const [announcementCreate, { loading }] = useAnnouncementCreateMutation();

  const { create } = useRecipientSelection("");
  const [recipientSelection, setRecipientSelection] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (recipientSelection) return;
    create(space).then((id) => setRecipientSelection(id));
  }, [create, recipientSelection, space]);

  const reactions = useReactions();
  const [hasReactions, setHasReactions] = useState(false);

  const [dialog, setDialog] = useState<"deadline" | "summary" | "progress" | "success" | "error" | undefined>(
    undefined
  );
  const [createdAnnouncementId, setCreatedAnnouncementId] = useState<string | undefined>(undefined);

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const [urgency, setUrgency] = useState<Urgency>(Urgency.NotUrgent);

  const [hasDeadline, setHasDeadline] = useState(false);
  const [hasExactDeadline, setHasExactDeadline] = useState(false);
  const [deadline, setDeadline] = useState<string | undefined>(undefined);

  const [selected, setSelected] = useState(0);

  const [titleError, setTitleError] = useState("");
  const [videoUrlError, setVideoUrlError] = useState("");
  const [textError, setTextError] = useState("");
  const [deadlineError, setDeadlineError] = useState("");
  const [selectedError, setSelectedError] = useState("");

  const submitSummary = useCallback(async () => {
    if (loading || !recipientSelection || !space) return;

    setDialog("progress");

    try {
      const input: AnnouncementCreateInput = {
        space: space,
        title: title,
        videoUrl: !videoUrl ? undefined : videoUrl,
        text: text,
        urgency: urgency,
        deadline: deadline,
        reactions: reactions.state.sanitizedReactions.map((r, i) => ({ position: i, reaction: r })),
        recipientSelection: recipientSelection,
      };

      if (!!media.preview) input.media = await media.upload();

      const { data } = await announcementCreate({ variables: { input } });

      setCreatedAnnouncementId(data?.announcementCreate.announcement.id);
      setDialog("success");
    } catch (e: any) {
      setDialog("error");
      resolveError(e);
    }
  }, [
    announcementCreate,
    deadline,
    loading,
    media,
    reactions.state.sanitizedReactions,
    recipientSelection,
    space,
    text,
    title,
    urgency,
    videoUrl,
  ]);

  const submit = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (loading || !recipientSelection) return;

      let isValid = true;

      if (!title.trim().length) {
        setTitleError(t(tk.announcementsCreate.form.title.required));
        isValid = false;
      }

      if (videoUrl.length > 0 && !isVimeoUrl(videoUrl) && !isYoutubeUrl(videoUrl)) {
        setVideoUrlError(t(tk.announcementsCreate.form.videoUrl.invalid));
        isValid = false;
      }

      if (!text.trim().length) {
        setTextError(t(tk.announcementsCreate.form.text.required));
        isValid = false;
      }

      if (hasDeadline && !!deadline && isBeforeNow(deadline)) {
        setDeadlineError(t(tk.announcementsCreate.form.deadline.invalid));
        isValid = false;
      }

      if (!selected) {
        setSelectedError(t(tk.documentsCreate.form.recipients.required));
        isValid = false;
      }

      if (!isValid) return;

      setDialog("summary");
    },
    [deadline, hasDeadline, loading, recipientSelection, selected, t, text, title, videoUrl]
  );

  const cancel = useCallback(() => history.goBack(), [history]);

  const openDeadlineDialog = useCallback(() => setDialog("deadline"), []);

  const closeDialog = useCallback(() => setDialog(undefined), []);

  const goToSent = useCallback(() => {
    if (createdAnnouncementId) {
      return history.replace(getRoute.announcementsDetail({ organization, space, tab, id: createdAnnouncementId }));
    }

    history.replace(getRoute.announcements({ organization, space, tab: "sent" }));
  }, [createdAnnouncementId, history, organization, space, tab]);

  const handleChangeTitle = useCallback(setTitle, [setTitle]);
  const handleChangeVideoUrl = useCallback(setVideoUrl, [setVideoUrl]);
  const handleChangeText = useCallback(setText, [setText]);
  const handleChangeUrgency = useCallback(setUrgency, [setUrgency]);
  const handleChangeDeadline = useCallback((index: number) => {
    const { format } = announcementCreateDeadlineOptions[index];
    const value = format(moment.utc().local())?.format();
    setDeadline(value);
  }, []);
  const handleChangeExactDeadline = useCallback(setDeadline, [setDeadline]);
  const handleChangeRecipientSelection = useCallback(setSelected, [setSelected]);

  const toggleHasDeadline = useCallback(() => {
    if (hasDeadline) {
      setHasExactDeadline(false);
      setDeadline(undefined);
    }

    setHasDeadline(() => !hasDeadline);
  }, [hasDeadline]);

  const toggleHasExactDeadline = useCallback(() => {
    handleChangeDeadline(0);
    setHasExactDeadline(() => !hasExactDeadline);
  }, [handleChangeDeadline, hasExactDeadline]);

  const toggleHasReactions = useCallback(() => {
    if (hasReactions) reactions.handlers.clear();
    setHasReactions(() => !hasReactions);
  }, [hasReactions, reactions.handlers]);

  useEffect(() => setTitleError(""), [title]);
  useEffect(() => setVideoUrlError(""), [videoUrl]);
  useEffect(() => setTextError(""), [text]);
  useEffect(() => setDeadlineError(""), [deadline]);
  useEffect(() => setSelectedError(""), [selected]);

  return {
    t,
    tk,
    media,
    reactions,
    data: { recipientSelection },
    state: {
      tab,
      loading,
      dialog,
      title,
      videoUrl,
      text,
      urgency,
      hasDeadline,
      hasExactDeadline,
      deadline,
      hasReactions,
      selected,
      titleError,
      videoUrlError,
      textError,
      deadlineError,
      selectedError,
    },
    handlers: {
      submit,
      cancel,
      submitSummary,
      openDeadlineDialog,
      closeDialog,
      goToSent,
      handleChangeTitle,
      handleChangeVideoUrl,
      handleChangeText,
      handleChangeUrgency,
      handleChangeDeadline,
      handleChangeExactDeadline,
      handleChangeRecipientSelection,
      toggleHasDeadline,
      toggleHasExactDeadline,
      toggleHasReactions,
    },
  };
};

type DeadlineOption = {
  title: string;
  options?: { count: number };
  format: (date: moment.Moment) => moment.Moment | null;
};

export const announcementCreateDeadlineOptions: DeadlineOption[] = [
  {
    title: tk.deadlines.afterHours,
    options: { count: 2 },
    format: (date) => date.add(2, "h"),
  },
  {
    title: tk.deadlines.todayEvening,
    format: (date) => date.hour(23).minute(59),
  },
  {
    title: tk.deadlines.tomorrowEvening,
    format: (date) => date.add(1, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.afterDays,
    options: { count: 2 },
    format: (date) => date.add(2, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.afterDays,
    options: { count: 3 },
    format: (date) => date.add(3, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.afterWeek,
    format: (date) => date.add(7, "d").hour(23).minute(59),
  },
];
