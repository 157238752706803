import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import { animated, useTransition } from "react-spring";
import { DropdownContext } from "components/Dropdown/Dropdown";

type Props = {
  children: React.ReactNode;
  alignment: "left" | "right";
};

export const DropdownMenuBox = ({ children, alignment }: Props) => {
  const { isDropdownOpen } = useContext(DropdownContext);

  const transition = useTransition(isDropdownOpen, null, {
    from: { transform: "translate3d(0, -10px, 0)", opacity: 0 },
    enter: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    leave: { transform: "translate3d(0, -10px, 0)", opacity: 0 },
  });

  const getAlignment = () => {
    switch (alignment) {
      case "left":
        return { left: "-7px" };
      case "right":
        return { right: "-7px" };
      default:
        return {};
    }
  };

  return (
    <Fragment>
      {transition.map(
        ({ item, key, props: style }) =>
          item && (
            <AnimatedWrapper key={key} style={{ ...style, ...getAlignment() }}>
              <StyledBox>{children}</StyledBox>
            </AnimatedWrapper>
          )
      )}
    </Fragment>
  );
};

const StyledBox = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 5px #00000026;
  border-radius: 5px;
  opacity: 1;
  overflow: hidden;
`;

export const AnimatedWrapper = styled(animated.div)`
  display: flex;
  position: absolute;
  top: 60px;
`;
