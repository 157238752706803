import React from "react";

type Props = {
  children?: never;
};

export const HeaderLogo = (_: Props) => {
  return (
    <svg xmlns={"http://www.w3.org/2000/svg"} width={"119"} height={"27"} viewBox={"0 0 119 27"} fill={"#FFFFFF"}>
      <path
        d={
          "M0,18.009c2.5-.133,4.992-.384,7.485-.348a12.5,12.5,0,0,1,3.172.96,1.858,1.858,0,0,0,1.287,0,8.293,8.293,0,0,1,6.694-.706,4.884,4.884,0,0,1,3.538,3.8,12.328,12.328,0,0,1,.383,2.742c.055,2.365.023,4.73.023,7.095,0,.2-.026.4-.045.651H19.014v-1.07c-.058-3.019-.071-6.044-.206-9.06a6.233,6.233,0,0,0-.714-2.469,2.207,2.207,0,0,0-4.043-.02,9.494,9.494,0,0,0-.862,3.517c-.145,2.654-.084,5.325-.1,7.986v1.106H9.6c0-.306-.035-.618-.039-.934-.045-2.872-.032-5.748-.164-8.614a9.918,9.918,0,0,0-.717-2.928,2.084,2.084,0,0,0-2.088-1.383A1.99,1.99,0,0,0,4.494,19.7a23.1,23.1,0,0,0-.878,4.714c-.148,2.235-.042,4.489-.045,6.734v1.06H0Z"
        }
        transform={"translate(0 -11.828)"}
      />
      <path
        d={
          "M144.591,39.887c0-1.952,0-3.9-.058-5.833a1.147,1.147,0,0,0-.4-.856,9.716,9.716,0,0,1-3.834-4.356A21.894,21.894,0,0,1,138,19.12h3.445c.106,1.392.116,2.827.341,4.229.322,2.027.74,4.05,1.248,6.038a7.469,7.469,0,0,0,1.177,2.368,2.424,2.424,0,0,0,4.182.078,9.542,9.542,0,0,0,1.866-4.6c.441-2.674.737-5.371,1.107-8.133h3.4c-.193,1.555-.322,3.09-.6,4.6a17.5,17.5,0,0,1-2.519,6.46,8.218,8.218,0,0,1-3.136,2.973.829.829,0,0,0-.418.576c0,2.059.026,4.118.048,6.181Z"
        }
        transform={"translate(-93.609 -12.887)"}
      />
      <path
        d={
          "M261.042,6.434a13.656,13.656,0,0,1,7.045-.69c4.275.651,6.073,2.746,6.179,7.111a11.464,11.464,0,0,1-.322,3.019c-.666,2.729-2.837,4.349-6.047,4.675a15.933,15.933,0,0,1-6.331-.429,1.492,1.492,0,0,0-.6,0c-1.1.127-2.194.263-3.374.407V0h3.442Zm9.869,6.766a32.832,32.832,0,0,0-.688-3.887,3.912,3.912,0,0,0-4.06-2.957c-2.3-.049-3.783.807-4.458,2.791a12.206,12.206,0,0,0,0,8c.643,1.887,2.078,2.749,4.217,2.749s3.593-.849,4.2-2.752A39.626,39.626,0,0,0,270.911,13.2Z"
        }
        transform={"translate(-174.73)"}
      />
      <path
        d={
          "M326.823,17c.9.124,1.93.2,2.947.41a5.794,5.794,0,0,1,4.963,5.341,14.877,14.877,0,0,1-.187,4.531,5.67,5.67,0,0,1-4.671,4.483,14.461,14.461,0,0,1-6.08.15c-3.6-.651-5.25-2.446-5.578-6.135a12.722,12.722,0,0,1,.19-3.474c.544-2.82,2.467-4.505,5.549-5C324.874,17.163,325.778,17.111,326.823,17Zm4.677,7.742a33.983,33.983,0,0,0-.708-3.9c-.6-2.007-2.062-2.928-4.259-2.947a4.064,4.064,0,0,0-4.256,2.85,12.375,12.375,0,0,0,0,7.924,4,4,0,0,0,4.182,2.807c2.165,0,3.651-.875,4.266-2.8a32.078,32.078,0,0,0,.775-3.926Z"
        }
        transform={"translate(-215.826 -11.47)"}
      />
      <path
        d={
          "M78.69,24.72c.071-.683.109-1.37.219-2.046a5.874,5.874,0,0,1,4.558-5.036,13.756,13.756,0,0,1,6.755-.172c3.313.745,4.973,2.759,5.2,6.334a12.676,12.676,0,0,1-.187,3.12,5.946,5.946,0,0,1-5.044,4.99,15.3,15.3,0,0,1-6.25.02,5.97,5.97,0,0,1-5.147-5.95c-.026-.416,0-.836,0-1.256Zm3.4-.036a34.379,34.379,0,0,0,.717,3.926c.6,2,2.081,2.928,4.259,2.928a4.036,4.036,0,0,0,4.288-2.928,12.609,12.609,0,0,0,0-7.758,4.577,4.577,0,0,0-8.483-.091A32.173,32.173,0,0,0,82.09,24.684Z"
        }
        transform={"translate(-53.377 -11.555)"}
      />
      <path
        d={
          "M205.615,17c.936.1,1.888.143,2.811.325,3.4.7,5.089,2.746,5.353,6.506a12.383,12.383,0,0,1-.177,2.944,5.966,5.966,0,0,1-5.079,5.058,15.056,15.056,0,0,1-6.343,0c-2.734-.582-4.565-2.2-4.9-5.065a15.423,15.423,0,0,1,.171-4.88c.582-2.807,2.705-4.066,5.333-4.554C203.71,17.15,204.659,17.114,205.615,17Zm4.825,7.729a35.319,35.319,0,0,0-.8-4.073c-.6-1.893-2.065-2.775-4.182-2.785a4.009,4.009,0,0,0-4.182,2.68,12.124,12.124,0,0,0-.029,8.165,3.958,3.958,0,0,0,4.14,2.736c2.136.016,3.612-.836,4.233-2.716a37.637,37.637,0,0,0,.817-3.995Z"
        }
        transform={"translate(-133.74 -11.47)"}
      />
    </svg>
  );
};
