import React, { useCallback } from "react";
import styled from "styled-components";
import { ReactionButton as Button } from "components/Detail/ReactionButton";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

type Reaction = {
  id: string;
  text: string;
};

type Props = {
  authorFirstName: string;
  reactions: Reaction[];
  isAfterDeadline: boolean;
  myReactionId?: string;
  onClick: (id: string) => any;
};

export const DetailReactionSection = ({
  isAfterDeadline,
  authorFirstName,
  reactions,
  myReactionId,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const handleClick = useCallback(
    (id: string) => {
      if (id !== myReactionId) onClick(id);
    },
    [myReactionId, onClick]
  );

  const sectionNotice = isAfterDeadline
    ? t(tk.announcements.reactions.reactionsAreStopped)
    : myReactionId
    ? `${t(tk.announcements.reactions.youHaveReacted)}:`
    : `${t(tk.announcements.reactions.userYourReaction, { user: authorFirstName })}:`;

  return (
    <Section>
      <SectionNoticeContainer>
        <SectionNotice>{sectionNotice}</SectionNotice>
      </SectionNoticeContainer>

      <ReactionsContainer>
        {reactions.map(({ id, text }) => (
          <ButtonContainer key={id}>
            <ReactionButton onClick={() => handleClick(id)} disabled={isAfterDeadline} selected={myReactionId === id}>
              {text}
            </ReactionButton>
          </ButtonContainer>
        ))}
      </ReactionsContainer>
    </Section>
  );
};

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 40px;
  border-top: 1px solid #f2f2f2;
`;

const SectionNoticeContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;

const buttonContainerMargin = 5;

const ReactionsContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  margin-top: ${12 - buttonContainerMargin}px;
  padding-left: ${60 - buttonContainerMargin}px;
  padding-right: ${60 - buttonContainerMargin}px;
`;

const SectionNotice = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 14px;
  color: ${theme.colors.IronGrey};
`;

const ButtonContainer = styled.div`
  position: relative;
  height: 35px;
  min-width: 125px;
  margin: ${buttonContainerMargin}px;
`;

const ReactionButton = styled(Button)<{ disabled: boolean }>`
  padding-left: 32px;
  padding-right: 32px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
