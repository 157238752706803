import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { DialogScreen, CloseButton, DialogButton, Icon, DetailRecipientDetail, PreviewSpinner } from "components";
import { mapConnection, resolveError, useChatLeaveMutation, useChatQuery } from "api";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

interface Props {
  chat?: string;
  onClose: () => any;
  onLeaveConversation: (chat: string) => any;
}

export const SelectNewOwnerDialog = ({ chat, onClose, onLeaveConversation }: Props) => {
  const { t } = useTranslation();

  const [user, setUser] = useState<string | undefined>();

  const { data, loading } = useChatQuery({ variables: { id: chat || "" }, skip: !chat });
  const [leave, { loading: loadingLeave }] = useChatLeaveMutation();

  const validUsers = useMemo(() => {
    if (!data?.chat) return [];

    return mapConnection(data.chat.interactions)
      .filter(({ id, archivedAt, acceptedAt }) => !archivedAt && !!acceptedAt && id !== data.chat?.myInteraction.id)
      .map(({ user }) => user);
  }, [data]);

  const leaveConversation = useCallback(async () => {
    if (loadingLeave || !chat) return;

    try {
      await leave({ variables: { input: { chat, newOwner: user } } });
      onLeaveConversation(chat);
      onClose();
    } catch (e: any) {
      resolveError(e);
    }
  }, [chat, leave, loadingLeave, onClose, onLeaveConversation, user]);

  if (!!data?.chat && loading)
    return (
      <DialogScreen>
        <DialogContainer>
          <PreviewSpinner />
        </DialogContainer>
      </DialogScreen>
    );

  return (
    <DialogScreen>
      <DialogContainer>
        <DialogCloseButton onClick={onClose} />

        <Heading>{validUsers.length > 0 ? t(tk.chat.pickNewOwner) : t(tk.chat.leaveGroup)}</Heading>

        <SubHeading>
          {validUsers.length > 0 ? t(tk.chat.youAreOwnerPickNew) : t(tk.chat.youAreLastChatWillBeDeleted)}
        </SubHeading>

        <Divider />

        {validUsers.length > 0 && (
          <MembersListWrapper>
            <MembersList>
              {validUsers.map((u) => (
                <MembersListItem key={u.id}>
                  <ClickableListItem onClick={() => setUser(u.id)}>
                    <DetailRecipientDetail
                      firstName={u.firstName}
                      lastName={u.lastName}
                      profileImage={u.profileImage}
                      isActive={u.isActive}
                    />

                    {u.id === user && <CheckIcon shape={"checked"} />}
                  </ClickableListItem>
                </MembersListItem>
              ))}
            </MembersList>
          </MembersListWrapper>
        )}

        <Divider />

        <ConfirmButtonWrapper>
          <DialogButton onClick={leaveConversation} disabled={(validUsers.length > 0 && !user) || loadingLeave}>
            {t(tk.chat.settings.leaveGroupAndDelete)}
          </DialogButton>
        </ConfirmButtonWrapper>
      </DialogContainer>
    </DialogScreen>
  );
};

/**
 * Styles
 */

const DialogContainer = styled.div`
  width: 400px;
  border-radius: 5px;
  background-color: ${theme.colors.SnowWhite};
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  color: ${theme.colors.NightSky};
  box-sizing: border-box;
`;

const DialogCloseButton = styled(CloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${theme.colors.SilverGrey};
`;

const Heading = styled.div`
  padding: 30px 0 0 26px;
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
`;

const SubHeading = styled.p`
  padding: 0 26px;
  font-weight: ${font.weight.medium};
  color: ${theme.colors.IronGrey};
  margin: 20px 0;
  line-height: 1.5;
`;

const MembersListWrapper = styled.div`
  padding: 15px 20px;
  max-height: 250px;
  overflow-y: scroll;
`;

const MembersList = styled.ul`
  margin: 0;
  padding: 0;
`;

const MembersListItem = styled.li`
  display: flex;
`;

const ClickableListItem = styled.a`
  padding: 4px 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const ConfirmButtonWrapper = styled.div`
  text-align: center;
  padding: 20px;
`;

const CheckIcon = styled(Icon)`
  color: #008c82;
  font-size: 12px;
  cursor: pointer;
`;
