import React from "react";
import styled from "styled-components";
import { Card } from "components/Card";

type Props = {
  children: React.ReactNode;
};

export const SearchInputResultsList = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

/**
 * Styles
 */

const Container = styled(Card)`
  display: flex;
  position: absolute;
  top: 100px;
  flex-direction: column;
  width: calc(100% - 60px);
  transform: translate3d(-20px, 0, 0);
  padding: 12px 10px;
  z-index: 100;
`;
