import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconPlusHexa = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 29.502 31.159"
      style={{ margin }}
    >
      <path
        id="plus"
        d="M20,2.313a8.273,8.273,0,0,0-8,0L6.125,5.688a7.955,7.955,0,0,0-4,6.938v6.688a7.955,7.955,0,0,0,4,6.938L12,29.627a7.957,7.957,0,0,0,8,0l5.875-3.375a7.955,7.955,0,0,0,4-6.938V12.626a7.955,7.955,0,0,0-4-6.938Zm.375-.75L26.313,5a8.8,8.8,0,0,1,4.438,7.625v6.688A8.975,8.975,0,0,1,26.313,27l-5.938,3.375a8.75,8.75,0,0,1-8.75,0L5.687,27a8.975,8.975,0,0,1-4.438-7.688V12.626A8.8,8.8,0,0,1,5.687,5l5.938-3.438a8.75,8.75,0,0,1,8.75,0Zm-.812,14.25V17h-2.5v2.688h-1.25V17H13.25V15.813h2.563V13.125h1.25v2.688Z"
        transform="translate(-1.249 -0.391)"
        fill={color}
      />
    </svg>
  );
};
