import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Option } from "components/Option";
import { Dialog, DialogScreen } from "components/Dialog";
import { useDashboardState } from "hooks";
import { AnnouncementFragment, resolveError, useAnnouncementDeleteMutation } from "api";
import { tk, useTranslation } from "translations";
import { getRoute } from "routes";

interface Props {
  announcement: AnnouncementFragment;
}

export const DeleteOption = ({ announcement }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { organization, space } = useDashboardState();

  const [actionDialogVisible, setActionDialogVisible] = useState(false);
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);

  const [announcementDelete, { loading }] = useAnnouncementDeleteMutation();

  const openActionDialog = useCallback(() => setActionDialogVisible(true), []);
  const closeActionDialog = useCallback(() => setActionDialogVisible(false), []);

  const openSuccessDialog = useCallback(() => setSuccessDialogVisible(true), []);
  const closeSuccessDialog = useCallback(() => {
    setSuccessDialogVisible(false);
    history.replace(getRoute.announcements({ organization, space, tab: "sent" }));
  }, [history, organization, space]);

  const deleteAnnouncement = useCallback(async () => {
    if (loading) return;

    try {
      await announcementDelete({ variables: { input: { announcement: announcement.id } } });
    } catch (e: any) {
      resolveError(e);
    }

    closeActionDialog();
    openSuccessDialog();
  }, [announcement.id, announcementDelete, closeActionDialog, loading, openSuccessDialog]);

  return (
    <>
      <Option icon={"delete"} label={t(tk.announcements.actions.delete.button)} onClick={openActionDialog} />

      {actionDialogVisible && (
        <DialogScreen>
          <Dialog
            iconImage={"delete"}
            buttonTitle={t(tk.common.yesDelete)}
            text={t(tk.announcements.actions.delete.description)}
            hasCloseButton={true}
            onButtonClick={deleteAnnouncement}
            onCloseButtonClick={closeActionDialog}
          />
        </DialogScreen>
      )}

      {successDialogVisible && (
        <DialogScreen>
          <Dialog
            buttonTitle={t(tk.common.ok)}
            onButtonClick={closeSuccessDialog}
            text={t(tk.announcements.actions.delete.deleted)}
          />
        </DialogScreen>
      )}
    </>
  );
};
