import React from "react";
import styled from "styled-components";
import { DetailHeading } from "components/Detail/DetailHeading";
import { DetailSubheading } from "components/Detail/DetailSubheading";

type Props = {
  heading: string;
  subheading: string;
  options?: JSX.Element;
};

export class DetailHeaderSection extends React.PureComponent<Props> {
  render() {
    return (
      <Section>
        <SubheadingContainer>
          <DetailSubheading>{this.props.subheading}</DetailSubheading>
        </SubheadingContainer>

        <HeadingContainer>
          <DetailHeading>{this.props.heading}</DetailHeading>
          {this.props.options}
        </HeadingContainer>
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  margin-bottom: 14px;
`;

const SubheadingContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;

const HeadingContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 4px;
  padding: 0 60px;
  justify-content: space-between;
`;
