import React, { useRef } from "react";
import styled from "styled-components";
import { animated, useSpring, useTransition } from "react-spring";
import { ListItemImage, ListItem, NotificationDot } from "components";
import { font, theme } from "styles";

type Props = {
  children: string;
  imageSrc: string;
  onClick: () => void;
  isActive: boolean;
  compact: boolean;
  isUnresolved?: boolean;
};

export const SidebarListItem = ({ children, imageSrc, onClick, isActive, compact, isUnresolved = false }: Props) => {
  const containerRef = useRef<HTMLLIElement>(null);

  const iconWrapperSpringStyle = useSpring({
    transform: compact ? "translate3d(16px, 0, 0)" : "translate3d(0px, 0, 0)",
  });

  const textTransition = useTransition(compact, null, {
    immediate: !containerRef.current, // change opacity without animation if true
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <Container ref={containerRef}>
      <ListItem onClick={onClick} isActive={isActive}>
        <ImageWrapper style={iconWrapperSpringStyle}>
          <ListItemImage src={imageSrc} alt={"space-logo"} width={"50px"} height={"55px"} />
          {isUnresolved && (
            <DotContainer>
              <NotificationDot />
            </DotContainer>
          )}
        </ImageWrapper>
        <TextWrapper>
          {textTransition.map(
            ({ item, key, props: style }) =>
              !item && (
                <Text key={key} style={style}>
                  {children}
                </Text>
              )
          )}
        </TextWrapper>
      </ListItem>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("li")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${theme.sidebarSupportLink.backgroundColor.hover};
  }
`;

const ImageWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transform-origin: center center;
`;

const DotContainer = styled.div`
  position: absolute;
  top: 0;
  right: 7px;
`;

const TextWrapper = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  height: 19px;
`;

const Text = styled(animated.span)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 25px;
  font-size: 16px;
  font-weight: ${font.weight.medium};
  color: ${theme.sidebarListItem.fontColor};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
