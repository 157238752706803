import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { AnnouncementListItem } from "components";
import { useDashboardState } from "hooks/useDashboardState";
import { useMe } from "hooks/useMe";
import { useUnresolvedRefetch } from "hooks/useUnresolvedRefetch";
import { fromNow, isBeforeNow } from "helpers";
import {
  mapConnection,
  resolveError,
  sortByLast,
  useAnnouncementInteractMutation,
  useSpaceReceivedAnnouncementsQuery,
  useSpaceSentAnnouncementsQuery,
} from "api";
import { tk, useTranslation } from "translations";
import { AnnouncementsTabs, getRoute } from "routes";

export const useAnnouncementsList = (
  tab: AnnouncementsTabs,
  activeAnnouncement?: string,
  prependWithNew: boolean = false
) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { organization, space } = useDashboardState();

  const { isManager } = useMe();
  const refetchUnresolved = useUnresolvedRefetch();

  /** Queries & Mutations */

  const { data: dataSent, loading: loadingSent } = useSpaceSentAnnouncementsQuery({
    variables: { id: space },
    skip: !isManager,
  });

  const { data: dataReceived, loading: loadingReceived } = useSpaceReceivedAnnouncementsQuery({
    variables: { id: space },
  });

  const [interact] = useAnnouncementInteractMutation();

  const loading = useMemo(() => loadingSent || loadingReceived, [loadingReceived, loadingSent]);

  /** Handlers */

  const showDetail = useCallback(
    async (id: string, markAsRead: boolean = false) => {
      if (markAsRead) {
        try {
          await interact({ variables: { input: { announcement: id, seen: true } } });
          await refetchUnresolved();
        } catch (e: any) {
          resolveError(e);
        }
      }

      history.push(getRoute.announcementsDetail({ organization, space, tab, id }));
    },
    [history, interact, organization, refetchUnresolved, space, tab]
  );

  const unArchive = useCallback(
    async (id: string) => {
      try {
        await interact({ variables: { input: { announcement: id, archived: false } } });
        history.push(getRoute.announcementsDetail({ organization, space, tab: "received", id }));
      } catch (e: any) {
        resolveError(e);
      }
    },
    [history, interact, organization, space]
  );

  /** Announcements mapping */

  const sentAnnouncements: AnnouncementListItem[] = useMemo(() => {
    const announcements = sortByLast(mapConnection(dataSent?.space?.sentAnnouncements)).map(
      ({ id, title, createdAt, recipientsTotalCount, recipientsSeenCount, recipientsReactedCount, imageThumbnail }) => {
        return {
          id,
          title,
          createdAt: fromNow(createdAt),
          hasImage: !!imageThumbnail,
          statistics: {
            total: recipientsTotalCount || 0,
            seen: recipientsSeenCount || 0,
            reacted: recipientsReactedCount || 0,
          },
          isActive: activeAnnouncement === id,
          onClick: () => showDetail(id),
        } as AnnouncementListItem;
      }
    );

    if (prependWithNew)
      return [
        {
          id: "new-announcement",
          title: t(tk.announcements.newAnnouncement),
          createdAt: t(tk.common.rightNow),
          isActive: true,
        },
        ...announcements,
      ];

    return announcements;
  }, [activeAnnouncement, dataSent, prependWithNew, showDetail, t]);

  const receivedAnnouncements = useMemo(() => {
    const unresolved: AnnouncementListItem[] = [];
    const received: AnnouncementListItem[] = [];
    const marked: AnnouncementListItem[] = [];
    const archive: AnnouncementListItem[] = [];

    if (!dataReceived) return { unresolved, received, marked, archive };

    const announcements = sortByLast(mapConnection(dataReceived?.space?.receivedAnnouncements));

    for (const announcement of announcements) {
      const { id, title, createdAt, reactionDeadlineAt, reactions, myInteraction, author } = announcement;

      const hasReactions = !!reactions.pageInfo.startCursor;
      const isAfterDeadline = !hasReactions || !reactionDeadlineAt ? false : isBeforeNow(reactionDeadlineAt);

      const isUnread = !myInteraction?.readAt;
      const needReaction = hasReactions && !isAfterDeadline && !myInteraction?.reactedAt;
      const isUnresolved = isUnread || needReaction;
      const isMarked = myInteraction?.marked;
      const isArchived = !!myInteraction?.archivedAt;

      const listItem: AnnouncementListItem = {
        id,
        title,
        createdAt: fromNow(createdAt),
        author: `${author.firstName} ${author.lastName}`,
        image: author.profileImage,
        hasImage: !!announcement.imageThumbnail,
        isActive: activeAnnouncement === id,
        isVisited: isArchived ? undefined : !isUnresolved,
        isMarked: isArchived ? undefined : isMarked,
        isUnread: isUnread,
        needReaction: needReaction,
        onClick: !isArchived ? () => showDetail(id, !myInteraction?.readAt) : undefined,
        onUnArchive: isArchived ? () => unArchive(id) : undefined,
      };

      if (isUnresolved && !isArchived) unresolved.push(listItem);
      if (!isArchived) received.push(listItem);
      if (isMarked && !isArchived) marked.push(listItem);
      if (isArchived) archive.push(listItem);
    }

    return { unresolved, received, marked, archive };
  }, [activeAnnouncement, dataReceived, showDetail, unArchive]);

  const unresolvedAnnouncementsCount = useMemo(
    () => receivedAnnouncements.unresolved.length,
    [receivedAnnouncements.unresolved.length]
  );

  return {
    loading,
    sentAnnouncements,
    receivedAnnouncements,
    unresolvedAnnouncementsCount,
  };
};
