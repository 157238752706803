import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { useAnnouncementsDetail } from "controllers";
import { AnnouncementsLayout } from "layouts";
import {
  AnnouncementPrint,
  Button,
  ChangeDeadlineOption,
  DeleteOption,
  Dialog,
  DialogScreen,
  HorizontalSeparator,
  Options,
  PreviewSpinner,
  RemindOption,
} from "components";
import * as Detail from "components/Detail";
import { AnnouncementsDetailParams } from "routes";
import { fromNow } from "helpers";
import { font, theme } from "styles";

interface Props extends RouteComponentProps<AnnouncementsDetailParams> {}

export const AnnouncementsDetail = ({ match }: Props) => {
  const { t, tk, data, state, handlers } = useAnnouncementsDetail(match.params.tab, match.params.id);

  const loading = useMemo(
    () => (!data.announcement && state.loading) || data.announcement?.id !== state.id,
    [data.announcement, state.id, state.loading]
  );

  return (
    <AnnouncementsLayout
      buttons={
        !data.isManager ? null : (
          <Button color={"green"} onClick={handlers.createAnnouncement}>
            {t(tk.announcements.navigation.create)}
          </Button>
        )
      }
      tab={state.tab}
      activeAnnouncementId={state.id}
    >
      {loading && <PreviewSpinner />}

      {!!data.announcement && !loading && (
        <Detail.Detail
          footer={
            state.isSent ? null : (
              <ArchiveLinkWrapper>
                <ArchiveLink onClick={handlers.archiveAnnouncement}>{t(tk.announcements.archive)}</ArchiveLink>
              </ArchiveLinkWrapper>
            )
          }
        >
          {!state.isSent && (
            <Detail.DetailMarkButtonSection marked={data.isMarked} onClick={handlers.toggleAnnouncementMark} />
          )}

          <Detail.DetailHeaderSection
            heading={data.announcement.title}
            subheading={fromNow(data.announcement.createdAt)}
            options={
              !state.isSent ? undefined : (
                <Options
                  isOpened={state.optionsVisible}
                  onToggle={handlers.toggleOptions}
                  title={t(tk.common.options)}
                  options={[
                    <RemindOption
                      key={"urge-option"}
                      announcement={data.announcement}
                      onSuccess={handlers.onActionsSuccess}
                    />,
                    <ChangeDeadlineOption
                      key={"change-deadline"}
                      announcement={data.announcement}
                      onSuccess={handlers.onActionsSuccess}
                    />,
                    <AnnouncementPrint
                      key={"print"}
                      announcement={data.announcement}
                      interactions={data.interactions}
                      reactions={data.reactions}
                    />,
                    <DeleteOption key={"delete"} announcement={data.announcement} />,
                  ]}
                />
              )
            }
          />

          <Detail.DetailMetaDetailSection
            authorFullName={`${data.announcement.author.firstName} ${data.announcement.author.lastName}`}
            authorMetaText={`${data.announcement.space.name} • ${
              data.announcement.authorMembership.position || t(tk.role[data.announcement.authorMembership.role])
            }`}
            authorProfileImage={data.announcement.author.profileImage}
          />

          {!!data.announcement.videoUrl && <Detail.DetailVideoSection videoUrl={data.announcement.videoUrl} />}

          <Detail.DetailTextSection text={data.announcement.text} />

          {!!data.announcement.imageOriginal && (
            <Detail.AnnouncementImageSection
              src={data.announcement.imageOriginal}
              thumbnail={data.announcement.imageThumbnail ?? undefined}
            />
          )}

          {(state.isSent || data.reactions.length > 0) && <HorizontalSeparator />}

          {!state.isSent && data.reactions.length > 0 && (
            <Detail.DetailReactionSection
              myReactionId={data.announcement.myInteraction?.reaction?.id}
              reactions={data.reactions}
              isAfterDeadline={data.isAfterDeadline}
              authorFirstName={data.announcement.author.firstName}
              onClick={handlers.handleReaction}
            />
          )}

          {state.isSent && (
            <Detail.DetailAnalyticsSectionByReaction
              interactions={data.interactions}
              reactions={data.reactions}
              lastChangesSeenAt={data.announcement.lastChangesSeenAt || undefined}
              urgedAt={data.announcement.urgedAt || undefined}
            />
          )}

          {state.isSent && <HorizontalSeparator />}

          {state.isSent && (
            <Detail.DetailLatestActivitiesSection
              sectionTitle={t(tk.announcements.activity.heading)}
              activities={data.logs}
              loadOlderActivities={handlers.loadMoreLogs}
              hasPreviousPage={data.hasMoreLogs}
            />
          )}

          {state.reactionDialog === "added" && (
            <DialogScreen>
              <Dialog
                iconImageBackgroundColor={"#2F424F"}
                iconColor={"#EFC75E"}
                text={t(tk.announcements.reactions.thankYouForReaction, { user: data.announcement.author.firstName })}
                buttonTitle={t(tk.common.ok)}
                onButtonClick={handlers.closeReactionDialog}
              />
            </DialogScreen>
          )}

          {state.reactionDialog === "changed" && (
            <DialogScreen>
              <Dialog
                iconImage={"plan"}
                text={t(tk.announcements.reactions.reactionChanged, { user: data.announcement.author.firstName })}
                buttonTitle={t(tk.common.ok)}
                onButtonClick={handlers.closeReactionDialog}
              />
            </DialogScreen>
          )}
        </Detail.Detail>
      )}
    </AnnouncementsLayout>
  );
};

/**
 * Styles
 */

const ArchiveLinkWrapper = styled.div`
  text-align: center;
`;

const ArchiveLink = styled.span`
  display: inline-block;
  padding: 15px;
  font-weight: ${font.weight.regular};
  font-size: 11px;
  color: ${theme.colors.IronGrey};
  line-height: 28px;
  cursor: pointer;
`;
