import React from "react";
import styled from "styled-components";
import { Icon, IconShape } from "components";

// TODO: copied from original moyobo webapp - should be refactored

const ignoredProps = [
  "color",
  "strokeWidth",
  "strokeColor",
  "imageSource",
  "imageSize",
  "iconShape",
  "iconColor",
  "iconSize",
  "iconData",
  "size",
  "characterSize",
  "characterWeight",
  "characterColor",
  "faded",
  "hasShadow",
  "cursor",
  "shadow",
];

const getWidth = (props: Props) => (props.size ? props.size.width : "65px");
const getHeight = (props: Props) => (props.size ? props.size.height : "70px");
const getOpacity = (props: Props) => (props.faded ? "0.5" : "1");

const HexagonContainer = styled.div.withConfig({ shouldForwardProp: (prop) => !ignoredProps.includes(prop) })`
  position: relative;
  min-width: ${getWidth};
  height: ${getHeight};
  opacity: ${getOpacity};
`;

const svgAttributes = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 60 66",
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
};

const getShadow = (props: Props) => {
  if (props.hasShadow) {
    return "filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3));";
  }
  if (props.shadow) {
    return `filter: drop-shadow(${props.shadow});`;
  }
  return "";
};

const Svg = styled.svg.withConfig({ shouldForwardProp: (prop) => !ignoredProps.includes(prop) })<Props>`
  position: absolute;
  top: 0;
  left: 0;
  ${getShadow};
  overflow: hidden;
`;

const pathAttributes = {
  id: "path",
  d:
    "M34.837,2.522l19.275,11.05c3.023,1.733 4.888,4.952 4.888,8.437l0,21.982c0,3.485 -1.865,6.704 " +
    "-4.888,8.437l-19.275,11.05c-2.996,1.718 -6.678,1.718 -9.674,0l-19.275,-11.05c-3.023,-1.733 -4.888,-4.952 " +
    "-4.888,-8.437l0,-21.982c0,-3.485 1.865,-6.704 4.888,-8.437l19.275,-11.05c2.996,-1.718 6.678,-1.718 9.674,0Z",
};

const getStrokeWidth = (props: Props) => (props.strokeWidth ? props.strokeWidth : "0px");
const getStrokeColor = (props: Props) => (props.strokeColor ? props.strokeColor : "rgba(0, 0, 0, 0.15)");
const getColor = (props: Props) => (props.color ? props.color : "#FFFFFF");

const Path = styled.path.withConfig({ shouldForwardProp: (prop) => !ignoredProps.includes(prop) })<Props>`
  fill: ${getColor};
  stroke: ${getStrokeColor};
  stroke-width: ${getStrokeWidth};
`;

const ContentContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const getFontSize = (props: Props) => (props.characterSize ? props.characterSize : "16px");
const getFontWeight = (props: Props) => (props.characterWeight ? props.characterWeight : "400");
const getFontColor = (props: Props) => (props.characterColor ? props.characterColor : "#FFFFFF");

const Character = styled.span.withConfig({ shouldForwardProp: (prop) => !ignoredProps.includes(prop) })<Props>`
  font-family: "Raleway", sans-serif;
  font-weight: ${getFontWeight};
  font-size: ${getFontSize};
  color: ${getFontColor};
`;

const IconBadge = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: 8px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: #008c82;
  border-radius: 16px;
  font-family: "Raleway", sans-serif;
  font-size: 10px;
  color: #ffffff;
  line-height: 10px;
  user-select: none;
`;

type Props = {
  color?: string;
  strokeWidth?: string;
  strokeColor?: string;
  imageSource?: string;
  imageSize?: number;
  iconShape?: IconShape;
  iconColor?: string;
  iconSize?: string;
  iconData?: string | number;
  size?: {
    width: string;
    height: string;
  };
  characterSize?: string;
  characterWeight?: string;
  characterColor?: string;
  faded?: boolean;
  hasShadow?: boolean;
  cursor?: string;
  className?: string;
  shadow?: string;
};

export class Hexagon extends React.PureComponent<Props & React.HTMLAttributes<HTMLDivElement>> {
  renderImage = () => {
    if (!this.props.imageSource) {
      return null;
    }
    const size = this.props.imageSize || 100;
    const offset = (100 - size) / 2;
    // noinspection HtmlDeprecatedTag
    return (
      <image
        clipPath={"url(#mask)"}
        xlinkHref={this.props.imageSource}
        width={`${size}%`}
        height={`${size}%`}
        x={`${offset}%`}
        y={`${offset}%`}
      />
    );
  };

  renderCharacter = () => {
    if (!this.props.children) {
      return null;
    }
    return (
      <ContentContainer>
        <Character
          characterSize={this.props.characterSize}
          characterWeight={this.props.characterWeight}
          characterColor={this.props.characterColor}
        >
          {this.props.children}
        </Character>
      </ContentContainer>
    );
  };

  renderIcon = () => {
    const { iconShape, iconData, iconColor, iconSize, cursor } = this.props;

    if (!iconShape) {
      return null;
    }

    const getIconColor = () => (iconColor ? iconColor : "#008C82");
    const getIconSize = () => (iconSize ? iconSize : "18px");
    const getCursorType = () => (cursor ? cursor : "default");

    return (
      <ContentContainer>
        <Icon
          shape={iconShape}
          style={{
            color: getIconColor(),
            fontSize: getIconSize(),
            userSelect: "none",
            cursor: getCursorType(),
          }}
        />

        {iconData && <IconBadge>{iconData}</IconBadge>}
      </ContentContainer>
    );
  };

  render() {
    return (
      <HexagonContainer faded={this.props.faded} size={this.props.size} {...this.props}>
        <Svg {...svgAttributes} hasShadow={this.props.hasShadow} shadow={this.props.shadow}>
          <defs>
            <clipPath id={"mask"}>
              <Path
                {...pathAttributes}
                color={this.props.color}
                strokeWidth={this.props.strokeWidth}
                strokeColor={this.props.strokeColor}
              />
            </clipPath>
          </defs>
          <Path
            {...pathAttributes}
            color={this.props.color}
            strokeWidth={this.props.strokeWidth}
            strokeColor={this.props.strokeColor}
          />
          {this.renderImage()}
        </Svg>
        {this.renderCharacter()}
        {this.renderIcon()}
      </HexagonContainer>
    );
  }
}
