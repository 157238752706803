import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  mapConnection,
  resolveError,
  sortByLast,
  useDocumentMarkAsReadMutation,
  useSpaceReceivedDocumentsQuery,
  useSpaceSentDocumentsQuery,
} from "api";
import { DocumentListItem } from "components";
import { useUnresolvedRefetch } from "hooks/useUnresolvedRefetch";
import { useDashboardState } from "hooks/useDashboardState";
import { useMe } from "hooks/useMe";
import { fromNow, isBefore } from "helpers";
import { tk, useTranslation } from "translations";
import { DocumentsTabs, getRoute } from "routes";

export const useDocumentsList = (tab: DocumentsTabs, activeDocument?: string, prependWithNew: boolean = false) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { organization, space } = useDashboardState();

  const { isManager } = useMe();
  const refetchUnresolved = useUnresolvedRefetch();

  /** Queries & Mutations */

  const { data: dataSent, loading: loadingSent } = useSpaceSentDocumentsQuery({
    variables: { id: space },
    skip: !isManager,
  });

  const { data: dataReceived, loading: loadingReceived } = useSpaceReceivedDocumentsQuery({
    variables: { id: space },
  });

  const [markAsRead] = useDocumentMarkAsReadMutation();

  const loading = useMemo(() => loadingSent || loadingReceived, [loadingReceived, loadingSent]);

  /** Handlers */

  const showDetail = useCallback(
    async (id: string, mark: boolean = false) => {
      if (mark) {
        try {
          await markAsRead({ variables: { input: { document: id } } });
          await refetchUnresolved();
        } catch (e: any) {
          resolveError(e);
        }
      }

      history.push(getRoute.documentsDetail({ organization, space, tab, id }));
    },
    [history, markAsRead, organization, refetchUnresolved, space, tab]
  );

  /** Documents mapping */

  const sentDocuments: DocumentListItem[] = useMemo(() => {
    const documents = sortByLast(mapConnection(dataSent?.space?.sentDocuments)).map(
      ({ id, title, createdAt, updatedAt }) => {
        return {
          id,
          title,
          createdAt: fromNow(updatedAt || createdAt),
          isActive: activeDocument === id,
          onClick: () => showDetail(id),
        } as DocumentListItem;
      }
    );

    if (prependWithNew)
      return [
        { id: "new-document", title: t(tk.documents.newDocument), createdAt: t(tk.common.rightNow), isActive: true },
        ...documents,
      ];

    return documents;
  }, [activeDocument, dataSent, prependWithNew, showDetail, t]);

  const receivedDocuments: DocumentListItem[] = useMemo(() => {
    return sortByLast(mapConnection(dataReceived?.space?.receivedDocuments)).map(
      ({ id, title, createdAt, updatedAt, myInteraction }) => {
        const marked = !!myInteraction?.readAt && !isBefore(myInteraction.readAt, updatedAt);

        return {
          id,
          title,
          createdAt: fromNow(updatedAt || createdAt),
          isActive: activeDocument === id,
          isVisited: marked,
          onClick: () => showDetail(id, !marked),
        } as DocumentListItem;
      }
    );
  }, [activeDocument, dataReceived, showDetail]);

  return {
    loading,
    sentDocuments,
    receivedDocuments,
  };
};
