import styled from "styled-components";
import { theme } from "styles";

export const HorizontalSeparator = styled("div").withConfig({
  shouldForwardProp: (prop) => !["margin"].includes(prop),
})<{ children?: never; margin?: string }>`
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  background-color: ${theme.horizontalSeparator.backgroundColor};
  margin: ${(props) => props.margin || 0};
`;
