import React from "react";
import { useStoriesCreate } from "controllers";
import { StoriesLayout, StoriesLayoutActionsWrapper } from "layouts";
import {
  Button,
  ButtonLink,
  Card,
  Dialog,
  DialogScreen,
  Field,
  FieldErrorMessage,
  FieldLabel,
  HorizontalSeparator,
  ImageUpload,
  Textarea,
} from "components";
import styled from "styled-components";

export const StoriesCreate = () => {
  const { t, tk, media, state, handlers } = useStoriesCreate();

  return (
    <StoriesLayout
      buttons={
        <StoriesLayoutActionsWrapper>
          <ButtonLink onClick={handlers.cancel} isDisabled={state.loading} margin={"0 30px 0 0"}>
            {t(tk.common.cancel)}
          </ButtonLink>
          <Button onClick={handlers.submit} isDisabled={state.loading} color={"green"}>
            {t(tk.common.send)}
          </Button>
        </StoriesLayoutActionsWrapper>
      }
    >
      <Content>
        <ContentCard>
          <Field width={"100%"} padding={"40px 50px"}>
            <FieldLabel>{t(tk.storiesCreate.form.photo.label)}</FieldLabel>
            <ImageUpload media={media} />
          </Field>

          <HorizontalSeparator />

          <Field width={"100%"} padding={"40px 50px 12px 50px"}>
            <FieldLabel required={true}>{t(tk.storiesCreate.form.text.label)}</FieldLabel>
            <Textarea
              value={state.text}
              placeholder={t(tk.storiesCreate.form.text.placeholder)}
              isDisabled={state.loading}
              width={"100%"}
              onChange={handlers.handleChangeText}
            />
            <FieldErrorMessage>{state.textError}</FieldErrorMessage>
          </Field>

          <HorizontalSeparator />

          <Footer>
            <ButtonLink onClick={handlers.cancel} isDisabled={state.loading} margin={"0 30px 0 0"}>
              {t(tk.common.cancel)}
            </ButtonLink>
            <Button onClick={handlers.submit} isDisabled={state.loading} color={"green"}>
              {t(tk.common.send)}
            </Button>
          </Footer>
        </ContentCard>

        {!!state.dialog && (
          <DialogScreen>
            {state.dialog === "progress" && (
              <Dialog
                text={t(tk.common.pleaseWait)}
                title={t(tk.storiesCreate.sending)}
                iconImage={"bell"}
                hasProgressBar={true}
              />
            )}

            {state.dialog === "success" && (
              <Dialog
                text={t(tk.storiesCreate.sent)}
                buttonTitle={t(tk.common.ok)}
                onButtonClick={handlers.goToStories}
              />
            )}

            {state.dialog === "error" && (
              <Dialog
                text={t(tk.storiesCreate.error)}
                buttonTitle={t(tk.common.ok)}
                iconImage={"bell"}
                iconImageBackgroundColor={"#C8102E"}
                onButtonClick={handlers.closeDialog}
              />
            )}
          </DialogScreen>
        )}
      </Content>
    </StoriesLayout>
  );
};

/**
 * Styles
 */

const Content = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 740px;
  margin: 25px auto;
  flex-shrink: 0;
`;

const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 50px;
`;
