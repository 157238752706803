import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconStar = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31.816 30.378"
      style={{ margin }}
    >
      <path
        id="star-inactive"
        d="M31.813,12.25c0-.5-.313-.813-1.063-.875L21.187,9.937,16.874,1.249c-.25-.5-.563-.813-.938-.813s-.688.313-.938.813L10.685,9.937,1.06,11.375C.372,11.438,0,11.75,0,12.25a1.423,1.423,0,0,0,.5.875l6.938,6.813L5.81,29.5a1.1,1.1,0,0,0-.063.375,1.263,1.263,0,0,0,.188.688.8.8,0,0,0,.625.25,1.665,1.665,0,0,0,.75-.188l8.625-4.563L24.5,30.626a1.887,1.887,0,0,0,.75.188c.563,0,.813-.313.813-.938V29.5l-1.688-9.563,6.938-6.813a1.172,1.172,0,0,0,.5-.875ZM21.75,19.063l1.375,8.063-7.188-3.813-7.25,3.813,1.375-8.063L4.249,13.375l8.063-1.188,3.625-7.313,3.625,7.313,8.063,1.188Z"
        transform="translate(0.003 -0.436)"
        fill={color}
      />
    </svg>
  );
};
