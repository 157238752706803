import styled from "styled-components";
import { font, theme } from "styles";

export const LandingPageCardTitle = styled("h1")`
  position: relative;
  font-size: 28px;
  font-weight: ${font.weight.black};
  color: ${theme.landingPageCardTitle.fontColor};
  margin-top: 0;
  margin-bottom: 24px;
  align-self: flex-start;
`;
