import { MeValidationFragment, SpaceState } from "api";

export const emailRegExp = /^[^\s@]+@[^\s@-]+\.[^\s@]{2,3}$/;
export const phoneNumberRegExp = /^(\+[1-9]\d{1,14})?$/; // https://www.twilio.com/docs/glossary/what-e164
export const vimeoUrlRegExp = /https:\/\/vimeo\.com\/\d+$/;
export const youtubeUrlRegExp = /https:\/\/youtu\.be\/.+/;
export const youtube2UrlRegExp = /https:\/\/(www\.)?youtube\.com\/watch\?v=.+/;

export const isEmail = (value: string): boolean => emailRegExp.test(value);

export const isPhoneNumber = (value: string): boolean => phoneNumberRegExp.test(value);

export const isVimeoUrl = (value: string) => vimeoUrlRegExp.test(value);

export const isYoutubeUrl = (value: string) => youtubeUrlRegExp.test(value) || youtube2UrlRegExp.test(value);

export const hasActiveSpace = (me?: MeValidationFragment | null): boolean => {
  for (const organization of me?.organizations.edges || []) {
    for (const space of organization?.node?.spaces.edges || []) {
      if (space?.node?.state !== SpaceState.Archived) return true;
    }
  }

  return false;
};
