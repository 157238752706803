import React from "react";

type Props = {
  children?: never;
  color?: string;
  width?: string;
  height?: string;
};

export const LandingPageHexagonDecoration = ({ color = "#000000", width = "100%", height = "100%" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 1080 1080"}
      version={"1.1"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      xmlSpace={"preserve"}
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }}
    >
      <g transform={"matrix(0.874672,0,0,0.824051,0,0)"}>
        <g transform={"matrix(1.10441,-0.314301,0.296111,1.17226,-88.4668,169.735)"}>
          <path
            d={
              "M547.282,14.511L929.782,233.081C964.055,252.665 985.276,289.233 985.276,328.707L985.276,763.887C985.276,803.361 964.055,839.929 929.782,859.513L547.282,1078.08C513.463,1097.41 471.815,1097.41 437.996,1078.08L55.496,859.513C21.223,839.929 0.001,803.362 0,763.888L0,328.708C0,289.234 21.221,252.666 55.494,233.082L438,14.511C471.818,-4.813 513.464,-4.813 547.282,14.511Z"
            }
            style={{ fill: color, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </svg>
  );
};
