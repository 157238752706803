import React from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children: string;
  onClick: () => void;
  isDisabled?: boolean;
  margin?: string;
};

export const ButtonLink = ({ children, onClick, isDisabled, margin }: Props) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <StyledButton type={"button"} onClick={handleClick} disabled={isDisabled} margin={margin}>
      <StyledButtonLabel>{children}</StyledButtonLabel>
    </StyledButton>
  );
};

/**
 * Styles
 */

const StyledButtonLabel = styled("span")`
  display: inline-flex;
  position: relative;
  color: ${theme.buttonLink.fontColor.default};
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 1;
`;

type StyledButtonProps = Pick<Props, "margin">;

const StyledButton = styled("button").withConfig({
  shouldForwardProp: (prop) => !["margin"].includes(prop),
})<StyledButtonProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  background-color: transparent;
  padding: 0 20px;
  border: none;
  border-radius: 5px;
  margin: ${(props) => props.margin ?? 0};
  text-decoration: none;
  cursor: pointer;

  &:hover ${StyledButtonLabel} {
    color: ${theme.buttonLink.fontColor.hover};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px ${theme.buttonLink.outlineColor};

    & ${StyledButtonLabel} {
      color: ${theme.buttonLink.fontColor.focus};
    }
  }

  &:active ${StyledButtonLabel} {
    color: ${theme.buttonLink.fontColor.active};
  }

  &:disabled {
    cursor: not-allowed;

    & ${StyledButtonLabel} {
      color: ${theme.buttonLink.fontColor.disabled};
    }
  }
`;
