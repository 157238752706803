import React, { useLayoutEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { font, theme } from "styles";

type Props = {
  children: string;
  compact: boolean;
};

export const SidebarTitle = ({ children, compact }: Props) => {
  const [textWidth, setTextWidth] = useState<number | string>("initial");
  const textRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      const { width } = textRef.current.getBoundingClientRect();
      setTextWidth(width);
    }
  }, []);

  const springStyle = useSpring({ width: compact ? 122 : textWidth });

  return (
    <Container>
      <Text style={springStyle} ref={textRef}>
        {children}
      </Text>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 65px;
  grid-area: title;
`;

const Text = styled(animated.span)`
  position: relative;
  font-size: 14px;
  font-weight: ${font.weight.bold};
  color: ${theme.sidebarTitle.fontColor};
  text-align: center;
  text-transform: uppercase;
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
