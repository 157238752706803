import React from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
};

export const ReactionListItem = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

/**
 * Styles
 */

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: 1fr;
  grid-template-areas: "bullet input delete";
  align-items: center;
  justify-items: start;
  height: 50px;
  width: 375px;
  box-sizing: border-box;
  border: 1px dashed ${theme.reactionListItem.borderColor};
  border-radius: 9px;
  padding-left: 12px;
  padding-right: 12px;
`;
