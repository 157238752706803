import React, { createContext } from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  isDisabled?: boolean;
  margin?: string;
  padding?: string;
  width?: string;
};

export const FieldContext = createContext<{ isFieldDisabled?: boolean }>({
  isFieldDisabled: undefined,
});

export const Field = ({ children, isDisabled = false, margin, padding, width }: Props) => {
  return (
    <FieldContext.Provider value={{ isFieldDisabled: isDisabled }}>
      <Container margin={margin} padding={padding} width={width}>
        {children}
      </Container>
    </FieldContext.Provider>
  );
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "margin" | "padding" | "width">;

const Container = styled("div").withConfig({
  shouldForwardProp: (prop) => !["margin", "padding", "width"].includes(prop),
})<ContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: ${(props) => props.margin ?? "0"};
  padding: ${(props) => props.padding ?? "0"};
  width: ${(props) => props.width ?? "initial"};
`;
