import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { tk, useTranslation } from "translations";
import { useDashboardState, useMedia } from "hooks";
import { MediaTypes, resolveError, StoryCreateInput, useStoryCreateMutation } from "api";
import { getRoute } from "routes";

export const useStoriesCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.stories) + " " + t(tk.common.documentTitleSuffix));

  const { organization, space } = useDashboardState();

  const media = useMedia(MediaTypes.StoryHeader, false);
  const [create, { loading: loadingCreate }] = useStoryCreateMutation();

  const loading = useMemo(() => media.loading || loadingCreate, [loadingCreate, media.loading]);

  const [text, setText] = useState("");
  const [textError, setTextError] = useState("");

  const [dialog, setDialog] = useState<"progress" | "success" | "error" | undefined>(undefined);

  const submit = useCallback(async () => {
    if (!space) return;

    if (!text.trim().length) return setTextError(t(tk.storiesCreate.form.text.required));

    setDialog("progress");

    try {
      const input: StoryCreateInput = { space, text };

      if (!!media.preview) input.media = await media.upload();

      await create({ variables: { input } });

      setDialog("success");
    } catch (e: any) {
      setDialog("error");
      resolveError(e);
    }
  }, [create, media, space, t, text]);

  const cancel = useCallback(() => history.goBack(), [history]);

  const goToStories = useCallback(() => {
    history.replace(getRoute.stories({ organization, space }));
  }, [history, organization, space]);

  const closeDialog = useCallback(() => setDialog(undefined), []);

  const handleChangeText = useCallback((value: string) => {
    setText(value);
    setTextError("");
  }, []);

  return {
    t,
    tk,
    media,
    state: { loading, dialog, text, textError },
    handlers: { submit, cancel, goToStories, closeDialog, handleChangeText },
  };
};
