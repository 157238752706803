import React, { PropsWithChildren } from "react";
import styled from "styled-components";

// TODO: copied from original moyobo webapp - should be refactored

const Button = styled.button<{ disabled: boolean }>`
  background-color: #2b729c;
  color: #ffffff;
  text-transform: uppercase;
  height: 54px;
  min-width: 175px;
  border: 0;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 3px 32px 0 32px;
  box-shadow: 0 4px 0 0 #1e5f85;
  line-height: 54px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  user-select: none;

  &:focus {
    outline: 0;
  }
`;

interface Props extends PropsWithChildren {
  onClick?: () => void;
  disabled?: boolean;
}

export class DialogButton extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    return (
      <Button onClick={this.props.onClick} disabled={this.props.disabled || false}>
        {this.props.children}
      </Button>
    );
  }
}
