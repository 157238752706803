import React, { useRef } from "react";
import styled from "styled-components";
import { IconCancel, IconSearch } from "icons";
import { font, theme } from "styles";

type Props = {
  children?: never;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  width?: string;
};

export const SearchInput = ({ value, placeholder, onChange, width }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: { target: { value: string } }) => {
    onChange(event.target.value);
  };

  const handleClick = () => {
    onChange("");
    inputRef.current?.focus();
  };

  return (
    <Container width={width}>
      <IconSearch color={theme.searchInput.searchIconColor} width={"16px"} height={"16px"} margin={"3px 4px 0 0"} />

      <StyledInput ref={inputRef} value={value} placeholder={placeholder} onChange={handleChange} type={"text"} />

      {value.length > 0 && (
        <ClearButton onClick={handleClick}>
          <IconCancel color={theme.searchInput.clearIconColor} width={"8px"} height={"6px"} />
        </ClearButton>
      )}
    </Container>
  );
};

/**
 * Styles
 */

type StyledContainerProps = Pick<Props, "width">;

const Container = styled("div").withConfig({
  shouldForwardProp: (prop) => !["width"].includes(prop),
})<StyledContainerProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  width: ${(props) => props.width ?? "initial"};
  height: 28px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${theme.searchInput.borderColor};
`;

const StyledInput = styled("input")`
  position: relative;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: ${font.family.default};
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${theme.searchInput.fontColor};
  padding: 4px 22px 4px 6px;
  margin-left: 4px;
  width: 100%;
  box-shadow: none;

  &::placeholder {
    color: ${theme.searchInput.playholderFontColor};
    opacity: 1;
  }

  &:hover {
    border: 1px solid ${theme.searchInput.inputBorderColor};
  }

  &:focus {
    border: 1px solid ${theme.searchInput.inputBorderColor};
    outline: none;
  }
`;

const ClearButton = styled("button")`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 4px;
  right: 4px;
  border: none;
  padding: 0;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${theme.searchInput.buttonBackgroundColor};
  cursor: pointer;
`;
