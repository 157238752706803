import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { DropdownContext } from "components/Dropdown/Dropdown";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  isActive?: boolean;
};

export const DropdownMenuItem = ({ children, onClick, isActive = false }: Props) => {
  const { setIsDropdownOpen } = useContext(DropdownContext);

  const handleClick = useCallback(() => {
    setIsDropdownOpen && setIsDropdownOpen(false);
    onClick();
  }, [onClick, setIsDropdownOpen]);

  return (
    <StyledListItem isActive={isActive} onClick={handleClick}>
      {children}
    </StyledListItem>
  );
};

export const StyledListItem = styled("li").withConfig({ shouldForwardProp: (prop) => !["isActive"].includes(prop) })<{
  isActive: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) => (props.isActive ? "#F9F9F9" : "transparent")};
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
`;
