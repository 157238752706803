import styled from "styled-components";

export const GuideDescription = styled("p")`
  position: relative;
  min-height: 70px;
  font-size: 16px;
  font-weight: 400;
  color: #2b2b2b;
  text-align: center;
  line-height: 22px;
  white-space: pre-line;
  margin: 0;
`;
