import React, { useContext } from "react";
import styled from "styled-components";
import { ListItemContext } from "components/ListItem/ListItem";
import { font, theme } from "styles";

type Props = {
  children: string;
  color?: "black" | "green";
  isBold?: boolean;
  margin?: string;
};

export const ListItemText = ({ children, color, isBold, margin }: Props) => {
  const { isDisabled } = useContext(ListItemContext);

  return (
    <StyledLabel color={color} isBold={isBold} margin={margin} isDisabled={isDisabled || false}>
      {children}
    </StyledLabel>
  );
};

/**
 * Styles
 */

type StyledLabelProps = Pick<Props, "color" | "isBold" | "margin"> & { isDisabled: boolean };

const StyledLabel = styled("span").withConfig({
  shouldForwardProp: (prop) => !["color", "isBold", "margin", "isDisabled"].includes(prop),
})<StyledLabelProps>`
  position: relative;
  color: ${(props) => {
    switch (props.color) {
      case "green":
        return theme.listItemText.fontColor.green;
      case "black":
      default:
        return theme.listItemText.fontColor.black;
    }
  }};
  font-size: 16px;
  font-weight: ${(props) => (props.isBold ? font.weight.bold : font.weight.medium)};
  margin: ${(props) => props.margin ?? "0"};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;
