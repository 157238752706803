import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
  GlobalID: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Upload: any;
  /** Represents NULL values */
  Void: any;
};


export type ActivateAccountInput = {
  activationCode: Scalars['String'];
  password: Scalars['String'];
};

export type ActivateAccountPayload = {
  __typename?: 'ActivateAccountPayload';
  user: InactiveUserNode;
};

export type AnnouncementCreateInput = {
  space: Scalars['GlobalID'];
  recipientSelection: Scalars['GlobalID'];
  title: Scalars['String'];
  text: Scalars['String'];
  urgency: Urgency;
  videoUrl?: Maybe<Scalars['String']>;
  reactions?: Maybe<Array<PositionedReaction>>;
  deadline?: Maybe<Scalars['DateTime']>;
  media?: Maybe<Scalars['GlobalID']>;
};

export type AnnouncementDeletePayload = {
  __typename?: 'AnnouncementDeletePayload';
  space: SpaceNode;
};

export type AnnouncementInput = {
  announcement: Scalars['GlobalID'];
};

export type AnnouncementInteractInput = {
  announcement: Scalars['GlobalID'];
  reaction?: Maybe<Scalars['GlobalID']>;
  seen?: Maybe<Scalars['Boolean']>;
  marked?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type AnnouncementInteractPayload = {
  __typename?: 'AnnouncementInteractPayload';
  interaction: AnnouncementUserInteractionNode;
};

export type AnnouncementLogNode = Node & {
  __typename?: 'AnnouncementLogNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  type: AnnouncementLogTypes;
  createdAt: Scalars['DateTime'];
  valueOld: Scalars['String'];
  valueNew: Scalars['String'];
};

/** A connection to a list of items. */
export type AnnouncementLogNodeConnection = {
  __typename?: 'AnnouncementLogNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementLogNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementLogNodeEdge = {
  __typename?: 'AnnouncementLogNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementLogNode;
};

export enum AnnouncementLogTypes {
  Seen = 'seen',
  Reaction = 'reaction',
  Urge = 'urge'
}

export type AnnouncementNode = Node & {
  __typename?: 'AnnouncementNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  space: SpaceNode;
  createdAt: Scalars['DateTime'];
  title: Scalars['String'];
  text: Scalars['String'];
  urgedAt?: Maybe<Scalars['DateTime']>;
  urgeCount: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  videoUrl?: Maybe<Scalars['String']>;
  reactionDeadlineAt?: Maybe<Scalars['DateTime']>;
  lastChangesSeenAt?: Maybe<Scalars['DateTime']>;
  interactions: AnnouncementUserInteractionNodeConnection;
  reactions: AnnouncementReactionNodeConnection;
  logs: AnnouncementLogNodeConnection;
  authorMembership: MembershipNode;
  myInteraction?: Maybe<AnnouncementUserInteractionNode>;
  recipientsTotalCount?: Maybe<Scalars['Int']>;
  recipientsSeenCount?: Maybe<Scalars['Int']>;
  recipientsReactedCount?: Maybe<Scalars['Int']>;
  imageThumbnail?: Maybe<Scalars['String']>;
  imageOriginal?: Maybe<Scalars['String']>;
};


export type AnnouncementNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AnnouncementNodeReactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AnnouncementNodeLogsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AnnouncementNodeConnection = {
  __typename?: 'AnnouncementNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementNodeEdge = {
  __typename?: 'AnnouncementNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementNode;
};

export type AnnouncementOrder = {
  createdAt?: Maybe<Ordering>;
  urgedAt?: Maybe<Ordering>;
};

export type AnnouncementPayload = {
  __typename?: 'AnnouncementPayload';
  announcement: AnnouncementNode;
};

export type AnnouncementReactionNode = Node & {
  __typename?: 'AnnouncementReactionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  position: Scalars['Int'];
  text: Scalars['String'];
  interactions: AnnouncementUserInteractionNodeConnection;
};


export type AnnouncementReactionNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AnnouncementReactionNodeConnection = {
  __typename?: 'AnnouncementReactionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementReactionNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementReactionNodeEdge = {
  __typename?: 'AnnouncementReactionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementReactionNode;
};

export type AnnouncementSetReactionDeadlineInput = {
  announcement: Scalars['GlobalID'];
  deadline?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementUserInteractionNode = Node & {
  __typename?: 'AnnouncementUserInteractionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  announcement: AnnouncementNode;
  reaction?: Maybe<AnnouncementReactionNode>;
  reactedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  marked: Scalars['Boolean'];
  sms?: Maybe<SmsNode>;
  lastReactionLog?: Maybe<AnnouncementLogNode>;
};

/** A connection to a list of items. */
export type AnnouncementUserInteractionNodeConnection = {
  __typename?: 'AnnouncementUserInteractionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementUserInteractionNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementUserInteractionNodeEdge = {
  __typename?: 'AnnouncementUserInteractionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementUserInteractionNode;
};

export type BenefitNode = Node & {
  __typename?: 'BenefitNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  image?: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangeProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  language?: Maybe<Languages>;
  profileImage?: Maybe<Scalars['GlobalID']>;
  notificationAnnouncementNotUrgent?: Maybe<Scalars['Boolean']>;
  notificationChat?: Maybe<Scalars['Boolean']>;
  notificationStory?: Maybe<Scalars['Boolean']>;
  notificationMyStoryComment?: Maybe<Scalars['Boolean']>;
};

export type ChangeProfilePayload = {
  __typename?: 'ChangeProfilePayload';
  me: MeNode;
  user: UserNode;
};

export type ChatAddUsersInput = {
  chat: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
};

export type ChatCreateInput = {
  organization: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
  name?: Maybe<Scalars['String']>;
};

export type ChatInput = {
  chat: Scalars['GlobalID'];
};

export type ChatLeaveInput = {
  chat: Scalars['GlobalID'];
  newOwner?: Maybe<Scalars['GlobalID']>;
};

export type ChatLeavePayload = {
  __typename?: 'ChatLeavePayload';
  organization: OrganizationNode;
};

export type ChatMessageNode = Node & {
  __typename?: 'ChatMessageNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type ChatMessageNodeConnection = {
  __typename?: 'ChatMessageNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ChatMessageNodeEdge>;
};

/** An edge in a connection. */
export type ChatMessageNodeEdge = {
  __typename?: 'ChatMessageNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ChatMessageNode;
};

export type ChatMessagePayload = {
  __typename?: 'ChatMessagePayload';
  message: ChatMessageNode;
};

export type ChatNode = Node & {
  __typename?: 'ChatNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  createdAt: Scalars['DateTime'];
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  createdBy: UserNode;
  organization: OrganizationNode;
  messages: ChatMessageNodeConnection;
  interactions: ChatUserInteractionNodeConnection;
  name?: Maybe<Scalars['String']>;
  myInteraction: ChatUserInteractionNode;
  metaMarkAsRead: Scalars['Boolean'];
};


export type ChatNodeMessagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ChatNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type ChatNodeConnection = {
  __typename?: 'ChatNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ChatNodeEdge>;
};

/** An edge in a connection. */
export type ChatNodeEdge = {
  __typename?: 'ChatNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ChatNode;
};

export type ChatPayload = {
  __typename?: 'ChatPayload';
  chat: ChatNode;
};

export type ChatRemoveUsersInput = {
  chat: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
};

export type ChatSendMessageInput = {
  chat: Scalars['GlobalID'];
  message: Scalars['String'];
};

export type ChatSetMutedInput = {
  chat: Scalars['GlobalID'];
  muted: Scalars['Boolean'];
};

export type ChatSetNameInput = {
  chat: Scalars['GlobalID'];
  name: Scalars['String'];
};

export type ChatUserInteractionNode = Node & {
  __typename?: 'ChatUserInteractionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  createdBy: UserNode;
  lastOpenedAt?: Maybe<Scalars['DateTime']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  muted: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type ChatUserInteractionNodeConnection = {
  __typename?: 'ChatUserInteractionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ChatUserInteractionNodeEdge>;
};

/** An edge in a connection. */
export type ChatUserInteractionNodeEdge = {
  __typename?: 'ChatUserInteractionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ChatUserInteractionNode;
};

export type ConfirmPasswordResetInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type ContactGroupCreateInput = {
  space: Scalars['GlobalID'];
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
};

export type ContactGroupDeleteInput = {
  contactGroup: Scalars['GlobalID'];
};

export type ContactGroupDeletePayload = {
  __typename?: 'ContactGroupDeletePayload';
  contactGroup?: Maybe<ContactGroupNode>;
  success: Scalars['Boolean'];
  failedUsers: Array<UserNode>;
};

export type ContactGroupNode = Node & {
  __typename?: 'ContactGroupNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  userCount: Scalars['Int'];
  users: ContactGroupUserConnection;
};


export type ContactGroupNodeUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type ContactGroupNodeConnection = {
  __typename?: 'ContactGroupNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ContactGroupNodeEdge>;
};

/** An edge in a connection. */
export type ContactGroupNodeEdge = {
  __typename?: 'ContactGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ContactGroupNode;
};

export type ContactGroupPayload = {
  __typename?: 'ContactGroupPayload';
  contactGroup: ContactGroupNode;
};

export type ContactGroupUpdateInput = {
  contactGroup: Scalars['GlobalID'];
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<Scalars['GlobalID']>>;
};

export type ContactGroupUpdatePayload = {
  __typename?: 'ContactGroupUpdatePayload';
  contactGroup: ContactGroupNode;
  success: Scalars['Boolean'];
  failedUsers: Array<UserNode>;
};

export type ContactGroupUser = Node & {
  __typename?: 'ContactGroupUser';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  role: Roles;
  position?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ContactGroupUserConnection = {
  __typename?: 'ContactGroupUserConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ContactGroupUserEdge>;
};

/** An edge in a connection. */
export type ContactGroupUserEdge = {
  __typename?: 'ContactGroupUserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ContactGroupUser;
};


export type DeactivatePushMessageTokenInput = {
  token: Scalars['String'];
};

export type DeviceSetInput = {
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  appBuild?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  deviceInfo: Scalars['JSON'];
};

export type DocumentCreateInput = {
  space: Scalars['GlobalID'];
  media: Scalars['GlobalID'];
  recipientSelection: Scalars['GlobalID'];
  title: Scalars['String'];
};

export type DocumentDeletePayload = {
  __typename?: 'DocumentDeletePayload';
  space: SpaceNode;
};

export type DocumentInput = {
  document: Scalars['GlobalID'];
};

export type DocumentMarkAllAsReadInput = {
  space: Scalars['GlobalID'];
};

export type DocumentMarkAllAsReadPayload = {
  __typename?: 'DocumentMarkAllAsReadPayload';
  space: SpaceNode;
};

export type DocumentMarkAsReadPayload = {
  __typename?: 'DocumentMarkAsReadPayload';
  interaction: DocumentUserInteractionNode;
};

export type DocumentNode = Node & {
  __typename?: 'DocumentNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  space: SpaceNode;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  interactions: DocumentUserInteractionNodeConnection;
  authorMembership: MembershipNode;
  myInteraction: DocumentUserInteractionNode;
  file: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type DocumentNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type DocumentNodeConnection = {
  __typename?: 'DocumentNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<DocumentNodeEdge>;
};

/** An edge in a connection. */
export type DocumentNodeEdge = {
  __typename?: 'DocumentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DocumentNode;
};

export type DocumentPayload = {
  __typename?: 'DocumentPayload';
  document: DocumentNode;
};

export type DocumentUpdateInput = {
  document: Scalars['GlobalID'];
  media?: Maybe<Scalars['GlobalID']>;
  recipientSelection?: Maybe<Scalars['GlobalID']>;
  title?: Maybe<Scalars['String']>;
};

export type DocumentUserInteractionNode = Node & {
  __typename?: 'DocumentUserInteractionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  readAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type DocumentUserInteractionNodeConnection = {
  __typename?: 'DocumentUserInteractionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<DocumentUserInteractionNodeEdge>;
};

/** An edge in a connection. */
export type DocumentUserInteractionNodeEdge = {
  __typename?: 'DocumentUserInteractionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DocumentUserInteractionNode;
};

export enum Errors {
  NotFound = 'not_found',
  PermissionDenied = 'permission_denied',
  ParameterRequired = 'parameter_required',
  SpaceMismatch = 'space_mismatch',
  SpaceNotDraft = 'space_not_draft',
  SpaceNotActive = 'space_not_active',
  SpaceNotArchived = 'space_not_archived',
  OperationNotAllowed = 'operation_not_allowed',
  ColorInvalidFormat = 'color_invalid_format',
  TokenInvalid = 'token_invalid',
  EmailInvalid = 'email_invalid',
  EmailAlreadyUsed = 'email_already_used',
  PhoneNumberInvalid = 'phone_number_invalid',
  PasswordTooShort = 'password_too_short',
  PasswordInvalid = 'password_invalid',
  MediaInvalidFormat = 'media_invalid_format',
  PreviewNotSupported = 'preview_not_supported',
  MediaAlreadyUsed = 'media_already_used',
  AnnouncementCannotUrgeYet = 'announcement_cannot_urge_yet',
  AnnouncementCannotUrgeAgain = 'announcement_cannot_urge_again',
  AnnouncementCannotUrgeAfterDeadline = 'announcement_cannot_urge_after_deadline',
  AnnouncementCannotUrgeDeleted = 'announcement_cannot_urge_deleted',
  ChatAddNoValidMembers = 'chat_add_no_valid_members',
  ChatMustSelectNewOwner = 'chat_must_select_new_owner'
}

export enum FcmType {
  Ios = 'ios',
  Android = 'android'
}

export type FeedbackInput = {
  text: Scalars['String'];
};


export type InactiveUserNode = Node & {
  __typename?: 'InactiveUserNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  activationCode: Scalars['String'];
  profileImage: Scalars['String'];
  profileImageFull: Scalars['String'];
};


export enum Languages {
  Cs = 'cs',
  En = 'en',
  De = 'de',
  Sk = 'sk'
}

export type LogBenefitClickInput = {
  benefit: Scalars['GlobalID'];
};

export type LogBenefitViewsInput = {
  benefits: Array<Scalars['GlobalID']>;
};

export type LogSponsorImpressionsInput = {
  sponsors: Array<Scalars['GlobalID']>;
};

export type MeNode = Node & {
  __typename?: 'MeNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  notificationAnnouncementNotUrgent: Scalars['Boolean'];
  notificationChat: Scalars['Boolean'];
  notificationStory: Scalars['Boolean'];
  notificationMyStoryComment: Scalars['Boolean'];
  profileImageFull: Scalars['String'];
  profileImage: Scalars['String'];
  userId: Scalars['GlobalID'];
  vocative: Scalars['String'];
  language?: Maybe<Languages>;
  organizations: OrganizationNodeConnection;
  ownedOrganizations: OrganizationNodeConnection;
};


export type MeNodeOrganizationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MeNodeOwnedOrganizationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MediaInput = {
  file: Scalars['Upload'];
  type: MediaTypes;
};

export type MediaNode = Node & {
  __typename?: 'MediaNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  size?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  file?: Maybe<Scalars['String']>;
  type: MediaTypes;
};

export type MediaPayload = {
  __typename?: 'MediaPayload';
  media: MediaNode;
};

export type MediaPreviewPayload = {
  __typename?: 'MediaPreviewPayload';
  preview: Scalars['String'];
};

export enum MediaTypes {
  Document = 'document',
  StoryHeader = 'story_header',
  ProfileImage = 'profile_image',
  OrganizationLogo = 'organization_logo',
  SpaceLogo = 'space_logo',
  SpaceHeader = 'space_header',
  SponsorLogo = 'sponsor_logo',
  SponsorImage = 'sponsor_image',
  BenefitImage = 'benefit_image',
  AnnouncementImage = 'announcement_image'
}

export type MembershipNode = Node & {
  __typename?: 'MembershipNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  role: Roles;
  position?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  inviteSentAt?: Maybe<Scalars['DateTime']>;
  space: SpaceNode;
  contactGroups: ContactGroupNodeConnection;
};


export type MembershipNodeContactGroupsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type MembershipNodeConnection = {
  __typename?: 'MembershipNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<MembershipNodeEdge>;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MembershipNodeEdge = {
  __typename?: 'MembershipNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: MembershipNode;
};

export type Mutations = {
  __typename?: 'Mutations';
  storyCreate: StoryPayload;
  storyUpdate: StoryPayload;
  storyDelete: StoryDeletePayload;
  storyAddComment: StoryAddCommentPayload;
  storyClap: StoryPayload;
  chatMarkAsRead: ChatPayload;
  chatCreate: ChatPayload;
  chatSendMessage: ChatMessagePayload;
  chatAddUsers: ChatPayload;
  chatRemoveUsers: ChatPayload;
  chatSetName: ChatPayload;
  chatLeave: ChatLeavePayload;
  chatAcceptInvitation: ChatPayload;
  chatSetMuted: ChatPayload;
  documentMarkAsRead: DocumentMarkAsReadPayload;
  documentsMarkAllAsRead: DocumentMarkAllAsReadPayload;
  documentCreate: DocumentPayload;
  documentUpdate: DocumentPayload;
  documentDelete: DocumentDeletePayload;
  changeProfile: ChangeProfilePayload;
  changePassword?: Maybe<Scalars['Void']>;
  activateAccount: ActivateAccountPayload;
  requestPasswordReset?: Maybe<Scalars['Void']>;
  confirmPasswordReset?: Maybe<Scalars['Void']>;
  registerPushMessageToken?: Maybe<Scalars['Void']>;
  deactivatePushMessageToken?: Maybe<Scalars['Void']>;
  announcementInteract: AnnouncementInteractPayload;
  announcementCreate: AnnouncementPayload;
  announcementUrge: AnnouncementPayload;
  announcementSetReactionDeadline: AnnouncementPayload;
  announcementDelete: AnnouncementDeletePayload;
  announcementReactionsChangesSeen: AnnouncementPayload;
  feedbackCreate?: Maybe<Scalars['Void']>;
  deviceSet?: Maybe<Scalars['Void']>;
  recipientSelectionCreate: RecipientSelectionPayload;
  recipientSelectionCreateFromDocument: RecipientSelectionPayload;
  recipientSelectionToggle: RecipientSelectionPayload;
  mediaCreate: MediaPayload;
  mediaPreview: MediaPreviewPayload;
  organizationAddOwner: OrganizationPayload;
  organizationRemoveOwner: OrganizationPayload;
  organizationUpdate: OrganizationPayload;
  organizationRemoveUsers: OrganizationPayload;
  organizationChangeUser: OrganizationChangeUserPayload;
  organizationChangeMembership: OrganizationChangeMembershipPayload;
  spaceCreate: SpacePayload;
  spaceUpdate: SpacePayload;
  spacePublish: SpacePayload;
  spaceArchive: SpacePayload;
  spaceUnarchive: SpacePayload;
  spaceSendInvite?: Maybe<Scalars['Void']>;
  spaceDeleteMemberships?: Maybe<Scalars['Void']>;
  spaceInviteUsers: SpaceInviteUsersPayload;
  spaceCheckUsers: SpaceCheckUsersPayload;
  contactGroupCreate: ContactGroupPayload;
  contactGroupUpdate: ContactGroupUpdatePayload;
  contactGroupDelete: ContactGroupDeletePayload;
  logSponsorImpressions?: Maybe<Scalars['Void']>;
  logBenefitViews?: Maybe<Scalars['Void']>;
  logBenefitClick?: Maybe<Scalars['Void']>;
  tokenAuth: Token;
  tokenRefresh: Token;
  tokenVerify: TokenPayloadType;
};


export type MutationsStoryCreateArgs = {
  input: StoryCreateInput;
};


export type MutationsStoryUpdateArgs = {
  input: StoryUpdateInput;
};


export type MutationsStoryDeleteArgs = {
  input: StoryInput;
};


export type MutationsStoryAddCommentArgs = {
  input: StoryAddCommentInput;
};


export type MutationsStoryClapArgs = {
  input: StoryClapInput;
};


export type MutationsChatMarkAsReadArgs = {
  input: ChatInput;
};


export type MutationsChatCreateArgs = {
  input: ChatCreateInput;
};


export type MutationsChatSendMessageArgs = {
  input: ChatSendMessageInput;
};


export type MutationsChatAddUsersArgs = {
  input: ChatAddUsersInput;
};


export type MutationsChatRemoveUsersArgs = {
  input: ChatRemoveUsersInput;
};


export type MutationsChatSetNameArgs = {
  input: ChatSetNameInput;
};


export type MutationsChatLeaveArgs = {
  input: ChatLeaveInput;
};


export type MutationsChatAcceptInvitationArgs = {
  input: ChatInput;
};


export type MutationsChatSetMutedArgs = {
  input: ChatSetMutedInput;
};


export type MutationsDocumentMarkAsReadArgs = {
  input: DocumentInput;
};


export type MutationsDocumentsMarkAllAsReadArgs = {
  input: DocumentMarkAllAsReadInput;
};


export type MutationsDocumentCreateArgs = {
  input: DocumentCreateInput;
};


export type MutationsDocumentUpdateArgs = {
  input: DocumentUpdateInput;
};


export type MutationsDocumentDeleteArgs = {
  input: DocumentInput;
};


export type MutationsChangeProfileArgs = {
  input: ChangeProfileInput;
};


export type MutationsChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationsActivateAccountArgs = {
  input: ActivateAccountInput;
};


export type MutationsRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationsConfirmPasswordResetArgs = {
  input: ConfirmPasswordResetInput;
};


export type MutationsRegisterPushMessageTokenArgs = {
  input: RegisterPushMessageTokenInput;
};


export type MutationsDeactivatePushMessageTokenArgs = {
  input: DeactivatePushMessageTokenInput;
};


export type MutationsAnnouncementInteractArgs = {
  input: AnnouncementInteractInput;
};


export type MutationsAnnouncementCreateArgs = {
  input: AnnouncementCreateInput;
};


export type MutationsAnnouncementUrgeArgs = {
  input: AnnouncementInput;
};


export type MutationsAnnouncementSetReactionDeadlineArgs = {
  input: AnnouncementSetReactionDeadlineInput;
};


export type MutationsAnnouncementDeleteArgs = {
  input: AnnouncementInput;
};


export type MutationsAnnouncementReactionsChangesSeenArgs = {
  input: AnnouncementInput;
};


export type MutationsFeedbackCreateArgs = {
  input: FeedbackInput;
};


export type MutationsDeviceSetArgs = {
  input: DeviceSetInput;
};


export type MutationsRecipientSelectionCreateArgs = {
  input: RecipientSelectionCreateInput;
};


export type MutationsRecipientSelectionCreateFromDocumentArgs = {
  input: RecipientSelectionCreateFromDocumentInput;
};


export type MutationsRecipientSelectionToggleArgs = {
  input: RecipientSelectionToggleInput;
};


export type MutationsMediaCreateArgs = {
  input: MediaInput;
};


export type MutationsMediaPreviewArgs = {
  input: MediaInput;
};


export type MutationsOrganizationAddOwnerArgs = {
  input: OrganizationAddOwnerInput;
};


export type MutationsOrganizationRemoveOwnerArgs = {
  input: OrganizationRemoveOwnerInput;
};


export type MutationsOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


export type MutationsOrganizationRemoveUsersArgs = {
  input: OrganizationRemoveUsersInput;
};


export type MutationsOrganizationChangeUserArgs = {
  input: OrganizationChangeUserInput;
};


export type MutationsOrganizationChangeMembershipArgs = {
  input: OrganizationChangeMembershipInput;
};


export type MutationsSpaceCreateArgs = {
  input: SpaceCreateInput;
};


export type MutationsSpaceUpdateArgs = {
  input: SpaceUpdateInput;
};


export type MutationsSpacePublishArgs = {
  input: SpaceInput;
};


export type MutationsSpaceArchiveArgs = {
  input: SpaceInput;
};


export type MutationsSpaceUnarchiveArgs = {
  input: SpaceInput;
};


export type MutationsSpaceSendInviteArgs = {
  input: SpaceSendInviteInput;
};


export type MutationsSpaceDeleteMembershipsArgs = {
  input: SpaceDeleteMembershipsInput;
};


export type MutationsSpaceInviteUsersArgs = {
  input: SpaceUsersToAddInput;
};


export type MutationsSpaceCheckUsersArgs = {
  input: SpaceUsersToAddInput;
};


export type MutationsContactGroupCreateArgs = {
  input: ContactGroupCreateInput;
};


export type MutationsContactGroupUpdateArgs = {
  input: ContactGroupUpdateInput;
};


export type MutationsContactGroupDeleteArgs = {
  input: ContactGroupDeleteInput;
};


export type MutationsLogSponsorImpressionsArgs = {
  input: LogSponsorImpressionsInput;
};


export type MutationsLogBenefitViewsArgs = {
  input: LogBenefitViewsInput;
};


export type MutationsLogBenefitClickArgs = {
  input: LogBenefitClickInput;
};


export type MutationsTokenAuthArgs = {
  username: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
};


export type MutationsTokenRefreshArgs = {
  refreshToken: Maybe<Scalars['String']>;
};


export type MutationsTokenVerifyArgs = {
  token: Scalars['String'];
};

/** An object with a Globally Unique ID */
export type Node = {
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
};

export type OnAnnouncementChangeMessage = {
  __typename?: 'OnAnnouncementChangeMessage';
  event: Scalars['String'];
  announcement: AnnouncementNode;
};

export type OnChatChangeMessage = {
  __typename?: 'OnChatChangeMessage';
  event: Scalars['String'];
  chat: ChatNode;
};

export type OnSpaceAnnouncementChangeMessage = {
  __typename?: 'OnSpaceAnnouncementChangeMessage';
  event: Scalars['String'];
  space: SpaceNode;
};

export type OnSpaceDocumentChangeMessage = {
  __typename?: 'OnSpaceDocumentChangeMessage';
  event: Scalars['String'];
  space: SpaceNode;
};

export enum Ordering {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST'
}

export type OrganizationAddOwnerInput = {
  organization: Scalars['GlobalID'];
  user: Scalars['GlobalID'];
};

export type OrganizationChangeMembershipInput = {
  membership: Scalars['GlobalID'];
  role?: Maybe<Roles>;
  position?: Maybe<Scalars['String']>;
};

export type OrganizationChangeMembershipPayload = {
  __typename?: 'OrganizationChangeMembershipPayload';
  membership: MembershipNode;
};

export type OrganizationChangeUserInput = {
  user: Scalars['GlobalID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['GlobalID']>;
  about?: Maybe<Scalars['String']>;
};

export type OrganizationChangeUserPayload = {
  __typename?: 'OrganizationChangeUserPayload';
  user: UserNode;
};

export type OrganizationNode = Node & {
  __typename?: 'OrganizationNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  owners: UserNodeConnection;
  name: Scalars['String'];
  officialName: Scalars['String'];
  crn: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  sponsorType: SponsorTypes;
  logo: Scalars['String'];
  users: UserNodeConnection;
  spaces: SpaceNodeConnection;
  chats: ChatNodeConnection;
  unresolvedChatCount: Scalars['Int'];
  sponsors: Array<SponsorNode>;
  benefits: Array<BenefitNode>;
  hasBenefits: Scalars['Boolean'];
};


export type OrganizationNodeOwnersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationNodeUsersArgs = {
  query?: Maybe<Scalars['String']>;
  order: Maybe<OrganizationUserOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationNodeSpacesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationNodeChatsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type OrganizationNodeConnection = {
  __typename?: 'OrganizationNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<OrganizationNodeEdge>;
};

/** An edge in a connection. */
export type OrganizationNodeEdge = {
  __typename?: 'OrganizationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: OrganizationNode;
};

export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  organization: OrganizationNode;
};

export type OrganizationRemoveOwnerInput = {
  organization: Scalars['GlobalID'];
  user: Scalars['GlobalID'];
};

export type OrganizationRemoveUsersInput = {
  organization: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
};

export type OrganizationUpdateInput = {
  organization: Scalars['GlobalID'];
  name?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  crn?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['GlobalID']>;
};

export type OrganizationUserOrder = {
  firstName?: Maybe<Ordering>;
  lastName?: Maybe<Ordering>;
};

/** Information to aid in pagination. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PositionedReaction = {
  position: Scalars['Int'];
  reaction: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  user: UserNode;
  userForActivation?: Maybe<InactiveUserNode>;
  organization: OrganizationNode;
  me: MeNode;
  contactGroup: ContactGroupNode;
  recipientSelection: RecipientSelectionNode;
  space: SpaceNode;
  announcement: AnnouncementNode;
  document: DocumentNode;
  chat: ChatNode;
  story: StoryNode;
  exposeErrors: Errors;
};


export type QueryUserArgs = {
  id: Scalars['GlobalID'];
};


export type QueryUserForActivationArgs = {
  activationCode: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['GlobalID'];
};


export type QueryContactGroupArgs = {
  id: Scalars['GlobalID'];
};


export type QueryRecipientSelectionArgs = {
  id: Scalars['GlobalID'];
};


export type QuerySpaceArgs = {
  id: Scalars['GlobalID'];
};


export type QueryAnnouncementArgs = {
  id: Scalars['GlobalID'];
};


export type QueryDocumentArgs = {
  id: Scalars['GlobalID'];
};


export type QueryChatArgs = {
  id: Scalars['GlobalID'];
};


export type QueryStoryArgs = {
  id: Scalars['GlobalID'];
};

export type RecipientSelectionContactGroupNode = Node & {
  __typename?: 'RecipientSelectionContactGroupNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  contactGroup: ContactGroupNode;
  selectedCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** A connection to a list of items. */
export type RecipientSelectionContactGroupNodeConnection = {
  __typename?: 'RecipientSelectionContactGroupNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<RecipientSelectionContactGroupNodeEdge>;
};

/** An edge in a connection. */
export type RecipientSelectionContactGroupNodeEdge = {
  __typename?: 'RecipientSelectionContactGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RecipientSelectionContactGroupNode;
};

export type RecipientSelectionCreateFromDocumentInput = {
  document: Scalars['GlobalID'];
};

export type RecipientSelectionCreateInput = {
  space: Scalars['GlobalID'];
};

export type RecipientSelectionNode = Node & {
  __typename?: 'RecipientSelectionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  createdBy: UserNode;
  space: SpaceNode;
  selectedCount: Scalars['Int'];
  contactGroups: RecipientSelectionContactGroupNodeConnection;
  users: RecipientSelectionUserNodeConnection;
};


export type RecipientSelectionNodeContactGroupsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RecipientSelectionNodeUsersArgs = {
  contactGroupId?: Maybe<Scalars['GlobalID']>;
  query?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RecipientSelectionPayload = {
  __typename?: 'RecipientSelectionPayload';
  recipientSelection: RecipientSelectionNode;
};

export type RecipientSelectionToggleInput = {
  recipientSelection: Scalars['GlobalID'];
  add: Scalars['Boolean'];
  contactGroup?: Maybe<Scalars['GlobalID']>;
  user?: Maybe<Scalars['GlobalID']>;
  all?: Maybe<Scalars['Boolean']>;
};

export type RecipientSelectionUserNode = Node & {
  __typename?: 'RecipientSelectionUserNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  membership: MembershipNode;
  selected: Scalars['Boolean'];
  space?: Maybe<Scalars['Void']>;
};

/** A connection to a list of items. */
export type RecipientSelectionUserNodeConnection = {
  __typename?: 'RecipientSelectionUserNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<RecipientSelectionUserNodeEdge>;
};

/** An edge in a connection. */
export type RecipientSelectionUserNodeEdge = {
  __typename?: 'RecipientSelectionUserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RecipientSelectionUserNode;
};

export type RegisterPushMessageTokenInput = {
  token: Scalars['String'];
  type: FcmType;
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export enum Roles {
  Manager = 'manager',
  Regular = 'regular'
}

export type SimpleUser = {
  email: Scalars['String'];
  role: Roles;
  contactGroups: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type SmsNode = Node & {
  __typename?: 'SmsNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  status: SmsStatus;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message: Scalars['String'];
};

export enum SmsStatus {
  New = 'new',
  Error = 'error',
  Accepted = 'accepted',
  Queued = 'queued',
  Sending = 'sending',
  Sent = 'sent',
  Receiving = 'receiving',
  Received = 'received',
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Failed = 'failed'
}

export type SpaceCheckUsersPayload = {
  __typename?: 'SpaceCheckUsersPayload';
  suggestedChanges: Array<SuggestedUser>;
};

export type SpaceCreateInput = {
  organization: Scalars['GlobalID'];
  name: Scalars['String'];
  officialName: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['GlobalID']>;
  header?: Maybe<Scalars['GlobalID']>;
};

export type SpaceDeleteMembershipsInput = {
  memberships: Array<Scalars['GlobalID']>;
};

export type SpaceInput = {
  space: Scalars['GlobalID'];
};

export type SpaceInviteUsersPayload = {
  __typename?: 'SpaceInviteUsersPayload';
  space: SpaceNode;
  failedEmails: Array<Scalars['String']>;
};

export type SpaceMembershipOrder = {
  createdAt?: Maybe<Ordering>;
  role?: Maybe<Ordering>;
  user_FirstName?: Maybe<Ordering>;
  user_LastName?: Maybe<Ordering>;
};

export type SpaceNode = Node & {
  __typename?: 'SpaceNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  officialName: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  organization: OrganizationNode;
  state: SpaceState;
  logo: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  members: MembershipNodeConnection;
  managerCount: Scalars['Int'];
  myMembership: MembershipNode;
  active: Scalars['Boolean'];
  stories: StoryNodeConnection;
  receivedAnnouncements: AnnouncementNodeConnection;
  sentAnnouncements: AnnouncementNodeConnection;
  unresolvedAnnouncementCount: Scalars['Int'];
  receivedDocuments: DocumentNodeConnection;
  sentDocuments: DocumentNodeConnection;
  unresolvedDocumentCount: Scalars['Int'];
  contactGroups: ContactGroupNodeConnection;
};


export type SpaceNodeMembersArgs = {
  query?: Maybe<Scalars['String']>;
  activated?: Maybe<Scalars['Boolean']>;
  order: Maybe<SpaceMembershipOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeStoriesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeReceivedAnnouncementsArgs = {
  marked?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  unresolved?: Maybe<Scalars['Boolean']>;
  order: Maybe<AnnouncementOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeSentAnnouncementsArgs = {
  order: Maybe<AnnouncementOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeReceivedDocumentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeSentDocumentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeContactGroupsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type SpaceNodeConnection = {
  __typename?: 'SpaceNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<SpaceNodeEdge>;
};

/** An edge in a connection. */
export type SpaceNodeEdge = {
  __typename?: 'SpaceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: SpaceNode;
};

export type SpacePayload = {
  __typename?: 'SpacePayload';
  space: SpaceNode;
};

export type SpaceSendInviteInput = {
  membership: Scalars['GlobalID'];
};

export enum SpaceState {
  Draft = 'draft',
  Active = 'active',
  Archived = 'archived'
}

export type SpaceUpdateInput = {
  space: Scalars['GlobalID'];
  name?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['GlobalID']>;
  header?: Maybe<Scalars['GlobalID']>;
};

export type SpaceUsersToAddInput = {
  space: Scalars['GlobalID'];
  users: Array<SimpleUser>;
};

export type SponsorNode = Node & {
  __typename?: 'SponsorNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  type: SponsorTypes;
  logo?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export enum SponsorTypes {
  Simple = 'simple',
  Graphical = 'graphical'
}

export type StoryAddCommentInput = {
  story: Scalars['GlobalID'];
  text: Scalars['String'];
};

export type StoryAddCommentPayload = {
  __typename?: 'StoryAddCommentPayload';
  comment: StoryCommentNode;
};

export type StoryClapInput = {
  story: Scalars['GlobalID'];
  count: Scalars['Int'];
};

export type StoryCommentNode = Node & {
  __typename?: 'StoryCommentNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  createdAt: Scalars['DateTime'];
  text: Scalars['String'];
};

/** A connection to a list of items. */
export type StoryCommentNodeConnection = {
  __typename?: 'StoryCommentNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<StoryCommentNodeEdge>;
};

/** An edge in a connection. */
export type StoryCommentNodeEdge = {
  __typename?: 'StoryCommentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StoryCommentNode;
};

export type StoryCreateInput = {
  space: Scalars['GlobalID'];
  text: Scalars['String'];
  media?: Maybe<Scalars['GlobalID']>;
};

export type StoryDeletePayload = {
  __typename?: 'StoryDeletePayload';
  space: SpaceNode;
};

export type StoryInput = {
  story: Scalars['GlobalID'];
};

export type StoryNode = Node & {
  __typename?: 'StoryNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  text: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  space: SpaceNode;
  claps: Scalars['Int'];
  comments: StoryCommentNodeConnection;
  myClapCount: Scalars['Int'];
  commentCount: Scalars['Int'];
  headerThumbnail?: Maybe<Scalars['String']>;
  headerOriginal?: Maybe<Scalars['String']>;
  commentedOnByUser: Scalars['Boolean'];
  authorMembership: MembershipNode;
};


export type StoryNodeCommentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type StoryNodeConnection = {
  __typename?: 'StoryNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<StoryNodeEdge>;
};

/** An edge in a connection. */
export type StoryNodeEdge = {
  __typename?: 'StoryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StoryNode;
};

export type StoryPayload = {
  __typename?: 'StoryPayload';
  story: StoryNode;
};

export type StoryUpdateInput = {
  story: Scalars['GlobalID'];
  media?: Maybe<Scalars['GlobalID']>;
  text: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onChatChange: OnChatChangeMessage;
  /** Broadcasts when sent/received announcement changes (someone sends reaction, reads the announcement, ...). */
  onAnnouncementChange: OnAnnouncementChangeMessage;
  /** Broadcasts when announcement is created or deleted in the space. Warning: doesn't check whether the subscribed user is affected by the event. */
  onSpaceAnnouncementsEvent: OnSpaceAnnouncementChangeMessage;
  /** Broadcasts when document is created or deleted in the space. Warning: doesn't check whether the subscribed user is affected by the event. */
  onSpaceDocumentsEvent: OnSpaceDocumentChangeMessage;
};

export type SuggestedUser = {
  __typename?: 'SuggestedUser';
  originalEmail: Scalars['String'];
  suggestedEmail: Scalars['String'];
  suggestedFirstName?: Maybe<Scalars['String']>;
  suggestedLastName?: Maybe<Scalars['String']>;
  suggestedPhoneNumber?: Maybe<Scalars['String']>;
  suggestedNotificationNumber?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
};

export type TokenPayloadType = {
  __typename?: 'TokenPayloadType';
  exp: Scalars['Int'];
  iat: Scalars['Int'];
};


export enum Urgency {
  NotUrgent = 'not_urgent',
  Urgent = 'urgent',
  VeryUrgent = 'very_urgent'
}

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  activationCode: Scalars['String'];
  profileImage: Scalars['String'];
  profileImageFull: Scalars['String'];
  memberships: MembershipNodeConnection;
  stories: StoryNodeConnection;
};


export type UserNodeMembershipsArgs = {
  organization: Scalars['GlobalID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserNodeStoriesArgs = {
  organization: Scalars['GlobalID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<UserNodeEdge>;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: UserNode;
};


export type AnnouncementFragment = { __typename?: 'AnnouncementNode', id: any, title: string, text: string, videoUrl?: Maybe<string>, imageOriginal?: Maybe<string>, imageThumbnail?: Maybe<string>, urgedAt?: Maybe<any>, urgeCount: number, createdAt: any, lastChangesSeenAt?: Maybe<any>, reactionDeadlineAt?: Maybe<any> };

export type AnnouncementAuthorFragment = { __typename?: 'UserNode', id: any, firstName: string, lastName: string, profileImage: string };

export type AnnouncementLogFragment = { __typename?: 'AnnouncementLogNode', id: any, type: AnnouncementLogTypes, valueOld: string, valueNew: string, createdAt: any, user: { __typename?: 'UserNode', id: any, isActive: boolean, firstName: string, lastName: string, profileImage: string } };

export type AuthorMembershipFragment = { __typename?: 'MembershipNode', id: any, role: Roles, position?: Maybe<string> };

export type ChatInteractionFragment = { __typename?: 'ChatUserInteractionNode', id: any, acceptedAt?: Maybe<any>, archivedAt?: Maybe<any>, lastOpenedAt?: Maybe<any>, user: { __typename?: 'UserNode', id: any, email: string, isActive: boolean, lastName: string, firstName: string, profileImage: string } };

export type ChatMessageFragment = { __typename?: 'ChatMessageNode', id: any, message: string, createdAt: any, author: { __typename?: 'UserNode', id: any, email: string, firstName: string, profileImage: string } };

export type ChatMyInteractionFragment = { __typename?: 'ChatUserInteractionNode', id: any, muted: boolean, acceptedAt?: Maybe<any>, lastOpenedAt?: Maybe<any>, createdBy: { __typename?: 'UserNode', id: any, firstName: string, profileImage: string } };

export type CommentAuthorFragment = { __typename?: 'UserNode', id: any, firstName: string, lastName: string, profileImage: string };

export type DocumentFragment = { __typename?: 'DocumentNode', id: any, file: string, size?: Maybe<number>, type: string, title: string, createdAt: any, updatedAt?: Maybe<any> };

export type DocumentAuthorFragment = { __typename?: 'UserNode', id: any, firstName: string, lastName: string, profileImage: string };

export type ListedDocumentFragment = { __typename?: 'DocumentNode', id: any, title: string, createdAt: any, updatedAt?: Maybe<any> };

export type MeFragment = { __typename?: 'MeNode', id: any, email: string, userId: any, isActive: boolean, firstName: string, lastName: string, profileImage: string, language?: Maybe<Languages>, organizations: { __typename?: 'OrganizationNodeConnection', edges: Array<{ __typename?: 'OrganizationNodeEdge', node: { __typename?: 'OrganizationNode', id: any, name: string, logo: string, hasBenefits: boolean, unresolvedChatCount: number, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: { __typename?: 'SpaceNode', id: any, name: string, logo: string, state: SpaceState, unresolvedDocumentCount: number, unresolvedAnnouncementCount: number, myMembership: { __typename?: 'MembershipNode', id: any, role: Roles } } }> } } }> } };

export type MeValidationFragment = { __typename?: 'MeNode', id: any, organizations: { __typename?: 'OrganizationNodeConnection', edges: Array<{ __typename?: 'OrganizationNodeEdge', node: { __typename?: 'OrganizationNode', id: any, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: { __typename?: 'SpaceNode', id: any, state: SpaceState } }> } } }> } };

export type MyAnnouncementInteractionFragment = { __typename?: 'AnnouncementUserInteractionNode', id: any, readAt?: Maybe<any>, reactedAt?: Maybe<any>, archivedAt?: Maybe<any>, marked: boolean, reaction?: Maybe<{ __typename?: 'AnnouncementReactionNode', id: any }> };

export type MyDocumentInteractionFragment = { __typename?: 'DocumentUserInteractionNode', id: any, readAt?: Maybe<any> };

export type OrganizationChatFragment = { __typename?: 'ChatNode', id: any, name?: Maybe<string>, createdAt: any, lastMessageAt?: Maybe<any>, createdBy: { __typename?: 'UserNode', id: any, firstName: string, profileImage: string }, myInteraction: { __typename?: 'ChatUserInteractionNode', id: any, createdAt: any, acceptedAt?: Maybe<any>, archivedAt?: Maybe<any>, lastOpenedAt?: Maybe<any>, createdBy: { __typename?: 'UserNode', id: any, firstName: string, profileImage: string } }, interactions: { __typename?: 'ChatUserInteractionNodeConnection', edges: Array<{ __typename?: 'ChatUserInteractionNodeEdge', cursor: string, node: { __typename?: 'ChatUserInteractionNode', id: any, createdAt: any, acceptedAt?: Maybe<any>, archivedAt?: Maybe<any>, lastOpenedAt?: Maybe<any>, user: { __typename?: 'UserNode', id: any, email: string, isActive: boolean, lastName: string, firstName: string, profileImage: string } } }> }, messages: { __typename?: 'ChatMessageNodeConnection', edges: Array<{ __typename?: 'ChatMessageNodeEdge', cursor: string, node: { __typename?: 'ChatMessageNode', id: any, message: string, createdAt: any, author: { __typename?: 'UserNode', id: any, profileImage: string } } }> } };

export type PageInfoFragment = { __typename?: 'PageInfo', startCursor?: Maybe<string>, endCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean };

export type RecipientFragment = { __typename?: 'RecipientSelectionUserNode', selected: boolean, membership: { __typename?: 'MembershipNode', id: any, role: Roles, position?: Maybe<string> }, user: { __typename?: 'UserNode', id: any, isActive: boolean, firstName: string, lastName: string, profileImage: string } };

export type RecipientSelectionFragment = { __typename?: 'RecipientSelectionNode', id: any, selectedCount: number, users: { __typename?: 'RecipientSelectionUserNodeConnection', pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<{ __typename?: 'RecipientSelectionUserNodeEdge', cursor: string, node: (
        { __typename?: 'RecipientSelectionUserNode' }
        & RecipientFragment
      ) }> }, contactGroups: { __typename?: 'RecipientSelectionContactGroupNodeConnection', pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<{ __typename?: 'RecipientSelectionContactGroupNodeEdge', cursor: string, node: (
        { __typename?: 'RecipientSelectionContactGroupNode' }
        & RecipientSelectionContactGroupFragment
      ) }> } };

export type RecipientSelectionContactGroupFragment = { __typename?: 'RecipientSelectionContactGroupNode', totalCount: number, selectedCount: number, contactGroup: { __typename?: 'ContactGroupNode', id: any, name: string, private: boolean } };

export type StoryFragment = { __typename?: 'StoryNode', id: any, text: string, createdAt: any, headerOriginal?: Maybe<string>, headerThumbnail?: Maybe<string>, claps: number, myClapCount: number, commentCount: number, commentedOnByUser: boolean, author: (
    { __typename?: 'UserNode' }
    & StoryAuthorFragment
  ), authorMembership: (
    { __typename?: 'MembershipNode' }
    & AuthorMembershipFragment
  ), comments: { __typename?: 'StoryCommentNodeConnection', pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<{ __typename?: 'StoryCommentNodeEdge', cursor: string, node: (
        { __typename?: 'StoryCommentNode' }
        & StoryCommentFragment
      ) }> } };

export type StoryAuthorFragment = { __typename?: 'UserNode', id: any, firstName: string, lastName: string, profileImage: string };

export type StoryCommentFragment = { __typename?: 'StoryCommentNode', id: any, text: string, createdAt: any, author: (
    { __typename?: 'UserNode' }
    & CommentAuthorFragment
  ) };

export type ActivateAccountMutationVariables = Exact<{
  input: ActivateAccountInput;
}>;


export type ActivateAccountMutation = { __typename?: 'Mutations', activateAccount: { __typename?: 'ActivateAccountPayload', user: { __typename?: 'InactiveUserNode', id: any } } };

export type AnnouncementCreateMutationVariables = Exact<{
  input: AnnouncementCreateInput;
}>;


export type AnnouncementCreateMutation = { __typename?: 'Mutations', announcementCreate: { __typename?: 'AnnouncementPayload', announcement: { __typename?: 'AnnouncementNode', id: any } } };

export type AnnouncementDeleteMutationVariables = Exact<{
  input: AnnouncementInput;
}>;


export type AnnouncementDeleteMutation = { __typename?: 'Mutations', announcementDelete: { __typename?: 'AnnouncementDeletePayload', space: { __typename?: 'SpaceNode', id: any } } };

export type AnnouncementInteractMutationVariables = Exact<{
  input: AnnouncementInteractInput;
}>;


export type AnnouncementInteractMutation = { __typename?: 'Mutations', announcementInteract: { __typename?: 'AnnouncementInteractPayload', interaction: (
      { __typename?: 'AnnouncementUserInteractionNode', announcement: { __typename?: 'AnnouncementNode', id: any, recipientsTotalCount?: Maybe<number>, recipientsSeenCount?: Maybe<number>, recipientsReactedCount?: Maybe<number> } }
      & MyAnnouncementInteractionFragment
    ) } };

export type AnnouncementReactionsChangesSeenMutationVariables = Exact<{
  input: AnnouncementInput;
}>;


export type AnnouncementReactionsChangesSeenMutation = { __typename?: 'Mutations', announcementReactionsChangesSeen: { __typename?: 'AnnouncementPayload', announcement: { __typename?: 'AnnouncementNode', id: any } } };

export type AnnouncementSetReactionDeadlineMutationVariables = Exact<{
  input: AnnouncementSetReactionDeadlineInput;
}>;


export type AnnouncementSetReactionDeadlineMutation = { __typename?: 'Mutations', announcementSetReactionDeadline: { __typename?: 'AnnouncementPayload', announcement: { __typename?: 'AnnouncementNode', id: any } } };

export type AnnouncementUrgeMutationVariables = Exact<{
  input: AnnouncementInput;
}>;


export type AnnouncementUrgeMutation = { __typename?: 'Mutations', announcementUrge: { __typename?: 'AnnouncementPayload', announcement: { __typename?: 'AnnouncementNode', id: any } } };

export type ChangeLanguageMutationVariables = Exact<{
  input: ChangeProfileInput;
}>;


export type ChangeLanguageMutation = { __typename?: 'Mutations', changeProfile: { __typename?: 'ChangeProfilePayload', me: { __typename?: 'MeNode', id: any, language?: Maybe<Languages> } } };

export type ChangeProfileMutationVariables = Exact<{
  input: ChangeProfileInput;
}>;


export type ChangeProfileMutation = { __typename?: 'Mutations', changeProfile: { __typename?: 'ChangeProfilePayload', me: { __typename?: 'MeNode', id: any } } };

export type ChatAcceptInvitationMutationVariables = Exact<{
  input: ChatInput;
}>;


export type ChatAcceptInvitationMutation = { __typename?: 'Mutations', chatAcceptInvitation: { __typename?: 'ChatPayload', chat: { __typename?: 'ChatNode', id: any, myInteraction: (
        { __typename?: 'ChatUserInteractionNode' }
        & ChatMyInteractionFragment
      ) } } };

export type ChatAddUsersMutationVariables = Exact<{
  input: ChatAddUsersInput;
}>;


export type ChatAddUsersMutation = { __typename?: 'Mutations', chatAddUsers: { __typename?: 'ChatPayload', chat: (
      { __typename?: 'ChatNode' }
      & OrganizationChatFragment
    ) } };

export type ChatCreateMutationVariables = Exact<{
  input: ChatCreateInput;
}>;


export type ChatCreateMutation = { __typename?: 'Mutations', chatCreate: { __typename?: 'ChatPayload', chat: { __typename?: 'ChatNode', id: any } } };

export type ChatLeaveMutationVariables = Exact<{
  input: ChatLeaveInput;
}>;


export type ChatLeaveMutation = { __typename?: 'Mutations', chatLeave: { __typename?: 'ChatLeavePayload', organization: { __typename?: 'OrganizationNode', id: any, chats: { __typename?: 'ChatNodeConnection', edges: Array<{ __typename?: 'ChatNodeEdge', node: (
            { __typename?: 'ChatNode' }
            & OrganizationChatFragment
          ) }> } } } };

export type ChatRemoveUsersMutationVariables = Exact<{
  input: ChatRemoveUsersInput;
}>;


export type ChatRemoveUsersMutation = { __typename?: 'Mutations', chatRemoveUsers: { __typename?: 'ChatPayload', chat: (
      { __typename?: 'ChatNode' }
      & OrganizationChatFragment
    ) } };

export type ChatSendMessageMutationVariables = Exact<{
  input: ChatSendMessageInput;
}>;


export type ChatSendMessageMutation = { __typename?: 'Mutations', chatSendMessage: { __typename?: 'ChatMessagePayload', message: { __typename?: 'ChatMessageNode', id: any } } };

export type ChatSetMutedMutationVariables = Exact<{
  input: ChatSetMutedInput;
}>;


export type ChatSetMutedMutation = { __typename?: 'Mutations', chatSetMuted: { __typename?: 'ChatPayload', chat: { __typename?: 'ChatNode', id: any, myInteraction: (
        { __typename?: 'ChatUserInteractionNode' }
        & ChatMyInteractionFragment
      ) } } };

export type ChatSetNameMutationVariables = Exact<{
  input: ChatSetNameInput;
}>;


export type ChatSetNameMutation = { __typename?: 'Mutations', chatSetName: { __typename?: 'ChatPayload', chat: (
      { __typename?: 'ChatNode' }
      & OrganizationChatFragment
    ) } };

export type ConfirmPasswordResetMutationVariables = Exact<{
  input: ConfirmPasswordResetInput;
}>;


export type ConfirmPasswordResetMutation = { __typename?: 'Mutations', confirmPasswordReset?: Maybe<any> };

export type DocumentCreateMutationVariables = Exact<{
  input: DocumentCreateInput;
}>;


export type DocumentCreateMutation = { __typename?: 'Mutations', documentCreate: { __typename?: 'DocumentPayload', document: { __typename?: 'DocumentNode', id: any } } };

export type DocumentDeleteMutationVariables = Exact<{
  input: DocumentInput;
}>;


export type DocumentDeleteMutation = { __typename?: 'Mutations', documentDelete: { __typename?: 'DocumentDeletePayload', space: { __typename?: 'SpaceNode', id: any } } };

export type DocumentMarkAsReadMutationVariables = Exact<{
  input: DocumentInput;
}>;


export type DocumentMarkAsReadMutation = { __typename?: 'Mutations', documentMarkAsRead: { __typename?: 'DocumentMarkAsReadPayload', interaction: (
      { __typename?: 'DocumentUserInteractionNode' }
      & MyDocumentInteractionFragment
    ) } };

export type DocumentUpdateMutationVariables = Exact<{
  input: DocumentUpdateInput;
}>;


export type DocumentUpdateMutation = { __typename?: 'Mutations', documentUpdate: { __typename?: 'DocumentPayload', document: (
      { __typename?: 'DocumentNode' }
      & DocumentFragment
    ) } };

export type FeedbackCreateMutationVariables = Exact<{
  input: FeedbackInput;
}>;


export type FeedbackCreateMutation = { __typename?: 'Mutations', feedbackCreate?: Maybe<any> };

export type LogBenefitClickMutationVariables = Exact<{
  input: LogBenefitClickInput;
}>;


export type LogBenefitClickMutation = { __typename?: 'Mutations', logBenefitClick?: Maybe<any> };

export type LogBenefitViewsMutationVariables = Exact<{
  input: LogBenefitViewsInput;
}>;


export type LogBenefitViewsMutation = { __typename?: 'Mutations', logBenefitViews?: Maybe<any> };

export type LogSponsorImpressionsMutationVariables = Exact<{
  input: LogSponsorImpressionsInput;
}>;


export type LogSponsorImpressionsMutation = { __typename?: 'Mutations', logSponsorImpressions?: Maybe<any> };

export type MediaCreateMutationVariables = Exact<{
  input: MediaInput;
}>;


export type MediaCreateMutation = { __typename?: 'Mutations', mediaCreate: { __typename?: 'MediaPayload', media: { __typename?: 'MediaNode', id: any } } };

export type MediaPreviewMutationVariables = Exact<{
  input: MediaInput;
}>;


export type MediaPreviewMutation = { __typename?: 'Mutations', mediaPreview: { __typename?: 'MediaPreviewPayload', preview: string } };

export type RecipientSelectionCreateMutationVariables = Exact<{
  input: RecipientSelectionCreateInput;
}>;


export type RecipientSelectionCreateMutation = { __typename?: 'Mutations', recipientSelectionCreate: { __typename?: 'RecipientSelectionPayload', recipientSelection: (
      { __typename?: 'RecipientSelectionNode' }
      & RecipientSelectionFragment
    ) } };

export type RecipientSelectionCreateFromDocumentMutationVariables = Exact<{
  input: RecipientSelectionCreateFromDocumentInput;
}>;


export type RecipientSelectionCreateFromDocumentMutation = { __typename?: 'Mutations', recipientSelectionCreateFromDocument: { __typename?: 'RecipientSelectionPayload', recipientSelection: (
      { __typename?: 'RecipientSelectionNode' }
      & RecipientSelectionFragment
    ) } };

export type RecipientSelectionToggleMutationVariables = Exact<{
  input: RecipientSelectionToggleInput;
}>;


export type RecipientSelectionToggleMutation = { __typename?: 'Mutations', recipientSelectionToggle: { __typename?: 'RecipientSelectionPayload', recipientSelection: (
      { __typename?: 'RecipientSelectionNode' }
      & RecipientSelectionFragment
    ) } };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutations', tokenRefresh: { __typename?: 'Token', token: string, refreshToken: string, refreshExpiresIn: number } };

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutations', requestPasswordReset?: Maybe<any> };

export type StoryAddCommentMutationVariables = Exact<{
  input: StoryAddCommentInput;
}>;


export type StoryAddCommentMutation = { __typename?: 'Mutations', storyAddComment: { __typename?: 'StoryAddCommentPayload', comment: (
      { __typename?: 'StoryCommentNode' }
      & StoryCommentFragment
    ) } };

export type StoryClapMutationVariables = Exact<{
  input: StoryClapInput;
}>;


export type StoryClapMutation = { __typename?: 'Mutations', storyClap: { __typename?: 'StoryPayload', story: { __typename?: 'StoryNode', id: any, claps: number, myClapCount: number } } };

export type StoryCreateMutationVariables = Exact<{
  input: StoryCreateInput;
}>;


export type StoryCreateMutation = { __typename?: 'Mutations', storyCreate: { __typename?: 'StoryPayload', story: { __typename?: 'StoryNode', id: any } } };

export type StoryDeleteMutationVariables = Exact<{
  input: StoryInput;
}>;


export type StoryDeleteMutation = { __typename?: 'Mutations', storyDelete: { __typename?: 'StoryDeletePayload', space: { __typename?: 'SpaceNode', id: any, name: string, stories: { __typename?: 'StoryNodeConnection', pageInfo: (
          { __typename?: 'PageInfo' }
          & PageInfoFragment
        ), edges: Array<{ __typename?: 'StoryNodeEdge', cursor: string, node: (
            { __typename?: 'StoryNode' }
            & StoryFragment
          ) }> } } } };

export type StoryUpdateMutationVariables = Exact<{
  input: StoryUpdateInput;
}>;


export type StoryUpdateMutation = { __typename?: 'Mutations', storyUpdate: { __typename?: 'StoryPayload', story: (
      { __typename?: 'StoryNode' }
      & StoryFragment
    ) } };

export type TokenAuthMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type TokenAuthMutation = { __typename?: 'Mutations', tokenAuth: { __typename?: 'Token', token: string, refreshToken: string, refreshExpiresIn: number } };

export type AnnouncementQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  sent: Scalars['Boolean'];
}>;


export type AnnouncementQuery = { __typename?: 'Query', announcement: (
    { __typename?: 'AnnouncementNode', space: { __typename?: 'SpaceNode', id: any, name: string }, author: (
      { __typename?: 'UserNode' }
      & AnnouncementAuthorFragment
    ), authorMembership: (
      { __typename?: 'MembershipNode' }
      & AuthorMembershipFragment
    ), reactions: { __typename?: 'AnnouncementReactionNodeConnection', edges: Array<{ __typename?: 'AnnouncementReactionNodeEdge', node: { __typename?: 'AnnouncementReactionNode', id: any, text: string, position: number } }> }, interactions?: Maybe<{ __typename?: 'AnnouncementUserInteractionNodeConnection', edges: Array<{ __typename?: 'AnnouncementUserInteractionNodeEdge', node: { __typename?: 'AnnouncementUserInteractionNode', id: any, readAt?: Maybe<any>, archivedAt?: Maybe<any>, reaction?: Maybe<{ __typename?: 'AnnouncementReactionNode', id: any }>, sms?: Maybe<{ __typename?: 'SmsNode', id: any, createdAt: any }>, user: { __typename?: 'UserNode', id: any, firstName: string, lastName: string, isActive: boolean }, lastReactionLog?: Maybe<{ __typename?: 'AnnouncementLogNode', id: any, createdAt: any, valueNew: string, valueOld: string }> } }> }>, myInteraction?: Maybe<(
      { __typename?: 'AnnouncementUserInteractionNode' }
      & MyAnnouncementInteractionFragment
    )> }
    & AnnouncementFragment
  ) };

export type AnnouncementLogsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  before: Maybe<Scalars['String']>;
}>;


export type AnnouncementLogsQuery = { __typename?: 'Query', announcement: { __typename?: 'AnnouncementNode', id: any, logs: { __typename?: 'AnnouncementLogNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'AnnouncementLogNodeEdge', cursor: string, node: (
          { __typename?: 'AnnouncementLogNode' }
          & AnnouncementLogFragment
        ) }> } } };

export type BenefitsQueryVariables = Exact<{ [key: string]: never; }>;


export type BenefitsQuery = { __typename?: 'Query', me: { __typename?: 'MeNode', id: any, organizations: { __typename?: 'OrganizationNodeConnection', edges: Array<{ __typename?: 'OrganizationNodeEdge', node: { __typename?: 'OrganizationNode', id: any, benefits: Array<{ __typename?: 'BenefitNode', id: any, url?: Maybe<string>, name: string, text: string, image?: Maybe<string>, createdAt: any }> } }> } } };

export type ChatQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type ChatQuery = { __typename?: 'Query', chat: { __typename?: 'ChatNode', id: any, name?: Maybe<string>, createdAt: any, metaMarkAsRead: boolean, createdBy: { __typename?: 'UserNode', id: any }, interactions: { __typename?: 'ChatUserInteractionNodeConnection', edges: Array<{ __typename?: 'ChatUserInteractionNodeEdge', node: (
          { __typename?: 'ChatUserInteractionNode' }
          & ChatInteractionFragment
        ) }> }, myInteraction: (
      { __typename?: 'ChatUserInteractionNode' }
      & ChatMyInteractionFragment
    ), organization: { __typename?: 'OrganizationNode', id: any, unresolvedChatCount: number }, messages: { __typename?: 'ChatMessageNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'ChatMessageNodeEdge', cursor: string, node: (
          { __typename?: 'ChatMessageNode' }
          & ChatMessageFragment
        ) }> } } };

export type ChatActivityQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type ChatActivityQuery = { __typename?: 'Query', chat: { __typename?: 'ChatNode', id: any, name?: Maybe<string>, lastMessageAt?: Maybe<any>, interactions: { __typename?: 'ChatUserInteractionNodeConnection', edges: Array<{ __typename?: 'ChatUserInteractionNodeEdge', cursor: string, node: (
          { __typename?: 'ChatUserInteractionNode' }
          & ChatInteractionFragment
        ) }> } } };

export type ChatGroupSettingsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type ChatGroupSettingsQuery = { __typename?: 'Query', chat: { __typename?: 'ChatNode', id: any, name?: Maybe<string>, createdBy: { __typename?: 'UserNode', id: any }, interactions: { __typename?: 'ChatUserInteractionNodeConnection', edges: Array<{ __typename?: 'ChatUserInteractionNodeEdge', cursor: string, node: (
          { __typename?: 'ChatUserInteractionNode' }
          & ChatInteractionFragment
        ) }> } } };

export type ChatNewMessagesQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  after: Maybe<Scalars['String']>;
}>;


export type ChatNewMessagesQuery = { __typename?: 'Query', chat: { __typename?: 'ChatNode', id: any, myInteraction: (
      { __typename?: 'ChatUserInteractionNode' }
      & ChatMyInteractionFragment
    ), messages: { __typename?: 'ChatMessageNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'ChatMessageNodeEdge', cursor: string, node: (
          { __typename?: 'ChatMessageNode' }
          & ChatMessageFragment
        ) }> } } };

export type ChatOrganizationChatsQueryVariables = Exact<{
  organization: Scalars['GlobalID'];
}>;


export type ChatOrganizationChatsQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, chats: { __typename?: 'ChatNodeConnection', edges: Array<{ __typename?: 'ChatNodeEdge', cursor: string, node: (
          { __typename?: 'ChatNode' }
          & OrganizationChatFragment
        ) }> } } };

export type ChatOrganizationUsersQueryVariables = Exact<{
  organization: Scalars['GlobalID'];
  query: Scalars['String'];
  first: Maybe<Scalars['Int']>;
}>;


export type ChatOrganizationUsersQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, users: { __typename?: 'UserNodeConnection', edges: Array<{ __typename?: 'UserNodeEdge', node: { __typename?: 'UserNode', id: any, isActive: boolean, lastName: string, firstName: string, profileImage: string, memberships: { __typename?: 'MembershipNodeConnection', totalCount?: Maybe<number>, edges: Array<{ __typename?: 'MembershipNodeEdge', node: { __typename?: 'MembershipNode', id: any, role: Roles, position?: Maybe<string>, space: { __typename?: 'SpaceNode', id: any, name: string } } }> } } }> } } };

export type ChatPreviousMessagesQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  before: Maybe<Scalars['String']>;
}>;


export type ChatPreviousMessagesQuery = { __typename?: 'Query', chat: { __typename?: 'ChatNode', id: any, myInteraction: (
      { __typename?: 'ChatUserInteractionNode' }
      & ChatMyInteractionFragment
    ), messages: { __typename?: 'ChatMessageNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'ChatMessageNodeEdge', cursor: string, node: (
          { __typename?: 'ChatMessageNode' }
          & ChatMessageFragment
        ) }> } } };

export type DocumentQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type DocumentQuery = { __typename?: 'Query', document: (
    { __typename?: 'DocumentNode', space: { __typename?: 'SpaceNode', id: any, name: string }, author: (
      { __typename?: 'UserNode' }
      & DocumentAuthorFragment
    ), authorMembership: (
      { __typename?: 'MembershipNode' }
      & AuthorMembershipFragment
    ), interactions: { __typename?: 'DocumentUserInteractionNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'DocumentUserInteractionNodeEdge', cursor: string, node: { __typename?: 'DocumentUserInteractionNode', id: any, user: { __typename?: 'UserNode', id: any, isActive: boolean, firstName: string, lastName: string, profileImage: string } } }> } }
    & DocumentFragment
  ) };

export type LoadOlderStoryCommentsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  before: Maybe<Scalars['String']>;
}>;


export type LoadOlderStoryCommentsQuery = { __typename?: 'Query', story: { __typename?: 'StoryNode', id: any, comments: { __typename?: 'StoryCommentNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'StoryCommentNodeEdge', cursor: string, node: (
          { __typename?: 'StoryCommentNode' }
          & StoryCommentFragment
        ) }> } } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: (
    { __typename?: 'MeNode' }
    & MeFragment
  ) };

export type MeValidationQueryVariables = Exact<{ [key: string]: never; }>;


export type MeValidationQuery = { __typename?: 'Query', me: (
    { __typename?: 'MeNode' }
    & MeValidationFragment
  ) };

export type RecipientSelectionQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type RecipientSelectionQuery = { __typename?: 'Query', recipientSelection: (
    { __typename?: 'RecipientSelectionNode' }
    & RecipientSelectionFragment
  ) };

export type SpaceReceivedAnnouncementsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type SpaceReceivedAnnouncementsQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, receivedAnnouncements: { __typename?: 'AnnouncementNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'AnnouncementNodeEdge', cursor: string, node: { __typename?: 'AnnouncementNode', id: any, title: string, createdAt: any, deletedAt?: Maybe<any>, imageThumbnail?: Maybe<string>, reactionDeadlineAt?: Maybe<any>, author: (
            { __typename?: 'UserNode' }
            & AnnouncementAuthorFragment
          ), reactions: { __typename?: 'AnnouncementReactionNodeConnection', pageInfo: (
              { __typename?: 'PageInfo' }
              & PageInfoFragment
            ) }, myInteraction?: Maybe<(
            { __typename?: 'AnnouncementUserInteractionNode' }
            & MyAnnouncementInteractionFragment
          )> } }> } } };

export type SpaceReceivedDocumentsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type SpaceReceivedDocumentsQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, receivedDocuments: { __typename?: 'DocumentNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'DocumentNodeEdge', cursor: string, node: (
          { __typename?: 'DocumentNode', myInteraction: (
            { __typename?: 'DocumentUserInteractionNode' }
            & MyDocumentInteractionFragment
          ) }
          & ListedDocumentFragment
        ) }> } } };

export type SpaceSentAnnouncementsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type SpaceSentAnnouncementsQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, sentAnnouncements: { __typename?: 'AnnouncementNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'AnnouncementNodeEdge', cursor: string, node: { __typename?: 'AnnouncementNode', id: any, title: string, createdAt: any, deletedAt?: Maybe<any>, imageThumbnail?: Maybe<string>, recipientsTotalCount?: Maybe<number>, recipientsSeenCount?: Maybe<number>, recipientsReactedCount?: Maybe<number> } }> } } };

export type SpaceSentDocumentsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type SpaceSentDocumentsQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, sentDocuments: { __typename?: 'DocumentNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'DocumentNodeEdge', cursor: string, node: { __typename?: 'DocumentNode', id: any, title: string, createdAt: any, updatedAt?: Maybe<any>, author: { __typename?: 'UserNode', id: any } } }> } } };

export type SpaceStoriesQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  cursor: Maybe<Scalars['String']>;
}>;


export type SpaceStoriesQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, name: string, stories: { __typename?: 'StoryNodeConnection', pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ), edges: Array<{ __typename?: 'StoryNodeEdge', cursor: string, node: (
          { __typename?: 'StoryNode' }
          & StoryFragment
        ) }> } } };

export type SponsorsQueryVariables = Exact<{ [key: string]: never; }>;


export type SponsorsQuery = { __typename?: 'Query', me: { __typename?: 'MeNode', id: any, organizations: { __typename?: 'OrganizationNodeConnection', edges: Array<{ __typename?: 'OrganizationNodeEdge', node: { __typename?: 'OrganizationNode', id: any, sponsorType: SponsorTypes, sponsors: Array<{ __typename?: 'SponsorNode', id: any, name: string, logo?: Maybe<string>, image?: Maybe<string> }> } }> } } };

export type StoryQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type StoryQuery = { __typename?: 'Query', story: (
    { __typename?: 'StoryNode' }
    & StoryFragment
  ) };

export type UnresolvedQueryVariables = Exact<{ [key: string]: never; }>;


export type UnresolvedQuery = { __typename?: 'Query', me: { __typename?: 'MeNode', id: any, organizations: { __typename?: 'OrganizationNodeConnection', edges: Array<{ __typename?: 'OrganizationNodeEdge', node: { __typename?: 'OrganizationNode', id: any, unresolvedChatCount: number, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: { __typename?: 'SpaceNode', id: any, unresolvedDocumentCount: number, unresolvedAnnouncementCount: number } }> } } }> } } };

export type UserForActivationQueryVariables = Exact<{
  activationCode: Scalars['String'];
}>;


export type UserForActivationQuery = { __typename?: 'Query', userForActivation?: Maybe<{ __typename?: 'InactiveUserNode', id: any, email: string, lastName: string, firstName: string, phoneNumber?: Maybe<string>, profileImage: string }> };

export type AnnouncementChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AnnouncementChangeSubscription = { __typename?: 'Subscription', onAnnouncementChange: { __typename?: 'OnAnnouncementChangeMessage', event: string, announcement: { __typename?: 'AnnouncementNode', id: any, reactionDeadlineAt?: Maybe<any>, recipientsSeenCount?: Maybe<number>, recipientsTotalCount?: Maybe<number>, recipientsReactedCount?: Maybe<number> } } };

export type ChatChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatChangeSubscription = { __typename?: 'Subscription', onChatChange: { __typename?: 'OnChatChangeMessage', event: string, chat: { __typename?: 'ChatNode', id: any, myInteraction: { __typename?: 'ChatUserInteractionNode', id: any, muted: boolean }, organization: { __typename?: 'OrganizationNode', id: any, unresolvedChatCount: number }, messages: { __typename?: 'ChatMessageNodeConnection', edges: Array<{ __typename?: 'ChatMessageNodeEdge', node: { __typename?: 'ChatMessageNode', id: any, message: string, createdAt: any, author: { __typename?: 'UserNode', id: any, lastName: string, firstName: string, profileImage: string } } }> } } } };

export type SpaceAnnouncementsEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SpaceAnnouncementsEventSubscription = { __typename?: 'Subscription', onSpaceAnnouncementsEvent: { __typename?: 'OnSpaceAnnouncementChangeMessage', event: string, space: { __typename?: 'SpaceNode', id: any } } };

export type SpaceDocumentsEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SpaceDocumentsEventSubscription = { __typename?: 'Subscription', onSpaceDocumentsEvent: { __typename?: 'OnSpaceDocumentChangeMessage', event: string, space: { __typename?: 'SpaceNode', id: any } } };

export const AnnouncementFragmentDoc = gql`
    fragment Announcement on AnnouncementNode {
  id
  title
  text
  videoUrl
  imageOriginal
  imageThumbnail
  urgedAt
  urgeCount
  createdAt
  lastChangesSeenAt
  reactionDeadlineAt
}
    `;
export const AnnouncementAuthorFragmentDoc = gql`
    fragment AnnouncementAuthor on UserNode {
  id
  firstName
  lastName
  profileImage
}
    `;
export const AnnouncementLogFragmentDoc = gql`
    fragment AnnouncementLog on AnnouncementLogNode {
  id
  type
  valueOld
  valueNew
  createdAt
  user {
    id
    isActive
    firstName
    lastName
    profileImage
  }
}
    `;
export const ChatInteractionFragmentDoc = gql`
    fragment ChatInteraction on ChatUserInteractionNode {
  id
  acceptedAt
  archivedAt
  lastOpenedAt
  user {
    id
    email
    isActive
    lastName
    firstName
    profileImage
  }
}
    `;
export const ChatMessageFragmentDoc = gql`
    fragment ChatMessage on ChatMessageNode {
  id
  message
  createdAt
  author {
    id
    email
    firstName
    profileImage
  }
}
    `;
export const ChatMyInteractionFragmentDoc = gql`
    fragment ChatMyInteraction on ChatUserInteractionNode {
  id
  muted
  acceptedAt
  lastOpenedAt
  createdBy {
    id
    firstName
    profileImage
  }
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on DocumentNode {
  id
  file
  size
  type
  title
  createdAt
  updatedAt
}
    `;
export const DocumentAuthorFragmentDoc = gql`
    fragment DocumentAuthor on UserNode {
  id
  firstName
  lastName
  profileImage
}
    `;
export const ListedDocumentFragmentDoc = gql`
    fragment ListedDocument on DocumentNode {
  id
  title
  createdAt
  updatedAt
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on MeNode {
  id
  email
  userId
  isActive
  firstName
  lastName
  profileImage
  language
  organizations {
    edges {
      node {
        id
        name
        logo
        hasBenefits
        unresolvedChatCount
        spaces {
          edges {
            node {
              id
              name
              logo
              state
              unresolvedDocumentCount
              unresolvedAnnouncementCount
              myMembership {
                id
                role
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const MeValidationFragmentDoc = gql`
    fragment MeValidation on MeNode {
  id
  organizations {
    edges {
      node {
        id
        spaces {
          edges {
            node {
              id
              state
            }
          }
        }
      }
    }
  }
}
    `;
export const MyAnnouncementInteractionFragmentDoc = gql`
    fragment MyAnnouncementInteraction on AnnouncementUserInteractionNode {
  id
  readAt
  reactedAt
  archivedAt
  marked
  reaction {
    id
  }
}
    `;
export const MyDocumentInteractionFragmentDoc = gql`
    fragment MyDocumentInteraction on DocumentUserInteractionNode {
  id
  readAt
}
    `;
export const OrganizationChatFragmentDoc = gql`
    fragment OrganizationChat on ChatNode {
  id
  name
  createdAt
  lastMessageAt
  createdBy {
    id
    firstName
    profileImage
  }
  myInteraction {
    id
    createdAt
    acceptedAt
    archivedAt
    lastOpenedAt
    createdBy {
      id
      firstName
      profileImage
    }
  }
  interactions {
    edges {
      cursor
      node {
        id
        createdAt
        acceptedAt
        archivedAt
        lastOpenedAt
        user {
          id
          email
          isActive
          lastName
          firstName
          profileImage
        }
      }
    }
  }
  messages(last: 1) {
    edges {
      cursor
      node {
        id
        message
        createdAt
        author {
          id
          profileImage
        }
      }
    }
  }
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const RecipientFragmentDoc = gql`
    fragment Recipient on RecipientSelectionUserNode {
  selected
  membership {
    id
    role
    position
  }
  user {
    id
    isActive
    firstName
    lastName
    profileImage
  }
}
    `;
export const RecipientSelectionContactGroupFragmentDoc = gql`
    fragment RecipientSelectionContactGroup on RecipientSelectionContactGroupNode {
  totalCount
  selectedCount
  contactGroup {
    id
    name
    private
  }
}
    `;
export const RecipientSelectionFragmentDoc = gql`
    fragment RecipientSelection on RecipientSelectionNode {
  id
  selectedCount
  users {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...Recipient
      }
    }
  }
  contactGroups {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...RecipientSelectionContactGroup
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${RecipientFragmentDoc}
${RecipientSelectionContactGroupFragmentDoc}`;
export const StoryAuthorFragmentDoc = gql`
    fragment StoryAuthor on UserNode {
  id
  firstName
  lastName
  profileImage
}
    `;
export const AuthorMembershipFragmentDoc = gql`
    fragment AuthorMembership on MembershipNode {
  id
  role
  position
}
    `;
export const CommentAuthorFragmentDoc = gql`
    fragment CommentAuthor on UserNode {
  id
  firstName
  lastName
  profileImage
}
    `;
export const StoryCommentFragmentDoc = gql`
    fragment StoryComment on StoryCommentNode {
  id
  text
  createdAt
  author {
    ...CommentAuthor
  }
}
    ${CommentAuthorFragmentDoc}`;
export const StoryFragmentDoc = gql`
    fragment Story on StoryNode {
  id
  text
  createdAt
  headerOriginal
  headerThumbnail
  claps
  myClapCount
  commentCount
  commentedOnByUser
  author {
    ...StoryAuthor
  }
  authorMembership {
    ...AuthorMembership
  }
  comments(last: 2) {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...StoryComment
      }
    }
  }
}
    ${StoryAuthorFragmentDoc}
${AuthorMembershipFragmentDoc}
${PageInfoFragmentDoc}
${StoryCommentFragmentDoc}`;
export const ActivateAccountDocument = gql`
    mutation activateAccount($input: ActivateAccountInput!) {
  activateAccount(input: $input) {
    user {
      id
    }
  }
}
    `;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(ActivateAccountDocument, baseOptions);
      }
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = ApolloReactCommon.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateAccountMutation, ActivateAccountMutationVariables>;
export const AnnouncementCreateDocument = gql`
    mutation announcementCreate($input: AnnouncementCreateInput!) {
  announcementCreate(input: $input) {
    announcement {
      id
    }
  }
}
    `;

/**
 * __useAnnouncementCreateMutation__
 *
 * To run a mutation, you first call `useAnnouncementCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementCreateMutation, { data, loading, error }] = useAnnouncementCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnouncementCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnnouncementCreateMutation, AnnouncementCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<AnnouncementCreateMutation, AnnouncementCreateMutationVariables>(AnnouncementCreateDocument, baseOptions);
      }
export type AnnouncementCreateMutationHookResult = ReturnType<typeof useAnnouncementCreateMutation>;
export type AnnouncementCreateMutationResult = ApolloReactCommon.MutationResult<AnnouncementCreateMutation>;
export type AnnouncementCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<AnnouncementCreateMutation, AnnouncementCreateMutationVariables>;
export const AnnouncementDeleteDocument = gql`
    mutation announcementDelete($input: AnnouncementInput!) {
  announcementDelete(input: $input) {
    space {
      id
    }
  }
}
    `;

/**
 * __useAnnouncementDeleteMutation__
 *
 * To run a mutation, you first call `useAnnouncementDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementDeleteMutation, { data, loading, error }] = useAnnouncementDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnouncementDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnnouncementDeleteMutation, AnnouncementDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AnnouncementDeleteMutation, AnnouncementDeleteMutationVariables>(AnnouncementDeleteDocument, baseOptions);
      }
export type AnnouncementDeleteMutationHookResult = ReturnType<typeof useAnnouncementDeleteMutation>;
export type AnnouncementDeleteMutationResult = ApolloReactCommon.MutationResult<AnnouncementDeleteMutation>;
export type AnnouncementDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AnnouncementDeleteMutation, AnnouncementDeleteMutationVariables>;
export const AnnouncementInteractDocument = gql`
    mutation announcementInteract($input: AnnouncementInteractInput!) {
  announcementInteract(input: $input) {
    interaction {
      ...MyAnnouncementInteraction
      announcement {
        id
        recipientsTotalCount
        recipientsSeenCount
        recipientsReactedCount
      }
    }
  }
}
    ${MyAnnouncementInteractionFragmentDoc}`;

/**
 * __useAnnouncementInteractMutation__
 *
 * To run a mutation, you first call `useAnnouncementInteractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementInteractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementInteractMutation, { data, loading, error }] = useAnnouncementInteractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnouncementInteractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>) {
        return ApolloReactHooks.useMutation<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>(AnnouncementInteractDocument, baseOptions);
      }
export type AnnouncementInteractMutationHookResult = ReturnType<typeof useAnnouncementInteractMutation>;
export type AnnouncementInteractMutationResult = ApolloReactCommon.MutationResult<AnnouncementInteractMutation>;
export type AnnouncementInteractMutationOptions = ApolloReactCommon.BaseMutationOptions<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>;
export const AnnouncementReactionsChangesSeenDocument = gql`
    mutation announcementReactionsChangesSeen($input: AnnouncementInput!) {
  announcementReactionsChangesSeen(input: $input) {
    announcement {
      id
    }
  }
}
    `;

/**
 * __useAnnouncementReactionsChangesSeenMutation__
 *
 * To run a mutation, you first call `useAnnouncementReactionsChangesSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementReactionsChangesSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementReactionsChangesSeenMutation, { data, loading, error }] = useAnnouncementReactionsChangesSeenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnouncementReactionsChangesSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnnouncementReactionsChangesSeenMutation, AnnouncementReactionsChangesSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<AnnouncementReactionsChangesSeenMutation, AnnouncementReactionsChangesSeenMutationVariables>(AnnouncementReactionsChangesSeenDocument, baseOptions);
      }
export type AnnouncementReactionsChangesSeenMutationHookResult = ReturnType<typeof useAnnouncementReactionsChangesSeenMutation>;
export type AnnouncementReactionsChangesSeenMutationResult = ApolloReactCommon.MutationResult<AnnouncementReactionsChangesSeenMutation>;
export type AnnouncementReactionsChangesSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<AnnouncementReactionsChangesSeenMutation, AnnouncementReactionsChangesSeenMutationVariables>;
export const AnnouncementSetReactionDeadlineDocument = gql`
    mutation announcementSetReactionDeadline($input: AnnouncementSetReactionDeadlineInput!) {
  announcementSetReactionDeadline(input: $input) {
    announcement {
      id
    }
  }
}
    `;

/**
 * __useAnnouncementSetReactionDeadlineMutation__
 *
 * To run a mutation, you first call `useAnnouncementSetReactionDeadlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementSetReactionDeadlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementSetReactionDeadlineMutation, { data, loading, error }] = useAnnouncementSetReactionDeadlineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnouncementSetReactionDeadlineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnnouncementSetReactionDeadlineMutation, AnnouncementSetReactionDeadlineMutationVariables>) {
        return ApolloReactHooks.useMutation<AnnouncementSetReactionDeadlineMutation, AnnouncementSetReactionDeadlineMutationVariables>(AnnouncementSetReactionDeadlineDocument, baseOptions);
      }
export type AnnouncementSetReactionDeadlineMutationHookResult = ReturnType<typeof useAnnouncementSetReactionDeadlineMutation>;
export type AnnouncementSetReactionDeadlineMutationResult = ApolloReactCommon.MutationResult<AnnouncementSetReactionDeadlineMutation>;
export type AnnouncementSetReactionDeadlineMutationOptions = ApolloReactCommon.BaseMutationOptions<AnnouncementSetReactionDeadlineMutation, AnnouncementSetReactionDeadlineMutationVariables>;
export const AnnouncementUrgeDocument = gql`
    mutation announcementUrge($input: AnnouncementInput!) {
  announcementUrge(input: $input) {
    announcement {
      id
    }
  }
}
    `;

/**
 * __useAnnouncementUrgeMutation__
 *
 * To run a mutation, you first call `useAnnouncementUrgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementUrgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementUrgeMutation, { data, loading, error }] = useAnnouncementUrgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnouncementUrgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnnouncementUrgeMutation, AnnouncementUrgeMutationVariables>) {
        return ApolloReactHooks.useMutation<AnnouncementUrgeMutation, AnnouncementUrgeMutationVariables>(AnnouncementUrgeDocument, baseOptions);
      }
export type AnnouncementUrgeMutationHookResult = ReturnType<typeof useAnnouncementUrgeMutation>;
export type AnnouncementUrgeMutationResult = ApolloReactCommon.MutationResult<AnnouncementUrgeMutation>;
export type AnnouncementUrgeMutationOptions = ApolloReactCommon.BaseMutationOptions<AnnouncementUrgeMutation, AnnouncementUrgeMutationVariables>;
export const ChangeLanguageDocument = gql`
    mutation changeLanguage($input: ChangeProfileInput!) {
  changeProfile(input: $input) {
    me {
      id
      language
    }
  }
}
    `;

/**
 * __useChangeLanguageMutation__
 *
 * To run a mutation, you first call `useChangeLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLanguageMutation, { data, loading, error }] = useChangeLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeLanguageMutation, ChangeLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeLanguageMutation, ChangeLanguageMutationVariables>(ChangeLanguageDocument, baseOptions);
      }
export type ChangeLanguageMutationHookResult = ReturnType<typeof useChangeLanguageMutation>;
export type ChangeLanguageMutationResult = ApolloReactCommon.MutationResult<ChangeLanguageMutation>;
export type ChangeLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeLanguageMutation, ChangeLanguageMutationVariables>;
export const ChangeProfileDocument = gql`
    mutation changeProfile($input: ChangeProfileInput!) {
  changeProfile(input: $input) {
    me {
      id
    }
  }
}
    `;

/**
 * __useChangeProfileMutation__
 *
 * To run a mutation, you first call `useChangeProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProfileMutation, { data, loading, error }] = useChangeProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeProfileMutation, ChangeProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeProfileMutation, ChangeProfileMutationVariables>(ChangeProfileDocument, baseOptions);
      }
export type ChangeProfileMutationHookResult = ReturnType<typeof useChangeProfileMutation>;
export type ChangeProfileMutationResult = ApolloReactCommon.MutationResult<ChangeProfileMutation>;
export type ChangeProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeProfileMutation, ChangeProfileMutationVariables>;
export const ChatAcceptInvitationDocument = gql`
    mutation chatAcceptInvitation($input: ChatInput!) {
  chatAcceptInvitation(input: $input) {
    chat {
      id
      myInteraction {
        ...ChatMyInteraction
      }
    }
  }
}
    ${ChatMyInteractionFragmentDoc}`;

/**
 * __useChatAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useChatAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatAcceptInvitationMutation, { data, loading, error }] = useChatAcceptInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatAcceptInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatAcceptInvitationMutation, ChatAcceptInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatAcceptInvitationMutation, ChatAcceptInvitationMutationVariables>(ChatAcceptInvitationDocument, baseOptions);
      }
export type ChatAcceptInvitationMutationHookResult = ReturnType<typeof useChatAcceptInvitationMutation>;
export type ChatAcceptInvitationMutationResult = ApolloReactCommon.MutationResult<ChatAcceptInvitationMutation>;
export type ChatAcceptInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatAcceptInvitationMutation, ChatAcceptInvitationMutationVariables>;
export const ChatAddUsersDocument = gql`
    mutation chatAddUsers($input: ChatAddUsersInput!) {
  chatAddUsers(input: $input) {
    chat {
      ...OrganizationChat
    }
  }
}
    ${OrganizationChatFragmentDoc}`;

/**
 * __useChatAddUsersMutation__
 *
 * To run a mutation, you first call `useChatAddUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatAddUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatAddUsersMutation, { data, loading, error }] = useChatAddUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatAddUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatAddUsersMutation, ChatAddUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatAddUsersMutation, ChatAddUsersMutationVariables>(ChatAddUsersDocument, baseOptions);
      }
export type ChatAddUsersMutationHookResult = ReturnType<typeof useChatAddUsersMutation>;
export type ChatAddUsersMutationResult = ApolloReactCommon.MutationResult<ChatAddUsersMutation>;
export type ChatAddUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatAddUsersMutation, ChatAddUsersMutationVariables>;
export const ChatCreateDocument = gql`
    mutation chatCreate($input: ChatCreateInput!) {
  chatCreate(input: $input) {
    chat {
      id
    }
  }
}
    `;

/**
 * __useChatCreateMutation__
 *
 * To run a mutation, you first call `useChatCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatCreateMutation, { data, loading, error }] = useChatCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatCreateMutation, ChatCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatCreateMutation, ChatCreateMutationVariables>(ChatCreateDocument, baseOptions);
      }
export type ChatCreateMutationHookResult = ReturnType<typeof useChatCreateMutation>;
export type ChatCreateMutationResult = ApolloReactCommon.MutationResult<ChatCreateMutation>;
export type ChatCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatCreateMutation, ChatCreateMutationVariables>;
export const ChatLeaveDocument = gql`
    mutation chatLeave($input: ChatLeaveInput!) {
  chatLeave(input: $input) {
    organization {
      id
      chats {
        edges {
          node {
            ...OrganizationChat
          }
        }
      }
    }
  }
}
    ${OrganizationChatFragmentDoc}`;

/**
 * __useChatLeaveMutation__
 *
 * To run a mutation, you first call `useChatLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatLeaveMutation, { data, loading, error }] = useChatLeaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatLeaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatLeaveMutation, ChatLeaveMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatLeaveMutation, ChatLeaveMutationVariables>(ChatLeaveDocument, baseOptions);
      }
export type ChatLeaveMutationHookResult = ReturnType<typeof useChatLeaveMutation>;
export type ChatLeaveMutationResult = ApolloReactCommon.MutationResult<ChatLeaveMutation>;
export type ChatLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatLeaveMutation, ChatLeaveMutationVariables>;
export const ChatRemoveUsersDocument = gql`
    mutation chatRemoveUsers($input: ChatRemoveUsersInput!) {
  chatRemoveUsers(input: $input) {
    chat {
      ...OrganizationChat
    }
  }
}
    ${OrganizationChatFragmentDoc}`;

/**
 * __useChatRemoveUsersMutation__
 *
 * To run a mutation, you first call `useChatRemoveUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatRemoveUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatRemoveUsersMutation, { data, loading, error }] = useChatRemoveUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatRemoveUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatRemoveUsersMutation, ChatRemoveUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatRemoveUsersMutation, ChatRemoveUsersMutationVariables>(ChatRemoveUsersDocument, baseOptions);
      }
export type ChatRemoveUsersMutationHookResult = ReturnType<typeof useChatRemoveUsersMutation>;
export type ChatRemoveUsersMutationResult = ApolloReactCommon.MutationResult<ChatRemoveUsersMutation>;
export type ChatRemoveUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatRemoveUsersMutation, ChatRemoveUsersMutationVariables>;
export const ChatSendMessageDocument = gql`
    mutation chatSendMessage($input: ChatSendMessageInput!) {
  chatSendMessage(input: $input) {
    message {
      id
    }
  }
}
    `;

/**
 * __useChatSendMessageMutation__
 *
 * To run a mutation, you first call `useChatSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatSendMessageMutation, { data, loading, error }] = useChatSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSendMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatSendMessageMutation, ChatSendMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatSendMessageMutation, ChatSendMessageMutationVariables>(ChatSendMessageDocument, baseOptions);
      }
export type ChatSendMessageMutationHookResult = ReturnType<typeof useChatSendMessageMutation>;
export type ChatSendMessageMutationResult = ApolloReactCommon.MutationResult<ChatSendMessageMutation>;
export type ChatSendMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatSendMessageMutation, ChatSendMessageMutationVariables>;
export const ChatSetMutedDocument = gql`
    mutation chatSetMuted($input: ChatSetMutedInput!) {
  chatSetMuted(input: $input) {
    chat {
      id
      myInteraction {
        ...ChatMyInteraction
      }
    }
  }
}
    ${ChatMyInteractionFragmentDoc}`;

/**
 * __useChatSetMutedMutation__
 *
 * To run a mutation, you first call `useChatSetMutedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatSetMutedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatSetMutedMutation, { data, loading, error }] = useChatSetMutedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSetMutedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatSetMutedMutation, ChatSetMutedMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatSetMutedMutation, ChatSetMutedMutationVariables>(ChatSetMutedDocument, baseOptions);
      }
export type ChatSetMutedMutationHookResult = ReturnType<typeof useChatSetMutedMutation>;
export type ChatSetMutedMutationResult = ApolloReactCommon.MutationResult<ChatSetMutedMutation>;
export type ChatSetMutedMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatSetMutedMutation, ChatSetMutedMutationVariables>;
export const ChatSetNameDocument = gql`
    mutation chatSetName($input: ChatSetNameInput!) {
  chatSetName(input: $input) {
    chat {
      ...OrganizationChat
    }
  }
}
    ${OrganizationChatFragmentDoc}`;

/**
 * __useChatSetNameMutation__
 *
 * To run a mutation, you first call `useChatSetNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatSetNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatSetNameMutation, { data, loading, error }] = useChatSetNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSetNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChatSetNameMutation, ChatSetNameMutationVariables>) {
        return ApolloReactHooks.useMutation<ChatSetNameMutation, ChatSetNameMutationVariables>(ChatSetNameDocument, baseOptions);
      }
export type ChatSetNameMutationHookResult = ReturnType<typeof useChatSetNameMutation>;
export type ChatSetNameMutationResult = ApolloReactCommon.MutationResult<ChatSetNameMutation>;
export type ChatSetNameMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatSetNameMutation, ChatSetNameMutationVariables>;
export const ConfirmPasswordResetDocument = gql`
    mutation confirmPasswordReset($input: ConfirmPasswordResetInput!) {
  confirmPasswordReset(input: $input)
}
    `;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>(ConfirmPasswordResetDocument, baseOptions);
      }
export type ConfirmPasswordResetMutationHookResult = ReturnType<typeof useConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationResult = ApolloReactCommon.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>;
export const DocumentCreateDocument = gql`
    mutation documentCreate($input: DocumentCreateInput!) {
  documentCreate(input: $input) {
    document {
      id
    }
  }
}
    `;

/**
 * __useDocumentCreateMutation__
 *
 * To run a mutation, you first call `useDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentCreateMutation, { data, loading, error }] = useDocumentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentCreateMutation, DocumentCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentCreateMutation, DocumentCreateMutationVariables>(DocumentCreateDocument, baseOptions);
      }
export type DocumentCreateMutationHookResult = ReturnType<typeof useDocumentCreateMutation>;
export type DocumentCreateMutationResult = ApolloReactCommon.MutationResult<DocumentCreateMutation>;
export type DocumentCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentCreateMutation, DocumentCreateMutationVariables>;
export const DocumentDeleteDocument = gql`
    mutation documentDelete($input: DocumentInput!) {
  documentDelete(input: $input) {
    space {
      id
    }
  }
}
    `;

/**
 * __useDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentDeleteMutation, { data, loading, error }] = useDocumentDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentDeleteMutation, DocumentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentDeleteMutation, DocumentDeleteMutationVariables>(DocumentDeleteDocument, baseOptions);
      }
export type DocumentDeleteMutationHookResult = ReturnType<typeof useDocumentDeleteMutation>;
export type DocumentDeleteMutationResult = ApolloReactCommon.MutationResult<DocumentDeleteMutation>;
export type DocumentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentDeleteMutation, DocumentDeleteMutationVariables>;
export const DocumentMarkAsReadDocument = gql`
    mutation documentMarkAsRead($input: DocumentInput!) {
  documentMarkAsRead(input: $input) {
    interaction {
      ...MyDocumentInteraction
    }
  }
}
    ${MyDocumentInteractionFragmentDoc}`;

/**
 * __useDocumentMarkAsReadMutation__
 *
 * To run a mutation, you first call `useDocumentMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentMarkAsReadMutation, { data, loading, error }] = useDocumentMarkAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentMarkAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentMarkAsReadMutation, DocumentMarkAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentMarkAsReadMutation, DocumentMarkAsReadMutationVariables>(DocumentMarkAsReadDocument, baseOptions);
      }
export type DocumentMarkAsReadMutationHookResult = ReturnType<typeof useDocumentMarkAsReadMutation>;
export type DocumentMarkAsReadMutationResult = ApolloReactCommon.MutationResult<DocumentMarkAsReadMutation>;
export type DocumentMarkAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentMarkAsReadMutation, DocumentMarkAsReadMutationVariables>;
export const DocumentUpdateDocument = gql`
    mutation documentUpdate($input: DocumentUpdateInput!) {
  documentUpdate(input: $input) {
    document {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentUpdateMutation, { data, loading, error }] = useDocumentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentUpdateMutation, DocumentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentUpdateMutation, DocumentUpdateMutationVariables>(DocumentUpdateDocument, baseOptions);
      }
export type DocumentUpdateMutationHookResult = ReturnType<typeof useDocumentUpdateMutation>;
export type DocumentUpdateMutationResult = ApolloReactCommon.MutationResult<DocumentUpdateMutation>;
export type DocumentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentUpdateMutation, DocumentUpdateMutationVariables>;
export const FeedbackCreateDocument = gql`
    mutation feedbackCreate($input: FeedbackInput!) {
  feedbackCreate(input: $input)
}
    `;

/**
 * __useFeedbackCreateMutation__
 *
 * To run a mutation, you first call `useFeedbackCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedbackCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedbackCreateMutation, { data, loading, error }] = useFeedbackCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFeedbackCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FeedbackCreateMutation, FeedbackCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<FeedbackCreateMutation, FeedbackCreateMutationVariables>(FeedbackCreateDocument, baseOptions);
      }
export type FeedbackCreateMutationHookResult = ReturnType<typeof useFeedbackCreateMutation>;
export type FeedbackCreateMutationResult = ApolloReactCommon.MutationResult<FeedbackCreateMutation>;
export type FeedbackCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<FeedbackCreateMutation, FeedbackCreateMutationVariables>;
export const LogBenefitClickDocument = gql`
    mutation logBenefitClick($input: LogBenefitClickInput!) {
  logBenefitClick(input: $input)
}
    `;

/**
 * __useLogBenefitClickMutation__
 *
 * To run a mutation, you first call `useLogBenefitClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogBenefitClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logBenefitClickMutation, { data, loading, error }] = useLogBenefitClickMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogBenefitClickMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogBenefitClickMutation, LogBenefitClickMutationVariables>) {
        return ApolloReactHooks.useMutation<LogBenefitClickMutation, LogBenefitClickMutationVariables>(LogBenefitClickDocument, baseOptions);
      }
export type LogBenefitClickMutationHookResult = ReturnType<typeof useLogBenefitClickMutation>;
export type LogBenefitClickMutationResult = ApolloReactCommon.MutationResult<LogBenefitClickMutation>;
export type LogBenefitClickMutationOptions = ApolloReactCommon.BaseMutationOptions<LogBenefitClickMutation, LogBenefitClickMutationVariables>;
export const LogBenefitViewsDocument = gql`
    mutation logBenefitViews($input: LogBenefitViewsInput!) {
  logBenefitViews(input: $input)
}
    `;

/**
 * __useLogBenefitViewsMutation__
 *
 * To run a mutation, you first call `useLogBenefitViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogBenefitViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logBenefitViewsMutation, { data, loading, error }] = useLogBenefitViewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogBenefitViewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogBenefitViewsMutation, LogBenefitViewsMutationVariables>) {
        return ApolloReactHooks.useMutation<LogBenefitViewsMutation, LogBenefitViewsMutationVariables>(LogBenefitViewsDocument, baseOptions);
      }
export type LogBenefitViewsMutationHookResult = ReturnType<typeof useLogBenefitViewsMutation>;
export type LogBenefitViewsMutationResult = ApolloReactCommon.MutationResult<LogBenefitViewsMutation>;
export type LogBenefitViewsMutationOptions = ApolloReactCommon.BaseMutationOptions<LogBenefitViewsMutation, LogBenefitViewsMutationVariables>;
export const LogSponsorImpressionsDocument = gql`
    mutation logSponsorImpressions($input: LogSponsorImpressionsInput!) {
  logSponsorImpressions(input: $input)
}
    `;

/**
 * __useLogSponsorImpressionsMutation__
 *
 * To run a mutation, you first call `useLogSponsorImpressionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogSponsorImpressionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logSponsorImpressionsMutation, { data, loading, error }] = useLogSponsorImpressionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogSponsorImpressionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogSponsorImpressionsMutation, LogSponsorImpressionsMutationVariables>) {
        return ApolloReactHooks.useMutation<LogSponsorImpressionsMutation, LogSponsorImpressionsMutationVariables>(LogSponsorImpressionsDocument, baseOptions);
      }
export type LogSponsorImpressionsMutationHookResult = ReturnType<typeof useLogSponsorImpressionsMutation>;
export type LogSponsorImpressionsMutationResult = ApolloReactCommon.MutationResult<LogSponsorImpressionsMutation>;
export type LogSponsorImpressionsMutationOptions = ApolloReactCommon.BaseMutationOptions<LogSponsorImpressionsMutation, LogSponsorImpressionsMutationVariables>;
export const MediaCreateDocument = gql`
    mutation mediaCreate($input: MediaInput!) {
  mediaCreate(input: $input) {
    media {
      id
    }
  }
}
    `;

/**
 * __useMediaCreateMutation__
 *
 * To run a mutation, you first call `useMediaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaCreateMutation, { data, loading, error }] = useMediaCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaCreateMutation, MediaCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<MediaCreateMutation, MediaCreateMutationVariables>(MediaCreateDocument, baseOptions);
      }
export type MediaCreateMutationHookResult = ReturnType<typeof useMediaCreateMutation>;
export type MediaCreateMutationResult = ApolloReactCommon.MutationResult<MediaCreateMutation>;
export type MediaCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<MediaCreateMutation, MediaCreateMutationVariables>;
export const MediaPreviewDocument = gql`
    mutation mediaPreview($input: MediaInput!) {
  mediaPreview(input: $input) {
    preview
  }
}
    `;

/**
 * __useMediaPreviewMutation__
 *
 * To run a mutation, you first call `useMediaPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaPreviewMutation, { data, loading, error }] = useMediaPreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaPreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaPreviewMutation, MediaPreviewMutationVariables>) {
        return ApolloReactHooks.useMutation<MediaPreviewMutation, MediaPreviewMutationVariables>(MediaPreviewDocument, baseOptions);
      }
export type MediaPreviewMutationHookResult = ReturnType<typeof useMediaPreviewMutation>;
export type MediaPreviewMutationResult = ApolloReactCommon.MutationResult<MediaPreviewMutation>;
export type MediaPreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<MediaPreviewMutation, MediaPreviewMutationVariables>;
export const RecipientSelectionCreateDocument = gql`
    mutation recipientSelectionCreate($input: RecipientSelectionCreateInput!) {
  recipientSelectionCreate(input: $input) {
    recipientSelection {
      ...RecipientSelection
    }
  }
}
    ${RecipientSelectionFragmentDoc}`;

/**
 * __useRecipientSelectionCreateMutation__
 *
 * To run a mutation, you first call `useRecipientSelectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientSelectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientSelectionCreateMutation, { data, loading, error }] = useRecipientSelectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipientSelectionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecipientSelectionCreateMutation, RecipientSelectionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<RecipientSelectionCreateMutation, RecipientSelectionCreateMutationVariables>(RecipientSelectionCreateDocument, baseOptions);
      }
export type RecipientSelectionCreateMutationHookResult = ReturnType<typeof useRecipientSelectionCreateMutation>;
export type RecipientSelectionCreateMutationResult = ApolloReactCommon.MutationResult<RecipientSelectionCreateMutation>;
export type RecipientSelectionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<RecipientSelectionCreateMutation, RecipientSelectionCreateMutationVariables>;
export const RecipientSelectionCreateFromDocumentDocument = gql`
    mutation recipientSelectionCreateFromDocument($input: RecipientSelectionCreateFromDocumentInput!) {
  recipientSelectionCreateFromDocument(input: $input) {
    recipientSelection {
      ...RecipientSelection
    }
  }
}
    ${RecipientSelectionFragmentDoc}`;

/**
 * __useRecipientSelectionCreateFromDocumentMutation__
 *
 * To run a mutation, you first call `useRecipientSelectionCreateFromDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientSelectionCreateFromDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientSelectionCreateFromDocumentMutation, { data, loading, error }] = useRecipientSelectionCreateFromDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipientSelectionCreateFromDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecipientSelectionCreateFromDocumentMutation, RecipientSelectionCreateFromDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<RecipientSelectionCreateFromDocumentMutation, RecipientSelectionCreateFromDocumentMutationVariables>(RecipientSelectionCreateFromDocumentDocument, baseOptions);
      }
export type RecipientSelectionCreateFromDocumentMutationHookResult = ReturnType<typeof useRecipientSelectionCreateFromDocumentMutation>;
export type RecipientSelectionCreateFromDocumentMutationResult = ApolloReactCommon.MutationResult<RecipientSelectionCreateFromDocumentMutation>;
export type RecipientSelectionCreateFromDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<RecipientSelectionCreateFromDocumentMutation, RecipientSelectionCreateFromDocumentMutationVariables>;
export const RecipientSelectionToggleDocument = gql`
    mutation recipientSelectionToggle($input: RecipientSelectionToggleInput!) {
  recipientSelectionToggle(input: $input) {
    recipientSelection {
      ...RecipientSelection
    }
  }
}
    ${RecipientSelectionFragmentDoc}`;

/**
 * __useRecipientSelectionToggleMutation__
 *
 * To run a mutation, you first call `useRecipientSelectionToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientSelectionToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientSelectionToggleMutation, { data, loading, error }] = useRecipientSelectionToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipientSelectionToggleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecipientSelectionToggleMutation, RecipientSelectionToggleMutationVariables>) {
        return ApolloReactHooks.useMutation<RecipientSelectionToggleMutation, RecipientSelectionToggleMutationVariables>(RecipientSelectionToggleDocument, baseOptions);
      }
export type RecipientSelectionToggleMutationHookResult = ReturnType<typeof useRecipientSelectionToggleMutation>;
export type RecipientSelectionToggleMutationResult = ApolloReactCommon.MutationResult<RecipientSelectionToggleMutation>;
export type RecipientSelectionToggleMutationOptions = ApolloReactCommon.BaseMutationOptions<RecipientSelectionToggleMutation, RecipientSelectionToggleMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  tokenRefresh(refreshToken: $refreshToken) {
    token
    refreshToken
    refreshExpiresIn
  }
}
    `;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = ApolloReactCommon.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation requestPasswordReset($input: RequestPasswordResetInput!) {
  requestPasswordReset(input: $input)
}
    `;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, baseOptions);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const StoryAddCommentDocument = gql`
    mutation storyAddComment($input: StoryAddCommentInput!) {
  storyAddComment(input: $input) {
    comment {
      ...StoryComment
    }
  }
}
    ${StoryCommentFragmentDoc}`;

/**
 * __useStoryAddCommentMutation__
 *
 * To run a mutation, you first call `useStoryAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoryAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storyAddCommentMutation, { data, loading, error }] = useStoryAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoryAddCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoryAddCommentMutation, StoryAddCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<StoryAddCommentMutation, StoryAddCommentMutationVariables>(StoryAddCommentDocument, baseOptions);
      }
export type StoryAddCommentMutationHookResult = ReturnType<typeof useStoryAddCommentMutation>;
export type StoryAddCommentMutationResult = ApolloReactCommon.MutationResult<StoryAddCommentMutation>;
export type StoryAddCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<StoryAddCommentMutation, StoryAddCommentMutationVariables>;
export const StoryClapDocument = gql`
    mutation storyClap($input: StoryClapInput!) {
  storyClap(input: $input) {
    story {
      id
      claps
      myClapCount
    }
  }
}
    `;

/**
 * __useStoryClapMutation__
 *
 * To run a mutation, you first call `useStoryClapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoryClapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storyClapMutation, { data, loading, error }] = useStoryClapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoryClapMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoryClapMutation, StoryClapMutationVariables>) {
        return ApolloReactHooks.useMutation<StoryClapMutation, StoryClapMutationVariables>(StoryClapDocument, baseOptions);
      }
export type StoryClapMutationHookResult = ReturnType<typeof useStoryClapMutation>;
export type StoryClapMutationResult = ApolloReactCommon.MutationResult<StoryClapMutation>;
export type StoryClapMutationOptions = ApolloReactCommon.BaseMutationOptions<StoryClapMutation, StoryClapMutationVariables>;
export const StoryCreateDocument = gql`
    mutation storyCreate($input: StoryCreateInput!) {
  storyCreate(input: $input) {
    story {
      id
    }
  }
}
    `;

/**
 * __useStoryCreateMutation__
 *
 * To run a mutation, you first call `useStoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storyCreateMutation, { data, loading, error }] = useStoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoryCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoryCreateMutation, StoryCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StoryCreateMutation, StoryCreateMutationVariables>(StoryCreateDocument, baseOptions);
      }
export type StoryCreateMutationHookResult = ReturnType<typeof useStoryCreateMutation>;
export type StoryCreateMutationResult = ApolloReactCommon.MutationResult<StoryCreateMutation>;
export type StoryCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StoryCreateMutation, StoryCreateMutationVariables>;
export const StoryDeleteDocument = gql`
    mutation storyDelete($input: StoryInput!) {
  storyDelete(input: $input) {
    space {
      id
      name
      stories(last: 10) @connection(key: "stories") {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...Story
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${StoryFragmentDoc}`;

/**
 * __useStoryDeleteMutation__
 *
 * To run a mutation, you first call `useStoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storyDeleteMutation, { data, loading, error }] = useStoryDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoryDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoryDeleteMutation, StoryDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StoryDeleteMutation, StoryDeleteMutationVariables>(StoryDeleteDocument, baseOptions);
      }
export type StoryDeleteMutationHookResult = ReturnType<typeof useStoryDeleteMutation>;
export type StoryDeleteMutationResult = ApolloReactCommon.MutationResult<StoryDeleteMutation>;
export type StoryDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StoryDeleteMutation, StoryDeleteMutationVariables>;
export const StoryUpdateDocument = gql`
    mutation storyUpdate($input: StoryUpdateInput!) {
  storyUpdate(input: $input) {
    story {
      ...Story
    }
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoryUpdateMutation__
 *
 * To run a mutation, you first call `useStoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storyUpdateMutation, { data, loading, error }] = useStoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoryUpdateMutation, StoryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StoryUpdateMutation, StoryUpdateMutationVariables>(StoryUpdateDocument, baseOptions);
      }
export type StoryUpdateMutationHookResult = ReturnType<typeof useStoryUpdateMutation>;
export type StoryUpdateMutationResult = ApolloReactCommon.MutationResult<StoryUpdateMutation>;
export type StoryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StoryUpdateMutation, StoryUpdateMutationVariables>;
export const TokenAuthDocument = gql`
    mutation tokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token
    refreshToken
    refreshExpiresIn
  }
}
    `;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return ApolloReactHooks.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = ApolloReactCommon.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const AnnouncementDocument = gql`
    query announcement($id: GlobalID!, $sent: Boolean!) {
  announcement(id: $id) {
    ...Announcement
    space {
      id
      name
    }
    author {
      ...AnnouncementAuthor
    }
    authorMembership {
      ...AuthorMembership
    }
    reactions {
      edges {
        node {
          id
          text
          position
        }
      }
    }
    interactions @include(if: $sent) {
      edges {
        node {
          id
          readAt
          archivedAt
          reaction {
            id
          }
          sms {
            id
            createdAt
          }
          user {
            id
            firstName
            lastName
            isActive
          }
          lastReactionLog {
            id
            createdAt
            valueNew
            valueOld
          }
        }
      }
    }
    myInteraction @skip(if: $sent) {
      ...MyAnnouncementInteraction
    }
  }
}
    ${AnnouncementFragmentDoc}
${AnnouncementAuthorFragmentDoc}
${AuthorMembershipFragmentDoc}
${MyAnnouncementInteractionFragmentDoc}`;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sent: // value for 'sent'
 *   },
 * });
 */
export function useAnnouncementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
        return ApolloReactHooks.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, baseOptions);
      }
export function useAnnouncementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, baseOptions);
        }
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = ApolloReactCommon.QueryResult<AnnouncementQuery, AnnouncementQueryVariables>;
export const AnnouncementLogsDocument = gql`
    query announcementLogs($id: GlobalID!, $before: String) {
  announcement(id: $id) {
    id
    logs(last: 10, before: $before) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...AnnouncementLog
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AnnouncementLogFragmentDoc}`;

/**
 * __useAnnouncementLogsQuery__
 *
 * To run a query within a React component, call `useAnnouncementLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAnnouncementLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnnouncementLogsQuery, AnnouncementLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<AnnouncementLogsQuery, AnnouncementLogsQueryVariables>(AnnouncementLogsDocument, baseOptions);
      }
export function useAnnouncementLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnnouncementLogsQuery, AnnouncementLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnnouncementLogsQuery, AnnouncementLogsQueryVariables>(AnnouncementLogsDocument, baseOptions);
        }
export type AnnouncementLogsQueryHookResult = ReturnType<typeof useAnnouncementLogsQuery>;
export type AnnouncementLogsLazyQueryHookResult = ReturnType<typeof useAnnouncementLogsLazyQuery>;
export type AnnouncementLogsQueryResult = ApolloReactCommon.QueryResult<AnnouncementLogsQuery, AnnouncementLogsQueryVariables>;
export const BenefitsDocument = gql`
    query benefits {
  me {
    id
    organizations {
      edges {
        node {
          id
          benefits {
            id
            url
            name
            text
            image
            createdAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBenefitsQuery__
 *
 * To run a query within a React component, call `useBenefitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBenefitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenefitsQuery, BenefitsQueryVariables>) {
        return ApolloReactHooks.useQuery<BenefitsQuery, BenefitsQueryVariables>(BenefitsDocument, baseOptions);
      }
export function useBenefitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenefitsQuery, BenefitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenefitsQuery, BenefitsQueryVariables>(BenefitsDocument, baseOptions);
        }
export type BenefitsQueryHookResult = ReturnType<typeof useBenefitsQuery>;
export type BenefitsLazyQueryHookResult = ReturnType<typeof useBenefitsLazyQuery>;
export type BenefitsQueryResult = ApolloReactCommon.QueryResult<BenefitsQuery, BenefitsQueryVariables>;
export const ChatDocument = gql`
    query chat($id: GlobalID!) {
  chat(id: $id) {
    id
    name
    createdAt
    metaMarkAsRead
    createdBy {
      id
    }
    interactions {
      edges {
        node {
          ...ChatInteraction
        }
      }
    }
    myInteraction {
      ...ChatMyInteraction
    }
    organization {
      id
      unresolvedChatCount
    }
    messages(last: 20) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...ChatMessage
        }
      }
    }
  }
}
    ${ChatInteractionFragmentDoc}
${ChatMyInteractionFragmentDoc}
${PageInfoFragmentDoc}
${ChatMessageFragmentDoc}`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, baseOptions);
      }
export function useChatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocument, baseOptions);
        }
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = ApolloReactCommon.QueryResult<ChatQuery, ChatQueryVariables>;
export const ChatActivityDocument = gql`
    query chatActivity($id: GlobalID!) {
  chat(id: $id) {
    id
    name
    lastMessageAt
    interactions {
      edges {
        cursor
        node {
          ...ChatInteraction
        }
      }
    }
  }
}
    ${ChatInteractionFragmentDoc}`;

/**
 * __useChatActivityQuery__
 *
 * To run a query within a React component, call `useChatActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatActivityQuery, ChatActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatActivityQuery, ChatActivityQueryVariables>(ChatActivityDocument, baseOptions);
      }
export function useChatActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatActivityQuery, ChatActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatActivityQuery, ChatActivityQueryVariables>(ChatActivityDocument, baseOptions);
        }
export type ChatActivityQueryHookResult = ReturnType<typeof useChatActivityQuery>;
export type ChatActivityLazyQueryHookResult = ReturnType<typeof useChatActivityLazyQuery>;
export type ChatActivityQueryResult = ApolloReactCommon.QueryResult<ChatActivityQuery, ChatActivityQueryVariables>;
export const ChatGroupSettingsDocument = gql`
    query chatGroupSettings($id: GlobalID!) {
  chat(id: $id) {
    id
    name
    createdBy {
      id
    }
    interactions {
      edges {
        cursor
        node {
          ...ChatInteraction
        }
      }
    }
  }
}
    ${ChatInteractionFragmentDoc}`;

/**
 * __useChatGroupSettingsQuery__
 *
 * To run a query within a React component, call `useChatGroupSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGroupSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGroupSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatGroupSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatGroupSettingsQuery, ChatGroupSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatGroupSettingsQuery, ChatGroupSettingsQueryVariables>(ChatGroupSettingsDocument, baseOptions);
      }
export function useChatGroupSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatGroupSettingsQuery, ChatGroupSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatGroupSettingsQuery, ChatGroupSettingsQueryVariables>(ChatGroupSettingsDocument, baseOptions);
        }
export type ChatGroupSettingsQueryHookResult = ReturnType<typeof useChatGroupSettingsQuery>;
export type ChatGroupSettingsLazyQueryHookResult = ReturnType<typeof useChatGroupSettingsLazyQuery>;
export type ChatGroupSettingsQueryResult = ApolloReactCommon.QueryResult<ChatGroupSettingsQuery, ChatGroupSettingsQueryVariables>;
export const ChatNewMessagesDocument = gql`
    query chatNewMessages($id: GlobalID!, $after: String) {
  chat(id: $id) {
    id
    myInteraction {
      ...ChatMyInteraction
    }
    messages(last: 10, after: $after) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...ChatMessage
        }
      }
    }
  }
}
    ${ChatMyInteractionFragmentDoc}
${PageInfoFragmentDoc}
${ChatMessageFragmentDoc}`;

/**
 * __useChatNewMessagesQuery__
 *
 * To run a query within a React component, call `useChatNewMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatNewMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatNewMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChatNewMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatNewMessagesQuery, ChatNewMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatNewMessagesQuery, ChatNewMessagesQueryVariables>(ChatNewMessagesDocument, baseOptions);
      }
export function useChatNewMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatNewMessagesQuery, ChatNewMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatNewMessagesQuery, ChatNewMessagesQueryVariables>(ChatNewMessagesDocument, baseOptions);
        }
export type ChatNewMessagesQueryHookResult = ReturnType<typeof useChatNewMessagesQuery>;
export type ChatNewMessagesLazyQueryHookResult = ReturnType<typeof useChatNewMessagesLazyQuery>;
export type ChatNewMessagesQueryResult = ApolloReactCommon.QueryResult<ChatNewMessagesQuery, ChatNewMessagesQueryVariables>;
export const ChatOrganizationChatsDocument = gql`
    query chatOrganizationChats($organization: GlobalID!) {
  organization(id: $organization) {
    id
    chats {
      edges {
        cursor
        node {
          ...OrganizationChat
        }
      }
    }
  }
}
    ${OrganizationChatFragmentDoc}`;

/**
 * __useChatOrganizationChatsQuery__
 *
 * To run a query within a React component, call `useChatOrganizationChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatOrganizationChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatOrganizationChatsQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useChatOrganizationChatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatOrganizationChatsQuery, ChatOrganizationChatsQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatOrganizationChatsQuery, ChatOrganizationChatsQueryVariables>(ChatOrganizationChatsDocument, baseOptions);
      }
export function useChatOrganizationChatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatOrganizationChatsQuery, ChatOrganizationChatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatOrganizationChatsQuery, ChatOrganizationChatsQueryVariables>(ChatOrganizationChatsDocument, baseOptions);
        }
export type ChatOrganizationChatsQueryHookResult = ReturnType<typeof useChatOrganizationChatsQuery>;
export type ChatOrganizationChatsLazyQueryHookResult = ReturnType<typeof useChatOrganizationChatsLazyQuery>;
export type ChatOrganizationChatsQueryResult = ApolloReactCommon.QueryResult<ChatOrganizationChatsQuery, ChatOrganizationChatsQueryVariables>;
export const ChatOrganizationUsersDocument = gql`
    query chatOrganizationUsers($organization: GlobalID!, $query: String!, $first: Int) {
  organization(id: $organization) {
    id
    users(query: $query, first: $first) {
      edges {
        node {
          id
          isActive
          lastName
          firstName
          profileImage
          memberships(organization: $organization) {
            totalCount
            edges {
              node {
                id
                role
                position
                space {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChatOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useChatOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatOrganizationUsersQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useChatOrganizationUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatOrganizationUsersQuery, ChatOrganizationUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatOrganizationUsersQuery, ChatOrganizationUsersQueryVariables>(ChatOrganizationUsersDocument, baseOptions);
      }
export function useChatOrganizationUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatOrganizationUsersQuery, ChatOrganizationUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatOrganizationUsersQuery, ChatOrganizationUsersQueryVariables>(ChatOrganizationUsersDocument, baseOptions);
        }
export type ChatOrganizationUsersQueryHookResult = ReturnType<typeof useChatOrganizationUsersQuery>;
export type ChatOrganizationUsersLazyQueryHookResult = ReturnType<typeof useChatOrganizationUsersLazyQuery>;
export type ChatOrganizationUsersQueryResult = ApolloReactCommon.QueryResult<ChatOrganizationUsersQuery, ChatOrganizationUsersQueryVariables>;
export const ChatPreviousMessagesDocument = gql`
    query chatPreviousMessages($id: GlobalID!, $before: String) {
  chat(id: $id) {
    id
    myInteraction {
      ...ChatMyInteraction
    }
    messages(last: 10, before: $before) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...ChatMessage
        }
      }
    }
  }
}
    ${ChatMyInteractionFragmentDoc}
${PageInfoFragmentDoc}
${ChatMessageFragmentDoc}`;

/**
 * __useChatPreviousMessagesQuery__
 *
 * To run a query within a React component, call `useChatPreviousMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatPreviousMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatPreviousMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useChatPreviousMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChatPreviousMessagesQuery, ChatPreviousMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ChatPreviousMessagesQuery, ChatPreviousMessagesQueryVariables>(ChatPreviousMessagesDocument, baseOptions);
      }
export function useChatPreviousMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatPreviousMessagesQuery, ChatPreviousMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChatPreviousMessagesQuery, ChatPreviousMessagesQueryVariables>(ChatPreviousMessagesDocument, baseOptions);
        }
export type ChatPreviousMessagesQueryHookResult = ReturnType<typeof useChatPreviousMessagesQuery>;
export type ChatPreviousMessagesLazyQueryHookResult = ReturnType<typeof useChatPreviousMessagesLazyQuery>;
export type ChatPreviousMessagesQueryResult = ApolloReactCommon.QueryResult<ChatPreviousMessagesQuery, ChatPreviousMessagesQueryVariables>;
export const DocumentDocument = gql`
    query document($id: GlobalID!) {
  document(id: $id) {
    ...Document
    space {
      id
      name
    }
    author {
      ...DocumentAuthor
    }
    authorMembership {
      ...AuthorMembership
    }
    interactions {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          user {
            id
            isActive
            firstName
            lastName
            profileImage
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentAuthorFragmentDoc}
${AuthorMembershipFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, baseOptions);
      }
export function useDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, baseOptions);
        }
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = ApolloReactCommon.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const LoadOlderStoryCommentsDocument = gql`
    query loadOlderStoryComments($id: GlobalID!, $before: String) {
  story(id: $id) {
    id
    comments(last: 5, before: $before) @connection(key: "comments") {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...StoryComment
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${StoryCommentFragmentDoc}`;

/**
 * __useLoadOlderStoryCommentsQuery__
 *
 * To run a query within a React component, call `useLoadOlderStoryCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOlderStoryCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOlderStoryCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useLoadOlderStoryCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadOlderStoryCommentsQuery, LoadOlderStoryCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadOlderStoryCommentsQuery, LoadOlderStoryCommentsQueryVariables>(LoadOlderStoryCommentsDocument, baseOptions);
      }
export function useLoadOlderStoryCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadOlderStoryCommentsQuery, LoadOlderStoryCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadOlderStoryCommentsQuery, LoadOlderStoryCommentsQueryVariables>(LoadOlderStoryCommentsDocument, baseOptions);
        }
export type LoadOlderStoryCommentsQueryHookResult = ReturnType<typeof useLoadOlderStoryCommentsQuery>;
export type LoadOlderStoryCommentsLazyQueryHookResult = ReturnType<typeof useLoadOlderStoryCommentsLazyQuery>;
export type LoadOlderStoryCommentsQueryResult = ApolloReactCommon.QueryResult<LoadOlderStoryCommentsQuery, LoadOlderStoryCommentsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MeValidationDocument = gql`
    query meValidation {
  me {
    ...MeValidation
  }
}
    ${MeValidationFragmentDoc}`;

/**
 * __useMeValidationQuery__
 *
 * To run a query within a React component, call `useMeValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeValidationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeValidationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeValidationQuery, MeValidationQueryVariables>) {
        return ApolloReactHooks.useQuery<MeValidationQuery, MeValidationQueryVariables>(MeValidationDocument, baseOptions);
      }
export function useMeValidationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeValidationQuery, MeValidationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeValidationQuery, MeValidationQueryVariables>(MeValidationDocument, baseOptions);
        }
export type MeValidationQueryHookResult = ReturnType<typeof useMeValidationQuery>;
export type MeValidationLazyQueryHookResult = ReturnType<typeof useMeValidationLazyQuery>;
export type MeValidationQueryResult = ApolloReactCommon.QueryResult<MeValidationQuery, MeValidationQueryVariables>;
export const RecipientSelectionDocument = gql`
    query recipientSelection($id: GlobalID!) {
  recipientSelection(id: $id) {
    ...RecipientSelection
  }
}
    ${RecipientSelectionFragmentDoc}`;

/**
 * __useRecipientSelectionQuery__
 *
 * To run a query within a React component, call `useRecipientSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientSelectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipientSelectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipientSelectionQuery, RecipientSelectionQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipientSelectionQuery, RecipientSelectionQueryVariables>(RecipientSelectionDocument, baseOptions);
      }
export function useRecipientSelectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipientSelectionQuery, RecipientSelectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipientSelectionQuery, RecipientSelectionQueryVariables>(RecipientSelectionDocument, baseOptions);
        }
export type RecipientSelectionQueryHookResult = ReturnType<typeof useRecipientSelectionQuery>;
export type RecipientSelectionLazyQueryHookResult = ReturnType<typeof useRecipientSelectionLazyQuery>;
export type RecipientSelectionQueryResult = ApolloReactCommon.QueryResult<RecipientSelectionQuery, RecipientSelectionQueryVariables>;
export const SpaceReceivedAnnouncementsDocument = gql`
    query spaceReceivedAnnouncements($id: GlobalID!) {
  space(id: $id) {
    id
    receivedAnnouncements {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          title
          createdAt
          deletedAt
          imageThumbnail
          reactionDeadlineAt
          author {
            ...AnnouncementAuthor
          }
          reactions {
            pageInfo {
              ...PageInfo
            }
          }
          myInteraction {
            ...MyAnnouncementInteraction
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AnnouncementAuthorFragmentDoc}
${MyAnnouncementInteractionFragmentDoc}`;

/**
 * __useSpaceReceivedAnnouncementsQuery__
 *
 * To run a query within a React component, call `useSpaceReceivedAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceReceivedAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceReceivedAnnouncementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceReceivedAnnouncementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceReceivedAnnouncementsQuery, SpaceReceivedAnnouncementsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceReceivedAnnouncementsQuery, SpaceReceivedAnnouncementsQueryVariables>(SpaceReceivedAnnouncementsDocument, baseOptions);
      }
export function useSpaceReceivedAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceReceivedAnnouncementsQuery, SpaceReceivedAnnouncementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceReceivedAnnouncementsQuery, SpaceReceivedAnnouncementsQueryVariables>(SpaceReceivedAnnouncementsDocument, baseOptions);
        }
export type SpaceReceivedAnnouncementsQueryHookResult = ReturnType<typeof useSpaceReceivedAnnouncementsQuery>;
export type SpaceReceivedAnnouncementsLazyQueryHookResult = ReturnType<typeof useSpaceReceivedAnnouncementsLazyQuery>;
export type SpaceReceivedAnnouncementsQueryResult = ApolloReactCommon.QueryResult<SpaceReceivedAnnouncementsQuery, SpaceReceivedAnnouncementsQueryVariables>;
export const SpaceReceivedDocumentsDocument = gql`
    query spaceReceivedDocuments($id: GlobalID!) {
  space(id: $id) {
    id
    receivedDocuments {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...ListedDocument
          myInteraction {
            ...MyDocumentInteraction
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${ListedDocumentFragmentDoc}
${MyDocumentInteractionFragmentDoc}`;

/**
 * __useSpaceReceivedDocumentsQuery__
 *
 * To run a query within a React component, call `useSpaceReceivedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceReceivedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceReceivedDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceReceivedDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceReceivedDocumentsQuery, SpaceReceivedDocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceReceivedDocumentsQuery, SpaceReceivedDocumentsQueryVariables>(SpaceReceivedDocumentsDocument, baseOptions);
      }
export function useSpaceReceivedDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceReceivedDocumentsQuery, SpaceReceivedDocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceReceivedDocumentsQuery, SpaceReceivedDocumentsQueryVariables>(SpaceReceivedDocumentsDocument, baseOptions);
        }
export type SpaceReceivedDocumentsQueryHookResult = ReturnType<typeof useSpaceReceivedDocumentsQuery>;
export type SpaceReceivedDocumentsLazyQueryHookResult = ReturnType<typeof useSpaceReceivedDocumentsLazyQuery>;
export type SpaceReceivedDocumentsQueryResult = ApolloReactCommon.QueryResult<SpaceReceivedDocumentsQuery, SpaceReceivedDocumentsQueryVariables>;
export const SpaceSentAnnouncementsDocument = gql`
    query spaceSentAnnouncements($id: GlobalID!) {
  space(id: $id) {
    id
    sentAnnouncements {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          title
          createdAt
          deletedAt
          imageThumbnail
          recipientsTotalCount
          recipientsSeenCount
          recipientsReactedCount
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;

/**
 * __useSpaceSentAnnouncementsQuery__
 *
 * To run a query within a React component, call `useSpaceSentAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceSentAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceSentAnnouncementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceSentAnnouncementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceSentAnnouncementsQuery, SpaceSentAnnouncementsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceSentAnnouncementsQuery, SpaceSentAnnouncementsQueryVariables>(SpaceSentAnnouncementsDocument, baseOptions);
      }
export function useSpaceSentAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceSentAnnouncementsQuery, SpaceSentAnnouncementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceSentAnnouncementsQuery, SpaceSentAnnouncementsQueryVariables>(SpaceSentAnnouncementsDocument, baseOptions);
        }
export type SpaceSentAnnouncementsQueryHookResult = ReturnType<typeof useSpaceSentAnnouncementsQuery>;
export type SpaceSentAnnouncementsLazyQueryHookResult = ReturnType<typeof useSpaceSentAnnouncementsLazyQuery>;
export type SpaceSentAnnouncementsQueryResult = ApolloReactCommon.QueryResult<SpaceSentAnnouncementsQuery, SpaceSentAnnouncementsQueryVariables>;
export const SpaceSentDocumentsDocument = gql`
    query spaceSentDocuments($id: GlobalID!) {
  space(id: $id) {
    id
    sentDocuments {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          title
          createdAt
          updatedAt
          author {
            id
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;

/**
 * __useSpaceSentDocumentsQuery__
 *
 * To run a query within a React component, call `useSpaceSentDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceSentDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceSentDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceSentDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceSentDocumentsQuery, SpaceSentDocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceSentDocumentsQuery, SpaceSentDocumentsQueryVariables>(SpaceSentDocumentsDocument, baseOptions);
      }
export function useSpaceSentDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceSentDocumentsQuery, SpaceSentDocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceSentDocumentsQuery, SpaceSentDocumentsQueryVariables>(SpaceSentDocumentsDocument, baseOptions);
        }
export type SpaceSentDocumentsQueryHookResult = ReturnType<typeof useSpaceSentDocumentsQuery>;
export type SpaceSentDocumentsLazyQueryHookResult = ReturnType<typeof useSpaceSentDocumentsLazyQuery>;
export type SpaceSentDocumentsQueryResult = ApolloReactCommon.QueryResult<SpaceSentDocumentsQuery, SpaceSentDocumentsQueryVariables>;
export const SpaceStoriesDocument = gql`
    query spaceStories($id: GlobalID!, $cursor: String) {
  space(id: $id) {
    id
    name
    stories(last: 10, before: $cursor) @connection(key: "stories") {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          ...Story
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${StoryFragmentDoc}`;

/**
 * __useSpaceStoriesQuery__
 *
 * To run a query within a React component, call `useSpaceStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceStoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSpaceStoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceStoriesQuery, SpaceStoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceStoriesQuery, SpaceStoriesQueryVariables>(SpaceStoriesDocument, baseOptions);
      }
export function useSpaceStoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceStoriesQuery, SpaceStoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceStoriesQuery, SpaceStoriesQueryVariables>(SpaceStoriesDocument, baseOptions);
        }
export type SpaceStoriesQueryHookResult = ReturnType<typeof useSpaceStoriesQuery>;
export type SpaceStoriesLazyQueryHookResult = ReturnType<typeof useSpaceStoriesLazyQuery>;
export type SpaceStoriesQueryResult = ApolloReactCommon.QueryResult<SpaceStoriesQuery, SpaceStoriesQueryVariables>;
export const SponsorsDocument = gql`
    query sponsors {
  me {
    id
    organizations {
      edges {
        node {
          id
          sponsorType
          sponsors {
            id
            name
            logo
            image
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSponsorsQuery__
 *
 * To run a query within a React component, call `useSponsorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSponsorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SponsorsQuery, SponsorsQueryVariables>) {
        return ApolloReactHooks.useQuery<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument, baseOptions);
      }
export function useSponsorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SponsorsQuery, SponsorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument, baseOptions);
        }
export type SponsorsQueryHookResult = ReturnType<typeof useSponsorsQuery>;
export type SponsorsLazyQueryHookResult = ReturnType<typeof useSponsorsLazyQuery>;
export type SponsorsQueryResult = ApolloReactCommon.QueryResult<SponsorsQuery, SponsorsQueryVariables>;
export const StoryDocument = gql`
    query story($id: GlobalID!) {
  story(id: $id) {
    ...Story
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoryQuery, StoryQueryVariables>) {
        return ApolloReactHooks.useQuery<StoryQuery, StoryQueryVariables>(StoryDocument, baseOptions);
      }
export function useStoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoryQuery, StoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StoryQuery, StoryQueryVariables>(StoryDocument, baseOptions);
        }
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>;
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>;
export type StoryQueryResult = ApolloReactCommon.QueryResult<StoryQuery, StoryQueryVariables>;
export const UnresolvedDocument = gql`
    query unresolved {
  me {
    id
    organizations {
      edges {
        node {
          id
          unresolvedChatCount
          spaces {
            edges {
              node {
                id
                unresolvedDocumentCount
                unresolvedAnnouncementCount
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUnresolvedQuery__
 *
 * To run a query within a React component, call `useUnresolvedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnresolvedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnresolvedQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnresolvedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnresolvedQuery, UnresolvedQueryVariables>) {
        return ApolloReactHooks.useQuery<UnresolvedQuery, UnresolvedQueryVariables>(UnresolvedDocument, baseOptions);
      }
export function useUnresolvedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnresolvedQuery, UnresolvedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnresolvedQuery, UnresolvedQueryVariables>(UnresolvedDocument, baseOptions);
        }
export type UnresolvedQueryHookResult = ReturnType<typeof useUnresolvedQuery>;
export type UnresolvedLazyQueryHookResult = ReturnType<typeof useUnresolvedLazyQuery>;
export type UnresolvedQueryResult = ApolloReactCommon.QueryResult<UnresolvedQuery, UnresolvedQueryVariables>;
export const UserForActivationDocument = gql`
    query userForActivation($activationCode: String!) {
  userForActivation(activationCode: $activationCode) {
    id
    email
    lastName
    firstName
    phoneNumber
    profileImage
  }
}
    `;

/**
 * __useUserForActivationQuery__
 *
 * To run a query within a React component, call `useUserForActivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserForActivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserForActivationQuery({
 *   variables: {
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useUserForActivationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserForActivationQuery, UserForActivationQueryVariables>) {
        return ApolloReactHooks.useQuery<UserForActivationQuery, UserForActivationQueryVariables>(UserForActivationDocument, baseOptions);
      }
export function useUserForActivationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserForActivationQuery, UserForActivationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserForActivationQuery, UserForActivationQueryVariables>(UserForActivationDocument, baseOptions);
        }
export type UserForActivationQueryHookResult = ReturnType<typeof useUserForActivationQuery>;
export type UserForActivationLazyQueryHookResult = ReturnType<typeof useUserForActivationLazyQuery>;
export type UserForActivationQueryResult = ApolloReactCommon.QueryResult<UserForActivationQuery, UserForActivationQueryVariables>;
export const AnnouncementChangeDocument = gql`
    subscription announcementChange {
  onAnnouncementChange {
    event
    announcement {
      id
      reactionDeadlineAt
      recipientsSeenCount
      recipientsTotalCount
      recipientsReactedCount
    }
  }
}
    `;

/**
 * __useAnnouncementChangeSubscription__
 *
 * To run a query within a React component, call `useAnnouncementChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementChangeSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AnnouncementChangeSubscription, AnnouncementChangeSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AnnouncementChangeSubscription, AnnouncementChangeSubscriptionVariables>(AnnouncementChangeDocument, baseOptions);
      }
export type AnnouncementChangeSubscriptionHookResult = ReturnType<typeof useAnnouncementChangeSubscription>;
export type AnnouncementChangeSubscriptionResult = ApolloReactCommon.SubscriptionResult<AnnouncementChangeSubscription>;
export const ChatChangeDocument = gql`
    subscription chatChange {
  onChatChange {
    event
    chat {
      id
      myInteraction {
        id
        muted
      }
      organization {
        id
        unresolvedChatCount
      }
      messages(last: 1) {
        edges {
          node {
            id
            message
            createdAt
            author {
              id
              lastName
              firstName
              profileImage
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChatChangeSubscription__
 *
 * To run a query within a React component, call `useChatChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatChangeSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ChatChangeSubscription, ChatChangeSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ChatChangeSubscription, ChatChangeSubscriptionVariables>(ChatChangeDocument, baseOptions);
      }
export type ChatChangeSubscriptionHookResult = ReturnType<typeof useChatChangeSubscription>;
export type ChatChangeSubscriptionResult = ApolloReactCommon.SubscriptionResult<ChatChangeSubscription>;
export const SpaceAnnouncementsEventDocument = gql`
    subscription spaceAnnouncementsEvent {
  onSpaceAnnouncementsEvent {
    event
    space {
      id
    }
  }
}
    `;

/**
 * __useSpaceAnnouncementsEventSubscription__
 *
 * To run a query within a React component, call `useSpaceAnnouncementsEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSpaceAnnouncementsEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceAnnouncementsEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSpaceAnnouncementsEventSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SpaceAnnouncementsEventSubscription, SpaceAnnouncementsEventSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<SpaceAnnouncementsEventSubscription, SpaceAnnouncementsEventSubscriptionVariables>(SpaceAnnouncementsEventDocument, baseOptions);
      }
export type SpaceAnnouncementsEventSubscriptionHookResult = ReturnType<typeof useSpaceAnnouncementsEventSubscription>;
export type SpaceAnnouncementsEventSubscriptionResult = ApolloReactCommon.SubscriptionResult<SpaceAnnouncementsEventSubscription>;
export const SpaceDocumentsEventDocument = gql`
    subscription spaceDocumentsEvent {
  onSpaceDocumentsEvent {
    event
    space {
      id
    }
  }
}
    `;

/**
 * __useSpaceDocumentsEventSubscription__
 *
 * To run a query within a React component, call `useSpaceDocumentsEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSpaceDocumentsEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceDocumentsEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSpaceDocumentsEventSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SpaceDocumentsEventSubscription, SpaceDocumentsEventSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<SpaceDocumentsEventSubscription, SpaceDocumentsEventSubscriptionVariables>(SpaceDocumentsEventDocument, baseOptions);
      }
export type SpaceDocumentsEventSubscriptionHookResult = ReturnType<typeof useSpaceDocumentsEventSubscription>;
export type SpaceDocumentsEventSubscriptionResult = ApolloReactCommon.SubscriptionResult<SpaceDocumentsEventSubscription>;