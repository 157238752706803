import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { useDashboardState, useMe } from "hooks";
import { useChatStore } from "chat/hooks/useChatStore";
import {
  mapConnection,
  resolveError,
  useChatOrganizationChatsQuery,
  useChatChangeSubscription,
  useChatCreateMutation,
  OrganizationChatFragment,
} from "api";
import { useSound } from "chat/hooks/useSound";

export const useChatList = (openChat: (id: string) => any, openGroupSettings: () => any) => {
  const { organization } = useDashboardState();
  const { soundEnabled } = useChatStore();
  const { playReceive } = useSound();
  const { me, activeOrganization } = useMe();

  const [compact, setCompact] = useState(true);
  const toggleCompact = useCallback(() => setCompact(!compact), [compact]);

  const [chatCreate] = useChatCreateMutation();

  const { data, loading, refetch } = useChatOrganizationChatsQuery({
    variables: { organization },
    fetchPolicy: "cache-and-network",
  });

  useChatChangeSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      if (!subscriptionData?.data?.onChatChange) return;

      const _soundEnabled = soundEnabled && !subscriptionData.data.onChatChange.chat?.myInteraction.muted;
      const _isMe = subscriptionData.data.onChatChange.chat?.messages.edges[0]?.node?.author.id === me?.userId;

      if (_soundEnabled && !_isMe) await playReceive();

      await refetch();
    },
  });

  const { chats, unreadMessagesCount } = useMemo(() => {
    let unreadMessagesCount = 0;
    let chats = mapConnection<OrganizationChatFragment>(data?.organization?.chats);

    if (!chats.length) return { chats: [], unreadMessagesCount };

    // Filter archived and empty chats
    chats = chats.filter(({ myInteraction, messages }) => !myInteraction.archivedAt && messages.edges.length > 0);

    // Sort by message createdAt
    chats = chats.sort(({ messages: a }, { messages: b }) => {
      const momentA = moment.utc(a.edges[0]?.node?.createdAt);
      const momentB = moment.utc(b.edges[0]?.node?.createdAt);
      if (momentA === momentB) return 0;
      return momentA < momentB ? 1 : -1;
    });

    let hasActiveChats = false;
    let hasInactiveChats = false;

    const mappedChats = chats.map(({ interactions, messages, ...rest }) => {
      const _interactions = mapConnection(interactions).filter(({ archivedAt }) => !archivedAt);
      const _messages = mapConnection(messages);
      const _isActive = isChatActive(_messages[0].createdAt);
      const _hasNewMessage = rest.lastMessageAt > rest.myInteraction.lastOpenedAt || !rest.myInteraction.lastOpenedAt;

      if (_isActive) hasActiveChats = true;
      if (!_isActive) hasInactiveChats = true;
      if (_hasNewMessage) unreadMessagesCount = unreadMessagesCount + 1;

      return {
        ...rest,
        interactions: _interactions,
        messages: _messages,
        isActive: _isActive,
        isGroupChat: _interactions.length > 2,
        isAccepted: !!rest.myInteraction.acceptedAt,
        lastMessage: _messages[_messages.length - 1],
        hasNewMessage: _hasNewMessage,
        hasLastActiveChatsDivider: false,
        hasOlderChatsDivider: false,
      };
    });

    const hasDividers = hasActiveChats && hasInactiveChats;

    if (hasDividers) {
      let activeDividerSet = false;
      let olderDividerSet = false;

      return {
        chats: mappedChats.map((ch) => {
          if (ch.isActive && !activeDividerSet) {
            activeDividerSet = true;
            return { ...ch, hasLastActiveChatsDivider: true };
          }

          if (!ch.isActive && !olderDividerSet) {
            olderDividerSet = true;
            return { ...ch, hasOlderChatsDivider: true };
          }

          return ch;
        }),
        unreadMessagesCount,
      };
    }

    return { chats: mappedChats, unreadMessagesCount };
  }, [data]);

  const createChat = useCallback(
    async (user: string) => {
      try {
        const { data } = await chatCreate({ variables: { input: { organization, users: [user] } } });

        if (!data?.chatCreate.chat.id) return;

        openChat(data.chatCreate.chat.id);
      } catch (e: any) {
        resolveError(e);
      }
    },
    [chatCreate, openChat, organization]
  );

  const openGroupDialog = useCallback(() => openGroupSettings(), [openGroupSettings]);

  return {
    data: { unreadMessagesCount, unresolvedCount: activeOrganization?.unresolvedChatCount || 0, chats },
    state: { compact, loading },
    handlers: { toggleCompact, createChat, openGroupDialog },
  };
};

/** Chat with a new message within last 24hrs is considered as active. */
const isChatActive = (lastMessageCreatedAt?: string): boolean => {
  if (!lastMessageCreatedAt) return false;

  return moment.utc(lastMessageCreatedAt) > moment().subtract(1, "days");
};
