import React, { useCallback, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Hexagon } from "components/Hexagon";
import { escapeOutput } from "helpers";
import { StoryCommentFragment } from "api";
import { font, theme } from "styles";

interface Props {
  comment: StoryCommentFragment;
}

export const Comment = ({ comment }: Props) => {
  const [timeVisible, setTimeVisible] = useState(false);

  const toggleTime = useCallback(() => setTimeVisible((prevState) => !prevState), []);

  return (
    <CommentWrapper>
      <CommentAuthorImage imageSource={comment.author.profileImage} size={{ width: "35px", height: "37px" }} />

      <CommentContent>
        <CommentBubble onClick={toggleTime}>
          <AuthorName>
            {comment.author.firstName} {comment.author.lastName}:{" "}
          </AuthorName>
          <span dangerouslySetInnerHTML={{ __html: escapeOutput(comment.text).replace(/\n/g, "<br />") }} />
        </CommentBubble>

        <CommentTime visible={timeVisible}>{moment.utc(comment.createdAt).local().format("LLL")}</CommentTime>
      </CommentContent>
    </CommentWrapper>
  );
};

/**
 * Styles
 */

const CommentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 10px 0;
`;

const CommentAuthorImage = styled(Hexagon)`
  margin-right: 14px;
  margin-top: 7px;
`;

const CommentBubble = styled.div`
  background: ${theme.colors.FadingGrey};
  border-radius: 20px;
  padding: 15px 20px;
  max-width: 290px;

  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 14px;
  color: ${theme.colors.NightSky};
  line-height: 22px;

  cursor: pointer;
`;

const CommentContent = styled.div``;

const CommentTime = styled.div.withConfig({ shouldForwardProp: (prop) => !["visible"].includes(prop) })<{
  visible: boolean;
}>`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 11px;
  color: ${theme.colors.IronGrey};
  height: ${(props) => (props.visible ? "26px" : "0px")};
  overflow: hidden;
  line-height: 26px;
  transition: all 300ms;
  margin-left: 20px;
`;

const AuthorName = styled.span`
  font-weight: ${font.weight.semiBold};
`;
