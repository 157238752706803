import React, { useCallback, useMemo, MouseEvent } from "react";
import styled from "styled-components";
import { Icon } from "components";
import { WindowHeader } from "chat/components/WindowHeader";
import { MessageList } from "chat/components/message/MessageList";
import { PopupMenu } from "chat/components/conversation/PopupMenu";
import { MessageInput } from "chat/components/message/MessageInput";
import { useConversation } from "chat/hooks/useConversation";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";
import { ChatQuery } from "api";

interface Props {
  chat: string;
  chatQueryData: ChatQuery;
  compact: boolean;
  onClose: () => any;
  onToggleCompact: () => any;
  onOpenGroupSettings: () => any;
  onOpenSelectNewOwner: () => any;
  onOpenActivity: () => any;
}

export const Conversation = ({
  chat,
  chatQueryData,
  compact,
  onClose,
  onToggleCompact,
  onOpenGroupSettings,
  onOpenSelectNewOwner,
  onOpenActivity,
}: Props) => {
  const { t } = useTranslation();

  const { refs, data, state, handlers } = useConversation(
    chat,
    chatQueryData,
    compact,
    onClose,
    onToggleCompact,
    onOpenSelectNewOwner
  );

  const handleClickHeaderControls = useCallback((e: MouseEvent<HTMLDivElement>) => e.stopPropagation(), []);

  const headerText = useMemo(() => {
    if (state.hasUnreadMessages) return t(tk.chat.newMessages);

    if (chatQueryData.chat?.name && data.otherInteractions.length > 1) return chatQueryData.chat.name;

    const names = data.otherInteractions.map(({ user }) => `${user.firstName} ${user.lastName}`);

    if (names.length === 1) return names[0];

    const text = names.slice(0, 1).join(", ");

    return t(tk.chat.namesAndOthers, { names: text, count: names.length - 1 });
  }, [chatQueryData.chat, data.otherInteractions, state.hasUnreadMessages, t]);

  const options = useMemo(() => {
    return [
      {
        title: data.isGroupChat ? t(tk.chat.settings.groupSettings) : t(tk.chat.settings.createGroup),
        action: onOpenGroupSettings,
      },
      {
        title: data.isMuted ? t(tk.chat.settings.unmute) : t(tk.chat.settings.mute),
        action: handlers.toggleMuted,
      },
      {
        title: t(tk.chat.activity.heading),
        action: onOpenActivity,
      },
      {
        title: data.isGroupChat ? t(tk.chat.settings.leaveGroupAndDelete) : t(tk.chat.settings.delete),
        action: handlers.leaveConversation,
      },
    ];
  }, [
    data.isGroupChat,
    data.isMuted,
    handlers.leaveConversation,
    handlers.toggleMuted,
    onOpenActivity,
    onOpenGroupSettings,
    t,
  ]);

  return (
    <ComponentWrapper>
      <WindowHeader isClickable={true} onClick={handlers.toggleCompact}>
        <HeaderTitle hasUnreadMessages={state.hasUnreadMessages}>
          {state.hasUnreadMessages && <ChatMessageIcon shape={"chat"} size={"22px"} color={theme.colors.TealGreen} />}
          {headerText}
        </HeaderTitle>
        <HeaderControls onClick={handleClickHeaderControls}>
          <PopupMenu icon={"settings"} correctionTop={compact ? 80 : 0} menuItems={options} />
          <ChatIcon shape={"cancel"} size={"26px"} onClick={onClose} />
        </HeaderControls>
      </WindowHeader>

      {!compact && (
        <>
          <Divider />

          {/** Group chat inactive users info */}
          {data.inactiveUsers.length > 0 && (
            <NotActiveUsersBlock>
              {data.isGroupChat && data.inactiveUsers.length === 1
                ? t(tk.chat.inactiveUser, { name: data.inactiveUsers[0].firstName })
                : t(tk.chat.inactiveUsers)}
            </NotActiveUsersBlock>
          )}

          {/** Messages */}
          <MessageList
            messages={data.messages}
            isGroupChat={data.isGroupChat}
            myUserId={data.me?.userId || ""}
            wrapperRef={refs.messageListRef}
            onScroll={handlers.handleScroll}
            scrollToBottom={handlers.scrollToBottom}
          />

          <Divider />

          {/** Input */}
          <MessageInput placeholder={t(tk.chat.messageInput.placeholder)} onSubmit={handlers.submitMessage} />
        </>
      )}
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const ComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.span.withConfig({ shouldForwardProp: (prop) => !["hasUnreadMessages"].includes(prop) })<{
  hasUnreadMessages: boolean;
}>`
  color: ${({ hasUnreadMessages }) => (hasUnreadMessages ? theme.colors.TealGreen : "inherit")};
  display: flex;
  align-items: center;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid #ececec;
`;

const ChatIcon = styled(Icon)`
  color: ${theme.colors.IronGrey};
  font-weight: ${font.weight.regular};
  cursor: pointer;
`;

const NotActiveUsersBlock = styled.div`
  background-color: #f2f2f2;
  color: ${theme.colors.IronGrey};
  padding: 15px 5px;
  text-align: center;
  line-height: 20px;
`;

const ChatMessageIcon = styled(Icon)`
  color: ${(props) => props.color};
  font-weight: ${font.weight.regular};
  padding: 0 9px 0 0;
  cursor: pointer;
  display: block;
`;
