import React, { PropsWithChildren } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${fadeIn} 0.3s ease-out 0s 1 normal forwards;
  z-index: 9999;
`;

interface Props extends PropsWithChildren {
  onClick?: () => void;
}

export class DialogScreen extends React.Component<Props> {
  render() {
    return <Screen onClick={this.props.onClick}>{this.props.children}</Screen>;
  }
}
