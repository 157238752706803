import React from "react";
import { theme } from "styles";
import { IconCommonPropsType } from "icons/types";

export const IconDocument = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.002 31.376"
      style={{ margin }}
    >
      <path
        id="document"
        d="M26.438,29.938H5.563a.22.22,0,0,1-.063-.125V1.563A.062.062,0,0,1,5.563,1.5H20.751V5.563a1.632,1.632,0,0,0,1.563,1.563H26.5V29.814a.216.216,0,0,1-.063.125ZM22.313,5.625l-.063-.063V2.5l3.188,3.125ZM28,6.375a.766.766,0,0,0-.25-.563L22.062.187A1.01,1.01,0,0,0,21.5,0H5.561A1.548,1.548,0,0,0,4,1.562v28.25a1.548,1.548,0,0,0,1.563,1.563H26.436A1.548,1.548,0,0,0,28,29.812Zm-5.375,3.688H9.375a.722.722,0,0,0-.75.75.71.71,0,0,0,.75.688h13.25a.709.709,0,0,0,.75-.688.722.722,0,0,0-.75-.75m0,4.437H9.375a.722.722,0,0,0-.75.75.71.71,0,0,0,.75.688h13.25a.709.709,0,0,0,.75-.688.722.722,0,0,0-.75-.75m0,4.438H9.375a.722.722,0,0,0-.75.75.71.71,0,0,0,.75.688h13.25a.709.709,0,0,0,.75-.688.722.722,0,0,0-.75-.75M16,23.375H9.375a.709.709,0,0,0-.75.688.722.722,0,0,0,.75.75H16a.722.722,0,0,0,.75-.75.71.71,0,0,0-.75-.688"
        transform="translate(-3.998 0.001)"
        fill={color}
      />
    </svg>
  );
};
