import { useCallback, useMemo, useState } from "react";
import { ALPHABET } from "components/ReactionList";

const maxCount = ALPHABET.length;

export const useReactions = () => {
  // Index of input with autoFocus
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [reactions, setReactions] = useState<string[]>([""]);

  const canAdd = useMemo(() => reactions.length + 1 <= maxCount, [reactions.length]);

  const sanitizedReactions = useMemo(() => reactions.filter((reaction) => reaction.length > 0), [reactions]);

  const stringReactions = useMemo(() => sanitizedReactions.join(", "), [sanitizedReactions]);

  const addReaction = useCallback(() => {
    if (!canAdd) return;

    setActiveIndex(reactions.length);
    setReactions([...reactions, ""]);
  }, [canAdd, reactions]);

  const removeReaction = useCallback((index: number) => setReactions(reactions.filter((_, i) => i !== index)), [
    reactions,
  ]);

  const handleChange = useCallback(
    (index: number, value: string) => setReactions(reactions.map((r, i) => (i === index ? value : r))),
    [reactions]
  );

  const clear = useCallback(() => {
    setActiveIndex(0);
    setReactions([""]);
  }, []);

  return {
    state: { activeIndex, reactions, canAdd, sanitizedReactions, stringReactions },
    handlers: { addReaction, removeReaction, handleChange, clear },
  };
};
