import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Icon, Hexagon, Button } from "components";
import { useMe } from "hooks";
import { WindowHeader } from "chat/components/WindowHeader";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";
import { ChatQuery, resolveError, useChatAcceptInvitationMutation, useChatLeaveMutation } from "api";

interface Props {
  chatQueryData: ChatQuery;
  onCloseChat: () => void;
  onAcceptInvitation: () => void;
  onRejectInvitation: () => void;
}

export const Invitation = ({ chatQueryData, onCloseChat, onAcceptInvitation, onRejectInvitation }: Props) => {
  const { t } = useTranslation();
  const { me } = useMe();

  const [accept, { loading: loadingAccept }] = useChatAcceptInvitationMutation();
  const [reject, { loading: loadingReject }] = useChatLeaveMutation();

  const loading = useMemo(() => loadingAccept || loadingReject, [loadingAccept, loadingReject]);

  const acceptInvitation = useCallback(async () => {
    if (loading || !chatQueryData.chat) return;

    try {
      await accept({ variables: { input: { chat: chatQueryData.chat.id } } });
      onAcceptInvitation();
    } catch (e: any) {
      resolveError(e);
    }
  }, [accept, chatQueryData.chat, loading, onAcceptInvitation]);

  const rejectInvitation = useCallback(async () => {
    if (loading || !chatQueryData.chat) return;

    try {
      await reject({ variables: { input: { chat: chatQueryData.chat.id } } });
      onRejectInvitation();
    } catch (e: any) {
      resolveError(e);
    }
  }, [chatQueryData.chat, loading, onRejectInvitation, reject]);

  if (!chatQueryData.chat) return null;

  const { name, myInteraction, interactions } = chatQueryData.chat;
  const author = myInteraction.createdBy;
  const otherInteractions = interactions.edges.filter(
    (edge) => edge?.node?.user.id !== author.id && edge?.node?.user.id !== me?.userId
  );
  const otherNames = otherInteractions.map(
    (interaction) => `${interaction?.node?.user.firstName} ${interaction?.node?.user.lastName}`
  );
  let names = otherNames.slice(0, 3).join(", ");
  const remains = otherNames.length - 3;
  if (remains > 0) names = t(tk.chat.namesAndOthers, { names, count: remains });
  const hexagonSize = { width: "50px", height: "50px" };

  return (
    <ComponentWrapper>
      <WindowHeader>
        <div>{name || ""}</div>
        <ChatIcon shape={"cancel"} size={"26px"} onClick={onCloseChat} />
      </WindowHeader>

      <Divider />

      <ContentWrapper>
        <ProfileImages>
          <Hexagon imageSource={author.profileImage} size={hexagonSize} />
          <Hexagon imageSource={otherInteractions[0]?.node?.user.profileImage} size={hexagonSize} />
          {otherInteractions.length === 2 && (
            <Hexagon imageSource={otherInteractions[1]?.node?.user.profileImage} size={hexagonSize} />
          )}
          {otherInteractions.length > 2 && (
            <Hexagon size={hexagonSize} color={"#F2F2F2"} characterColor={theme.colors.TealGreen}>
              {`+${otherInteractions.length - 1}`}
            </Hexagon>
          )}
        </ProfileImages>

        <strong>{name || ""}</strong>

        <p>{t(tk.chat.groupChatInvitation, { user: author.firstName, otherUsers: names })}</p>
      </ContentWrapper>

      <AcceptButtonWrapper>
        <Button color={"blue"} isDisabled={loading} onClick={acceptInvitation}>
          {t(tk.chat.acceptInvitation)}
        </Button>
      </AcceptButtonWrapper>

      <RejectLink onClick={rejectInvitation}>{t(tk.chat.rejectInvitation)}</RejectLink>
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const ComponentWrapper = styled.div`
  text-align: center;
`;

const ChatIcon = styled(Icon)`
  color: ${theme.colors.IronGrey};
  font-weight: ${font.weight.regular};
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${theme.colors.SilverGrey};
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const ProfileImages = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const AcceptButtonWrapper = styled.div`
  width: 200px;
  margin: 0 auto 20px auto;
`;

const RejectLink = styled.a`
  color: #b33938;
  cursor: pointer;
  font-weight: ${font.weight.semiBold};
`;
