import { useCallback } from "react";
import sendAudioSrc from "assets/sounds/chat-send.mp3";
import receiveAudioSrc from "assets/sounds/chat-receive.mp3";

const sendAudio = new Audio(sendAudioSrc);
const receiveAudio = new Audio(receiveAudioSrc);

sendAudio.volume = 0.3;
receiveAudio.volume = 0.7;

export const useSound = () => {
  const playSend = useCallback(async () => {
    try {
      await sendAudio.play();
    } catch (e: any) {
      // ignore
    }
  }, []);

  const playReceive = useCallback(async () => {
    try {
      await receiveAudio.play();
    } catch (e: any) {
      // ignore
    }
  }, []);

  return { playSend, playReceive };
};
