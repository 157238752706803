import React from "react";
import styled from "styled-components";
import { tk, useTranslation } from "translations";
import { font } from "styles";

const NoContentImage = require("assets/images/no-content.png");

type Props = {
  notice?: string;
  margin?: string;
  width?: string;
};

export const NoContentNotice = ({ notice, margin, width }: Props) => {
  const { t } = useTranslation();

  return (
    <ContentContainer margin={margin} width={width}>
      <ImageSection>
        <Image src={NoContentImage} />
      </ImageSection>
      <NoticeSection>
        <Notice>{!notice ? t(tk.common.noContent) : notice}</Notice>
      </NoticeSection>
    </ContentContainer>
  );
};

/**
 * Styles
 */

const ContentContainer = styled.div.withConfig({ shouldForwardProp: (prop) => !["margin", "width"].includes(prop) })<{
  margin?: string;
  width?: string;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width || "auto"};
`;

const ImageSection = styled.div`
  position: relative;
`;

const NoticeSection = styled.div`
  position: relative;
  margin-top: 12px;
  text-align: center;
`;

const Image = styled.img`
  width: 100px;
`;

const Notice = styled.span`
  font-weight: ${font.weight.regular};
  font-size: 16px;
  color: #000000;
  line-height: 24px;
  white-space: pre-wrap;
`;
