type EnvKey =
  | "NODE_ENV"
  | "PUBLIC_URL"
  | "REACT_APP_API_URL"
  | "REACT_APP_API_WSS_URL"
  | "REACT_APP_PRIVACY_POLICY_URL"
  | "REACT_APP_MOYOBO_WEB_URL"
  | "REACT_APP_SENTRY_DSN"
  | "REACT_APP_SENTRY_RELEASE"
  | "REACT_APP_GTM_ID";

export const getEnv = (key: EnvKey): string => {
  const value = process.env[key];

  if (value !== undefined) return value;

  throw new Error(`ENV variable "${key}" is not defined.`);
};
