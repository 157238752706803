import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { animated, useTransition } from "react-spring";
import { theme } from "styles";

type Props = {
  children: string;
};

export const FieldErrorMessage = ({ children }: Props) => {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (children !== "") {
      setErrorMessage(children);
    }
  }, [children]);

  const transition = useTransition(Boolean(children), null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <Container>
      {transition.map(
        ({ item, props: style, key }) =>
          item && (
            <StyledInputErrorMessage style={style} key={key}>
              {errorMessage}
            </StyledInputErrorMessage>
          )
      )}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 20px;
  width: 100%;
  margin-top: 8px;
`;

const StyledInputErrorMessage = styled(animated.span)`
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.fieldErrorMessage.fontColor};
`;
