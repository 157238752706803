import styled from "styled-components";

export const TabPanels = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  grid-area: tab-panels;
`;
