import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { LanguageSelectContext } from "components/LanguageSelect/LanguageSelect";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
};

export const LanguageSelectListItem = ({ children, onClick }: Props) => {
  const { isSelectOpen, setIsSelectOpen } = useContext(LanguageSelectContext);

  const animationStyle = useSpring({
    transform: `translate3d(0, ${isSelectOpen ? "0" : "-10"}px, 0)`,
    opacity: isSelectOpen ? 1 : 0,
  });

  const handleClick = useCallback(async () => {
    if (!isSelectOpen) return;

    await onClick();

    if (setIsSelectOpen) setIsSelectOpen(false);
  }, [isSelectOpen, onClick, setIsSelectOpen]);

  return (
    <AnimatedListItem style={animationStyle} onClick={handleClick}>
      {children}
    </AnimatedListItem>
  );
};

const AnimatedListItem = styled(animated.li)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
`;
