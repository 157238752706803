import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTitle } from "react-use";
import { ListItem, ListItemMetaText, ListItemText, ListItemTextWrapper } from "components";
import * as Tabs from "components/Tabs";
import { IconSupport } from "icons";
import { useDashboardState, useSupport } from "hooks";
import { HelpItem } from "helpers";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";
import { getRoute } from "routes";

interface Props {
  tab: "regular" | "manager" | "feedback";
  activeTopicId?: string;
  support?: ReturnType<typeof useSupport>;
  children: React.ReactNode;
}

export const SupportLayout = ({ tab, activeTopicId, support, children }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.support.heading) + " " + t(tk.common.documentTitleSuffix));

  const contentContainerRef = useRef<HTMLDivElement>(null);

  const { organization, space } = useDashboardState();

  const handleClickRegular = useCallback(() => {
    history.push(getRoute.support({ organization, space, role: "regular", topic: "all" }));
  }, [history, organization, space]);

  const handleClickManager = useCallback(() => {
    history.push(getRoute.support({ organization, space, role: "manager", topic: "all" }));
  }, [history, organization, space]);

  const handleClickFeedback = useCallback(() => {
    history.push(getRoute.feedback({ organization, space }));
  }, [history, organization, space]);

  const selectTopic = useCallback(
    (topic: string) => {
      if (tab === "feedback") return;

      history.push(getRoute.support({ organization, space, role: tab, topic }));
    },
    [history, organization, space, tab]
  );

  useEffect(() => contentContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" }), [activeTopicId]);

  return (
    <Container>
      <Navigation>
        <NavigationWrapper>
          <Heading>{t(tk.support.heading)}</Heading>
        </NavigationWrapper>
      </Navigation>

      <Content>
        <Tabs.Tabs>
          <Tabs.TabList>
            <Tabs.Tab isActive={tab === "regular"} onClick={handleClickRegular}>
              {t(tk.role.regular)}
            </Tabs.Tab>
            <Tabs.Tab isActive={tab === "manager"} onClick={handleClickManager}>
              {t(tk.role.manager)}
            </Tabs.Tab>
            <Tabs.Tab isActive={tab === "feedback"} onClick={handleClickFeedback}>
              {t(tk.support.feedback)}
            </Tabs.Tab>
          </Tabs.TabList>

          <Tabs.TabPanels>
            <Tabs.TabPanel>
              {support &&
                tab === "regular" &&
                support.regular.root.map((topic) => (
                  <Topic key={topic.id} topic={topic} activeTopicId={activeTopicId || ""} onClick={selectTopic} />
                ))}

              {support &&
                tab === "manager" &&
                support.manager.root.map((topic) => (
                  <Topic key={topic.id} topic={topic} activeTopicId={activeTopicId || ""} onClick={selectTopic} />
                ))}

              {tab === "feedback" && (
                <ItemWrapper>
                  <ListItem isActive={true} hasIllustration={true} hasIndicator={true} onClick={() => {}}>
                    <IconSupport width={22} height={24} color={theme.colors.TealGreen} />
                    <ListItemTextWrapper margin={"0 15px"}>
                      <ListItemText color={"green"} isBold={false}>
                        {t(tk.support.reportProblem)}
                      </ListItemText>
                      <ListItemMetaText>{t(tk.common.rightNow)}</ListItemMetaText>
                    </ListItemTextWrapper>
                  </ListItem>
                </ItemWrapper>
              )}
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs.Tabs>

        <ContentContainer ref={contentContainerRef}>{children}</ContentContainer>
      </Content>
    </Container>
  );
};

interface TopicProps {
  topic: HelpItem;
  activeTopicId: string;
  onClick: (id: string) => any;
}

const Topic = ({ topic, activeTopicId, onClick }: TopicProps) => {
  const handleClick = useCallback(() => onClick(topic.id), [onClick, topic.id]);

  return (
    <ItemWrapper key={topic.id}>
      <ListItem isActive={topic.id === activeTopicId} hasIllustration={true} hasIndicator={true} onClick={handleClick}>
        <IconSupport width={22} height={24} color={theme.colors.TealGreen} />
        <ListItemText color={"green"} isBold={false} margin={"0 15px"}>
          {topic.title}
        </ListItemText>
      </ListItem>
    </ItemWrapper>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  position: relative;
  flex: 1 auto;
  overflow: hidden;
`;

const Content = styled("div")`
  display: flex;
  flex: 1 100%;
  height: calc(100% - 65px);
`;

const Navigation = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: end;
  height: 65px;
  width: 100%;
  padding: 0 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.navbar.borderColor};
  background-color: ${theme.navbar.backgroundColor};
  grid-area: navbar;
`;

const NavigationWrapper = styled("nav")`
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  align-items: center;
`;

const Heading = styled.span`
  font-size: 14px;
  font-weight: ${font.weight.bold};
  color: ${theme.sidebarTitle.fontColor};
  text-transform: uppercase;
`;

const ContentContainer = styled("div")`
  position: relative;
  width: 100%;
  padding: 0 25px;
  overflow: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
`;
