import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useDashboardState, useMe, useUnresolvedRefetch } from "hooks";
import {
  announcementLogsUpdateQuery,
  mapConnection,
  resolveError,
  sortByLast,
  useAnnouncementChangeSubscription,
  useAnnouncementInteractMutation,
  useAnnouncementLogsQuery,
  useAnnouncementQuery,
  useAnnouncementReactionsChangesSeenMutation,
} from "api";
import { tk, useTranslation } from "translations";
import { AnnouncementsTabs, getRoute } from "routes";
import { isBefore, isBeforeNow } from "helpers";

export const useAnnouncementsDetail = (tab: AnnouncementsTabs, id: string) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.announcements) + " " + t(tk.common.documentTitleSuffix));

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [reactionDialog, setReactionDialog] = useState<"added" | "changed" | undefined>(undefined);
  const [optionsVisible, setOptionsVisible] = useState(false);

  const { isManager } = useMe();
  const refetchUnresolved = useUnresolvedRefetch();

  const { organization, space } = useDashboardState();

  const isSent = useMemo(() => tab === "sent", [tab]);

  const { data, loading, refetch } = useAnnouncementQuery({
    variables: { id, sent: isSent },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: logsData,
    loading: loadingLogs,
    fetchMore: fetchMoreLogs,
    refetch: refetchLogs,
  } = useAnnouncementLogsQuery({
    variables: { id, before: null },
    fetchPolicy: "cache-and-network",
    skip: !isSent,
  });

  useAnnouncementChangeSubscription({
    skip: !isManager,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.onAnnouncementChange?.announcement?.id !== id) return;
      refetch().finally();
      refetchLogs().finally();
    },
  });

  const [reactionsChangesSeen] = useAnnouncementReactionsChangesSeenMutation();

  const [interact, { loading: interacting }] = useAnnouncementInteractMutation();

  const announcement = useMemo(() => data?.announcement, [data]);

  const interactions = useMemo(
    () => (announcement?.interactions ? mapConnection(announcement?.interactions) : []),
    [announcement]
  );

  const reactions = useMemo(
    () => mapConnection(announcement?.reactions).sort(({ position: a }, { position: b }) => a - b),
    [announcement]
  );

  const logs = useMemo(() => sortByLast(mapConnection(logsData?.announcement?.logs)), [logsData]);

  const hasMoreLogs = useMemo(() => logsData?.announcement?.logs.pageInfo.hasPreviousPage || false, [logsData]);

  const isMarked = useMemo(() => !!announcement?.myInteraction?.marked, [announcement]);

  const isAfterDeadline = useMemo(() => {
    if (!announcement?.reactionDeadlineAt) return false;
    return isBeforeNow(announcement.reactionDeadlineAt);
  }, [announcement]);

  const createAnnouncement = useCallback(() => {
    history.push(getRoute.announcementsCreate({ organization, space, tab: "sent" }));
  }, [history, organization, space]);

  const deleteAnnouncement = useCallback(() => setDeleteDialogVisible(true), []);

  const archiveAnnouncement = useCallback(async () => {
    try {
      await interact({ variables: { input: { announcement: id, archived: true } } });
      history.replace(getRoute.announcements({ organization, space, tab: "archive" }));
    } catch (e: any) {
      resolveError(e);
    }
  }, [history, id, interact, organization, space]);

  const toggleAnnouncementMark = useCallback(async () => {
    if (interacting) return;

    try {
      await interact({ variables: { input: { announcement: id, marked: !isMarked } } });
    } catch (e: any) {
      resolveError(e);
    }
  }, [id, interact, interacting, isMarked]);

  const closeReactionDialog = useCallback(() => setReactionDialog(undefined), []);

  const handleReaction = useCallback(
    async (reaction: string) => {
      const isNew = !announcement?.myInteraction?.reactedAt;

      try {
        await interact({ variables: { input: { announcement: id, reaction } } });
        if (isNew) await refetchUnresolved();
        setReactionDialog(isNew ? "added" : "changed");
      } catch (e: any) {
        resolveError(e);
      }
    },
    [announcement, id, interact, refetchUnresolved]
  );

  const markReactionsAsSeen = useCallback(async () => {
    if (!isSent || !reactions.length || !logs.length) return;

    const skip = announcement?.lastChangesSeenAt && !isBefore(announcement?.lastChangesSeenAt, logs[0].createdAt);

    if (skip) return;

    try {
      await reactionsChangesSeen({ variables: { input: { announcement: id } } });
    } catch (e: any) {
      resolveError(e);
    }
  }, [announcement, id, isSent, logs, reactions.length, reactionsChangesSeen]);

  const loadMoreLogs = useCallback(async () => {
    const before = logsData?.announcement?.logs.pageInfo.startCursor || null;
    await fetchMoreLogs({ variables: { id, before }, updateQuery: announcementLogsUpdateQuery });
  }, [fetchMoreLogs, id, logsData]);

  const toggleOptions = useCallback(() => setOptionsVisible(!optionsVisible), [optionsVisible]);

  const onActionsSuccess = useCallback(() => {
    refetch().finally();
    refetchLogs().finally();
    toggleOptions();
  }, [refetch, refetchLogs, toggleOptions]);

  useEffect(() => {
    markReactionsAsSeen().finally();
  }, [markReactionsAsSeen]);

  return {
    t,
    tk,
    data: { isManager, announcement, interactions, reactions, logs, hasMoreLogs, isMarked, isAfterDeadline },
    state: { id, tab, loading, loadingLogs, deleteDialogVisible, reactionDialog, isSent, optionsVisible },
    handlers: {
      createAnnouncement,
      deleteAnnouncement,
      archiveAnnouncement,
      toggleAnnouncementMark,
      handleReaction,
      closeReactionDialog,
      loadMoreLogs,
      toggleOptions,
      onActionsSuccess,
    },
  };
};
