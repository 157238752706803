import React, { useCallback } from "react";
import styled from "styled-components";
import { RecipientsSelectionAddButton } from "components/RecipientSelection/RecipientsSelectionAddButton";
import { Spinner } from "components/Spinner";
import { IconChecked } from "icons";
import { tk, useTranslation } from "translations";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
  id: string;
  selected: boolean;
  loading?: boolean;
  clickRow?: boolean;
  onClick: (id: string) => void;
};

export const SearchInputResultsListItem = ({ children, id, selected, loading, clickRow = false, onClick }: Props) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <Container clickable={clickRow} onClick={clickRow ? handleClick : undefined}>
      {children}
      <AddButtonWrapper>
        {loading && (
          <SpinnerContainer>
            <Spinner color={theme.colors.TealGreen} size={{ width: "3px", height: "30px", gap: "2px" }} />
          </SpinnerContainer>
        )}

        {!loading && !selected && (
          <RecipientsSelectionAddButton onClick={handleClick}>
            {t(tk.recipientsSelection.addButtonLabel)}
          </RecipientsSelectionAddButton>
        )}

        {!loading && selected && (
          <SpinnerContainer>
            <IconChecked color={theme.colors.TealGreen} width={"16px"} height={"12px"} />
          </SpinnerContainer>
        )}
      </AddButtonWrapper>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["clickable"].includes(prop) })<{
  clickable: boolean;
}>`
  display: grid;
  position: relative;
  grid-template-columns: max-content 1fr 1fr max-content;
  grid-template-rows: max-content;
  grid-template-areas: ". . . add-button";
  grid-column-gap: 10px;
  align-items: center;
  width: 100%;
  padding: 2px 10px;
  border-radius: 3px;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};

  &:hover {
    background-color: ${theme.searchInputResultsListItem.backgroundColor};
  }
`;

const AddButtonWrapper = styled("div")`
  display: flex;
  position: relative;
  grid-area: add-button;
`;

const SpinnerContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  width: 56px;
`;
