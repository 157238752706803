import React, { useCallback } from "react";
import { Media } from "hooks";
import styled from "styled-components";
import { IconGallery } from "icons";
import { font, theme } from "styles";
import { tk, useTranslation } from "translations";
import { ImageCrop } from "components/ImageCrop";

interface Props {
  media: Media;
  currentImageSrc?: string;
  onDelete?: () => any;
}

export const ImageUpload = ({ media, currentImageSrc, onDelete = () => {} }: Props) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    media.clear();
    onDelete();
  }, [media, onDelete]);

  const hasImage = !!media.preview || !!currentImageSrc;

  return (
    <Container>
      <InnerContainer>
        {hasImage && (
          <ImageWrapper>
            <Image src={media.preview || currentImageSrc} />
          </ImageWrapper>
        )}

        <ImageCrop media={media} />

        <UploadArea>
          <UploadInfoContainer>
            <IconGallery height={35} width={40} color={theme.colors.IronGrey} margin={15} />
            <UploadInfo>{!hasImage ? t(tk.imageUpload.drop.empty) : t(tk.imageUpload.drop.replace)}</UploadInfo>
          </UploadInfoContainer>
          <UploadInput {...media.fileInputProps} hidden={false} />
        </UploadArea>
      </InnerContainer>

      {hasImage && (
        <DeleteLinkContainer>
          <DeleteLink onClick={handleDelete}>{t(tk.common.delete)}</DeleteLink>
        </DeleteLinkContainer>
      )}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  max-width: 50%;
  margin-right: 20px;
`;

const Image = styled.img`
  aspect-ratio: 4/3;
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  height: auto;
  background-color: ${theme.colors.FadingGrey};
`;

const UploadArea = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 128px;
  background-color: #f9f9f9;
  border: 1px dashed #bfc0c2;
  border-radius: 5px;
`;

const UploadInfoContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const UploadInfo = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: #9b9b9b;
  margin-top: 6px;
`;

const UploadInput = styled.input`
  flex-grow: 1;
  opacity: 0;
  cursor: pointer;
`;

const DeleteLinkContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 5px;
`;

const DeleteLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.colors.FireRed};
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: ${font.weight.regular};
`;
