import styled from "styled-components";
import { font, theme } from "styles";

export const LandingPageCardText = styled("p")`
  position: relative;
  font-size: 16px;
  font-weight: ${font.weight.regular};
  line-height: 24px;
  color: ${theme.landingPageCardText.fontColor};
  margin-top: 0;
  margin-bottom: 24px;
  align-self: flex-start;
`;
