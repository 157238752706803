import styled from "styled-components";

export const LanguageName = styled("span")`
  font-size: 14px;
  font-weight: 400;
  color: #303c48;
  margin-left: 20px;
  width: 100%;
  text-align: left;
`;
