import React, { createContext, PropsWithChildren, useRef, useState } from "react";
import * as serviceWorker from "serviceWorker";

type Value = {
  isUpdateAvailable: boolean;
  updateAssets: () => void;
};

const ServiceWorkerContext = createContext<Value>({ isUpdateAvailable: false, updateAssets: () => {} });

export const ServiceWorkerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  const updateInterval = useRef(0);

  React.useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);

        updateInterval.current = window.setInterval(() => registration.update(), 1000 * 60 * 5);
      },
      onWaiting: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);

        updateInterval.current = window.setInterval(() => registration.update(), 1000 * 60 * 5);
      },
    });

    return () => window.clearInterval(updateInterval.current);
  }, []);

  React.useEffect(() => {
    if (!waitingServiceWorker) return;

    waitingServiceWorker.addEventListener("statechange", (event: Event) => {
      // @ts-ignore
      if (event.target?.state === "activated") window.location.reload();
    });
  }, [waitingServiceWorker]);

  const value = React.useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

export const useServiceWorker = () => {
  return React.useContext(ServiceWorkerContext);
};

/** Source: https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a */
