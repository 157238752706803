import React from "react";
import styled from "styled-components";
import { Hexagon } from "components/Hexagon";
import { font, theme } from "styles";

type Props = {
  profileImageSource: string;
  firstName: string;
  lastName: string;
  activity: string;
};

export class DetailLatestActivityDetail extends React.PureComponent<Props> {
  render() {
    return (
      <ContentContainer>
        <Hexagon
          imageSource={this.props.profileImageSource}
          size={{
            width: "22px",
            height: "24px",
          }}
        />
        <Text>{`${this.props.firstName} ${this.props.lastName} ${this.props.activity}`}</Text>
      </ContentContainer>
    );
  }
}

/**
 * Styles
 */

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const Text = styled.span`
  font-weight: ${font.weight.medium};
  font-size: 12px;
  color: ${theme.colors.IronGrey};
  padding: 0 10px;
  line-height: 16px;
`;
