import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { Navigation } from "components";

type Props = {
  children: React.ReactNode;
  buttons: React.ReactNode;
  onScroll?: (e: SyntheticEvent<HTMLDivElement>) => any;
};

export const StoriesLayout = ({ children, buttons, onScroll }: Props) => {
  return (
    <Container>
      <Navigation>{buttons}</Navigation>

      <Content>
        <ContentContainer onScroll={onScroll}>{children}</ContentContainer>
      </Content>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  position: relative;
  flex: 1 auto;
  overflow: hidden;
`;

const Content = styled("div")`
  display: flex;
  flex: 1 100%;
  height: calc(100% - 65px);
`;

const ContentContainer = styled("div")`
  position: relative;
  width: 100%;
  padding: 0 25px;
  overflow: auto;
`;

export const StoriesLayoutActionsWrapper = styled("div")`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
