import React from "react";
import styled from "styled-components";
import { ListItemImage } from "components";
import * as Dropdown from "components/Dropdown";
import * as Language from "components/LanguageSelect";
import { IconHandshake, IconLogout } from "icons";
import { useDashboardState, useLanguages, useMe } from "hooks";
import { useAuth } from "api";
import { theme } from "styles";
import { tk, useTranslation } from "translations";
import { useHistory } from "react-router-dom";
import { getRoute } from "routes";

type Props = {
  children?: never;
};

// TODO: finish language select

export const HeaderProfileDropdown = (_: Props) => {
  const { t } = useTranslation();

  const { me, activeOrganization, loading } = useMe();
  const { logout } = useAuth();
  const { activeOption, otherOptions } = useLanguages();

  const { organization, space } = useDashboardState();
  const history = useHistory();
  const goToBenefits = () => history.push(getRoute.benefits({ organization, space }));

  if (!me || loading) return null;

  return (
    <Dropdown.Dropdown alignment={"right"}>
      <Dropdown.DropdownToggle>
        <Dropdown.DropdownToggleIndicator rotationDirection={"clockwise"} />
        <Dropdown.DropdownToggleLabel>
          {me.firstName} {me.lastName}
        </Dropdown.DropdownToggleLabel>
        <ListItemImage src={me.profileImage} alt={"user-avatar"} width={"40px"} height={"45px"} />
      </Dropdown.DropdownToggle>

      <Dropdown.DropdownMenuBox alignment={"right"}>
        <Dropdown.DropdownMenu>
          <Dropdown.DropdownMenuItem onClick={logout}>
            <IconLogout width={"18px"} color={theme.colors.DarkBlue} />
            <SignOutText>{t(tk.common.signOut)}</SignOutText>
          </Dropdown.DropdownMenuItem>
        </Dropdown.DropdownMenu>

        <DividingLine />

        {!!activeOrganization?.hasBenefits && (
          <>
            <Dropdown.DropdownMenu>
              <Dropdown.DropdownMenuItem onClick={goToBenefits}>
                <IconHandshake width={"18px"} color={theme.colors.DarkBlue} />
                <SignOutText>{t(tk.benefits)}</SignOutText>
              </Dropdown.DropdownMenuItem>
            </Dropdown.DropdownMenu>

            <DividingLine />
          </>
        )}

        <Language.LanguageSelect>
          <Language.LanguageSelectToggle>
            <Language.LanguageFlag src={activeOption.flag} />
            <Language.LanguageName>{activeOption.name}</Language.LanguageName>
            <Language.LanguageSelectToggleIndicator />
          </Language.LanguageSelectToggle>

          <Language.LanguageSelectBox itemsCount={otherOptions.length}>
            <Language.LanguageSelectList>
              {otherOptions.map(({ id, name, flag, onSelect }) => (
                <Language.LanguageSelectListItem key={id} onClick={onSelect}>
                  <Language.LanguageFlag src={flag} />
                  <Language.LanguageName>{name}</Language.LanguageName>
                </Language.LanguageSelectListItem>
              ))}
            </Language.LanguageSelectList>
          </Language.LanguageSelectBox>
        </Language.LanguageSelect>
      </Dropdown.DropdownMenuBox>
    </Dropdown.Dropdown>
  );
};

/**
 * Styles
 */

const SignOutText = styled("span")`
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.DarkBlue};
  margin: 10px 0 10px 25px;
`;

const DividingLine = styled("div")`
  display: flex;
  position: relative;
  height: 1px;
  width: 100%;
  background-color: ${theme.colors.SilverGrey};
`;
