import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconUndo = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28.001 27.822"
      style={{ margin }}
    >
      <path
        id="unarchive"
        d="M6,6.75,8.625,4.062a1.169,1.169,0,0,0,0-1.625,1.047,1.047,0,0,0-1.563-.063L2.374,7.062a1.093,1.093,0,0,0,0,1.563l4.688,4.688A1.105,1.105,0,0,0,8.625,11.75L6,8.937H18.375a9.282,9.282,0,0,1,0,18.563H3.187a1.188,1.188,0,1,0,0,2.376H18.375a11.563,11.563,0,1,0,0-23.125Z"
        transform="translate(-1.999 -2.054)"
        fill={color}
      />
    </svg>
  );
};
