import styled from "styled-components";
import { theme } from "styles";

export const LandingPageHelpText = styled("span")`
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.landingPageHelpText.fontColor};
  margin-top: 40px;
`;
