import React, { useMemo } from "react";
import styled from "styled-components";
import { DialogScreen, CloseButton, PreviewSpinner, Icon, Hexagon } from "components";
import { tk, useTranslation } from "translations";
import { useChatActivityQuery } from "api";
import { font, theme } from "styles";
import { useMe } from "hooks";
import { isSameOrAfter, timeAgo } from "helpers";

interface Props {
  chat?: string;
  onClose: () => any;
}

export const ActivityDialog = ({ chat, onClose }: Props) => {
  const { t } = useTranslation();

  const { me } = useMe();

  const { data, loading } = useChatActivityQuery({
    variables: { id: chat || "" },
    fetchPolicy: "network-only",
    skip: !chat,
  });

  const lastMessageAt = data?.chat.lastMessageAt;

  const interactions = useMemo(() => {
    return (
      data?.chat.interactions.edges
        .filter(({ node }) => node.user.id !== me?.userId)
        .map(({ node: { lastOpenedAt, user } }) => ({ lastOpenedAt, ...user })) ?? []
    );
  }, [data?.chat.interactions.edges, me?.userId]);

  if (loading) {
    return (
      <DialogScreen>
        <DialogContainer>
          <PreviewSpinner />
        </DialogContainer>
      </DialogScreen>
    );
  }

  return (
    <DialogScreen>
      <DialogContainer>
        <DialogCloseButton onClick={onClose} />
        <Heading>{t(tk.chat.activity.heading)}</Heading>
        <SubHeading>
          <Icon shape={"chat"} size={"18px"} color={theme.colors.IronGrey} />
          {t(tk.chat.lastMessage)}{" "}
          {timeAgo(lastMessageAt, {
            sameDay: `[${t(tk.common.todayAt)}] HH:mm:ss`,
            lastDay: `[${t(tk.common.yesterdayAt)}] HH:mm:ss`,
            lastWeek: `[${t(tk.common.lastWeek)}] DD.MM.YYYY HH:mm:ss`,
            sameElse: "DD.MM.YYYY HH:mm:ss",
          })}
        </SubHeading>

        <Divider />

        <MembersContainer>
          {interactions.map(({ id, profileImage, firstName, lastName, lastOpenedAt }) => {
            const allRead = !!lastOpenedAt && isSameOrAfter(lastOpenedAt, lastMessageAt);

            return (
              <Member key={id}>
                <Hexagon imageSource={profileImage} size={{ width: "35px", height: "37px" }} />
                <MemberDetails>
                  <span>{`${firstName} ${lastName}`}</span>
                  <LastOpenedAt>
                    {!lastOpenedAt ? (
                      t(tk.chat.neverOpened)
                    ) : allRead ? (
                      t(tk.chat.allRead)
                    ) : (
                      <>
                        {t(tk.chat.lastSeen)}{" "}
                        {timeAgo(lastOpenedAt, {
                          sameDay: `[${t(tk.common.todayAt)}] HH:mm:ss`,
                          lastDay: `[${t(tk.common.yesterdayAt)}] HH:mm:ss`,
                          lastWeek: `[${t(tk.common.lastWeek)}] DD.MM.YYYY HH:mm:ss`,
                          sameElse: "DD.MM.YYYY HH:mm:ss",
                        })}
                      </>
                    )}
                  </LastOpenedAt>
                </MemberDetails>

                {allRead ? (
                  <Icon shape={"checked"} size={"18px"} color={theme.colors.TealGreen} />
                ) : (
                  <Icon shape={"time"} size={"18px"} color={theme.colors.SunsetOrange} />
                )}
              </Member>
            );
          })}
        </MembersContainer>
      </DialogContainer>
    </DialogScreen>
  );
};

/**
 * Styles
 */

const DialogContainer = styled.div`
  width: 400px;
  min-height: 192px;
  border-radius: 5px;
  background-color: ${theme.colors.SnowWhite};
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  color: ${theme.colors.NightSky};
  box-sizing: border-box;
`;

const DialogCloseButton = styled(CloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${theme.colors.SilverGrey};
`;

const Heading = styled.div`
  padding: 30px 0 0 26px;
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
`;

const SubHeading = styled.p`
  padding: 0 26px;
  font-weight: ${font.weight.medium};
  color: ${theme.colors.IronGrey};
  margin: 20px 0;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MembersContainer = styled.div`
  padding: 20px 26px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MemberDetails = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 2px;
`;

const LastOpenedAt = styled.span`
  color: ${theme.colors.IronGrey};
  font-size: 10px;
`;
