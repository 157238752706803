import React, { ChangeEvent, useContext } from "react";
import styled from "styled-components";
import { FieldContext } from "components/Field/Field";
import { font, theme } from "styles";

type Props = {
  children: string;
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  isDisabled?: boolean;
  margin?: string;
};

export const Radio = ({ children, isChecked, onChange, value, name, isDisabled = false, margin }: Props) => {
  const { isFieldDisabled } = useContext(FieldContext);

  const disabled = isFieldDisabled !== undefined ? isFieldDisabled : isDisabled;

  return (
    <Container margin={margin}>
      <StyledInput
        type={"radio"}
        onChange={onChange}
        checked={isChecked}
        value={value}
        name={name}
        id={name}
        disabled={disabled}
      />
      <DotContainer isDisabled={disabled} isChecked={isChecked}>
        {isChecked && <Dot isDisabled={disabled} />}
      </DotContainer>
      <Label htmlFor={name} isDisabled={disabled}>
        {children}
      </Label>
    </Container>
  );
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "margin">;

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["margin"].includes(prop) })<ContainerProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) => props.margin ?? 0};
`;

type DotContainerProps = { isDisabled: boolean; isChecked: boolean };

const DotContainer = styled("div").withConfig({
  shouldForwardProp: (prop) => !["isDisabled", "isChecked"].includes(prop),
})<DotContainerProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 1px solid
    ${(props) => (props.isDisabled ? theme.radio.borderColor.disabled : theme.radio.borderColor.default)};
  background-color: ${(props) =>
    props.isDisabled && !props.isChecked ? theme.radio.backgroundColor.disabled : theme.radio.backgroundColor.default};
`;

type DotProps = { isDisabled: boolean };

const Dot = styled("div").withConfig({ shouldForwardProp: (prop) => !["isDisabled"].includes(prop) })<DotProps>`
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${(props) =>
    props.isDisabled ? theme.radio.dotBackgroundColor.disabled : theme.radio.dotBackgroundColor.default};
`;

type LabelProps = { isDisabled: boolean };

const Label = styled("label").withConfig({ shouldForwardProp: (prop) => !["isDisabled"].includes(prop) })<LabelProps>`
  position: relative;
  font-size: 16px;
  font-weight: ${font.weight.regular};
  color: ${(props) => (props.isDisabled ? theme.radio.fontColor.disabled : theme.radio.fontColor.default)};
  margin-left: 15px;
`;

const StyledInput = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  margin: 0;
  border-radius: 24px;
  cursor: pointer;
  opacity: 0;
  z-index: 1;

  &:hover ~ ${DotContainer} {
    border-color: ${theme.radio.borderColor.hover};
  }

  &:focus ~ ${DotContainer} {
    box-shadow: 0 0 0 5px ${theme.radio.outlineColor};
  }

  &:disabled {
    cursor: not-allowed;

    & ~ ${DotContainer} {
      border-color: ${theme.radio.borderColor.disabled};
    }
  }
`;
