import React, { Component, ErrorInfo } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { ColorfulLogo } from "components/ColorfulLogo";
import { ButtonLink } from "components/ButtonLink";
import { Button } from "components/Button";
import { tk } from "translations";
import { routes } from "routes";
import image from "../assets/images/500.png";

interface Props extends WithTranslation {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  private reload = () => window.location.reload();

  private redirect = () => window.location.replace(routes.dashboard);

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as Record<any, any>);
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    const { t } = this.props;

    return (
      <ErrorContainer>
        <MessageContainer>
          <ColorfulLogo width={124} height={51} />
          <ErrorImage />

          <NoticeInfo>{t(tk.errorBoundary.heading)}</NoticeInfo>
          <NoticeHeader>{t(tk.errorBoundary.subheading)}</NoticeHeader>
          <NoticeText>
            {t(tk.errorBoundary.info)}
            <br />
            {t(tk.errorBoundary.info2)}
          </NoticeText>

          <ButtonContainer>
            <Button onClick={this.reload} color={"blue"}>
              {t(tk.errorBoundary.reload)}
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonLink onClick={this.redirect}>{t(tk.errorBoundary.back)}</ButtonLink>
          </ButtonContainer>
        </MessageContainer>
      </ErrorContainer>
    );
  }
}

export default withTranslation()(ErrorBoundary);

/**
 * Styled
 */

const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const MessageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  top: 20px;
  min-height: 600px;
  max-width: 650px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 150px;
`;

const ErrorImage = styled.div`
  position: relative;
  background-image: url(${image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 610px;
  height: 332px;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const NoticeInfo = styled.span`
  text-align: center;
  font-weight: 100;
  font-size: 14px;
  color: grey;
`;

const NoticeHeader = styled.h2``;

const NoticeText = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 35px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 35px;
  width: 300px;
  margin-top: 10px;
`;
