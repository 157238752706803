import React from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { IconChevron } from "icons";
import { theme } from "styles";

type Props = {
  children?: never;
  onClick: () => void;
  orientation: "left" | "right";
};

export const HexagonButton = ({ onClick, orientation }: Props) => {
  const springStyle = useSpring({
    transform: `rotate3d(0, 0, 1, ${orientation === "left" ? "90" : "-90"}deg)`,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <StyledButton onClick={handleClick}>
      <StyledSvg
        width={"100%"}
        height={"100%"}
        viewBox={"0 0 33 36"}
        version={"1.1"}
        xmlns={"http://www.w3.org/2000/svg"}
        xmlnsXlink={"http://www.w3.org/1999/xlink"}
        xmlSpace={"preserve"}
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
          left: orientation === "left" ? 1 : 0,
        }}
      >
        <g transform="matrix(1.03125,0,0,1.02857,0.515625,0.0226286)">
          <path
            d="M15.5,34.978C14.812,34.978 14.132,34.8 13.535,34.462L1.5,27.66C0.266,26.963 -0.5,25.656 -0.5,24.249L-0.5,10.707C-0.5,9.3 0.266,7.993 1.5,7.296L13.535,0.494C14.132,0.157 14.812,-0.022 15.5,-0.022C16.188,-0.022 16.868,0.157 17.465,0.494L29.5,7.296C30.734,7.993 31.5,9.3 31.5,10.707L31.5,24.249C31.5,25.656 30.734,26.963 29.5,27.66L17.465,34.462C16.868,34.8 16.188,34.978 15.5,34.978Z"
            style={{ fill: "rgb(10,145,135)", fillRule: "nonzero" }}
          />
        </g>
      </StyledSvg>
      <AnimatedIconWrapper style={springStyle}>
        <IconChevron width={"12px"} height={"8px"} color={theme.colors.SnowWhite} />
      </AnimatedIconWrapper>
    </StyledButton>
  );
};

/**
 * Styles
 */

const StyledButton = styled("button")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 34px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

const StyledSvg = styled("svg")`
  position: absolute;
  top: 0;
  left: 0;
  filter: drop-shadow(0 2px 4px #00000016);
`;

const AnimatedIconWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: rotate3d(0, 1, 0, -90deg);
  transform-origin: center center;
`;
