import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { StoriesLayout, StoriesLayoutActionsWrapper } from "layouts";
import { useStoriesEdit } from "controllers";
import {
  Button,
  ButtonLink,
  Card,
  Dialog,
  DialogScreen,
  Field,
  FieldErrorMessage,
  FieldLabel,
  HorizontalSeparator,
  ImageUpload,
  Textarea,
} from "components";
import { StoriesEditParams } from "routes";
import { font, theme } from "styles";

interface Props extends RouteComponentProps<StoriesEditParams> {}

export const StoriesEdit = ({ match }: Props) => {
  const { t, tk, media, data, state, handlers } = useStoriesEdit(match.params);

  const renderContent = !!data.story;

  return (
    <StoriesLayout
      buttons={
        <StoriesLayoutActionsWrapper>
          <ButtonLink onClick={handlers.cancel} isDisabled={state.loading} margin={"0 30px 0 0"}>
            {t(tk.common.cancel)}
          </ButtonLink>
          <Button onClick={handlers.submit} isDisabled={state.loading} color={"green"}>
            {t(tk.common.saveChanges)}
          </Button>
        </StoriesLayoutActionsWrapper>
      }
    >
      {renderContent && (
        <Content>
          <ContentCard>
            <Field width={"100%"} padding={"40px 50px"}>
              <FieldLabel>{t(tk.storiesCreate.form.photo.label)}</FieldLabel>
              <ImageUpload
                media={media}
                currentImageSrc={state.imageDeleted ? undefined : data.story?.headerOriginal || undefined}
                onDelete={handlers.handleImageDelete}
              />
            </Field>

            <HorizontalSeparator />

            <Field width={"100%"} padding={"40px 50px 12px 50px"}>
              <FieldLabel required={true}>{t(tk.storiesCreate.form.text.label)}</FieldLabel>
              <Textarea
                value={state.text}
                placeholder={t(tk.storiesCreate.form.text.placeholder)}
                isDisabled={state.loading}
                width={"100%"}
                onChange={handlers.handleChangeText}
              />
              <FieldErrorMessage>{state.textError}</FieldErrorMessage>
            </Field>

            <HorizontalSeparator />

            <Footer>
              <DeleteLink onClick={handlers.remove}>{t(tk.storiesEdit.delete)}</DeleteLink>

              <FooterButtonsContainer>
                <ButtonLink onClick={handlers.cancel} isDisabled={state.loading} margin={"0 30px 0 0"}>
                  {t(tk.common.cancel)}
                </ButtonLink>
                <Button onClick={handlers.submit} isDisabled={state.loading} color={"green"}>
                  {t(tk.common.saveChanges)}
                </Button>
              </FooterButtonsContainer>
            </Footer>
          </ContentCard>

          {!!state.dialog && (
            <DialogScreen>
              {state.dialog === "progress" && (
                <Dialog
                  text={t(tk.common.pleaseWait)}
                  title={t(tk.common.savingChanges)}
                  iconImage={"bell"}
                  hasProgressBar={true}
                />
              )}

              {state.dialog === "success" && (
                <Dialog
                  text={t(tk.common.changesSaved)}
                  buttonTitle={t(tk.common.ok)}
                  onButtonClick={handlers.goToStories}
                />
              )}

              {state.dialog === "error" && (
                <Dialog
                  text={t(tk.storiesCreate.error)}
                  buttonTitle={t(tk.common.ok)}
                  iconImage={"bell"}
                  iconImageBackgroundColor={"#C8102E"}
                  onButtonClick={handlers.closeDialog}
                />
              )}

              {state.dialog === "delete" && (
                <Dialog
                  text={t(tk.storiesEdit.confirmDelete)}
                  buttonTitle={t(tk.common.yesDelete)}
                  iconImage={"delete"}
                  hasCloseButton={true}
                  onButtonClick={handlers.submitRemove}
                  onCloseButtonClick={handlers.closeDialog}
                />
              )}
            </DialogScreen>
          )}
        </Content>
      )}
    </StoriesLayout>
  );
};

/**
 * Styles
 */

const Content = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 740px;
  margin: 25px auto;
  flex-shrink: 0;
`;

const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 50px;
`;

const FooterButtonsContainer = styled.div`
  display: flex;
`;

const DeleteLink = styled.span`
  font-weight: ${font.weight.bold};
  font-size: 14px;
  color: ${theme.colors.FireRed};
  cursor: pointer;
`;
