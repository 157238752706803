import React from "react";
import styled from "styled-components";
import { theme } from "styles";

interface Props {
  label: string;
}

export const TimeDivider = ({ label }: Props) => (
  <Wrapper>
    <TextWrapper>{label}</TextWrapper>
  </Wrapper>
);

/**
 * Styles
 */

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 7px;
  border-top: 1px solid ${theme.colors.FadingGrey};
  color: ${theme.colors.IronGrey};
`;

const TextWrapper = styled.div`
  padding: 10px 20px;
`;
