import { useUnresolvedRefetch } from "hooks/useUnresolvedRefetch";
import {
  useAnnouncementChangeSubscription,
  useSpaceAnnouncementsEventSubscription,
  useSpaceReceivedAnnouncementsQuery,
  useSpaceSentAnnouncementsQuery,
} from "api";

const INTERVAL = 1000 * 60 * 5; // 5 minutes;

const MASTER_SKIP = true;

/** Polling was replaced by subscriptions, but it can be enabled again by changing MASTER_SKIP */

export const useAnnouncementsPoll = (space: string, isManager: boolean, skip: boolean = false) => {
  const refetchUnresolved = useUnresolvedRefetch();

  const { refetch: refetchSent } = useSpaceSentAnnouncementsQuery({
    variables: { id: space },
    pollInterval: MASTER_SKIP ? undefined : INTERVAL,
    skip: !isManager || !space.length || skip || MASTER_SKIP,
  });

  const { refetch: refetchReceived } = useSpaceReceivedAnnouncementsQuery({
    variables: { id: space },
    pollInterval: MASTER_SKIP ? undefined : INTERVAL,
    skip: !space.length || skip || MASTER_SKIP,
  });

  useAnnouncementChangeSubscription({ skip: !isManager });

  useSpaceAnnouncementsEventSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      const id = subscriptionData.data?.onSpaceAnnouncementsEvent?.space.id;

      if (!id) return;

      if (isManager) await refetchSent({ id });

      await refetchReceived({ id });

      await refetchUnresolved();
    },
  });
};
