import { useUnresolvedRefetch } from "hooks/useUnresolvedRefetch";
import { useSpaceDocumentsEventSubscription, useSpaceReceivedDocumentsQuery, useSpaceSentDocumentsQuery } from "api";

const INTERVAL = 1000 * 60 * 5; // 5 minutes;

const MASTER_SKIP = true;

/** Polling was replaced by subscriptions, but it can be enabled again by changing MASTER_SKIP */

export const useDocumentsPoll = (space: string, isManager: boolean, skip: boolean = false) => {
  const refetchUnresolved = useUnresolvedRefetch();

  const { refetch: refetchSent } = useSpaceSentDocumentsQuery({
    variables: { id: space },
    pollInterval: MASTER_SKIP ? undefined : INTERVAL,
    skip: !isManager || !space.length || skip || MASTER_SKIP,
  });

  const { refetch: refetchReceived } = useSpaceReceivedDocumentsQuery({
    variables: { id: space },
    pollInterval: MASTER_SKIP ? undefined : INTERVAL,
    skip: !space.length || skip || MASTER_SKIP,
  });

  useSpaceDocumentsEventSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      const id = subscriptionData.data?.onSpaceDocumentsEvent?.space.id;

      if (!id) return;

      if (isManager) await refetchSent({ id });

      await refetchReceived({ id });

      await refetchUnresolved();
    },
  });
};
