import React from "react";
import styled from "styled-components";
import { theme, font } from "styles";

interface Props {
  value?: number;
  hasOutline?: boolean;
}

export const NotificationDot = ({ value, hasOutline = false }: Props) => {
  return (
    <Dot hasValue={!!value} hasPadding={!!value && value > 9}>
      {value ?? null}
      {hasOutline && !value && <Center />}
    </Dot>
  );
};

const Dot = styled.div.withConfig({ shouldForwardProp: (prop) => !["hasPadding", "hasValue"].includes(prop) })<{
  hasPadding?: boolean;
  hasValue: boolean;
}>`
  position: relative;
  flex-shrink: 0;
  min-width: ${(props) => (props.hasValue ? 16 : 8)}px;
  height: ${(props) => (props.hasValue ? 16 : 8)}px;
  background: ${theme.colors.SunsetOrange};
  border: 1px solid ${theme.colors.SnowWhite};
  border-radius: 16px;
  box-sizing: initial;
  text-align: center;
  color: ${theme.colors.SnowWhite};
  font-size: 11px;
  font-weight: ${font.weight.semiBold};
  line-height: 16px;
  padding: 0 ${(props) => (props.hasPadding ? 3 : 0)}px;
`;

const Center = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 4px;
  height: 4px;
  background-color: ${theme.colors.SnowWhite};
  border-radius: 4px;
`;
