import React from "react";
import { Media } from "hooks";
import styled from "styled-components";
import { Icon } from "components/Icon";
import { IconDocument } from "icons";
import { theme } from "styles";
import { tk, useTranslation } from "translations";

interface Props {
  media: Media;
  currentFile?: { src: string; name: string };
}

export const FileUpload = ({ media, currentFile }: Props) => {
  const { t } = useTranslation();

  const hasFile = !!media.preview || !!currentFile;

  return (
    <Container>
      <InnerContainer>
        {hasFile && (
          <FileWrapper>
            <FileIcon shape={"document"} />
            <FileName>{media.preview || currentFile?.name}</FileName>
          </FileWrapper>
        )}

        {!hasFile && (
          <UploadArea>
            <UploadInfoContainer>
              <IconDocument height={35} width={40} color={theme.colors.IronGrey} margin={15} />
              <UploadInfo>{t(tk.fileUpload.drop.empty)}</UploadInfo>
            </UploadInfoContainer>
            <UploadInput {...media.fileInputProps} hidden={false} />
          </UploadArea>
        )}

        {hasFile && (
          <UploadButton>
            <UploadButtonInput {...media.fileInputProps} hidden={false} />
            <UploadLabel>{!currentFile ? t(tk.fileUpload.change) : t(tk.fileUpload.replace)}</UploadLabel>
          </UploadButton>
        )}
      </InnerContainer>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const FileWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  cursor: default;
  flex-grow: 1;
`;

const FileIcon = styled(Icon)`
  color: #a7b7c5;
  font-size: 22px;
  transform: translateX(-3px);
`;

const FileName = styled.div`
  position: relative;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #008c82;
  padding-left: 3px;
`;

const UploadArea = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 128px;
  background-color: #f9f9f9;
  border: 1px dashed #bfc0c2;
  border-radius: 5px;
`;

const UploadInfoContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const UploadInfo = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: #9b9b9b;
  margin-top: 6px;
`;

const UploadInput = styled.input`
  flex-grow: 1;
  opacity: 0;
  cursor: pointer;
`;

const UploadButton = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  user-select: none;
  overflow: hidden;
`;

const UploadButtonInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
`;

const UploadLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 0;
  color: #008c82;
`;
