import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { getRoute, GuideParams, routes } from "routes";
import { tk, useTranslation } from "translations";

export const useGuide = (step: GuideParams["step"]) => {
  const { t } = useTranslation();

  useTitle(t(tk.guide[step].heading) + " " + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const next = useCallback(() => {
    if (step === "4") return history.replace(routes.dashboard);

    const nextSteps: { [key: string]: GuideParams["step"] } = { "1": "2", "2": "3", "3": "4" };

    history.replace(getRoute.guide({ step: nextSteps[step] }));
  }, [history, step]);

  return {
    t,
    tk,
    state: { step },
    handlers: { next },
  };
};
