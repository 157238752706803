import React from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  footer?: React.ReactNode;
  children: React.ReactNode;
};

export class Detail extends React.Component<Props> {
  render() {
    return (
      <DetailContainer>
        <ContentContainer>{this.props.children}</ContentContainer>
        {this.props.footer}
      </DetailContainer>
    );
  }
}

/**
 * Styles
 */

const DetailContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 40px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 3px;
  background-color: ${theme.colors.SnowWhite};
`;
