import React from "react";
import styled, { keyframes } from "styled-components";
import { CloseButton, DialogButton, Hexagon } from "components";
import { withTranslation, WithTranslation } from "react-i18next";
import { font, theme } from "styles";
import { tk } from "translations";

// TODO: copied from original moyobo webapp - should be refactored

const whistle = require("assets/images/dialog/whistle-icon.png");
const bell = require("assets/images/dialog/bell-icon.png");
const calendar = require("assets/images/dialog/calendar-icon.png");
const deleteIcon = require("assets/images/dialog/delete-icon.png");
const document = require("assets/images/dialog/document-icon.png");
const dashboardPost = require("assets/images/dialog/dashboard-post-icon.png");
const plan = require("assets/images/dialog/plan-icon.png");

const DialogWindow = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 315px;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 30px;
  box-sizing: border-box;
`;

const Text = styled.p`
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #404040;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 30px;
  text-align: center;
  margin: 0;
  width: 100%;
`;

const AnnouncementTitle = styled.h4`
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #404040;
  margin: 0;
  padding-top: 16px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

const AnnouncementImage = styled.img`
  margin-top: 8px;
  width: 80px;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  border-radius: 5px;
  background-color: ${theme.colors.FadingGrey};
`;

const AnnouncementText = styled(Text)`
  padding-top: 8px;
  color: #9b9b9b;
  word-wrap: break-word;
`;

const AnnouncementOptions = styled.div`
  display: flex;
  position: relative;
`;

const AnnouncementOption = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 90px;
  margin-bottom: 28px;
`;

const AnnouncementOptionTitle = styled.h6`
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #9b9b9b;
  margin: 8px 0 0 0;
  white-space: pre-wrap;
  text-align: center;
`;

const ReactionOptionsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const ReactionOptionsTitle = styled.h5`
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #404040;
  margin: 0;
`;

const ReactionOptions = styled(Text)`
  padding-top: 4px;
`;

const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  padding: 0 24px;
`;

const Progress = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 5px;
  overflow: hidden;
`;

const slide = keyframes`
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
`;

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #008c82;
  border-radius: 5px;
  transform: translateX(50%);
  animation: ${slide} 1s ease-out 0s infinite normal forwards;
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  padding-bottom: 15px;
`;

const TextCloseButton = styled.div`
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.bold};
  font-size: 12px;
  color: ${theme.colors.TealGreen};
  cursor: pointer;
`;

type Props = {
  iconImage?: "bell" | "whistle" | "document" | "dashboardPost" | "plan" | "calendar" | "delete";
  iconImageBackgroundColor?: string;
  iconColor?: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
  hasCloseButton?: boolean;
  onCloseButtonClick?: () => void;
  title?: string;
  announcementText?: string;
  announcementImage?: string;
  text?: string;
  reactions?: string;
  announcementReceivers?: number;
  announcementIsUrged?: boolean;
  hasProgressBar?: boolean;
  noButton?: boolean;
  hasTextCloseButton?: boolean;
  textCloseButtonCaption?: string;
  children?: React.ReactNode;
} & WithTranslation;

export const Dialog = withTranslation()(
  class DialogStatic extends React.Component<Props> {
    renderDialogIcon = () => {
      if (this.props.iconImage) {
        let imageSource = null;
        const color = this.props.iconImageBackgroundColor ? this.props.iconImageBackgroundColor : "#2F424F";

        switch (this.props.iconImage) {
          case "bell":
            imageSource = bell;
            break;
          case "whistle":
            imageSource = whistle;
            break;
          case "document":
            imageSource = document;
            break;
          case "dashboardPost":
            imageSource = dashboardPost;
            break;
          case "plan":
            imageSource = plan;
            break;
          case "calendar":
            imageSource = calendar;
            break;
          case "delete":
            imageSource = deleteIcon;
            break;
          default:
            break;
        }
        return (
          <Hexagon
            imageSource={imageSource}
            color={color}
            size={{
              width: "100px",
              height: "112px",
            }}
            style={{ marginTop: 38 }}
          />
        );
      }

      const iconColor = this.props.iconColor ? this.props.iconColor : "#FFFFFF";
      const backgroundColor = this.props.iconImageBackgroundColor ? this.props.iconImageBackgroundColor : "#008C82";

      return (
        <Hexagon
          iconShape={"checked"}
          color={backgroundColor}
          iconColor={iconColor}
          iconSize={"32px"}
          size={{
            width: "100px",
            height: "112px",
          }}
          style={{ marginTop: 38 }}
        />
      );
    };

    handleCloseClick = () => {
      if (!this.props.onCloseButtonClick) return;
      this.props.onCloseButtonClick();
    };

    renderCloseButton = () => {
      if (!this.props.hasCloseButton) {
        return null;
      }
      return <CloseButton style={{ position: "absolute", top: 19, right: 16 }} onClick={this.handleCloseClick} />;
    };

    renderAnnouncementTitle = () => {
      if (!this.props.title) {
        return null;
      }

      return <AnnouncementTitle>{this.props.title}</AnnouncementTitle>;
    };

    renderAnnouncementImage = () => {
      if (!this.props.announcementImage) return null;

      return <AnnouncementImage src={this.props.announcementImage} />;
    };

    renderAnnouncementText = () => {
      if (!this.props.announcementText) {
        return null;
      }

      let { announcementText: text } = this.props;
      const maxLength = 38;

      if (text.length > maxLength) {
        text = `${text.substr(0, maxLength - 3)}...`;
      }

      return <AnnouncementText>{text}</AnnouncementText>;
    };

    renderText = () => {
      if (!this.props.text) {
        return null;
      }

      return <Text>{this.props.text}</Text>;
    };

    renderAnnouncementOptions = () => {
      if (!this.props.title || !this.props.announcementText) {
        return null;
      }

      const { announcementReceivers, announcementIsUrged } = this.props;
      const urgingStatus = announcementIsUrged ? this.props.t(tk.common.enabled) : this.props.t(tk.common.disabled);

      return (
        <AnnouncementOptions>
          <AnnouncementOption>
            <Hexagon
              characterSize={"20px"}
              characterWeight={"600"}
              size={{
                width: "50px",
                height: "56px",
              }}
              color={"#2F424F"}
            >
              {announcementReceivers}
            </Hexagon>
            <AnnouncementOptionTitle>
              {this.props.t(tk.announcements.summary.recipientsLabel, { count: announcementReceivers })}
            </AnnouncementOptionTitle>
          </AnnouncementOption>
          <AnnouncementOption>
            <Hexagon
              imageSource={whistle}
              size={{
                width: "50px",
                height: "56px",
              }}
              color={"#2F424F"}
            />
            <AnnouncementOptionTitle>{`${this.props.t(
              tk.announcements.summary.urging
            )}\n${urgingStatus}`}</AnnouncementOptionTitle>
          </AnnouncementOption>
        </AnnouncementOptions>
      );
    };

    renderReactionOptions = () => {
      const { reactions } = this.props;
      if (!reactions || reactions === "") {
        return null;
      }

      return (
        <ReactionOptionsContainer>
          <ReactionOptionsTitle>{this.props.t(tk.announcements.summary.reactionOptions)}:</ReactionOptionsTitle>
          <ReactionOptions>{this.props.reactions}</ReactionOptions>
        </ReactionOptionsContainer>
      );
    };

    renderProgressBar = () => {
      if (!this.props.hasProgressBar) {
        return null;
      }

      return (
        <ProgressContainer>
          <Progress>
            <Bar />
          </Progress>
        </ProgressContainer>
      );
    };

    renderButton = () => {
      if (this.props.hasProgressBar || this.props.noButton) {
        return null;
      }

      return <DialogButton onClick={this.props.onButtonClick}>{this.props.buttonTitle}</DialogButton>;
    };

    renderTextCloseButton = () => {
      if (!this.props.hasTextCloseButton) {
        return null;
      }

      return (
        <TextCloseButton onClick={this.props.onCloseButtonClick}>{this.props.textCloseButtonCaption}</TextCloseButton>
      );
    };

    render() {
      return (
        <DialogWindow>
          {this.renderCloseButton()}
          {this.renderDialogIcon()}
          {this.renderAnnouncementTitle()}
          {this.renderAnnouncementImage()}
          {this.renderAnnouncementText()}
          {this.renderText()}
          {this.props.children && <ChildrenWrapper>{this.props.children}</ChildrenWrapper>}
          {this.renderAnnouncementOptions()}
          {this.renderReactionOptions()}
          {this.renderProgressBar()}
          {this.renderButton()}
          {this.renderTextCloseButton()}
        </DialogWindow>
      );
    }
  }
);
