import moment from "moment";
import { CalendarSpec } from "moment/moment";

export const fromNow = (time: string): string => moment.utc(time).local().fromNow();

export const formatTime = (time: string, format: string): string => moment.utc(time).local().format(format);

export const isBefore = (a: string, b: string): boolean => moment.utc(a).isBefore(moment.utc(b));

export const isBeforeNow = (time: string): boolean => moment.utc(time).isBefore(moment.utc());

export const isSameOrAfter = (a: string, b: string): boolean => moment.utc(a).isSameOrAfter(moment.utc(b));

export const timeAgo = (time: string, formats: CalendarSpec = {}): string => moment.utc(time).local().calendar(formats);

export const isSameDay = (a: string, b: string): boolean => {
  return moment.utc(a).format("D.M.Y") === moment.utc(b).format("D.M.Y");
};
