import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Title = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #404040;
  line-height: 24px;
`;

export class SelectionDialogTitle extends React.Component<PropsWithChildren> {
  render() {
    return <Title {...this.props}>{this.props.children}</Title>;
  }
}
