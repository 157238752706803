import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
};

export const SidebarList = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

/**
 * Styles
 */

const Container = styled("ul")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  grid-area: list;
`;
