import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
};

export const SelectOption = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
