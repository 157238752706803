import React, { useCallback } from "react";
import styled from "styled-components";
import { RecipientsSelectionRemoveButton } from "components/RecipientSelection/RecipientsSelectionRemoveButton";
import { tk, useTranslation } from "translations";

type Props = {
  children: React.ReactNode;
  id: string;
  onRemoveClick: (id: string) => any;
};

export const SelectedRecipientsListItem = ({ children, id, onRemoveClick }: Props) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => onRemoveClick(id), [id, onRemoveClick]);

  return (
    <Container>
      {children}
      <RemoveButtonWrapper>
        <RecipientsSelectionRemoveButton onClick={handleClick}>
          {t(tk.recipientsSelection.removeButtonLabel)}
        </RecipientsSelectionRemoveButton>
      </RemoveButtonWrapper>
    </Container>
  );
};

/**
 * Styles
 */

const RemoveButtonWrapper = styled("div")`
  display: flex;
  position: relative;
  grid-area: remove-button;
  opacity: 0;
`;

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content 1fr 1fr max-content;
  grid-template-rows: 1fr;
  grid-template-areas: ". . . remove-button";
  grid-column-gap: 10px;
  align-items: center;
  width: calc(100% + 40px);
  height: 40px;
  padding: 0 20px;
  transform: translate3d(-20px, 0, 0);

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    & ${RemoveButtonWrapper} {
      opacity: 1;
    }
  }
`;
