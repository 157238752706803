import React, { useContext } from "react";
import { StepsContext } from "components";
import { Dot } from "components";

type Props = {
  children?: never;
  isActive?: boolean;
};

export const Step = ({ isActive }: Props) => {
  const { index, activeIndex } = useContext(StepsContext);

  const isStepActive = activeIndex && index ? activeIndex >= index : isActive;

  return <Dot isOutlined={!isStepActive} borderWidth={1} />;
};
