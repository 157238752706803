import styled from "styled-components";
import { theme } from "styles";

export const LandingPageHelpLink = styled("div")`
  display: flex;
  position: relative;
  margin-top: 10px;

  a {
    font-size: 18px;
    font-weight: 500;
    color: ${theme.landingPageHelpLink.fontColor};
    text-decoration: none;
  }
`;
