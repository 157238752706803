export type HelpItem = {
  id: string;
  title: string;
  content: Array<ContentParagraph | ContentImage | ContentList>;
  items: HelpItem[];
};

type ContentParagraph = {
  type: "paragraph";
  content: string;
};
type ContentImage = {
  type: "image";
  title: string;
  src: () => string;
};
type ContentList = {
  type: "list";
  items: string[];
};

export class SupportHelper {
  private readonly data: HelpItem[];

  constructor(data: any) {
    this.data = data;
  }

  get root() {
    return this.data;
  }

  getById = (id: HelpItem["id"]): HelpItem | undefined => {
    const path = id.split(".");
    const rootId = path[0];
    const rest = path.slice(1);

    let topic: HelpItem | undefined = this.findChildren(this.data, null, rootId);
    let prefix = rootId;

    rest.forEach((part) => {
      topic = this.findChildren(topic?.items || [], prefix, part);
    });

    return topic;
  };

  private findChildren(items: HelpItem[], prefix: string | null, idPart: string): HelpItem | undefined {
    const id = prefix ? `${prefix}.${idPart}` : idPart;
    return items.find((item) => item.id === id);
  }
}
