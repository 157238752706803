import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useDashboardState, useMe, useMedia, useRecipientSelection } from "hooks";
import { DocumentUpdateInput, MediaTypes, resolveError, useDocumentQuery, useDocumentUpdateMutation } from "api";
import { DocumentsTabs, getRoute } from "routes";
import { tk, useTranslation } from "translations";

export const useDocumentsEdit = (tab: DocumentsTabs, id: string) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.documents) + " " + t(tk.common.documentTitleSuffix));

  const { me } = useMe();

  const { organization, space } = useDashboardState();

  const media = useMedia(MediaTypes.Document, false, true);

  const { data, loading: loadingDocument } = useDocumentQuery({ variables: { id }, fetchPolicy: "cache-and-network" });

  const [update, { loading: loadingUpdate }] = useDocumentUpdateMutation();

  const { createFromDocument } = useRecipientSelection("");
  const [recipientSelection, setRecipientSelection] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (recipientSelection) return;

    createFromDocument(id).then((id) => setRecipientSelection(id));
  }, [createFromDocument, id, recipientSelection]);

  const loading = useMemo(
    () => loadingDocument || media.loading || loadingUpdate || !recipientSelection,
    [loadingDocument, loadingUpdate, media.loading, recipientSelection]
  );

  const document = useMemo(() => data?.document, [data]);

  const fileName = useMemo(() => decodeURIComponent(document?.file?.split("/").pop() || ""), [document]);

  const [dialog, setDialog] = useState<"progress" | "success" | "error" | undefined>(undefined);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [selected, setSelected] = useState(0);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedError, setSelectedError] = useState("");

  const recipientsChanged = useMemo(() => {
    const originalUserIds = document?.interactions.edges.map((edge) => edge?.node?.user.id || "") || [];

    if (originalUserIds.length !== selectedUserIds.length) return true;

    const a = originalUserIds.sort();
    const b = [...selectedUserIds].sort();
    return a.some((v, i) => v !== b[i]);
  }, [document, selectedUserIds]);

  const cancel = useCallback(() => history.goBack(), [history]);

  const submit = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (loading || !recipientSelection) return;

      if (!title.trim().length) return setTitleError(t(tk.documentsCreate.form.title.required));

      if (!selected) return setSelectedError(t(tk.documentsCreate.form.recipients.required));

      const mediaChanged = !!media.preview;
      const titleChanged = title !== document?.title;

      if (!mediaChanged && !titleChanged && !recipientsChanged) return setDialog("success");

      setDialog("progress");

      try {
        const input: DocumentUpdateInput = { document: id, title, recipientSelection };

        if (mediaChanged) input.media = await media.upload();

        if (mediaChanged && !input.media) return setDialog("error");

        await update({ variables: { input } });

        setDialog("success");
      } catch (e: any) {
        setDialog("error");
        resolveError(e);
      }
    },
    [document, id, loading, media, recipientSelection, recipientsChanged, selected, t, title, update]
  );

  const closeDialog = useCallback(() => setDialog(undefined), []);

  const goBack = useCallback(() => {
    history.replace(getRoute.documentsDetail({ organization, space, tab, id }));
  }, [history, id, organization, space, tab]);

  const handleChangeTitle = useCallback(setTitle, [setTitle]);
  const handleChangeRecipientSelection = useCallback((selected: number, userIds: string[]) => {
    setSelected(selected);
    setSelectedUserIds(userIds);
  }, []);

  useEffect(() => {
    if (!document?.title) return;
    setTitle(document.title);
  }, [document]);

  useEffect(() => setTitleError(""), [title]);
  useEffect(() => setSelectedError(""), [selected]);

  /** If not author, go back to detail */
  useEffect(() => {
    if (!document?.author.id || !me?.userId) return;
    if (document.author.id === me.userId) return;

    goBack();
  }, [document, goBack, history, me]);

  return {
    t,
    tk,
    media,
    data: { document, fileName, recipientSelection },
    state: { id, tab, loading, dialog, title, titleError, selected, selectedError },
    handlers: { cancel, submit, closeDialog, goBack, handleChangeTitle, handleChangeRecipientSelection },
  };
};
