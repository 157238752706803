import React from "react";
import { theme } from "styles";
import { IconCommonPropsType } from "icons/types";

export const IconFeed = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={height} height={width} viewBox="0 0 27.625 25" style={{ margin }}>
      <g id="feed" transform="translate(-2.188 -3.563)" fill={color}>
        <path
          id="Path_587"
          data-name="Path 587"
          d="M4.688,3.563H27.313a2.5,2.5,0,0,1,2.5,2.5v20a2.5,2.5,0,0,1-2.5,2.5H4.688a2.5,2.5,0,0,1-2.5-2.5v-20a2.5,2.5,0,0,1,2.5-2.5Zm.25,1.884a.875.875,0,0,0-.875.875V25.8a.875.875,0,0,0,.875.875H27.063a.875.875,0,0,0,.875-.875V6.322a.875.875,0,0,0-.875-.875H4.938Z"
        />
        <path
          id="Path_588"
          data-name="Path 588"
          d="M6.125,13.188h19.75a.813.813,0,0,1,.813.813v9.875a.813.813,0,0,1-.813.813H6.125a.813.813,0,0,1-.813-.813V14A.813.813,0,0,1,6.125,13.188Zm19.313,1.25H6.563v9H25.438Z"
        />
        <path
          id="Path_589"
          data-name="Path 589"
          d="M11.188,9.563a.875.875,0,0,1,.875-.875h11.25a.875.875,0,0,1,.875.875v.125a.875.875,0,0,1-.875.875H12.063a.875.875,0,0,1-.875-.875Z"
        />
        <path
          id="Path_590"
          data-name="Path 590"
          d="M7.469,7.4a.313.313,0,0,1,.313,0l1.69.976a.312.312,0,0,1,.156.271V10.6a.314.314,0,0,1-.156.271l-1.69.976a.313.313,0,0,1-.313,0l-1.69-.976a.312.312,0,0,1-.156-.271V8.65a.314.314,0,0,1,.156-.271Z"
        />
      </g>
    </svg>
  );
};
