import React from "react";
import styled from "styled-components";
import { Hexagon } from "components";
import { font, theme } from "styles";

const ImageSize = {
  width: "38px",
  height: "41px",
};

type Props = {
  fullName: string;
  metaText: string;
  frontImage: string;
  backImage?: string;
};

export class Author extends React.PureComponent<Props> {
  render() {
    const { frontImage, backImage } = this.props;

    return (
      <DetailContainer>
        <ImagesSection wider={!!backImage}>
          {backImage && (
            <ImageContainer indented={false}>
              {/* Back Image */}
              <Hexagon imageSource={backImage} size={ImageSize} strokeWidth={"2px"} />
            </ImageContainer>
          )}
          <ImageContainer indented={!!backImage}>
            {/* Front Image */}
            <Hexagon imageSource={frontImage} size={ImageSize} strokeWidth={"2px"} strokeColor={"#FFFFFF"} />
          </ImageContainer>
        </ImagesSection>

        <AuthorDetailsSection>
          <FullName>{this.props.fullName}</FullName>
          <Meta>{this.props.metaText}</Meta>
        </AuthorDetailsSection>
      </DetailContainer>
    );
  }
}

/**
 * Styles
 */

const DetailContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-left: 2px;
  cursor: default;
`;

const getWidth = (props: { wider: boolean }) => (props.wider ? "68px" : "38px");

const ImagesSection = styled.div.withConfig({ shouldForwardProp: (prop) => !["wider"].includes(prop) })<{
  wider: boolean;
}>`
  position: relative;
  width: ${getWidth};
  height: ${ImageSize.height};
`;

const getIndent = (props: { indented: boolean }) => (props.indented ? "30px" : "0px");

const ImageContainer = styled.div.withConfig({ shouldForwardProp: (prop) => !["indented"].includes(prop) })<{
  indented: boolean;
}>`
  position: absolute;
  width: ${ImageSize.width};
  height: ${ImageSize.height};
  top: 0;
  left: ${getIndent};
`;

const AuthorDetailsSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 14px;
  padding-bottom: 3px;
`;

const FullName = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.semiBold};
  font-size: 14px;
  color: ${theme.colors.NightSky};
  line-height: 20px;
`;

const Meta = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.medium};
  font-size: 11px;
  color: ${theme.colors.IronGrey};
  line-height: 15px;
`;
