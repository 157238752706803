import React, { CSSProperties, useMemo, useRef } from "react";
import { formatTime } from "helpers";
import { Option } from "components/Option";
import { ColorfulLogo } from "components/ColorfulLogo";
import { tk, useTranslation } from "translations";

type Interaction = {
  id: string;
  user: {
    firstName: string;
    lastName: string;
  };
  reaction?: { id: string } | null;
  readAt?: string;
};

type Reaction = {
  id: string;
  text: string;
};

interface Props {
  announcement: {
    title: string;
    text: string;
    createdAt: string;
    urgedAt?: string;
  };
  interactions: Interaction[];
  reactions: Reaction[];
}

export const AnnouncementPrint = ({ announcement, interactions, reactions }: Props) => {
  const { t } = useTranslation();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const print = () => {
    const cw = iframeRef.current?.contentWindow;
    const html = iframeRef.current?.innerHTML;

    if (!cw || !html) return;

    cw.document.open();
    cw.document.write(html);
    cw.document.close();

    cw.focus();
    cw.print();
  };

  const sortedInteractions = useMemo(
    () =>
      interactions.sort((a, b) => {
        const lastName = a.user.lastName.localeCompare(b.user.lastName);
        const firstName = a.user.firstName.localeCompare(b.user.firstName);

        return lastName !== 0 ? lastName : firstName;
      }),
    [interactions]
  );

  return (
    <>
      <Option icon={"document"} label={t(tk.announcements.actions.print.button)} onClick={print} />

      <iframe ref={iframeRef} title="print" style={style.iframe}>
        <div style={style.content}>
          <ColorfulLogo height={20} />

          <h3>{announcement.title}</h3>
          <p>{announcement.text}</p>

          <p style={style.info}>
            <span>
              <strong>{t(tk.announcements.actions.print.sent)}: </strong>
              {formatTime(announcement.createdAt, DATE_FORMAT)}
            </span>
            <br />
            <span>
              <strong>{t(tk.announcements.actions.print.urged)}: </strong>
              {announcement.urgedAt ? formatTime(announcement.urgedAt, DATE_FORMAT) : "---"}
            </span>
          </p>

          <table style={style.table}>
            <thead>
              <tr>
                <th align="left" style={style.cell}>
                  {t(tk.announcements.actions.print.name)}
                </th>
                <th align="left" style={style.cell}>
                  {t(tk.announcements.actions.print.seen)}
                </th>
                <th align="left" style={style.cell}>
                  {t(tk.announcements.actions.print.reaction)}
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedInteractions.map((reaction) => (
                <tr key={reaction.id}>
                  <td style={style.cell}>
                    {reaction.user.lastName} {reaction.user.firstName}
                  </td>
                  <td style={style.cell}>{reaction.readAt ? formatTime(reaction.readAt, DATE_FORMAT) : "---"}</td>
                  <td style={style.cell}>{reactions.find((r) => r.id === reaction.reaction?.id)?.text ?? "---"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </iframe>
    </>
  );
};

const DATE_FORMAT = "DD. MM. YYYY HH:mm";

type StyleKey = "iframe" | "content" | "info" | "table" | "cell";

const style: Record<StyleKey, CSSProperties> = {
  iframe: { display: "none", position: "absolute", height: 0, width: 0 },
  content: { fontFamily: "'Raleway', sans-serif", fontSize: "16px" },
  info: { fontSize: "14px" },
  table: { width: "100%", border: "1px solid #afafaf", borderCollapse: "collapse", fontSize: "14px" },
  cell: {
    borderBottom: "1px solid #afafaf",
    borderRight: "1px solid #afafaf",
    padding: "6px 8px",
    backgroundColor: "inherit",
  },
};
