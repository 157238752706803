import styled from "styled-components";
import { theme } from "styles";

export const LandingPageFormMetaLink = styled("span")`
  position: relative;
  margin-top: 24px;

  a {
    font-size: 12px;
    font-weight: 500;
    color: ${theme.landingPageFormMetaLink.fontColor};
  }
`;
