import React from "react";
import styled from "styled-components";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
  width?: number;
};

export const TabList = ({ children, width }: Props) => {
  return (
    <Container width={width}>
      <TabWrapper>{children}</TabWrapper>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["width"].includes(prop) })<{
  width?: number;
}>`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  width: ${(props) => (!props.width ? 355 : props.width)}px;
  height: 75px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.tabList.borderColor};
  overflow-x: auto;
  grid-area: tab-list;
`;

const TabWrapper = styled("div")`
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 26px;
  padding: 26px 24px 0 24px;
  height: 100%;
`;
