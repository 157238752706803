import styled from "styled-components";
import { theme } from "styles";

export const LandingPageFormMetaText = styled("span")`
  position: relative;
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.landingPageFormMetaText.fontColor};
`;
