import React, { ChangeEvent, useContext } from "react";
import styled from "styled-components";
import { FieldContext } from "components";
import { font, theme } from "styles";

type Props = {
  children?: never;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  maxLength?: number;
  required?: boolean;
  width?: string;
  type?: string;
};

export const Input = ({ placeholder, value, onChange, isDisabled, maxLength, required, width, type }: Props) => {
  const { isFieldDisabled } = useContext(FieldContext);
  const disabled = isFieldDisabled !== undefined ? isFieldDisabled : isDisabled;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      width={width}
      maxLength={maxLength}
      required={required}
      disabled={disabled}
      type={type}
    />
  );
};

/**
 * Styles
 */

type StyledInputProps = Pick<Props, "width">;

const StyledInput = styled("input").withConfig({
  shouldForwardProp: (prop) => !["width"].includes(prop),
})<StyledInputProps>`
  position: relative;
  width: ${(props) => props.width ?? "initial"};
  box-shadow: none;
  font-family: ${font.family.default};
  font-size: 16px;
  line-height: 1;
  font-weight: ${font.weight.regular};
  color: ${theme.input.fontColor.default};
  border-top-color: transparent;
  border-bottom-color: ${theme.input.borderColor.default};
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  padding: 10px 0;
  margin: 0 11px;
  transform: translate3d(-11px, 0, 0);
  transform-origin: center center;
  box-sizing: content-box;

  &::placeholder {
    color: ${theme.input.placeholderFontColor.default};
    opacity: 1;
  }

  &:hover {
    border-radius: 3px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-color: ${theme.input.borderColor.hover};
    border-bottom-color: ${theme.input.borderColor.hover};
    border-left-color: ${theme.input.borderColor.hover};
    border-right-color: ${theme.input.borderColor.hover};
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
  }

  &:focus {
    border-radius: 3px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-color: ${theme.input.borderColor.focus};
    border-bottom-color: ${theme.input.borderColor.focus};
    border-left-color: ${theme.input.borderColor.focus};
    border-right-color: ${theme.input.borderColor.focus};
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    outline: none;
    box-shadow: 0 0 0 5px ${theme.input.outlineColor};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.input.fontColor.disabled};
    background-color: transparent;

    &::placeholder {
      color: ${theme.input.placeholderFontColor.disabled};
    }

    &:hover {
      border-radius: 0;
      margin: 0 11px;
      padding-left: 0;
      padding-right: 0;
      border-top-color: transparent;
      border-bottom-color: ${theme.input.borderColor.disabled};
      border-left-color: ${theme.input.borderColor.disabled};
      border-right-color: ${theme.input.borderColor.disabled};
      border-left-width: 0;
      border-right-width: 0;
    }
  }
`;
