import React from "react";
import styled from "styled-components";
import { IconChevron } from "icons";
import { theme } from "styles";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
};

export const CardBackButton = ({ children, onClick }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <StyleButton onClick={handleClick}>
      <IconWrapper>
        <IconChevron color={theme.cardBackButton.fontColor} width={"18px"} height={"10px"} />
      </IconWrapper>
      <ButtonLabel>{children}</ButtonLabel>
    </StyleButton>
  );
};

/**
 * Styles
 */

const StyleButton = styled("button")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  align-self: flex-start;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px ${theme.cardBackButton.outlineColor};
  }
`;

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg) translate3d(0, 4px, 0);
  transform-origin: center center;
`;

const ButtonLabel = styled("span")`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.cardBackButton.fontColor};
  margin-left: 4px;
`;
