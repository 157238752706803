// automatically generated by scripts/keys

export const tk = {
  "activation": {
    "back": "activation.back",
    "form": {
      "activationCode": {
        "invalid": "activation.form.activationCode.invalid",
        "label": "activation.form.activationCode.label",
        "placeholder": "activation.form.activationCode.placeholder"
      },
      "email": {
        "exists": "activation.form.email.exists",
        "invalid": "activation.form.email.invalid",
        "label": "activation.form.email.label"
      },
      "firstName": {
        "label": "activation.form.firstName.label"
      },
      "lastName": {
        "label": "activation.form.lastName.label"
      },
      "password": {
        "label": "activation.form.password.label",
        "placeholder": "activation.form.password.placeholder",
        "tooShort": "activation.form.password.tooShort"
      },
      "passwordConfirmation": {
        "label": "activation.form.passwordConfirmation.label",
        "notEqual": "activation.form.passwordConfirmation.notEqual",
        "placeholder": "activation.form.passwordConfirmation.placeholder"
      },
      "phoneNumber": {
        "invalid": "activation.form.phoneNumber.invalid",
        "label": "activation.form.phoneNumber.label"
      },
      "submit": "activation.form.submit"
    },
    "heading": "activation.heading",
    "login": "activation.login",
    "loginLabel": "activation.loginLabel",
    "termsAndConditions": {
      "link": "activation.termsAndConditions.link",
      "prefix": "activation.termsAndConditions.prefix"
    }
  },
  "announcements": {
    "actions": {
      "changeDeadline": {
        "button": "announcements.actions.changeDeadline.button",
        "expireAt": "announcements.actions.changeDeadline.expireAt",
        "expireIn": "announcements.actions.changeDeadline.expireIn",
        "expireNow": "announcements.actions.changeDeadline.expireNow",
        "expiredBefore": "announcements.actions.changeDeadline.expiredBefore",
        "expiresIn": "announcements.actions.changeDeadline.expiresIn",
        "noExpiration": "announcements.actions.changeDeadline.noExpiration"
      },
      "delete": {
        "button": "announcements.actions.delete.button",
        "deleted": "announcements.actions.delete.deleted",
        "description": "announcements.actions.delete.description"
      },
      "urge": {
        "button": "announcements.actions.urge.button",
        "cantUrgeAfterDeadline": "announcements.actions.urge.cantUrgeAfterDeadline",
        "urgeAnnouncementNotice": "announcements.actions.urge.urgeAnnouncementNotice",
        "urgeAnnouncementSuccess": "announcements.actions.urge.urgeAnnouncementSuccess",
        "urgedCountLast": "announcements.actions.urge.urgedCountLast",
        "urgedCountNext": "announcements.actions.urge.urgedCountNext",
        "urgedLimit": "announcements.actions.urge.urgedLimit",
        "youDidntUrge": "announcements.actions.urge.youDidntUrge"
      },
      "print": {
        "button": "announcements.actions.print.button",
        "name": "announcements.actions.print.name",
        "reaction": "announcements.actions.print.reaction",
        "seen": "announcements.actions.print.seen",
        "sent": "announcements.actions.print.sent",
        "urged": "announcements.actions.print.urged"
      }
    },
    "activity": {
      "changedReaction": "announcements.activity.changedReaction",
      "heading": "announcements.activity.heading",
      "noActivity": "announcements.activity.noActivity",
      "reacted": "announcements.activity.reacted",
      "reminded": "announcements.activity.reminded",
      "seen": "announcements.activity.seen",
      "showOlder": "announcements.activity.showOlder"
    },
    "archive": "announcements.archive",
    "interactions": {
      "changedFrom": "announcements.interactions.changedFrom",
      "seen": "announcements.interactions.seen",
      "smsSent": "announcements.interactions.smsSent",
      "unseen": "announcements.interactions.unseen",
      "urged": "announcements.interactions.urged",
      "wontReact": "announcements.interactions.wontReact"
    },
    "navigation": {
      "create": "announcements.navigation.create"
    },
    "newAnnouncement": "announcements.newAnnouncement",
    "reactions": {
      "reactionChanged": "announcements.reactions.reactionChanged",
      "reactionsAreStopped": "announcements.reactions.reactionsAreStopped",
      "thankYouForReaction": "announcements.reactions.thankYouForReaction",
      "userYourReaction": "announcements.reactions.userYourReaction",
      "youHaveReacted": "announcements.reactions.youHaveReacted"
    },
    "summary": {
      "reactionOptions": "announcements.summary.reactionOptions",
      "reactionsLabel": "announcements.summary.reactionsLabel",
      "reactionsLabel_plural": "announcements.summary.reactionsLabel_plural",
      "recipientsLabel": "announcements.summary.recipientsLabel",
      "recipientsLabel_plural": "announcements.summary.recipientsLabel_plural",
      "seenLabel": "announcements.summary.seenLabel",
      "seenLabel_plural": "announcements.summary.seenLabel_plural",
      "urging": "announcements.summary.urging"
    },
    "tabs": {
      "archive": "announcements.tabs.archive",
      "marked": "announcements.tabs.marked",
      "received": "announcements.tabs.received",
      "sent": "announcements.tabs.sent",
      "unresolved": "announcements.tabs.unresolved"
    }
  },
  "announcementsCreate": {
    "error": "announcementsCreate.error",
    "form": {
      "deadline": {
        "description": "announcementsCreate.form.deadline.description",
        "exactTimeDescription": "announcementsCreate.form.deadline.exactTimeDescription",
        "exactTimeHeading": "announcementsCreate.form.deadline.exactTimeHeading",
        "exactTimeLabel": "announcementsCreate.form.deadline.exactTimeLabel",
        "heading": "announcementsCreate.form.deadline.heading",
        "invalid": "announcementsCreate.form.deadline.invalid",
        "label": "announcementsCreate.form.deadline.label"
      },
      "reactions": {
        "description": "announcementsCreate.form.reactions.description",
        "heading": "announcementsCreate.form.reactions.heading",
        "label": "announcementsCreate.form.reactions.label",
        "reactionList": {
          "add": "announcementsCreate.form.reactions.reactionList.add",
          "placeholder": "announcementsCreate.form.reactions.reactionList.placeholder"
        }
      },
      "recipients": {
        "label": "announcementsCreate.form.recipients.label",
        "required": "announcementsCreate.form.recipients.required"
      },
      "text": {
        "label": "announcementsCreate.form.text.label",
        "placeholder": "announcementsCreate.form.text.placeholder",
        "required": "announcementsCreate.form.text.required"
      },
      "title": {
        "label": "announcementsCreate.form.title.label",
        "placeholder": "announcementsCreate.form.title.placeholder",
        "required": "announcementsCreate.form.title.required"
      },
      "urgency": {
        "heading": "announcementsCreate.form.urgency.heading",
        "label": "announcementsCreate.form.urgency.label",
        "radio": {
          "notUrgent": "announcementsCreate.form.urgency.radio.notUrgent",
          "urgent": "announcementsCreate.form.urgency.radio.urgent",
          "veryUrgent": "announcementsCreate.form.urgency.radio.veryUrgent"
        }
      },
      "videoUrl": {
        "invalid": "announcementsCreate.form.videoUrl.invalid",
        "label": "announcementsCreate.form.videoUrl.label",
        "placeholder": "announcementsCreate.form.videoUrl.placeholder"
      },
      "imageUpload": {
        "label": "announcementsCreate.form.imageUpload.label"
      }
    },
    "sending": "announcementsCreate.sending",
    "sent": "announcementsCreate.sent"
  },
  "chat": {
    "acceptInvitation": "chat.acceptInvitation",
    "chat": "chat.chat",
    "createNewGroup": "chat.createNewGroup",
    "groupChatInvitation": "chat.groupChatInvitation",
    "inactiveUser": "chat.inactiveUser",
    "inactiveUsers": "chat.inactiveUsers",
    "lastActiveChats": "chat.lastActiveChats",
    "leaveGroup": "chat.leaveGroup",
    "messageInput": {
      "placeholder": "chat.messageInput.placeholder"
    },
    "namesAndOthers": "chat.namesAndOthers",
    "namesAndOthers_plural": "chat.namesAndOthers_plural",
    "newMessages": "chat.newMessages",
    "olderChats": "chat.olderChats",
    "pickNewOwner": "chat.pickNewOwner",
    "rejectInvitation": "chat.rejectInvitation",
    "search": {
      "placeholder": "chat.search.placeholder"
    },
    "settings": {
      "createGroup": "chat.settings.createGroup",
      "createNewGroup": "chat.settings.createNewGroup",
      "delete": "chat.settings.delete",
      "groupSettings": "chat.settings.groupSettings",
      "invitationPlaceholder": "chat.settings.invitationPlaceholder",
      "leaveGroupAndDelete": "chat.settings.leaveGroupAndDelete",
      "members": "chat.settings.members",
      "mute": "chat.settings.mute",
      "name": "chat.settings.name",
      "namePlaceholder": "chat.settings.namePlaceholder",
      "unmute": "chat.settings.unmute"
    },
    "userGroupInvitation": "chat.userGroupInvitation",
    "youAreLastChatWillBeDeleted": "chat.youAreLastChatWillBeDeleted",
    "youAreOwnerPickNew": "chat.youAreOwnerPickNew",
    "activity": {
      "heading": "chat.activity.heading"
    },
    "allRead": "chat.allRead",
    "lastMessage": "chat.lastMessage",
    "lastSeen": "chat.lastSeen",
    "neverOpened": "chat.neverOpened"
  },
  "common": {
    "announcements": "common.announcements",
    "cancel": "common.cancel",
    "change": "common.change",
    "changesSaved": "common.changesSaved",
    "delete": "common.delete",
    "disabled": "common.disabled",
    "documentTitleSuffix": "common.documentTitleSuffix",
    "documents": "common.documents",
    "enabled": "common.enabled",
    "help": "common.help",
    "noContent": "common.noContent",
    "nobody": "common.nobody",
    "notActive": "common.notActive",
    "ok": "common.ok",
    "options": "common.options",
    "pleaseWait": "common.pleaseWait",
    "rightNow": "common.rightNow",
    "save": "common.save",
    "saveChanges": "common.saveChanges",
    "savingChanges": "common.savingChanges",
    "select": "common.select",
    "send": "common.send",
    "signOut": "common.signOut",
    "spaces": "common.spaces",
    "stories": "common.stories",
    "version": "common.version",
    "yesDelete": "common.yesDelete",
    "signUp": "common.signUp",
    "confirm": "common.confirm",
    "lastWeek": "common.lastWeek",
    "todayAt": "common.todayAt",
    "yesterdayAt": "common.yesterdayAt"
  },
  "deadlines": {
    "afterDays": "deadlines.afterDays",
    "afterDays_plural": "deadlines.afterDays_plural",
    "afterHours": "deadlines.afterHours",
    "afterHours_plural": "deadlines.afterHours_plural",
    "afterWeek": "deadlines.afterWeek",
    "never": "deadlines.never",
    "now": "deadlines.now",
    "todayEvening": "deadlines.todayEvening",
    "tomorrowEvening": "deadlines.tomorrowEvening"
  },
  "documents": {
    "change": "documents.change",
    "delete": "documents.delete",
    "deleteConfirmation": "documents.deleteConfirmation",
    "download": "documents.download",
    "lastUpdate": "documents.lastUpdate",
    "navigation": {
      "create": "documents.navigation.create"
    },
    "newDocument": "documents.newDocument",
    "sharedWith": "documents.sharedWith",
    "sharedWith_plural": "documents.sharedWith_plural",
    "tabs": {
      "received": "documents.tabs.received",
      "sent": "documents.tabs.sent"
    }
  },
  "documentsCreate": {
    "error": "documentsCreate.error",
    "form": {
      "file": {
        "label": "documentsCreate.form.file.label",
        "required": "documentsCreate.form.file.required"
      },
      "recipients": {
        "label": "documentsCreate.form.recipients.label",
        "required": "documentsCreate.form.recipients.required"
      },
      "title": {
        "label": "documentsCreate.form.title.label",
        "placeholder": "documentsCreate.form.title.placeholder",
        "required": "documentsCreate.form.title.required"
      }
    },
    "sending": "documentsCreate.sending",
    "sent": "documentsCreate.sent"
  },
  "errorBoundary": {
    "back": "errorBoundary.back",
    "heading": "errorBoundary.heading",
    "info": "errorBoundary.info",
    "info2": "errorBoundary.info2",
    "reload": "errorBoundary.reload",
    "subheading": "errorBoundary.subheading"
  },
  "fileUpload": {
    "change": "fileUpload.change",
    "drop": {
      "empty": "fileUpload.drop.empty"
    },
    "replace": "fileUpload.replace"
  },
  "guide": {
    "1": {
      "description": "guide.1.description",
      "heading": "guide.1.heading"
    },
    "2": {
      "description": "guide.2.description",
      "heading": "guide.2.heading"
    },
    "3": {
      "description": "guide.3.description",
      "heading": "guide.3.heading"
    },
    "4": {
      "description": "guide.4.description",
      "heading": "guide.4.heading"
    },
    "finish": "guide.finish",
    "next": "guide.next"
  },
  "imageUpload": {
    "drop": {
      "empty": "imageUpload.drop.empty",
      "replace": "imageUpload.drop.replace"
    },
    "crop": {
      "error": "imageUpload.crop.error",
      "heading": "imageUpload.crop.heading"
    }
  },
  "login": {
    "activate": "login.activate",
    "activateLabel": "login.activateLabel",
    "form": {
      "email": {
        "label": "login.form.email.label",
        "placeholder": "login.form.email.placeholder"
      },
      "error": "login.form.error",
      "password": {
        "label": "login.form.password.label",
        "placeholder": "login.form.password.placeholder"
      },
      "submit": "login.form.submit"
    },
    "heading": "login.heading",
    "noOrganizationOrSpace": "login.noOrganizationOrSpace",
    "passwordRecovery": "login.passwordRecovery"
  },
  "passwordRecovery": {
    "codeInfo": "passwordRecovery.codeInfo",
    "form": {
      "email": {
        "invalid": "passwordRecovery.form.email.invalid",
        "label": "passwordRecovery.form.email.label",
        "placeholder": "passwordRecovery.form.email.placeholder"
      },
      "password": {
        "label": "passwordRecovery.form.password.label",
        "placeholder": "passwordRecovery.form.password.placeholder",
        "tooShort": "passwordRecovery.form.password.tooShort"
      },
      "submit": "passwordRecovery.form.submit",
      "token": {
        "label": "passwordRecovery.form.token.label",
        "placeholder": "passwordRecovery.form.token.placeholder"
      }
    },
    "heading": "passwordRecovery.heading",
    "login": "passwordRecovery.login",
    "loginLabel": "passwordRecovery.loginLabel",
    "tokenError": {
      "back": "passwordRecovery.tokenError.back",
      "info": "passwordRecovery.tokenError.info",
      "tip": "passwordRecovery.tokenError.tip"
    }
  },
  "recipientsSelection": {
    "addButtonLabel": "recipientsSelection.addButtonLabel",
    "addGroup": "recipientsSelection.addGroup",
    "contactGroupsModal": {
      "add": "recipientsSelection.contactGroupsModal.add",
      "add_plural": "recipientsSelection.contactGroupsModal.add_plural",
      "heading": "recipientsSelection.contactGroupsModal.heading",
      "subheading": "recipientsSelection.contactGroupsModal.subheading"
    },
    "everyone": "recipientsSelection.everyone",
    "noMatch": "recipientsSelection.noMatch",
    "nonSelectedRecipientNotice": "recipientsSelection.nonSelectedRecipientNotice",
    "removeAllButtonLabel": "recipientsSelection.removeAllButtonLabel",
    "removeButtonLabel": "recipientsSelection.removeButtonLabel",
    "searchByName": "recipientsSelection.searchByName",
    "selectedRecipientsList": {
      "heading": "recipientsSelection.selectedRecipientsList.heading",
      "heading_plural": "recipientsSelection.selectedRecipientsList.heading_plural"
    }
  },
  "role": {
    "manager": "role.manager",
    "regular": "role.regular"
  },
  "stories": {
    "addComment": "stories.addComment",
    "clapped": "stories.clapped",
    "comments": "stories.comments",
    "comments_plural": "stories.comments_plural",
    "edit": "stories.edit",
    "loadOlderComments": "stories.loadOlderComments",
    "navigation": {
      "create": "stories.navigation.create"
    },
    "sharedBy": "stories.sharedBy",
    "sharedIn": "stories.sharedIn"
  },
  "storiesCreate": {
    "error": "storiesCreate.error",
    "form": {
      "photo": {
        "label": "storiesCreate.form.photo.label"
      },
      "text": {
        "label": "storiesCreate.form.text.label",
        "placeholder": "storiesCreate.form.text.placeholder",
        "required": "storiesCreate.form.text.required"
      }
    },
    "sending": "storiesCreate.sending",
    "sent": "storiesCreate.sent"
  },
  "storiesEdit": {
    "confirmDelete": "storiesEdit.confirmDelete",
    "delete": "storiesEdit.delete"
  },
  "support": {
    "feedback": "support.feedback",
    "feedbackPlaceholder": "support.feedbackPlaceholder",
    "feedbackSuccess": "support.feedbackSuccess",
    "heading": "support.heading",
    "reportProblem": "support.reportProblem",
    "topics": "support.topics"
  },
  "updateAvailable": "updateAvailable",
  "updateAvailableDescription": "updateAvailableDescription",
  "sponsors": {
    "modal": {
      "heading": "sponsors.modal.heading",
      "partner": "sponsors.modal.partner"
    }
  },
  "benefits": "benefits"
};
