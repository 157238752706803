import React from "react";
import styled from "styled-components";
import { Icon } from "components";
import { fromNow } from "helpers";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

type Props = {
  src: string;
  author: string;
  spaceName: string;
  createdAt: string;
  onClose: () => void;
};

export const ImageOverlay = ({ src, author, spaceName, createdAt, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Overlay onClick={onClose}>
      <ImageOverlayContainer>
        <ImageOverlayCaption>
          {t(tk.stories.sharedBy, { name: author })} {t(tk.stories.sharedIn, { space: spaceName })} {fromNow(createdAt)}
          <Icon shape={"cancel"} size={"36px"} cursor={"pointer"} onClick={onClose} />
        </ImageOverlayCaption>
        <ImageOverlayImageWrapper>
          <ImageOverlayImage src={src} onClick={(e: any) => e.stopPropagation()} />
        </ImageOverlayImageWrapper>
      </ImageOverlayContainer>
    </Overlay>
  );
};

/**
 * Styles
 */

const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const ImageOverlayContainer = styled.div`
  max-width: 883px;
  margin: 0 auto;
  height: 100%;
  padding: 120px 20px;
`;

const ImageOverlayImageWrapper = styled.div`
  position: relative;
  height: calc(100% - 50px);
`;

const ImageOverlayImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const ImageOverlayCaption = styled.div`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 16px;
  color: ${theme.colors.SnowWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  margin-bottom: 7px;
`;
