import React from "react";
import styled from "styled-components";
import { Dot } from "components/Dot";
import { font, theme } from "styles";

type Props = {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => any;
};

export const Tab = ({ children, isActive = false, onClick = () => {} }: Props) => {
  return (
    <Container onClick={onClick}>
      <Text isActive={isActive}>{children}</Text>
      {isActive && <Dot size={"6px"} />}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
`;

type TextProps = {
  isActive: boolean;
};

const Text = styled("span").withConfig({ shouldForwardProp: (prop) => !["isActive"].includes(prop) })<TextProps>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: ${(props) => (props.isActive ? font.weight.bold : font.weight.medium)};
  color: ${(props: TextProps) => (props.isActive ? theme.tab.fontColor.active : theme.tab.fontColor.default)};
  margin-bottom: 4px;

  svg {
    fill: ${(props: TextProps) => (props.isActive ? theme.tab.iconColor.active : theme.tab.iconColor.default)};
    stroke: ${(props: TextProps) => (props.isActive ? theme.tab.iconColor.active : theme.tab.iconColor.default)};
  }
`;
