import { useMemo } from "react";
import { useMe } from "hooks/useMe";
import { Language } from "translations";
import { SupportHelper } from "helpers";

const paths = {
  regular: {
    [Language.Cs]: () => require("assets/support/regular/cs/data"),
    [Language.Sk]: () => require("assets/support/regular/sk/data"),
    [Language.En]: () => require("assets/support/regular/en/data"),
    [Language.De]: () => require("assets/support/regular/de/data"),
  },
  manager: {
    [Language.Cs]: () => require("assets/support/manager/cs/data"),
    [Language.Sk]: () => require("assets/support/manager/sk/data"),
    [Language.En]: () => require("assets/support/manager/en/data"),
    [Language.De]: () => require("assets/support/manager/de/data"),
  },
};

export const useSupport = () => {
  const { activeLanguage } = useMe();

  const topics = useMemo(
    () => ({
      regular: paths.regular[activeLanguage](),
      manager: paths.manager[activeLanguage](),
    }),
    [activeLanguage]
  );

  const { regular, manager } = useMemo(
    () => ({
      regular: new SupportHelper(topics.regular.default ? topics.regular.default : topics.regular),
      manager: new SupportHelper(topics.manager.default ? topics.manager.default : topics.manager),
    }),
    [topics]
  );

  return { regular, manager };
};
