import React from "react";
import styled from "styled-components";
import { font, theme } from "styles";

type Props = {
  children: string;
  hasError?: boolean;
  required?: boolean;
};

export const Title = ({ hasError, required, children }: Props) => {
  return (
    <StyledTitle hasError={hasError} required={required}>
      {children}
    </StyledTitle>
  );
};

/**
 * Styles
 */

const StyledTitle = styled.span.withConfig({
  shouldForwardProp: (prop) => !["hasError", "required"].includes(prop),
})<Props>`
  font-size: 10px;
  font-weight: ${font.weight.bold};
  color: ${(props) => (props.hasError ? theme.colors.FireRed : theme.colors.NightSky)};
  text-transform: uppercase;

  &:before {
    display: ${(props) => (props.required ? "inline" : "none")};
    content: "* ";
    color: ${theme.colors.FireRed};
    font-size: 12px;
  }
`;
