import { useCallback, useMemo, useState } from "react";
import {
  chatNewMessagesUpdateQuery,
  chatPreviousMessagesUpdateQuery,
  ChatQuery,
  mapConnection,
  useChatNewMessagesLazyQuery,
  useChatPreviousMessagesLazyQuery,
} from "api";

export const useConversationMessages = (chat: string, data: ChatQuery, onNewMessage: () => any) => {
  /** Previous messages */
  const [
    fetchPrevious,
    { data: previousData, loading: fetchingPrevious, called: fetchedPrevious, fetchMore: fetchMorePrevious },
  ] = useChatPreviousMessagesLazyQuery();

  const [fetchingMorePrevious, setFetchingMorePrevious] = useState(false);

  const previousMessages = useMemo(() => {
    return mapConnection(previousData?.chat?.messages).sort(({ createdAt: a }, { createdAt: b }) => {
      return a === b ? 0 : a > b ? 1 : -1;
    });
  }, [previousData]);

  /** New messages */

  const [fetchNew, { data: newData, called: fetchedNew, fetchMore: fetchMoreNew }] = useChatNewMessagesLazyQuery({
    onCompleted: onNewMessage,
  });

  const [fetchingMoreNew, setFetchingMoreNew] = useState(false);

  const newMessages = useMemo(() => {
    const ids: string[] = [];
    return mapConnection(newData?.chat?.messages)
      .sort(({ createdAt: a }, { createdAt: b }) => (a === b ? 0 : a > b ? 1 : -1))
      .filter(({ id }) => {
        if (ids.includes(id)) return false;
        ids.push(id);
        return true;
      });
  }, [newData]);

  /** Messages */

  const messages = useMemo(() => {
    const messages = mapConnection(data.chat?.messages).sort(({ createdAt: a }, { createdAt: b }) => {
      return a === b ? 0 : a > b ? 1 : -1;
    });

    return [...previousMessages, ...messages, ...newMessages];
  }, [data.chat, newMessages, previousMessages]);

  const firstMessageCursor = useMemo(() => messages[0]?.cursor, [messages]);
  const lastMessageCursor = useMemo(() => messages[messages.length - 1]?.cursor, [messages]);
  const hasPreviousMessages = useMemo(() => {
    return fetchedPrevious
      ? !!previousData?.chat?.messages.pageInfo.hasPreviousPage
      : !!data.chat?.messages.pageInfo.hasPreviousPage;
  }, [data.chat, fetchedPrevious, previousData]);

  /** Handlers */

  const preventFetchPrevious = useMemo(
    () => !hasPreviousMessages || !firstMessageCursor || fetchingPrevious || fetchingMorePrevious,
    [fetchingMorePrevious, fetchingPrevious, firstMessageCursor, hasPreviousMessages]
  );

  const fetchPreviousMessages = useCallback(async () => {
    if (preventFetchPrevious) return;

    if (!fetchedPrevious) return fetchPrevious({ variables: { id: chat, before: firstMessageCursor } });

    setFetchingMorePrevious(true);

    await fetchMorePrevious({
      variables: { before: firstMessageCursor },
      updateQuery: chatPreviousMessagesUpdateQuery,
    });

    setFetchingMorePrevious(false);
  }, [chat, fetchMorePrevious, fetchPrevious, fetchedPrevious, firstMessageCursor, preventFetchPrevious]);

  const fetchNewMessages = useCallback(async () => {
    if (fetchingMoreNew) return;

    if (!fetchedNew) return fetchNew({ variables: { id: chat, after: lastMessageCursor || null } });

    setFetchingMoreNew(true);

    await fetchMoreNew({
      variables: { after: lastMessageCursor || null },
      updateQuery: chatNewMessagesUpdateQuery,
    });

    setFetchingMoreNew(false);

    onNewMessage();
  }, [chat, fetchMoreNew, fetchNew, fetchedNew, fetchingMoreNew, lastMessageCursor, onNewMessage]);

  return {
    messages,
    fetchingPrevious: fetchingPrevious || fetchingMorePrevious,
    fetchPreviousMessages,
    fetchNewMessages,
  };
};
