import React from "react";
import styled from "styled-components";
import { IconStar, IconStarFilled } from "icons";
import { theme } from "styles";

type Props = {
  marked: boolean;
  onClick: () => void;
};

export class DetailMarkButtonSection extends React.PureComponent<Props> {
  render() {
    return (
      <Section onClick={this.props.onClick}>
        {this.props.marked ? <Icon color={theme.colors.TealGreen} /> : <IconOutline color={theme.colors.IronGrey} />}
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: absolute;
  width: 22px;
  height: 22px;
  top: 24px;
  right: 24px;
  z-index: 9;
  cursor: pointer;
`;

const IconOutline = styled(IconStar)`
  width: 100%;
  height: 100%;
`;

const Icon = styled(IconStarFilled)`
  width: 100%;
  height: 100%;
`;
