import React from "react";
import styled from "styled-components";
import { Media } from "hooks";
import { tk, useTranslation } from "translations";

interface Props {
  media: Media;
  defaultSrc: string;
}

export const ProfileImageUpload = ({ media, defaultSrc }: Props) => {
  const { t } = useTranslation();

  return (
    <Container onClick={media.openFileBrowser}>
      <StyledImage src={media.preview || defaultSrc} />
      <StyledText>{t(tk.common.change)}</StyledText>
      <input {...media.fileInputProps} />
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 127px;
  height: 143px;
  cursor: pointer;
`;

const StyledText = styled("span")`
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
`;

const StyledImage = styled("img")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(40%);
`;
