import { useCallback, useMemo, useRef, useState } from "react";
import { useMe } from "hooks";
import { useConversationMessages } from "chat/hooks/useConversationMessages";
import { useSound } from "chat/hooks/useSound";
import { useChatStore } from "chat/hooks/useChatStore";
import {
  ChatQuery,
  mapConnection,
  resolveError,
  useChatChangeSubscription,
  useChatLeaveMutation,
  useChatSendMessageMutation,
  useChatSetMutedMutation,
} from "api";

export const useConversation = (
  chat: string,
  data: ChatQuery,
  compact: boolean,
  onClose: () => any,
  onToggleCompact: () => any,
  onSelectNewOwner: () => any
) => {
  const { me } = useMe();
  const { playSend } = useSound();
  const { soundEnabled } = useChatStore();

  const messageListRef = useRef<HTMLDivElement>(null);

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  const scrollToBottom = useCallback(
    () => messageListRef.current?.scrollTo({ top: messageListRef.current.scrollHeight }),
    []
  );

  const handleNewMessage = useCallback(() => {
    if (compact) setHasUnreadMessages(true);

    scrollToBottom();
  }, [compact, scrollToBottom]);

  const { messages, fetchingPrevious, fetchNewMessages, fetchPreviousMessages } = useConversationMessages(
    chat,
    data,
    handleNewMessage
  );

  useChatChangeSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      const isThisChat = subscriptionData.data?.onChatChange?.chat?.id === chat;
      const isMe = subscriptionData.data?.onChatChange?.chat?.messages?.edges[0]?.node?.author.id === me?.userId;
      if (isThisChat && !isMe) await fetchNewMessages();
    },
  });

  const isGroupChat = useMemo(() => (data.chat?.interactions.edges.length || 0) > 2, [data.chat]);

  const isMuted = useMemo(() => data.chat?.myInteraction.muted || false, [data.chat]);

  const otherInteractions = useMemo(() => {
    if (!data.chat?.interactions) return [];

    return mapConnection(data.chat.interactions)
      .filter(({ user, archivedAt }) => {
        return user.id !== me?.userId && !archivedAt;
      })
      .sort((a, b) => {
        if (!a.user.isActive) return -1;
        if (!b.user.isActive) return 1;
        if (a.acceptedAt) return b.acceptedAt ? 0 : 1;
        return b.acceptedAt ? -1 : 0;
      });
  }, [data.chat, me]);

  const inactiveUsers = useMemo(() => {
    return otherInteractions.filter(({ user }) => !user.isActive).map(({ user }) => user);
  }, [otherInteractions]);

  const [send, { loading }] = useChatSendMessageMutation();

  const [setMuted] = useChatSetMutedMutation();

  const [leave] = useChatLeaveMutation();

  const handleScroll = useCallback(async () => {
    if (fetchingPrevious || !messageListRef.current || messageListRef.current.scrollTop > 290) return;

    await fetchPreviousMessages();
  }, [fetchPreviousMessages, fetchingPrevious]);

  const submitMessage = useCallback(
    async (message: string) => {
      if (loading) return;

      try {
        await send({ variables: { input: { chat, message } } });

        if (!isMuted && soundEnabled) await playSend();

        await fetchNewMessages();
      } catch (e: any) {
        resolveError(e);
      }
    },
    [chat, fetchNewMessages, isMuted, loading, playSend, send, soundEnabled]
  );

  const toggleCompact = useCallback(() => {
    if (compact) setHasUnreadMessages(false);
    onToggleCompact();
  }, [compact, onToggleCompact]);

  const toggleMuted = useCallback(async () => {
    try {
      await setMuted({ variables: { input: { chat, muted: !isMuted } } });
    } catch (e: any) {
      resolveError(e);
    }
  }, [chat, isMuted, setMuted]);

  const leaveConversation = useCallback(async () => {
    const isOwner = data.chat?.createdBy.id === me?.userId;

    if (isGroupChat && isOwner) return onSelectNewOwner();

    try {
      await leave({ variables: { input: { chat } } });
      onClose();
    } catch (e: any) {
      resolveError(e);
    }
  }, [chat, data.chat, isGroupChat, leave, me, onClose, onSelectNewOwner]);

  return {
    refs: { messageListRef },
    data: { messages, isGroupChat, isMuted, me, otherInteractions, inactiveUsers },
    state: { hasUnreadMessages },
    handlers: {
      handleScroll,
      scrollToBottom,
      submitMessage,
      toggleCompact,
      toggleMuted,
      leaveConversation,
    },
  };
};
