import React from "react";
import { Link, RouteComponentProps, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { useDashboard } from "controllers";
import * as Page from "pages";
import { Header, HeaderLogo, HeaderOrganizationSelect, HeaderProfileDropdown, Sidebar, Sponsors } from "components";
import { Chat } from "chat";
import { DashboardParams, routes } from "routes";

interface Props extends RouteComponentProps<DashboardParams> {}

export const Dashboard = (props: Props) => {
  const { data, state } = useDashboard(props);

  if (!state.render) return null;

  return (
    <Layout>
      {/* Key change will trigger sponsors refetch */}
      <Sponsors key={data.activeOrganizationId} activeOrganizationId={data.activeOrganizationId} />

      <Header>
        <HeaderOrganizationSelect />

        <StyledLink to={data.storiesRoute}>
          <HeaderLogo />
        </StyledLink>

        <HeaderProfileDropdown />
      </Header>

      <Content>
        <Sidebar />

        <Switch>
          <Route path={routes.storiesCreate} component={Page.StoriesCreate} exact={true} />
          <Route path={routes.storiesEdit} component={Page.StoriesEdit} exact={true} />
          <Route path={routes.stories} component={Page.Stories} exact={true} />

          <Route path={routes.documentsCreate} component={Page.DocumentsCreate} exact={true} />
          <Route path={routes.documentsEdit} component={Page.DocumentsEdit} exact={true} />
          <Route path={routes.documentsDetail} component={Page.DocumentsDetail} exact={true} />
          <Route path={routes.documents} component={Page.Documents} exact={true} />

          <Route path={routes.announcementsCreate} component={Page.AnnouncementsCreate} exact={true} />
          <Route path={routes.announcementsDetail} component={Page.AnnouncementsDetail} exact={true} />
          <Route path={routes.announcements} component={Page.Announcements} exact={true} />

          <Route path={routes.support} component={Page.Support} exact={true} />
          <Route path={routes.feedback} component={Page.Feedback} exact={true} />
          <Route path={routes.benefits} component={Page.Benefits} exact={true} />
        </Switch>
      </Content>

      <Chat />
    </Layout>
  );
};

/**
 * Styles
 */

const Layout = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Content = styled.div`
  position: absolute;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
`;

const StyledLink = styled(Link)`
  display: flex;
  position: relative;
`;
