import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconSearch = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" style={{ margin }}>
      <path
        id="search"
        d="M31.625,29.75l-7.688-7.688A13.765,13.765,0,0,0,27,13.5a13.6,13.6,0,1,0-5,10.437l7.75,7.688a1.326,1.326,0,0,0,1.875-1.875ZM13.5,24.313A10.813,10.813,0,1,1,24.313,13.5,10.817,10.817,0,0,1,13.5,24.313Z"
        transform="translate(0 0.001)"
        fill={color}
      />
    </svg>
  );
};
