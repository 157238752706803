import { createGlobalStyle } from "styled-components";
import "react-mde/lib/styles/css/react-mde-all.css";
import "@fortawesome/fontawesome-free-webfonts/css/fontawesome.css";
import "@fortawesome/fontawesome-free-webfonts/css/fa-solid.css";
import { font } from "./typography";
import { theme } from "./theme";

// @ts-ignore
import moyoboFont from "assets/fonts/moyobo.ttf";

export const GlobalStyle = createGlobalStyle`
  /* TODO: remove moyobo font when all icons in app will be using svg */
  
  @font-face {
  	font-family: 'moyobo';
  	src: url(${moyoboFont});
  }

  body {
    margin: 0;
    font-family: ${font.family.default};
    background-color: ${theme.body.backgroundColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  * {
  	box-sizing: border-box;
  }
  
  .react-mde {
    width: 100%;
  }
  
  #root .mde-text {
    border: none;
  }
  
  #root .mde-preview-content {
		font-family: 'Raleway', sans-serif;
		font-size: 14px;
		font-weight: 300;
		line-height: 24px;
		border: 1px dotted rgba(0,0,0,0.15);
		border-radius: 0.25em;
		padding: 10px;
		margin-top: 8px;
  }
  
  #root .public-DraftEditor-content {
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  #root .public-DraftEditor-content:focus {
    background-color: #F2F2F2;
  }
  
  #root .react-mde {
		border: none;
		outline: none;
		box-shadow: none;
		font-family: 'Raleway', sans-serif;
		font-size: 14px;
		font-weight: 300;
		line-height: 24px;
		height: auto;
  }
  
  #root .mde-header ul.mde-header-group li.mde-header-item button {
    color: #767676;
  }
  
  .linkify-chat__incoming { color: #404040; }
  .linkify-chat__outgoing { color: #FFFFFF; }

  .rc-tooltip {
    .rc-tooltip-inner {
      border-radius: 3px !important;
    }
  }
  .rc-tooltip.primary-tooltip {
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
    font-weight: 500;

    .rc-tooltip-inner {
      background-color: #292D32 !important;
      color: #F2F2F2 !important;
    }

    &.rc-tooltip-placement-right {
      .rc-tooltip-arrow {
        border-right-color: #292D32 !important;
      }
    }

    &.rc-tooltip-placement-left {
      .rc-tooltip-arrow {
        border-left-color: #292D32 !important;
      }
    }

    &.rc-tooltip-placement-top {
      .rc-tooltip-arrow {
        border-top-color: #292D32 !important;
      }
    }

    &.rc-tooltip-placement-bottom {
      .rc-tooltip-arrow {
        border-bottom-color: #292D32 !important;
      }
    }
  }
  
  /* Scrollbar */
  
  *::-webkit-scrollbar {
    border: 0;
    width: 6px;
    height: 3px;
    position: absolute;
  }
  
  *:hover::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbar.backgroundColor};
  }
`;
