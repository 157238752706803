import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

type Props = {
  src: string;
};

export class DetailImageSection extends React.PureComponent<Props> {
  render() {
    return (
      <Section>
        <ImageContainer>
          <Image src={this.props.src} />
        </ImageContainer>
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 60px;
`;
