import styled from "styled-components";
import backgroundImage from "assets/images/header-background.png";

export const Header = styled("header")`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #0a9187;
  background-image: url(${backgroundImage});
  background-position: center center;
  background-size: cover;
  box-shadow: 0 2px 6px #292d3234;
  padding: 0 20px;
  z-index: 1; /* Prevents box-shadow to be covered */
`;
