import React, { useCallback, useMemo } from "react";
import { MouseEvent } from "react";
import styled from "styled-components";
import { Icon, NotificationDot } from "components";
import { WindowHeader } from "chat/components/WindowHeader";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

import soundOnIcon from "assets/images/chat/sound_on.png";
import soundOffIcon from "assets/images/chat/sound_off.png";

interface Props {
  unreadMessagesCount: number;
  unresolvedCount: number;
  compact: boolean;
  sound: boolean;
  onClick: () => any;
  toggleSound: () => void;
}

export const ListWindowHeader = ({
  unreadMessagesCount,
  unresolvedCount,
  compact,
  sound,
  onClick,
  toggleSound,
}: Props) => {
  const { t } = useTranslation();

  const hasNewMessages = useMemo(() => unreadMessagesCount > 0 && compact, [compact, unreadMessagesCount]);

  const handleToggleSound = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      toggleSound();
    },
    [toggleSound]
  );

  const labelColor = hasNewMessages ? theme.colors.TealGreen : theme.colors.IronGrey;

  return (
    <StyledHeader onClick={onClick}>
      <ChatIcon shape={"chat"} size={"22px"} color={labelColor} />
      <Label color={labelColor}>{hasNewMessages ? t(tk.chat.newMessages) : t(tk.chat.chat)}</Label>

      {unresolvedCount > 0 && <NotificationDot value={unresolvedCount} />}

      <Control onClick={handleToggleSound}>
        <SoundIcon src={sound ? soundOnIcon : soundOffIcon} />
      </Control>
    </StyledHeader>
  );
};

/**
 * Styles
 */

const StyledHeader = styled(WindowHeader)`
  cursor: pointer;
`;

const ChatIcon = styled(Icon)`
  color: ${(props) => props.color};
  font-weight: ${font.weight.regular};
  padding: 0 9px 0 0;
  cursor: pointer;
`;

const Label = styled.div`
  flex: 1;
  color: ${(props) => props.color};
`;

const Control = styled.a`
  display: flex;
  height: 100%;
  align-items: center;
  width: 30px;
  justify-content: center;
  cursor: pointer;
`;

const SoundIcon = styled.img`
  width: 22px;
  height: 18px;
  float: right;
`;
