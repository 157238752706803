import React from "react";
import { Link, LinkProps, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  to: string;
  activeRoutes?: string[];
};

export const NavigationLink = ({ children, to, activeRoutes }: Props) => {
  const isActive = !!useRouteMatch(!activeRoutes ? to : activeRoutes);

  return (
    <StyledLink to={to} isActive={isActive}>
      {children}
    </StyledLink>
  );
};

/**
 * Styles
 */

type StyledLinkProps = LinkProps & { isActive: boolean };

const StyledLink = styled(({ isActive, children, ...rest }: StyledLinkProps) => <Link {...rest}>{children}</Link>)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  opacity: ${(props: Pick<StyledLinkProps, "isActive">) => (props.isActive ? 1 : 0.5)};
`;
