import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  margin?: string;
  alignment?: "left" | "right";
};

export const ListItemTextWrapper = styled("div").withConfig({
  shouldForwardProp: (prop) => !["margin", "alignment"].includes(prop),
})<Props>`
  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 8px;
  margin: ${(props) => props.margin ?? "0"};
  justify-items: ${(props: Props) => props.alignment ?? "left"};
`;
