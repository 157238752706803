import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconStarFilled = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31.975 29.881"
      style={{ margin }}
      fill={color}
    >
      <path
        id="star-active"
        d="M14.875,1.75a1.264,1.264,0,0,1,2.25,0l4,7.875a1.108,1.108,0,0,0,.875.688l8.938,1.25a1.148,1.148,0,0,1,1,.813,1.3,1.3,0,0,1-.313,1.25l-6.438,6.125a1.2,1.2,0,0,0-.375,1.063l1.5,8.688a1.065,1.065,0,0,1-.438,1.188,1.255,1.255,0,0,1-1.313.125l-8-4.125a1.193,1.193,0,0,0-1.125,0l-8,4.125a1.265,1.265,0,0,1-1.313-.125A1.065,1.065,0,0,1,5.685,29.5l1.5-8.688a1.2,1.2,0,0,0-.375-1.063L.372,13.626a1.3,1.3,0,0,1-.313-1.25,1.149,1.149,0,0,1,1-.813L10,10.313a1.108,1.108,0,0,0,.875-.688Z"
        transform="translate(-0.011 -1.062)"
      />
    </svg>
  );
};
