import React, { useCallback, useContext } from "react";
import { HeaderDropdownContext } from "components/HeaderDropdown/HeaderDropdown";
import { StyledListItem } from "components/Dropdown";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  isActive?: boolean;
};

export const HeaderDropdownMenuItem = ({ children, onClick, isActive = false }: Props) => {
  const { setIsDropdownOpen } = useContext(HeaderDropdownContext);

  const handleClick = useCallback(() => {
    setIsDropdownOpen && setIsDropdownOpen(false);
    onClick();
  }, [onClick, setIsDropdownOpen]);

  return (
    <StyledListItem isActive={isActive} onClick={handleClick}>
      {children}
    </StyledListItem>
  );
};
