import React, { PropsWithChildren } from "react";
import { materialTheme } from "components/DateTimePicker/theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";

interface Props extends PropsWithChildren<{}> {}

export const DateTimePickerWrapper = ({ children }: Props) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};
