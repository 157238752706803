import { useCallback, useState } from "react";
import { useUpdateEffect } from "react-use";
import { useDashboardState } from "hooks";

type Conversation = { chat: string; compact: boolean };

export type ChatDialog = undefined | "groupSettings" | "selectNewOwner" | "activity";

export const useChat = () => {
  const { organization } = useDashboardState();

  const [openConversations, setOpenConversations] = useState<Conversation[]>([]);

  useUpdateEffect(() => setOpenConversations([]), [organization]);

  const toggleCompact = useCallback(
    (chat: string) => {
      setOpenConversations(openConversations.map((c) => (c.chat !== chat ? c : { chat, compact: !c.compact })));
    },
    [openConversations]
  );

  const openConversation = useCallback(
    (chat: string) => {
      const existingConversation = openConversations.find((conversation) => conversation.chat === chat);

      /** If conversation is already open and it's not compact, do nothing */
      if (existingConversation && !existingConversation.compact) return;

      /** If conversation is already open and it's compact, expand the conversation */
      if (existingConversation && existingConversation.compact) return toggleCompact(chat);

      /** If there are 3 open conversations, close first and open the new one */
      if (openConversations.length === 3) {
        return setOpenConversations([openConversations[1], openConversations[2], { chat, compact: false }]);
      }

      /** If conversation not open, open it */
      setOpenConversations([...openConversations, { chat, compact: false }]);
    },
    [openConversations, toggleCompact]
  );

  const closeConversation = useCallback(
    (chat: string) => {
      setOpenConversations(openConversations.filter((c) => c.chat !== chat));
    },
    [openConversations]
  );

  /** Dialog */
  const [dialog, setDialog] = useState<ChatDialog>(undefined);
  const [dialogChat, setDialogChat] = useState<string | undefined>(undefined);

  const openGroupSettingsDialog = useCallback((chat?: string) => {
    setDialogChat(chat);
    setDialog("groupSettings");
  }, []);

  const openSelectNewOwnerDialog = useCallback((chat: string) => {
    setDialogChat(chat);
    setDialog("selectNewOwner");
  }, []);

  const openActivityDialog = useCallback((chat: string) => {
    setDialogChat(chat);
    setDialog("activity");
  }, []);

  const closeDialog = useCallback(() => {
    setDialog(undefined);
    setDialogChat(undefined);
  }, []);

  const handleChangeGroupSettings = useCallback(
    (chat: string) => {
      closeDialog();
      openConversation(chat);
    },
    [closeDialog, openConversation]
  );

  return {
    state: { openConversations, dialog, dialogChat },
    handlers: {
      openConversation,
      closeConversation,
      toggleCompact,
      openGroupSettingsDialog,
      openSelectNewOwnerDialog,
      openActivityDialog,
      handleChangeGroupSettings,
      closeDialog,
    },
  };
};
