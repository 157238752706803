import styled from "styled-components";
import { font, theme } from "styles";

export const SelectOptionDetail = styled("span")`
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${theme.selectOptionDetail.fontColor};
  margin-top: 8px;
`;
