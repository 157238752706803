import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useDashboardState, useMe } from "hooks";
import { DocumentsTabs, getRoute } from "routes";
import { tk, useTranslation } from "translations";

export const useDocuments = (tab: DocumentsTabs) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.documents) + " " + t(tk.common.documentTitleSuffix));

  const { organization, space } = useDashboardState();

  const { isManager } = useMe();

  const createDocument = useCallback(() => {
    history.push(getRoute.documentsCreate({ organization, space, tab: "sent" }));
  }, [history, organization, space]);

  return {
    t,
    tk,
    data: { isManager },
    state: { tab },
    handlers: { createDocument },
  };
};
