import useStore, { createStore } from "global-hook-store";

const chatStore = createStore(
  { soundEnabled: true },
  {
    toggleSoundEnabled: (_state) => ({ ..._state, soundEnabled: !_state.soundEnabled }),
  }
);

export const useChatStore = () => {
  const { actions, state, setState } = useStore(chatStore);

  return { ...actions, ...state, setState };
};
