import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  margin?: string;
};

export const ReactionList = ({ children, margin }: Props) => {
  return <Container margin={margin}>{children}</Container>;
};

/**
 * Styles
 */

type ContainerProps = Pick<Props, "margin">;

const Container = styled("div").withConfig({ shouldForwardProp: (prop) => !["margin"].includes(prop) })<ContainerProps>`
  display: grid;
  position: relative;
  grid-template-columns: max-content;
  grid-auto-rows: max-content;
  grid-row-gap: 11px;
  grid-auto-flow: row;
  justify-items: start;
  margin: ${(props) => props.margin ?? 0};
`;
