import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { SidebarTitle } from "components/Sidebar/SidebarTitle";
import { SidebarList } from "components/Sidebar/SidebarList";
import { SidebarListSpinner } from "components/Sidebar/SidebarListSpinner";
import { SidebarListItem } from "components/Sidebar/SidebarListItem";
import { useDashboardState, useMe } from "hooks";
import { tk, useTranslation } from "translations";
import { getRoute } from "routes";

type Props = { compact: boolean };

export const SidebarSpacesList = ({ compact }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { organization, space } = useDashboardState();
  const { activeOrganization } = useMe();

  const selectSpace = useCallback((id: string) => history.push(getRoute.dashboard({ organization, space: id })), [
    history,
    organization,
  ]);

  return (
    <>
      <SidebarTitle compact={compact}>{t(tk.common.spaces)}</SidebarTitle>
      <SidebarList>
        {!activeOrganization ? (
          <SidebarListSpinner />
        ) : (
          activeOrganization.spaces.map(({ id, name, logo, unresolvedDocumentCount, unresolvedAnnouncementCount }) => (
            <SidebarListItem
              key={id}
              imageSrc={logo}
              isActive={id === space}
              compact={compact}
              isUnresolved={unresolvedDocumentCount > 0 || unresolvedAnnouncementCount > 0}
              onClick={() => selectSpace(id)}
            >
              {name}
            </SidebarListItem>
          ))
        )}
      </SidebarList>
    </>
  );
};
