import React from "react";
import { theme } from "styles";

type Props = {
  children?: never;
  width?: string | number;
  height?: string | number;
};

export const ImagePlaceholder = ({ width = "100%", height = "100%" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 126 142"}
      version={"1.1"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      xmlSpace={"preserve"}
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
        position: "relative",
      }}
    >
      <path
        d={
          "M58.083,1.313C61.124,-0.436 64.877,-0.436 67.918,1.313L121.091,31.913C124.123,33.653 125.999,36.892 126,40.388L126,101.612C125.998,105.107 124.122,108.344 121.091,110.084L67.918,140.684C64.877,142.433 61.124,142.433 58.083,140.684L4.91,110.084C1.879,108.344 0.003,105.107 0,101.612L0,40.388C0.002,36.893 1.878,33.656 4.909,31.916L58.083,1.313Z"
        }
        style={{ fill: theme.colors.SilverGrey }}
      />
    </svg>
  );
};
