import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { DocumentsLayout, DocumentsLayoutActionsWrapper } from "layouts";
import { useDocumentsEdit } from "controllers";
import {
  Button,
  ButtonLink,
  Card,
  Dialog,
  DialogScreen,
  Field,
  FieldErrorMessage,
  FieldLabel,
  FileUpload,
  Form,
  FormActions,
  HorizontalSeparator,
  Input,
  PreviewSpinner,
  RecipientsSelection,
} from "components";
import { DocumentsEditParams } from "routes";

interface Props extends RouteComponentProps<DocumentsEditParams> {}

export const DocumentsEdit = ({ match }: Props) => {
  const { t, tk, media, data, state, handlers } = useDocumentsEdit(match.params.tab, match.params.id);

  return (
    <DocumentsLayout
      buttons={
        <DocumentsLayoutActionsWrapper>
          <ButtonLink isDisabled={state.loading} margin={"0 30px 0 0"} onClick={handlers.cancel}>
            {t(tk.common.cancel)}
          </ButtonLink>
          <Button isDisabled={state.loading} color={"green"} onClick={handlers.submit}>
            {t(tk.common.save)}
          </Button>
        </DocumentsLayoutActionsWrapper>
      }
      tab={state.tab}
      activeDocumentId={state.id}
    >
      {((state.loading && !data.document) || !data.recipientSelection) && <PreviewSpinner />}

      {!!data.document && !!data.recipientSelection && (
        <FormContainer>
          <Form onSubmit={handlers.submit}>
            {/** Title */}
            <Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <FieldLabel required={true}>{t(tk.documentsCreate.form.title.label)}</FieldLabel>
              <Input
                value={state.title}
                placeholder={t(tk.documentsCreate.form.title.placeholder)}
                width={"100%"}
                onChange={handlers.handleChangeTitle}
              />
              <FieldErrorMessage>{state.titleError}</FieldErrorMessage>
            </Field>

            <HorizontalSeparator />

            {/** File */}
            <Field width={"100%"} padding={"40px 50px"}>
              <FieldLabel>{t(tk.documentsCreate.form.file.label)}</FieldLabel>
              <FileUpload media={media} currentFile={{ name: data.fileName, src: data.document?.file || "" }} />
            </Field>

            <HorizontalSeparator />

            {/** Recipients */}
            <Field width={"100%"} padding={"0 50px"} margin={"30px 0 10px 0"}>
              <FieldLabel required={true}>{t(tk.documentsCreate.form.recipients.label)}</FieldLabel>
              <RecipientsSelection
                recipientSelection={data.recipientSelection}
                onChange={handlers.handleChangeRecipientSelection}
              />
              <FieldErrorMessage>{state.selectedError}</FieldErrorMessage>
            </Field>

            <HorizontalSeparator />

            <FormActions>
              <ButtonLink isDisabled={state.loading} margin={"0 30px 0 0"} onClick={handlers.cancel}>
                {t(tk.common.cancel)}
              </ButtonLink>
              <Button type={"submit"} isDisabled={state.loading} color={"green"}>
                {t(tk.common.save)}
              </Button>
            </FormActions>
          </Form>

          {!!state.dialog && (
            <DialogScreen>
              {state.dialog === "progress" && (
                <Dialog
                  text={t(tk.common.pleaseWait)}
                  title={t(tk.common.savingChanges)}
                  iconImage={"document"}
                  hasProgressBar={true}
                />
              )}

              {state.dialog === "success" && (
                <Dialog
                  text={t(tk.common.changesSaved)}
                  buttonTitle={t(tk.common.ok)}
                  onButtonClick={handlers.goBack}
                />
              )}

              {state.dialog === "error" && (
                <Dialog
                  text={t(tk.documentsCreate.error)}
                  buttonTitle={t(tk.common.ok)}
                  iconImage={"document"}
                  iconImageBackgroundColor={"#C8102E"}
                  onButtonClick={handlers.closeDialog}
                />
              )}
            </DialogScreen>
          )}
        </FormContainer>
      )}
    </DocumentsLayout>
  );
};

/**
 * Styles
 */

const FormContainer = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 740px;
  margin: 25px auto;
  flex-shrink: 0;
`;
