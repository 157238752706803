import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useDashboardState, useMe } from "hooks";
import { DocumentsTabs, getRoute } from "routes";
import { tk, useTranslation } from "translations";
import { mapConnection, resolveError, useDocumentDeleteMutation, useDocumentQuery } from "api";

export const useDocumentsDetail = (tab: DocumentsTabs, id: string) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.documents) + " " + t(tk.common.documentTitleSuffix));

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const { me, isManager } = useMe();

  const { organization, space } = useDashboardState();

  const { data, loading } = useDocumentQuery({ variables: { id }, fetchPolicy: "cache-and-network" });

  const [deleteDocumentMutation] = useDocumentDeleteMutation();

  const document = useMemo(() => data?.document, [data]);

  const fileName = useMemo(() => decodeURIComponent(document?.file?.split("/").pop() || ""), [document]);

  const isAuthor = useMemo(() => me?.userId === document?.author.id, [document, me]);

  const recipients = useMemo(() => mapConnection(document?.interactions).map(({ user }) => user), [document]);

  const createDocument = useCallback(() => {
    history.push(getRoute.documentsCreate({ organization, space, tab: "sent" }));
  }, [history, organization, space]);

  const editDocument = useCallback(() => {
    history.push(getRoute.documentsEdit({ organization, space, tab, id }));
  }, [history, id, organization, space, tab]);

  const deleteDocument = useCallback(() => setDeleteDialogVisible(true), []);

  const closeDeleteDialog = useCallback(() => setDeleteDialogVisible(false), []);

  const handleDeleteDocument = useCallback(async () => {
    try {
      await deleteDocumentMutation({ variables: { input: { document: id } } });
      closeDeleteDialog();
      history.replace(getRoute.documents({ organization, space, tab }));
    } catch (e: any) {
      resolveError(e);
      closeDeleteDialog();
    }
  }, [closeDeleteDialog, deleteDocumentMutation, history, id, organization, space, tab]);

  return {
    t,
    tk,
    data: { isManager, isAuthor, document, fileName, recipients },
    state: { id, tab, loading, deleteDialogVisible },
    handlers: { createDocument, editDocument, deleteDocument, closeDeleteDialog, handleDeleteDocument },
  };
};
