import React, { useCallback, useMemo, useRef, useState } from "react";
import { SponsorsQuery, SponsorTypes, useLogSponsorImpressionsMutation, useSponsorsQuery } from "api";
import styled from "styled-components";
import { CloseButton } from "components/CloseButton";
import { DialogScreen } from "components/Dialog";
import { useLocalStorage } from "react-use";
import moment from "moment";
import { tk, useTranslation } from "translations";

/** Local Storage keys */
export const LS_SPONSORS = "@moyobo-webapp/sponsors";

type Sponsor = SponsorsQuery["me"]["organizations"]["edges"][number]["node"]["sponsors"][number];

type Props = {
  activeOrganizationId?: string;
};

export const Sponsors = ({ activeOrganizationId }: Props) => {
  const { t } = useTranslation();

  // Record<organizationId, sponsorsLastSeenDate>
  const [lsSponsors, setLsSponsors] = useLocalStorage<Record<string, string>>(LS_SPONSORS, {});

  const [sponsorType, setSponsorType] = useState<SponsorTypes>();
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const [countdown, setCountdown] = useState(5);
  const countdownRef = useRef(countdown);
  countdownRef.current = countdown;

  const countdownIntervalRef = useRef<number>();
  const mountTimeRef = useRef(new Date().getTime());

  const today = useMemo(() => moment(new Date()).format("DD/MM/YYYY"), []);
  const skip = !activeOrganizationId || today === lsSponsors[activeOrganizationId];

  const close = useCallback(() => {
    setIsOpen(false);
    window.clearInterval(countdownIntervalRef.current);
  }, []);

  const open = useCallback(
    (sponsorType: SponsorTypes, sponsors: Sponsor[]) => {
      if (!sponsors.length || !activeOrganizationId) return;

      countdownIntervalRef.current = window.setInterval(() => {
        if (countdownRef.current <= 1) return close();
        setCountdown((prevState) => prevState - 1);
      }, 1000);

      setLsSponsors((prev) => ({ ...prev, [activeOrganizationId]: today }));
      setSponsorType(sponsorType);
      setSponsors(sponsors.slice(0, sponsorType === SponsorTypes.Simple ? 4 : 1));
      setCountdown(5);
      setIsOpen(true);
    },
    [activeOrganizationId, close, setLsSponsors, today]
  );

  const [logImpression] = useLogSponsorImpressionsMutation();

  const onCompleted = useCallback(
    (data?: SponsorsQuery) => {
      if (new Date().getTime() - mountTimeRef.current > 3000) return;

      const org = data?.me.organizations.edges.find(({ node }) => node.id === activeOrganizationId)?.node;

      if (!org) return;

      const { sponsorType, sponsors } = org;
      open(sponsorType, sponsors);
    },
    [activeOrganizationId, open]
  );

  useSponsorsQuery({ skip, onCompleted, fetchPolicy: "network-only" });

  if (!isOpen) return null;

  return (
    <DialogScreen>
      <DialogWindow isLarge={sponsorType === SponsorTypes.Graphical}>
        <Countdown>
          <span>{countdown}</span>
          <svg>
            <circle r="14" cx="16" cy="16"></circle>
          </svg>
        </Countdown>
        <CloseButton style={{ position: "absolute", top: 19, right: 16 }} onClick={close} />
        <AnnouncementTitle>{t(tk.sponsors.modal.heading)}</AnnouncementTitle>
        <ChildrenWrapper>
          {sponsorType === SponsorTypes.Simple &&
            sponsors.map((sponsor) => (
              <SponsorContainer key={sponsor.id}>
                <SponsorLogo
                  src={sponsor.logo ?? ""}
                  alt={sponsor.name}
                  onLoad={() => logImpression({ variables: { input: { sponsors: [sponsor.id] } } })}
                />
                <SponsorName>{sponsor.name}</SponsorName>
              </SponsorContainer>
            ))}
          {sponsorType === SponsorTypes.Graphical && (
            <div>
              <SponsorImage
                src={sponsors[0].image ?? ""}
                alt={sponsors[0].name}
                onLoad={() => logImpression({ variables: { input: { sponsors: [sponsors[0].id] } } })}
              />
              <Footer>
                <SponsorName>{t(tk.sponsors.modal.partner)}</SponsorName>
                <SponsorLogo src={sponsors[0].logo ?? ""} alt={sponsors[0].name} isSmall={true} />
              </Footer>
            </div>
          )}
        </ChildrenWrapper>
      </DialogWindow>
    </DialogScreen>
  );
};

const DialogWindow = styled.div.withConfig({ shouldForwardProp: (prop) => !["isLarge"].includes(prop) })<{
  isLarge?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${({ isLarge }) => (isLarge ? 600 : 424)}px;
  max-height: 100vh;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 30px;
  box-sizing: border-box;
`;

const AnnouncementTitle = styled.h4`
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #404040;
  margin: 0;
  padding-top: 24px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 64px;
  margin-top: 24px;
`;

const SponsorContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 122px;
  margin-bottom: 32px;
`;

const SponsorImage = styled.img`
  width: calc(100% - 64px);
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  margin-right: 32px;
  margin-left: 32px;
  border-radius: 5px;
`;

const SponsorLogo = styled.img.withConfig({ shouldForwardProp: (prop) => !["isSmall"].includes(prop) })<{
  isSmall?: boolean;
}>`
  width: ${({ isSmall }) => (isSmall ? 40 : 112)}px;
  height: ${({ isSmall }) => (isSmall ? 40 : 112)}px;
  object-fit: contain;
  border-radius: 5px;
`;

const SponsorName = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #2b2b2b;
  margin-top: 8px;
`;

const Countdown = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 16px;
  left: 16px;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  line-height: 0;
  padding-top: 15px;
  color: #afafaf;

  @keyframes countdown {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 120px;
    }
  }

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  > svg > circle {
    stroke-dasharray: 120px;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    stroke-width: 2px;
    stroke: #afafaf;
    fill: none;
    animation: countdown linear infinite;
    animation-duration: 6000ms;
    animation-delay: -1000ms;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;
