import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useServiceWorker } from "hooks";
import { font, theme } from "styles";
import { tk, useTranslation } from "translations";

type Props = { version: string; style?: CSSProperties };

export const SidebarAppVersion = ({ version, style }: Props) => {
  const { t } = useTranslation();

  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  return (
    <Container style={style}>
      {isUpdateAvailable ? (
        <Update title={t(tk.updateAvailableDescription)} onClick={updateAssets}>
          {t(tk.updateAvailable)}
        </Update>
      ) : (
        <Text>{version}</Text>
      )}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 34px;
  grid-area: version;
`;

const Text = styled("span")`
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${theme.sidebarAppVersion.fontColor};
`;

const Update = styled.abbr`
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.bold};
  color: ${theme.sidebarAppVersion.fontColor};
  text-align: center;
  cursor: pointer;
`;
