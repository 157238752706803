import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MediaTypes, resolveError, useMediaCreateMutation, useMediaPreviewMutation } from "api";
import { clearTempUrl, getTempUrl } from "helpers";

const CROP_ENABLED = false;

export const useMedia = (type: MediaTypes, apiPreview: boolean = true, isFile: boolean = false) => {
  const [mediaCreate, { loading: loadingCreate }] = useMediaCreateMutation();
  const [mediaPreview, { loading: loadingPreview }] = useMediaPreviewMutation();

  const loading = useMemo(() => loadingCreate || loadingPreview, [loadingCreate, loadingPreview]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | undefined>(undefined);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [imageToCrop, setImageToCrop] = useState<{ url: string; type: string; name: string } | undefined>(undefined);

  const handleCropSubmit = useCallback(
    async (file: File) => {
      setFile(file);
      clearTempUrl(preview);

      if (!apiPreview) return setPreview(getTempUrl(file));

      try {
        const { data } = await mediaPreview({ variables: { input: { file, type } } });

        setPreview(data?.mediaPreview.preview);
      } catch (e: any) {
        setPreview(getTempUrl(file));
      }
    },
    [apiPreview, mediaPreview, preview, type]
  );

  const handleCropClose = useCallback(() => setImageToCrop(undefined), []);

  const openFileBrowser = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleChangeFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files[0]) return;

      const file = e.target.files[0];

      if (!isFile && !file.type.startsWith("image/")) {
        e.preventDefault();
        return;
      }

      if (!isFile) {
        if (CROP_ENABLED) return setImageToCrop({ url: getTempUrl(file), type: file.type, name: file.name });

        return handleCropSubmit(file);
      }

      setFile(file);
      setPreview(file.name);
    },
    [handleCropSubmit, isFile]
  );

  const upload = useCallback(async () => {
    if (!preview || !file) {
      return undefined;
    }

    try {
      const { data } = await mediaCreate({ variables: { input: { file, type } } });

      return data?.mediaCreate.media.id;
    } catch (e: any) {
      resolveError(e);
    }

    return undefined;
  }, [file, mediaCreate, preview, type]);

  const clear = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    setPreview(undefined);
    setFile(undefined);
  }, []);

  const fileInputProps = useMemo(
    () => ({
      ref: fileInputRef,
      type: "file",
      accept: !isFile ? "image/*" : undefined,
      hidden: true,
      multiple: false,
      onChange: handleChangeFile,
    }),
    [handleChangeFile, isFile]
  );

  useEffect(
    () => () => {
      if (!isFile) clearTempUrl(preview);
    },
    [isFile, preview]
  );

  return {
    loading,
    preview,
    imageToCrop,
    fileInputProps,
    openFileBrowser,
    handleCropSubmit,
    handleCropClose,
    upload,
    clear,
  };
};

export type Media = ReturnType<typeof useMedia>;
