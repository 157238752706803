export const resolveAuthorMetaDetails = (authority: string, role: string, position?: string): string => {
  return position ? `${authority} • ${position}` : `${authority} • ${role}`;
};

export const escapeOutput = (output: string) =>
  output
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#x27")
    .replace(/\//g, "&#x2F");

export const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);
