import React, { ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components";
import { font, theme } from "styles";

type Props = {
  children?: never;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  isFocused: boolean;
};

export const ReactionListItemInput = ({ value, placeholder, isFocused, onChange }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") event.preventDefault();
  };

  return (
    <StyledInput
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      autoFocus={isFocused}
    />
  );
};

/**
 * Styles
 */

const StyledInput = styled("input")`
  flex-grow: 1;
  font-family: ${font.family.default};
  font-weight: ${font.weight.regular};
  font-size: 14px;
  color: ${theme.reactionListItemInput.fontColor.input};
  margin: 0 5px;
  padding: 5px;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  outline: none;

  &::placeholder {
    color: ${theme.reactionListItemInput.fontColor.placeholder};
    opacity: 1;
  }

  &:focus {
    background-color: ${theme.reactionListItemInput.backgroundColor};
  }
`;
