import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Option } from "components/Option";
import { Dialog, DialogScreen } from "components/Dialog";
import * as Select from "components/Select";
import { useUnresolvedRefetch } from "hooks";
import { AnnouncementFragment, resolveError, useAnnouncementSetReactionDeadlineMutation } from "api";
import { tk, useTranslation } from "translations";

interface Props {
  announcement: AnnouncementFragment;
  onSuccess: () => any;
}

export const ChangeDeadlineOption = ({ announcement, onSuccess }: Props) => {
  const { t } = useTranslation();

  const [actionDialogVisible, setActionDialogVisible] = useState(false);
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);

  const [deadline, setDeadline] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChangeDeadline = useCallback(
    (index: number) => {
      const { format } = announcementChangeDeadlineOptions[index];
      const time = format(moment.utc().local());

      let m = "";
      if (index === 0) m = t(tk.announcements.actions.changeDeadline.expireNow);
      if (index > 0 && time) m = t(tk.announcements.actions.changeDeadline.expireIn, { in: time.fromNow() });
      if (!time) m = t(tk.announcements.actions.changeDeadline.noExpiration);

      setSuccessMessage(m);
      setDeadline(time?.format());
    },
    [t]
  );

  const [setReactionDeadline, { loading }] = useAnnouncementSetReactionDeadlineMutation();
  const refetchUnresolved = useUnresolvedRefetch();

  const meta = useMemo(() => {
    const { reactionDeadlineAt } = announcement;

    const deadlineMoment = moment.utc(reactionDeadlineAt);

    if (!reactionDeadlineAt) return undefined;

    if (deadlineMoment.isAfter(moment.utc()))
      return t(tk.announcements.actions.changeDeadline.expiresIn, { in: deadlineMoment.fromNow() });

    return t(tk.announcements.actions.changeDeadline.expiredBefore, { before: deadlineMoment.fromNow() });
  }, [announcement, t]);

  const openActionDialog = useCallback(() => setActionDialogVisible(true), []);
  const closeActionDialog = useCallback(() => setActionDialogVisible(false), []);

  const openSuccessDialog = useCallback(() => setSuccessDialogVisible(true), []);
  const closeSuccessDialog = useCallback(() => {
    setSuccessDialogVisible(false);
    onSuccess();
  }, [onSuccess]);

  const changeDeadline = useCallback(async () => {
    if (loading) return;

    try {
      await setReactionDeadline({
        variables: { input: { announcement: announcement.id, deadline: deadline || null } },
      });
      await refetchUnresolved();
    } catch (e: any) {
      resolveError(e);
    }

    closeActionDialog();
    openSuccessDialog();
  }, [
    announcement.id,
    closeActionDialog,
    deadline,
    loading,
    openSuccessDialog,
    refetchUnresolved,
    setReactionDeadline,
  ]);

  return (
    <>
      <Option
        icon={"calendar"}
        label={t(tk.announcements.actions.changeDeadline.button)}
        meta={meta}
        onClick={openActionDialog}
      />

      {actionDialogVisible && (
        <DialogScreen>
          <Dialog
            iconImage={"calendar"}
            buttonTitle={t(tk.common.saveChanges)}
            text={t(tk.announcements.actions.changeDeadline.expireAt)}
            hasCloseButton={true}
            onButtonClick={changeDeadline}
            onCloseButtonClick={closeActionDialog}
          >
            <Select.Select onChange={handleChangeDeadline} margin={"25px 0 0 0"}>
              {announcementChangeDeadlineOptions.map(({ title, options, format }, i) => {
                const time = format(moment.utc().local());
                return (
                  <Select.SelectOption key={`deadline-${i}`}>
                    <Select.SelectOptionLabel>{t(title, options)}</Select.SelectOptionLabel>
                    <Select.SelectOptionDetail>{!time ? "" : time.format("LLLL")}</Select.SelectOptionDetail>
                  </Select.SelectOption>
                );
              })}
            </Select.Select>
          </Dialog>
        </DialogScreen>
      )}

      {successDialogVisible && (
        <DialogScreen>
          <Dialog buttonTitle={t(tk.common.ok)} onButtonClick={closeSuccessDialog} text={successMessage} />
        </DialogScreen>
      )}
    </>
  );
};

type DeadlineOption = {
  title: string;
  options?: { count: number };
  format: (date: moment.Moment) => moment.Moment | null;
};

export const announcementChangeDeadlineOptions: DeadlineOption[] = [
  {
    title: tk.deadlines.now,
    format: (date) => date,
  },
  {
    title: tk.deadlines.afterHours,
    options: { count: 2 },
    format: (date) => date.add(2, "h"),
  },
  {
    title: tk.deadlines.todayEvening,
    format: (date) => date.hour(23).minute(59),
  },
  {
    title: tk.deadlines.tomorrowEvening,
    format: (date) => date.add(1, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.afterDays,
    options: { count: 2 },
    format: (date) => date.add(2, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.afterDays,
    options: { count: 3 },
    format: (date) => date.add(3, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.afterWeek,
    format: (date) => date.add(7, "d").hour(23).minute(59),
  },
  {
    title: tk.deadlines.never,
    format: () => null,
  },
];
