import React, { useCallback, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled from "styled-components";
import { Icon, IconShape } from "components";
import { theme } from "styles";

interface Props {
  icon: IconShape;
  menuItems: { title: string; action: () => void }[];
  correctionTop?: number;
}

export const PopupMenu = ({ icon, menuItems, correctionTop = 0 }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const close = useCallback(() => setOpen(false), []);
  const toggle = useCallback(() => setOpen((open) => !open), []);

  useEffect(() => {
    if (open) window.setTimeout(() => setVisible(true));
    setVisible(false);
  }, [open]);

  useClickAway(wrapperRef, close);

  return (
    <ComponentWrapper ref={wrapperRef}>
      <IconWrapper shape={icon} color={"#BFC0C2"} size={"18px"} onClick={toggle} />

      {open && (
        <MenuWrapper top={-15 - correctionTop} correctionTop={correctionTop} visible={visible}>
          {menuItems.map(({ title, action }) => (
            <MenuItem key={title} onClick={action}>
              {title}
            </MenuItem>
          ))}
        </MenuWrapper>
      )}
    </ComponentWrapper>
  );
};

/**
 * Styles
 */

const ComponentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 6px;
`;

const IconWrapper = styled(Icon)`
  cursor: pointer;
`;

const MenuWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["top", "correctionTop", "visible"].includes(prop),
})<{ top: number; correctionTop: number; visible: boolean }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: 38px;
  padding: 10px 0;
  background-color: ${theme.colors.SnowWhite};
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  z-index: 2;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 150ms ease-in-out;

  &:after {
    content: "";
    position: absolute;
    top: ${(props) => props.correctionTop + 15}px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent white white;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.15);
  }
`;

const MenuItem = styled.a`
  color: ${theme.colors.TealGreen};
  display: block;
  padding: 3px 25px;
  font-size: 12px;
  cursor: pointer;
  margin: 5px 0;
  white-space: nowrap;
`;
