import React from "react";
import styled from "styled-components";
import { StoriesLayout } from "layouts";
import { useStories } from "controllers";
import { Button, Spinner, Story } from "components";
import { font, theme } from "styles";

export const Stories = () => {
  const { t, tk, data, state, handlers } = useStories();

  const renderContent = !!data.spaceName && !!data.pageInfo && data.hasValidData;

  return (
    <StoriesLayout
      buttons={
        <Button onClick={handlers.createStory} color={"green"}>
          {t(tk.stories.navigation.create)}
        </Button>
      }
      onScroll={handlers.handleScroll}
    >
      {renderContent && (
        <Wrapper>
          <TitleWrapper>{data.spaceName}</TitleWrapper>

          {data.stories.map((story) => (
            <Story key={story.cursor} spaceName={data.spaceName} story={story} onEdit={handlers.editStory} />
          ))}

          {state.loading && (
            <SpinnerWrapper>
              <Spinner color={theme.colors.IronGrey} />
            </SpinnerWrapper>
          )}
        </Wrapper>
      )}

      {state.loading && !renderContent && (
        <SpinnerWrapper>
          <Spinner color={theme.colors.IronGrey} />
        </SpinnerWrapper>
      )}
    </StoriesLayout>
  );
};

/**
 * Styles
 */

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  margin: 25px 0 10px;
  color: ${theme.colors.NightSky};

  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.black};
  font-size: 24px;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0;
`;
