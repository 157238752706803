import React, { createContext } from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  activeStep: number;
};

export const StepsContext = createContext<{ index?: number; activeIndex?: number }>({
  index: undefined,
  activeIndex: undefined,
});

export const Steps = ({ children, activeStep }: Props) => {
  return (
    <Container>
      {React.Children.map(children, (child, index) => (
        <StepsContext.Provider value={{ index: index + 1, activeIndex: activeStep }}>{child}</StepsContext.Provider>
      ))}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-template-rows: max-content;
  grid-gap: 5px;
`;
