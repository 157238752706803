import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconLogout = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18.999 21"
      style={{ strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1, margin }}
    >
      <g id="logout" transform="translate(0.5 0.5)">
        <path
          id="Path_557"
          data-name="Path 557"
          d="M49,181.975H60m0,0L56.127,186M60,181.975,56.127,178"
          transform="translate(-49 -172)"
          fill="none"
          stroke={color}
        />
        <path
          id="Path_558"
          data-name="Path 558"
          d="M128,74.553V78h14V58H128v3.592"
          transform="translate(-124.001 -58)"
          fill="none"
          stroke={color}
        />
      </g>
    </svg>
  );
};
