import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { SidebarAppVersion, SidebarSpacesList, SidebarSupportLink } from "components/Sidebar";
import { HexagonButton } from "components";
import { useDashboardState } from "hooks";
import { getAppVersion } from "helpers";
import { tk, useTranslation } from "translations";
import { getRoute } from "routes";
import { theme } from "styles";

type Props = {
  children?: never;
};

export const Sidebar = (_: Props) => {
  const { t } = useTranslation();

  const { organization, space } = useDashboardState();

  const [compact, setCompact] = useState(false);

  const toggle = useCallback(() => setCompact(!compact), [compact]);

  const springStyle = useSpring({ width: compact ? "123px" : "356px" });

  return (
    <Wrapper style={springStyle}>
      <Container>
        <SidebarSpacesList compact={compact} />

        <SidebarSupportLink
          to={getRoute.support({ organization, space, role: "regular", topic: "all" })}
          compact={compact}
        >
          {t(tk.common.help)}
        </SidebarSupportLink>

        <SidebarAppVersion version={`${t(tk.common.version)} ${getAppVersion}`} />
      </Container>
      <ToggleButtonWrapper>
        <HexagonButton onClick={toggle} orientation={compact ? "right" : "left"} />
      </ToggleButtonWrapper>
    </Wrapper>
  );
};

/**
 * Styles
 */

const Wrapper = styled(animated.div)`
  position: relative;
  width: 356px;
  flex-shrink: 0;
  background-color: ${theme.sidebar.backgroundColor};
  border-right: 1px solid ${theme.sidebar.borderColor};
`;

const Container = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ToggleButtonWrapper = styled("div")`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: -15px;
  bottom: 54px;
  z-index: 1;
`;
