import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Description = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #9b9b9b;
  white-space: pre-wrap;
  line-height: 24px;
  text-align: center;
`;

export class SelectionDialogDescription extends React.Component<PropsWithChildren> {
  render() {
    return <Description {...this.props}>{this.props.children}</Description>;
  }
}
