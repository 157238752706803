import React, { useCallback, useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Hexagon } from "components/Hexagon";
import { resolveError, useStoryClapMutation } from "api";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

interface Props {
  claps: number;
  myClaps: number;
  storyId: string;
}

export const Claps = ({ claps, myClaps, storyId }: Props) => {
  const { t } = useTranslation();

  const [clapping, setClapping] = useState(false);

  const [clapCount, setClapCount] = useState(0);
  const clapCountRef = useRef(clapCount);
  clapCountRef.current = clapCount;

  const [clapTimeout, setClapTimeout] = useState<number>(0);
  const clapTimeoutRef = useRef(clapTimeout);
  clapTimeoutRef.current = clapTimeout;

  const [phase1Emotion, setPhase1Emotion] = useState(false);
  const [phase2Emotion, setPhase2Emotion] = useState(false);
  const [emotions, setEmotions] = useState<number[]>(getRandomEmotions());

  const [storyClap] = useStoryClapMutation();

  const stopClapping = useCallback(async () => {
    setClapTimeout(0);
    setClapping(false);

    try {
      await storyClap({ variables: { input: { story: storyId, count: clapCountRef.current } } });
    } catch (e: any) {
      resolveError(e);
    }

    setClapCount((prevState) => prevState - clapCountRef.current);
  }, [storyClap, storyId]);

  const clap = useCallback(() => {
    if (clapTimeoutRef.current) window.clearTimeout(clapTimeout);

    const newClaps = clapCount + 1 + myClaps;

    setClapping(true);
    setClapCount((prevState) => prevState + 1);

    if (newClaps > 0 && newClaps % 10 === 0 && newClaps % 20 !== 0) {
      setPhase1Emotion(true);
      window.setTimeout(() => {
        setPhase1Emotion(false);
        setEmotions(getRandomEmotions);
      }, 600);
    }

    if (newClaps > 0 && newClaps % 20 === 0) {
      setPhase2Emotion(true);
      window.setTimeout(() => {
        setPhase2Emotion(false);
        setEmotions(getRandomEmotions);
      }, 600);
    }

    setClapTimeout(window.setTimeout(stopClapping, 500));
  }, [clapCount, clapTimeout, myClaps, stopClapping]);

  return (
    <Wrapper>
      <Emotion
        characterSize={"14px"}
        imageSource={emotionIcons[`emotion${emotions[0]}`]}
        imageSize={66}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        top={y1}
        right={x1}
        visible={phase1Emotion || phase2Emotion}
        delay={0}
        size={{
          width: "60px",
          height: "66px",
        }}
      />
      <Emotion
        characterSize={"14px"}
        imageSource={emotionIcons[`emotion${emotions[1]}`]}
        imageSize={66}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        top={y1}
        right={x2}
        visible={phase1Emotion || phase2Emotion}
        delay={50}
        size={{
          width: "60px",
          height: "66px",
        }}
      />
      <Emotion
        characterSize={"14px"}
        imageSource={emotionIcons[`emotion${emotions[2]}`]}
        imageSize={66}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        top={y2}
        right={x1 + xHalf}
        visible={phase1Emotion || phase2Emotion}
        delay={0}
        size={{
          width: "60px",
          height: "66px",
        }}
      />
      <Emotion
        characterSize={"14px"}
        imageSource={emotionIcons[`emotion${emotions[3]}`]}
        imageSize={66}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        top={y2}
        right={x1 - xHalf}
        visible={phase2Emotion}
        delay={50}
        size={{
          width: "60px",
          height: "66px",
        }}
      />
      <Emotion
        characterSize={"14px"}
        imageSource={emotionIcons[`emotion${emotions[4]}`]}
        imageSize={66}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        top={y1}
        right={x2 + xFull}
        visible={phase2Emotion}
        delay={100}
        size={{
          width: "60px",
          height: "66px",
        }}
      />
      <Emotion
        characterSize={"14px"}
        imageSource={emotionIcons[`emotion${emotions[5]}`]}
        imageSize={66}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        top={y2 + yFull}
        right={x2}
        visible={phase2Emotion}
        delay={100}
        size={{
          width: "60px",
          height: "66px",
        }}
      />

      <ClappingBackground
        color={theme.colors.TealGreen}
        characterSize={"14px"}
        characterWeight={font.weight.bold.toString()}
        visible={clapping ? true : clapCount === 0 ? "none" : false}
        size={{ width: "60px", height: "66px" }}
      >
        +{myClaps + clapCount}
      </ClappingBackground>

      <ClapCountText>{t(tk.stories.clapped, { count: claps + clapCount })}</ClapCountText>

      <ClapButton
        iconShape={"clap"}
        shadow={"0px 0px 10px rgba(0,0,0,0.1)"}
        cursor={"pointer"}
        onClick={clap}
        size={{ width: "50px", height: "53px" }}
      />
    </Wrapper>
  );
};

/**
 * Styles
 */

const ClapButton = styled(Hexagon)`
  margin-left: 11px;
  cursor: pointer;
`;

const fadeIn = keyframes`
  from {
    transform: scale(0.7);
    opacity: 0;
    top: -50px;
  }

  to {
    transform: scale(1);
    opacity: 1;
    top: -80px;
  }
`;
const fadeOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
    top: -80px;
  }

  100% {
    transform: scale(0.7);
    opacity: 0;
    top: -50px;
  }
`;

type ClappingBackgroundProps = { visible: boolean | "none" };

const ClappingBackground = styled(Hexagon).withConfig({
  shouldForwardProp: (prop) => !["visible"].includes(prop),
})<ClappingBackgroundProps>`
  transform: scale(0.7);
  opacity: 0;
  ${(props) =>
    props.visible === true
      ? css`
          animation: ${fadeIn} 100ms ease-in;
        `
      : props.visible === false
      ? css`
          animation: ${fadeOut} 100ms ease-in;
        `
      : css``};
  animation-fill-mode: forwards;
  cursor: pointer;
  position: absolute;
  top: -80px;
  right: -5px;
`;

const ClapCountText = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: ${font.weight.regular};
  font-size: 14px;
  color: ${theme.colors.IronGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const flash = keyframes`
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  
  10% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.7);
    opacity: 0;
  }
`;

type EmotionProps = { top: number; right: number; delay: number; visible: boolean };

const StyledEmotion = styled(Hexagon).withConfig({
  shouldForwardProp: (prop) => !["top", "right", "delay", "visible"].includes(prop),
})<EmotionProps>`
  cursor: pointer;
  position: absolute;
  transform: scale(0.7);
  opacity: 0;
  ${(props) =>
    props.visible &&
    css`
      animation: ${flash} 500ms linear;
    `};
  animation-delay: ${(props: EmotionProps) => props.delay}ms;
  top: ${(props: EmotionProps) => props.top}px;
  right: ${(props: EmotionProps) => props.right}px;
`;

const Emotion = StyledEmotion;

const x1 = 30;
const x2 = 100;
const xFull = x2 - x1;
const xHalf = xFull / 2;
const y1 = -140;
const y2 = -200;
const yFull = y2 - y1;

const emotionIcons: { [key: string]: string } = {
  emotion1: require("assets/images/emotions/emotion1.png"),
  emotion2: require("assets/images/emotions/emotion2.png"),
  emotion3: require("assets/images/emotions/emotion3.png"),
  emotion4: require("assets/images/emotions/emotion4.png"),
  emotion5: require("assets/images/emotions/emotion5.png"),
  emotion6: require("assets/images/emotions/emotion6.png"),
  emotion7: require("assets/images/emotions/emotion7.png"),
  emotion8: require("assets/images/emotions/emotion8.png"),
  emotion9: require("assets/images/emotions/emotion9.png"),
  emotion10: require("assets/images/emotions/emotion10.png"),
  emotion11: require("assets/images/emotions/emotion11.png"),
  emotion12: require("assets/images/emotions/emotion12.png"),
  emotion13: require("assets/images/emotions/emotion13.png"),
  emotion14: require("assets/images/emotions/emotion14.png"),
  emotion15: require("assets/images/emotions/emotion15.png"),
};

const getRandomEmotions = () => {
  const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const random = [];

  while (random.length !== 6) {
    const index = Math.floor(Math.random() * list.length);
    const item = list[index];
    list.splice(index, 1);
    random.push(item);
  }

  return random;
};
