import React from "react";
import styled from "styled-components";
import { RecipientsSelectionRemoveButton } from "components/RecipientSelection/RecipientsSelectionRemoveButton";
import { tk, useTranslation } from "translations";
import { font, theme } from "styles";

type Props = {
  children: React.ReactNode;
  count: number;
  onRemoveAll?: () => void;
};

export const SelectedRecipientsList = ({ children, count, onRemoveAll = () => {} }: Props) => {
  const { t } = useTranslation();

  const hasSelectedUsers = count > 0;

  return (
    <Container>
      {!hasSelectedUsers && (
        <NonSelectedRecipientContainer>
          <NonSelectedRecipientContainerText>
            {t(tk.recipientsSelection.nonSelectedRecipientNotice)}
          </NonSelectedRecipientContainerText>
        </NonSelectedRecipientContainer>
      )}

      {hasSelectedUsers && (
        <ListContainer>
          <ListHeader>
            <Heading>{t(tk.recipientsSelection.selectedRecipientsList["heading"], { count })}</Heading>
            <RecipientsSelectionRemoveButton onClick={onRemoveAll}>
              {t(tk.recipientsSelection.removeAllButtonLabel)}
            </RecipientsSelectionRemoveButton>
          </ListHeader>
          {children}
        </ListContainer>
      )}
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const NonSelectedRecipientContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  padding-bottom: 15px;
`;

const NonSelectedRecipientContainerText = styled("span")`
  position: relative;
  font-size: 16px;
  font-weight: ${font.weight.regular};
  color: ${theme.recipientsSelectionList.noResult.fontColor};
`;

const ListContainer = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-template-areas:
    "list-header"
    ".";
  width: 100%;
`;

const ListHeader = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-area: list-header;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled("span")`
  position: relative;
  font-size: 12px;
  font-weight: ${font.weight.medium};
  color: ${theme.recipientsSelectionList.heading.fontColor};
`;
