import React from "react";
import styled from "styled-components";
import { DetailAuthor } from "components/Detail/DetailAuthor";

type Props = {
  authorFullName: string;
  authorMetaText: string;
  authorProfileImage: string;
  authorAuthorityImage?: string;
  rightSideContainer?: JSX.Element;
};

export class DetailMetaDetailSection extends React.PureComponent<Props> {
  render() {
    return (
      <Section>
        <AuthorDetailContainer>
          <DetailAuthor
            fullName={this.props.authorFullName}
            metaText={this.props.authorMetaText}
            frontImage={this.props.authorProfileImage}
            backImage={this.props.authorAuthorityImage}
          />
        </AuthorDetailContainer>

        {this.props.rightSideContainer}
      </Section>
    );
  }
}

/**
 * Styles
 */

const Section = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-left: 60px;
  padding-right: 50px;
`;

const AuthorDetailContainer = styled.div`
  display: flex;
  position: relative;
`;
