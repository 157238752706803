import React, { Fragment } from "react";
import styled from "styled-components";
import { NotificationDot, PreviewSpinner, SearchInput } from "components";
import { Window } from "chat/components/Window";
import { ListWindowHeader } from "chat/components/list/ListWindowHeader";
import { ListItem } from "chat/components/list/ListItem";
import { useChatStore } from "chat/hooks/useChatStore";
import { useChatList } from "chat/hooks/useChatList";
import { useSearch } from "chat/hooks/useSearch";
import { tk, useTranslation } from "translations";
import { theme } from "styles";

interface Props {
  onChatOpen: (id: string) => any;
  onOpenGroupSettings: () => any;
}

export const ListWindow = ({ onChatOpen, onOpenGroupSettings }: Props) => {
  const { t } = useTranslation();

  const { data, state, handlers } = useChatList(onChatOpen, onOpenGroupSettings);
  const { soundEnabled, toggleSoundEnabled } = useChatStore();
  const search = useSearch();

  return (
    <Window
      compact={state.compact}
      position={0}
      loading={state.loading && !data.chats.length}
      height={"calc(100% - 180px)"}
    >
      {/** Header */}
      <ListWindowHeader
        unreadMessagesCount={state.loading ? 0 : data.unreadMessagesCount}
        unresolvedCount={data.unresolvedCount}
        compact={state.compact}
        sound={soundEnabled}
        onClick={handlers.toggleCompact}
        toggleSound={toggleSoundEnabled}
      />

      {/** Content */}
      {!state.compact && (
        <>
          <Divider />

          <SearchInputWrapper>
            <SearchInput value={search.query} placeholder={t(tk.chat.search.placeholder)} onChange={search.setQuery} />
          </SearchInputWrapper>

          <NewGroupChatButtonWrapper>
            <NewGroupChatButton onClick={handlers.openGroupDialog}>+ {t(tk.chat.createNewGroup)}</NewGroupChatButton>
          </NewGroupChatButtonWrapper>

          <ListWrapper>
            {/** Active chats */}
            {!search.query && (
              <List>
                {data.chats.map((chat) => {
                  let divider = null;
                  if (chat.hasLastActiveChatsDivider) {
                    divider = <ChatsDivider>{t(tk.chat.lastActiveChats)}</ChatsDivider>;
                  }
                  if (chat.hasOlderChatsDivider) {
                    divider = <ChatsDivider>{t(tk.chat.olderChats)}</ChatsDivider>;
                  }

                  /** Direct message (2 users) */
                  if (!chat.isGroupChat) {
                    const otherUserInteraction = chat.interactions.find((i) => i.id !== chat.myInteraction.id);

                    if (!otherUserInteraction) return null;

                    const { firstName, lastName, profileImage, isActive } = otherUserInteraction.user;

                    return (
                      <Fragment key={chat.id}>
                        {divider}
                        <ListItem
                          id={chat.id}
                          heading={`${firstName} ${lastName}`}
                          subheading={chat.lastMessage.message}
                          imageSrc={profileImage}
                          isActive={isActive}
                          rightContent={chat.hasNewMessage ? <NotificationDot /> : null}
                          onClick={() => onChatOpen(chat.id)}
                        />
                      </Fragment>
                    );
                  }

                  /** Group chat - not accepted */
                  if (!chat.isAccepted) {
                    const heading = t(tk.chat.userGroupInvitation, { name: chat.myInteraction.createdBy.firstName });
                    const names = chat.interactions
                      .filter((i) => i.id !== chat.myInteraction.id)
                      .map(({ user }) => user.firstName)
                      .join(", ");
                    return (
                      <Fragment key={chat.id}>
                        {divider}
                        <ListItem
                          id={chat.id}
                          heading={heading}
                          subheading={names}
                          imageSrc={chat.myInteraction.createdBy.profileImage}
                          isActive={true}
                          showGroupIcon={true}
                          rightContent={<NotificationDot />}
                          onClick={() => onChatOpen(chat.id)}
                        />
                      </Fragment>
                    );
                  }

                  /** Group chat - accepted */
                  const names = chat.interactions
                    .filter((i) => i.id !== chat.myInteraction.id)
                    .map(({ user }) => user.firstName);
                  const heading = !!chat.name
                    ? chat.name
                    : t(tk.chat.namesAndOthers, { names: names.slice(0, 1), count: names.length - 1 });
                  return (
                    <Fragment key={chat.id}>
                      {divider}
                      <ListItem
                        id={chat.id}
                        heading={heading}
                        subheading={chat.lastMessage.message}
                        imageSrc={chat.lastMessage.author.profileImage}
                        isActive={true}
                        showGroupIcon={true}
                        rightContent={chat.hasNewMessage ? <NotificationDot /> : null}
                        onClick={() => onChatOpen(chat.id)}
                      />
                    </Fragment>
                  );
                })}
              </List>
            )}

            {/** Search results */}
            {search.query && (
              <>
                {search.loading && (
                  <SpinnerWrapper>
                    <PreviewSpinner />
                  </SpinnerWrapper>
                )}
                {!search.loading && (
                  <List>
                    {search.users.map(({ id, isActive, firstName, lastName, profileImage, space, position, role }) => (
                      <ListItem
                        key={id}
                        id={id}
                        heading={`${firstName} ${lastName}`}
                        subheading={`${space}${!position && !role ? "" : "•"}${position || t(tk.role[role])}`}
                        imageSrc={profileImage}
                        isActive={isActive}
                        onClick={handlers.createChat}
                      />
                    ))}
                  </List>
                )}
              </>
            )}
          </ListWrapper>
        </>
      )}
    </Window>
  );
};

/**
 * Styles
 */

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.SilverGrey};
  flex-shrink: 0;
`;

const ChatsDivider = styled.div`
  color: ${theme.colors.IronGrey};
  padding: 25px 17px 0 17px;
`;

const SearchInputWrapper = styled.div`
  padding: 12px 14px 2px;
`;

const ListWrapper = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  height: 100%;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

const NewGroupChatButtonWrapper = styled.div`
  padding: 5px 20px 0 0;
  text-align: right;
`;

const NewGroupChatButton = styled.span`
  color: ${theme.colors.TealGreen};
  font-size: 10px;
  cursor: pointer;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
