import React from "react";
import styled, { keyframes } from "styled-components";

const SpinnerContainer = styled.div`
  display: flex;
  position: relative;
`;

const stretch = keyframes`
	0%, 40%, 100% {
		transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1.0);
	}
`;

const getColor = (props: Props) => (props.color ? props.color : "#000000");
const getWidth = (props: Props) => (props.size ? props.size.width : "6px");
const getHeight = (props: Props) => (props.size ? props.size.height : "40px");
const getGap = (props: Props) => (props.size ? props.size.gap : "2px");

const Rectangle = styled.div`
  position: relative;
  background-color: ${getColor};
  width: ${getWidth};
  height: ${getHeight};

  animation: ${stretch} 1.2s infinite ease-in-out;

  &:nth-of-type(2) {
    animation-delay: -1.1s;
    margin-left: ${getGap};
  }

  &:nth-of-type(3) {
    animation-delay: -1s;
    margin: 0 ${getGap};
  }

  &:nth-of-type(4) {
    animation-delay: -0.9s;
    margin-right: ${getGap};
  }

  &:nth-of-type(5) {
    animation-delay: -0.8s;
  }
`;

type Props = {
  color?: string;
  size?: {
    width: string;
    height: string;
    gap: string;
  };
};

export class Spinner extends React.PureComponent<Props> {
  render() {
    return (
      <SpinnerContainer>
        <Rectangle {...this.props} />
        <Rectangle {...this.props} />
        <Rectangle {...this.props} />
        <Rectangle {...this.props} />
        <Rectangle {...this.props} />
      </SpinnerContainer>
    );
  }
}
