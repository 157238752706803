import React, { useContext } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { LanguageSelectContext } from "components/LanguageSelect/LanguageSelect";

type Props = {
  children: React.ReactNode;
  itemsCount: number;
};

export const LanguageSelectBox = ({ children, itemsCount }: Props) => {
  const { isSelectOpen } = useContext(LanguageSelectContext);

  const animationStyle = useSpring({
    height: `${isSelectOpen ? itemsCount * 45 : 0}px`,
  });

  return <AnimatedWrapper style={animationStyle}>{children}</AnimatedWrapper>;
};

const AnimatedWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  width: 100%;
`;
