import React from "react";
import styled from "styled-components";
import { font, theme } from "styles";

export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

type Props = {
  children?: never;
  index: number;
};

export const ReactionListItemBullet = ({ index }: Props) => {
  return (
    <Bullet>
      <Svg
        width={"100%"}
        height={"100%"}
        viewBox={"0 0 27 30"}
        version={"1.1"}
        xmlns={"http://www.w3.org/2000/svg"}
        xmlnsXlink={"http://www.w3.org/1999/xlink"}
        xmlSpace={"preserve"}
      >
        <path
          d={
            "M14.782,0.87L25.113,6.77C26.039,7.299 26.613,8.287 26.613,9.353L26.613,21.11C26.613,22.176 26.039,23.164 25.113,23.693L14.782,29.6C13.868,30.122 12.744,30.122 11.83,29.6L1.5,23.693C0.574,23.164 0,22.176 0,21.11L0,9.356C0,8.29 0.574,7.302 1.5,6.773L11.83,0.87C12.744,0.348 13.868,0.348 14.782,0.87Z"
          }
        />
      </Svg>
      <BulletCharacter>{ALPHABET.charAt(index)}</BulletCharacter>
    </Bullet>
  );
};

/**
 * Styles
 */

const Bullet = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 30px;
`;

const Svg = styled("svg")`
  position: absolute;
  top: 0;
  left: 0;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  fill: ${theme.reactionListItemBullet.backgroundColor};
`;

const BulletCharacter = styled("span")`
  position: relative;
  font-size: 14px;
  font-weight: ${font.weight.bold};
  color: ${theme.reactionListItemBullet.fontColor};
`;
