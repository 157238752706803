import React, { useRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { animated, useSpring, useTransition } from "react-spring";
import styled from "styled-components";
import { IconSupport } from "icons";
import { font, theme } from "styles";

type Props = { children: string; compact: boolean } & LinkProps;

export const SidebarSupportLink = ({ children, compact, ...linkProps }: Props) => {
  const containerRef = useRef<HTMLAnchorElement>(null);

  const iconWrapperSpringStyle = useSpring({
    transform: compact
      ? "translate3d(20px, 0, 0) rotate3d(0, 0, 1, 45deg)"
      : "translate3d(0px, 0, 0) rotate3d(0, 0, 1, 45deg)",
  });

  const textTransition = useTransition(compact, null, {
    immediate: !containerRef.current, // change opacity without animation if true
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <Container {...linkProps} ref={containerRef}>
      <IconWrapper style={iconWrapperSpringStyle}>
        <IconSupport width={"32px"} height={"32px"} color={theme.sidebarSupportLink.fontColor} />
      </IconWrapper>
      <TextWrapper>
        {textTransition.map(
          ({ item, key, props: style }) =>
            !item && (
              <Text key={key} style={style}>
                {children}
              </Text>
            )
        )}
      </TextWrapper>
    </Container>
  );
};

/**
 * Styles
 */

const Container = styled(Link).withConfig({ shouldForwardProp: (prop) => !["compact"].includes(prop) })<LinkProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 70px;
  padding: 0 25px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin-top: auto;

  &:hover {
    background-color: ${theme.sidebarSupportLink.backgroundColor.hover};
  }
`;

const IconWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 32px;
  width: 32px;
  transform-origin: center center;
  transform: rotate3d(0, 0, 1, 45deg);
`;

const TextWrapper = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  height: 19px;
`;

const Text = styled(animated.span)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  font-size: 16px;
  font-weight: ${font.weight.medium};
  color: ${theme.sidebarSupportLink.fontColor};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
