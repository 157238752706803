import React from "react";
import styled from "styled-components";
import { SelectionDialogTitle } from "components/RecipientSelection/SelectionDialogTitle";
import { SelectionDialogDescription } from "components/RecipientSelection/SelectionDialogDescription";
import { SelectionListItem } from "components/RecipientSelection/SelectionListItem";
import { GroupIcon } from "components/RecipientSelection/GroupIcon";
import { DialogButton } from "components/DialogButton";
import { CloseButton } from "components/CloseButton";
import { DialogScreen } from "components/Dialog";
import { tk, useTranslation } from "translations";

type ContactGroup = {
  id: string;
  name: string;
  private: boolean;
  totalCount: number;
  selectedCount: number;
};

type Props = {
  contactGroups: ContactGroup[];
  visible: boolean;
  onAdd: (id: string) => any;
  onClose: () => any;
  onConfirmation: () => any;
};

export const GroupSelectionDialog = ({ contactGroups, visible, onAdd, onClose, onConfirmation }: Props) => {
  const { t } = useTranslation();

  const renderGroups = () => {
    return contactGroups
      ? contactGroups.map(({ id, name, private: isPrivate, totalCount, selectedCount }) => (
          <SelectionListItem
            key={id}
            icon={<GroupIcon isPrivate={isPrivate} />}
            name={name}
            membersCount={totalCount - selectedCount}
            onClick={() => onAdd(id)}
            dissabled={selectedCount === totalCount}
          />
        ))
      : null;
  };

  if (!visible) return null;

  return (
    <DialogScreen onClick={onClose}>
      <ContentContainer onClick={(e: any) => e.stopPropagation()}>
        <DialogContainer>
          <DialogCloseButton onClick={onClose} />

          <TitleSection>
            <SelectionDialogTitle>{t(tk.recipientsSelection.contactGroupsModal.heading)}</SelectionDialogTitle>
          </TitleSection>

          <DescriptionSection>
            <SelectionDialogDescription>
              {t(tk.recipientsSelection.contactGroupsModal.subheading)}
            </SelectionDialogDescription>
          </DescriptionSection>

          <GroupSelectionSection>{renderGroups()}</GroupSelectionSection>

          <ButtonSection>
            <DialogButton onClick={onConfirmation}>{t(tk.common.ok)}</DialogButton>
          </ButtonSection>
        </DialogContainer>
      </ContentContainer>
    </DialogScreen>
  );
};

/**
 * Styles
 */

const dialogSize = {
  width: "474px",
};

const ContentContainer = styled.div`
  position: relative;
  flex-basis: auto;
  width: ${dialogSize.width};

  &:before,
  &:after {
    content: "";
    display: block;
    width: ${dialogSize.width};
    height: 40px;
  }
`;

const DialogContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: ${dialogSize.width};
  flex-basis: auto;
  background-color: #ffffff;
  border-radius: 5px;
`;

const DialogCloseButton = styled(CloseButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
`;

const dialogSection = `
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const TitleSection = styled.div`
  ${dialogSection};
  padding-top: 34px;
`;

const DescriptionSection = styled.div`
  ${dialogSection};
  padding-top: 10px;
  padding-bottom: 20px;
`;

const GroupSelectionSection = styled.div`
  ${dialogSection};
  border-top: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 340px;
  overflow-y: scroll;
`;

const ButtonSection = styled.div`
  ${dialogSection};
  padding-top: 20px;
  padding-bottom: 24px;
`;
