import React from "react";
import { IconCommonPropsType } from "icons/types";
import { theme } from "styles";

export const IconSupport = ({
  width = "100%",
  color = theme.colors.NightSky,
  height = "100%",
  margin,
}: IconCommonPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31.752 31.752"
      style={{ margin }}
    >
      <path
        id="support"
        d="M29.125,25.813a3.335,3.335,0,0,1-3.313,3.313H24.624a15.439,15.439,0,0,0,4.5-4.5ZM13.5,30.25,14.375,25a6.086,6.086,0,0,0,1.5.125,6.086,6.086,0,0,0,1.5-.125l.875,5.25a15.1,15.1,0,0,1-4.75,0ZM5.938,29.125a3.335,3.335,0,0,1-3.313-3.313V24.624a16.228,16.228,0,0,0,4.5,4.5ZM2.625,5.938A3.335,3.335,0,0,1,5.938,2.625H7.126a17.12,17.12,0,0,0-4.5,4.5ZM18.25,1.563l-.875,5.188a12.041,12.041,0,0,0-1.5-.125,12.235,12.235,0,0,0-1.5.125L13.5,1.563a14.555,14.555,0,0,1,2.375-.25,14.555,14.555,0,0,1,2.375.25Zm7.563,1.062a3.335,3.335,0,0,1,3.313,3.313V7.126a16.241,16.241,0,0,0-4.5-4.5ZM24.688,18.688l5.25.875A14.526,14.526,0,0,1,19.563,29.938l-.875-5.25A9.016,9.016,0,0,0,24.688,18.688Zm0-5.625a9.141,9.141,0,0,0-6-6l.875-5.25A14.526,14.526,0,0,1,29.938,12.188Zm5.75,2.812a14.621,14.621,0,0,1-.188,2.375L25,17.375a6.086,6.086,0,0,0,.125-1.5,6.086,6.086,0,0,0-.125-1.5l5.25-.875A14.63,14.63,0,0,1,30.438,15.875Zm-22.5,0a7.938,7.938,0,1,1,7.938,7.938,7.95,7.95,0,0,1-7.938-7.938Zm-.875,2.813a9.143,9.143,0,0,0,6,6l-.875,5.25A14.526,14.526,0,0,1,1.813,19.563Zm0-5.625-5.25-.875A14.526,14.526,0,0,1,12.188,1.813l.875,5.25a9.273,9.273,0,0,0-6,6Zm-5.75,2.812a14.543,14.543,0,0,1,.25-2.375l5.188.875a12.041,12.041,0,0,0-.125,1.5,12.235,12.235,0,0,0,.125,1.5l-5.188.875a14.555,14.555,0,0,1-.25-2.375Zm30.437,0a15.231,15.231,0,0,0-1.313-6.313V5.937a4.615,4.615,0,0,0-4.625-4.625H22.187A16.282,16.282,0,0,0,15.874,0,16.282,16.282,0,0,0,9.561,1.312H5.936A4.615,4.615,0,0,0,1.311,5.937V9.562A16.282,16.282,0,0,0,0,15.875a16.282,16.282,0,0,0,1.313,6.313v3.625a4.615,4.615,0,0,0,4.625,4.625H9.561a15.231,15.231,0,0,0,6.313,1.313,15.231,15.231,0,0,0,6.313-1.313h3.625a4.615,4.615,0,0,0,4.625-4.625V22.188A15.231,15.231,0,0,0,31.75,15.875Z"
        transform="translate(0.002 0.001)"
        fill={color}
      />
    </svg>
  );
};
