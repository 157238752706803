import React from "react";
import { Hexagon } from "components/Hexagon";
import { theme } from "styles";
import styled from "styled-components";

const groupSymbol = require("assets/images/group.png");
const maskBadge = require("assets/images/mask.png");

interface Props {
  isPrivate?: boolean;
}

export const GroupIcon = ({ isPrivate = false }: Props) => {
  return (
    <Wrapper>
      <Hexagon
        size={{ width: "40px", height: "45px" }}
        color={!isPrivate ? theme.colors.TealGreen : theme.colors.IronGrey}
        imageSource={groupSymbol}
      />
      {isPrivate && <Badge src={maskBadge} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Badge = styled.img`
  position: absolute;
  right: -4px;
  bottom: 2px;
  height: 16px;
  width: 16px;
`;
