import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AnnouncementsParams } from "routes";
import { AnnouncementsLayout } from "layouts";
import { useAnnouncements } from "controllers";
import { Button } from "components";

interface Props extends RouteComponentProps<AnnouncementsParams> {}

export const Announcements = ({ match }: Props) => {
  const { t, tk, data, state, handlers } = useAnnouncements(match.params.tab);

  return (
    <AnnouncementsLayout
      buttons={
        !data.isManager ? null : (
          <Button color={"green"} onClick={handlers.createAnnouncement}>
            {t(tk.announcements.navigation.create)}
          </Button>
        )
      }
      tab={state.tab}
    >
      {null}
    </AnnouncementsLayout>
  );
};
