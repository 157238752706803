import React from "react";
import styled from "styled-components";

// TODO: copied from original moyobo webapp - should be refactored

// noinspection SpellCheckingInspection
const shapes = {
  arrow: "a",
  cancel: "b",
  checked: "d",
  document: "g",
  announcements: "k",
  "star-inactive": "q",
  "star-active": "r",
  search: "u",
  default: "p",
  chat: "c",
  comments: "e",
  compose: "f",
  gallery: "h",
  home: "i",
  "like-active": "j",
  notifications: "k",
  password: "l",
  photo: "m",
  time: "n",
  support: "o",
  streams: "p",
  share: "s",
  settings: "t",
  profile: "v",
  "like-inactive": "w",
  group: "A",
  "comments-empty": "y",
  clap: "x",
  unarchive: "I",
  hexaplus: "D",
  options: "F",
  hexaminus: "E",
  calendar: "G",
  archive: "H",
  "contact-call": "B",
  "contact-chat": "C",
  delete: "z",
  "icon-urged": "K",
  "icon-change": "L",
  "icon-edit": "M",
  "icon-mute": "N",
  "icon-no-reaction": "O",
  "icon-sms": "P",
};

export type IconShape = keyof typeof shapes;
type Props = {
  shape: IconShape;
  color?: string;
  size?: string;
  cursor?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const StyledIcon = styled.span<Props>`
  font-family: "moyobo", sans-serif;
  font-size: ${(props) => (props.size ? props.size : "12px")};
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  cursor: ${(props) => (props.cursor ? props.cursor : "default")};
`;

export class Icon extends React.PureComponent<Props> {
  render() {
    return <StyledIcon {...this.props}>{shapes[this.props.shape] || shapes.default}</StyledIcon>;
  }
}
