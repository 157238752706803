import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useDashboardState, useMedia, useRecipientSelection } from "hooks";
import { tk, useTranslation } from "translations";
import { DocumentsTabs, getRoute } from "routes";
import { MediaTypes, resolveError, useDocumentCreateMutation } from "api";

export const useDocumentsCreate = (tab: DocumentsTabs) => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.common.documents) + " " + t(tk.common.documentTitleSuffix));

  const { organization, space } = useDashboardState();

  const media = useMedia(MediaTypes.Document, false, true);

  const [documentCreate, { loading: loadingDocumentCreate }] = useDocumentCreateMutation();

  const { create } = useRecipientSelection("");
  const [recipientSelection, setRecipientSelection] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (recipientSelection) return;
    create(space).then((id) => setRecipientSelection(id));
  }, [create, recipientSelection, space]);

  const loading = useMemo(() => media.loading || loadingDocumentCreate, [loadingDocumentCreate, media.loading]);

  const [dialog, setDialog] = useState<"progress" | "success" | "error" | undefined>(undefined);
  const [createdDocumentId, setCreatedDocumentId] = useState<string | undefined>(undefined);

  const [title, setTitle] = useState("");
  const [selected, setSelected] = useState(0);

  const [titleError, setTitleError] = useState("");
  const [fileError, setFileError] = useState("");
  const [selectedError, setSelectedError] = useState("");

  const submit = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (loading || !recipientSelection) return;

      if (!title.trim().length) return setTitleError(t(tk.documentsCreate.form.title.required));

      if (!media.preview) return setFileError(t(tk.documentsCreate.form.file.required));

      if (!selected) return setSelectedError(t(tk.documentsCreate.form.recipients.required));

      setDialog("progress");

      try {
        const mediaId = await media.upload();

        if (!mediaId) return setDialog("error");

        const { data } = await documentCreate({
          variables: { input: { space, title, media: mediaId, recipientSelection } },
        });

        setCreatedDocumentId(data?.documentCreate.document.id);
        setDialog("success");
      } catch (e: any) {
        setDialog("error");
        resolveError(e);
      }
    },
    [documentCreate, loading, media, recipientSelection, selected, space, t, title]
  );

  const cancel = useCallback(() => history.goBack(), [history]);

  const closeDialog = useCallback(() => setDialog(undefined), []);

  const goToSent = useCallback(() => {
    if (createdDocumentId) {
      return history.replace(getRoute.documentsDetail({ organization, space, tab, id: createdDocumentId }));
    }

    history.replace(getRoute.documents({ organization, space, tab: "sent" }));
  }, [createdDocumentId, history, organization, space, tab]);

  const handleChangeTitle = useCallback(setTitle, [setTitle]);

  const handleChangeRecipientSelection = useCallback(setSelected, [setSelected]);

  useEffect(() => setTitleError(""), [title]);
  useEffect(() => setFileError(""), [media.preview]);
  useEffect(() => setSelectedError(""), [selected]);

  return {
    t,
    tk,
    media,
    data: { recipientSelection },
    state: { tab, title, titleError, fileError, selectedError, loading, dialog },
    handlers: { submit, cancel, handleChangeTitle, handleChangeRecipientSelection, closeDialog, goToSent },
  };
};
